import { queryBuilder } from '../utils/dxf';

import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

export const buildFetchExternalQuery = (scopeId, label) => queryBuilder({
  variables: {
    filter: { type: 'JSON' },
    from: { type: 'Int' },
    limit: { type: 'Int' },
    ordering: { type: 'JSON' },
  },
  fields: {
    [`filter${capitalizeFirstLetter(scopeId)}`]: {
      varArgs: {
        filter: 'filter',
        from: 'from',
        limit: 'limit',
        ordering: 'ordering',
      },
      fields: {
        filteredCount: true,
        from: true,
        limit: true,
        ordering: true,
        pagedCount: true,
        items: {
          fields: {
            // Assuming id is always the identifier.
            id: true,
            // How will we derive the label field.
            [label]: true,
          },
        },
      },
    },
    [`${scopeId}Count`]: true,
  },
});

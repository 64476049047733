import PropTypes from 'prop-types';

/**
 * @see [OrderField](cargo-universal/dxSchema/typedefs/OrderField)
 */
export const orderingEntryPropTypes = {
  /**
   * The order direction, either `asc` or `desc`. Default is 'asc'.
   */
  direction: PropTypes.oneOf(['asc', 'desc']),

  /**
   * The id of the field to order against.
   */
  field: PropTypes.string.isRequired,

  /**
   * Specifies whether null values should come first or last in the ordered values. Default is 'last'.
   */
  nulls: PropTypes.oneOf(['first', 'last']),

  /**
   * Sort in a case-insensitive manner. Default is false.
   */
  caseInsensitive: PropTypes.bool,
};

/**
 * @see [Ordering](cargo-universal/dxSchema/typedefs/Ordering)
 * @see [createPageLoadStateReducer](react-frontend/reducers)
 * @see Methods such as _getCollection_ in [Controller](cargo-service/dxControllers/Controller).
 */
export const orderingPropType = PropTypes.arrayOf(PropTypes.shape(orderingEntryPropTypes));

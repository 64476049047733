/* eslint-disable no-shadow */
/* eslint-disable no-multi-assign */
import forIn from 'lodash/forIn';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import { DxScalarType } from '../dxSchema/DxScalarType';
import { Activity } from '../auth';

import { isString } from '../isString';

// -- To Dos ----------- --- -- -

// TODO: Derive field constraints from GraphQL schema.
// TODO: Refactor as DxQuery class.
// TODO: Update the auth rule inference such that it infers scoped rules where appropriate and
//   re-enable the _authRule_ property.

// -- Typedefs ----------- --- -- -

/**
 * @typedef {object} DxQuerySpec
 *
 * @property {object.<string, DxVariableSpec>} [variables] - Variable declarations.
 * @property {object.<string, DxQueryFieldSpec>} [fields] - Selection specs.
 *
 * @example
 *  const querySpec = {
 *    variables: {
 *      id: { type: 'String', required: true }
 *    },
 *    fields: {
 *      employee: {
 *        args: { id: id },
 *        fields: {
 *          name: true,
 *          address: {
 *            fields: {
 *              id: true,
 *              street_name: true,
 *            },
 *          },
 *        },
 *      },
 *    },
 *  };
 *
 * <caption>The queryBuilder will yield the following GraphQL query string for this example:</caption>
 *
 * @example
 * query ($id: String!) {
 *   employee(id: $id) {
 *     name,
 *     address {
 *       id,
 *       street_name
 *     }
 *   }
 * }
 */

/**
 * @typedef {object} DxQueryFieldSpec
 * @property {object.<string, *>} [args] - GraphQL field arguments as a string to value map.
 * @property {object.<string, string>} [varArgs] - Arguments with variables.
 */

/**
 * @typedef {object} DxVariableSpec
 * @property {string} type - One of the allowed GraphQL types, i.e. 'String', 'Int', etc.
 *   @see http://graphql.org/graphql-js/basic-types/
 * @property {boolean} [required] - Adds the '!' to the GraphQL type and checks that it is provided.
 * @property {*} [default] -
 */

// -- API Functions ----------- --- -- -

/**
 * Builds a GraphQL query for a dxAPI field using regular javascript objects.
 *
 * The result will be validated before being submitted. TODO
 *
 * ## dxQueryBuilder Functions
 *
 * - _dxQueryBuilder_: build a GraphQL query for dxAPIs.
 * - _queryBuilder_: build a GraphQL query for other APIs.
 * - _dxMutationBuilder_: build a GraphQL mutation for dxAPIs.
 * - _mutationBuilder_: build a GraphQL mutation for other APIs.
 *
 * @param {dxSchema|DxSchemaBase} schema
 * @param {DxQuerySpec} query
 * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @returns {DxQuery}
 */
export function dxQueryBuilder(schema, query, authRule) {
  if (!schema.isDxSchema) {
    throw new Error(`Expected a dxSchema as first argument, instead got "${schema}".`);
  }
  if (!isObject(query)) {
    throw new Error(`Expected a query declaration object as second argument, instead got "${query}".`);
  }
  initFieldTypes(schema);
  return buildQuery(query, false, queryRootFieldTypes, true, authRule);
}

/**
 * Builds a GraphQL mutation for a dxAPI field using regular javascript objects.
 *
 * The result will be validated before being submitted. TODO
 *
 * ## dxQueryBuilder Functions
 *
 * - _dxQueryBuilder_: build a GraphQL query for dxAPIs.
 * - _queryBuilder_: build a GraphQL query for other APIs.
 * - _dxMutationBuilder_: build a GraphQL mutation for dxAPIs.
 * - _mutationBuilder_: build a GraphQL mutation for other APIs.
 *
 * @param {dxSchema/DxSchemaBase} schema
 * @param {DxQuerySpec} query
 * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @returns {DxQuery}
 */
export function dxMutationBuilder(schema, query, authRule) {
  if (!schema.isDxSchema) {
    throw new Error(`Expected a dxSchema as first argument, instead got "${schema}".`);
  }
  if (!isObject(query)) {
    throw new Error(`Expected a query declaration object as second argument, instead got "${query}".`);
  }
  initFieldTypes(schema);
  return buildQuery(query, true, mutationRootFieldTypes, true, authRule);
}

/**
 * Builds a GraphQL query for other APIs using regular javascript objects.
 *
 * ## dxQueryBuilder Functions
 *
 * - _dxQueryBuilder_: build a GraphQL query for dxAPIs.
 * - _queryBuilder_: build a GraphQL query for other APIs.
 * - _dxMutationBuilder_: build a GraphQL mutation for dxAPIs.
 * - _mutationBuilder_: build a GraphQL mutation for other APIs.
 *
 * @param {DxQuerySpec} query
 * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @returns {DxQuery}
 */
export function queryBuilder(query, authRule) {
  if (query.isDxSchema) {
    throw new Error('The queryBuilder method no longer takes a dxSchema as first argument.');
  }
  if (!isObject(query)) {
    throw new Error(`Expected a query declaration object as first argument, instead got "${query}".`);
  }
  return buildQuery(query, false, queryRootFieldTypes, false, authRule);
}

/**
 * Builds a GraphQL mutation for other APIs using regular javascript objects.
 *
 * ## dxQueryBuilder Functions
 *
 * - _dxQueryBuilder_: build a GraphQL query for dxAPIs.
 * - _queryBuilder_: build a GraphQL query for other APIs.
 * - _dxMutationBuilder_: build a GraphQL mutation for dxAPIs.
 * - _mutationBuilder_: build a GraphQL mutation for other APIs.
 *
 * @param {DxQuerySpec} query
 * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @returns {DxQuery}
 */
export function mutationBuilder(query, authRule) {
  if (query.isDxSchema) {
    throw new Error('The mutationBuilder method no longer takes a dxSchema as first argument.');
  }
  if (!isObject(query)) {
    throw new Error(`Expected a query declaration object as first argument, instead got "${query}".`);
  }
  return buildQuery(query, true, mutationRootFieldTypes, false, authRule);
}

// -- Setup - Output Types --------------- --- -- -

/// output types:
const DX_QUERY = 'DX_QUERY';
const DX_ITEM = 'DX_ITEM';
const DX_ITEMSET = 'DX_ITEMSET';
const DX_RELATEE = 'DX_RELATEE';
const DX_RELATEES = 'DX_RELATEES';
const DX_PROP = 'DX_PROP';
const DX_FILTER_RESULT = 'DX_FILTER_RESULT';
// const DX_FILTER_ITEMS = 'DX_FILTER_ITEMS';
const DX_MUTATEE = 'DX_MUTATEE';
const DX_MUTATION_RESULT = 'DX_MUTATION_RESULT';

// -- Setup - Field Types --------------- --- -- -

const dxMutationFieldType = new Map([
  ['reason', { output: DX_PROP }],
  ['stack', { output: DX_PROP }],
  ['success', { output: DX_PROP }],
]);

/**
 * Maps root query field names to field types.
 * @type {Map.<string, {}>}
 */
const queryRootFieldTypes = new Map();

/**
 * Maps collection ids to query field names to field types.
 * @type {Map.<string, Map.<string, {}>>}
 */
const queryCollFieldTypes = new Map();

/**
 * Maps root mutation field names to field types.
 * @type {Map.<string, {}>}
 */
const mutationRootFieldTypes = new Map();

/**
 * Maps collection ids to mutation field names to field types.
 * @type {Map.<string, {}|Map>}
 */
const mutationCollFieldTypes = new Map();

const filterResponseTypesByCollId = new Map();

function initQueryRootFieldTypes(schema) {
  const types = queryRootFieldTypes;
  schema.forEachCollection((collSchema) => {
    if (!collSchema.apiTerms) {
      throw new Error(`The apiTerms are not provided for the "${collSchema.id}" collection.`);
    }
    const { plural, singular } = collSchema.apiTerms;
    const auth = collSchema.auth.view || new Activity(collSchema.id, 'view'); // TODO: disabled
    types.set(plural, {
      acceptsArgs: true,
      auth,
      hasFields: true,
      output: DX_ITEMSET,
      schema: collSchema,
    });
    types.set(singular, {
      acceptsArgs: true,
      auth,
      hasFields: true,
      output: DX_ITEM,
      requiredArgs: ['id'],
      schema: collSchema,
    });
    types.set(`${plural}Count`, {
      auth,
    });
    types.set(`has${collSchema.singularCapped}`, {
      acceptsArgs: true,
      auth,
      requiredArgs: ['id'],
    });
    types.set(`filter${collSchema.pluralCapped}`, {
      acceptsArgs: true,
      auth,
      hasFields: true,
      output: DX_FILTER_RESULT,
      schema: collSchema,
    });

    filterResponseTypesByCollId.set(collSchema.id, new Map(Object.entries({
      filteredCount: { output: DX_PROP },
      from: { output: DX_PROP },
      items: {
        hasFields: true,
        output: DX_ITEMSET,
        schema: collSchema,
      },
      limit: { output: DX_PROP },
      ordering: { output: DX_PROP },
      pagedCount: { output: DX_PROP },
      reason: { output: DX_PROP },
      success: { output: DX_PROP },
    })));
  });
}

function initQueryCollFieldTypes(schema) {
  schema.forEachCollection((collSchema) => {
    const types = new Map();

    // Add property fields:
    collSchema.forEachField((field, fieldId) => {
      const fieldType = {
        output: DX_PROP,
        schema: field,
      };
      const dxScalarType = DxScalarType.getType(field.type);
      if (dxScalarType.isSerialized) {
        fieldType.deserialize = dxScalarType.deserialize;
      }
      types.set(fieldId, fieldType);
    });

    // Add relatee(s) fields:
    collSchema.forEachRelateeSchema((relateeSchema, fieldId) => {
      const { coCollectionSchema } = relateeSchema;
      const auth = coCollectionSchema.auth.view || `${coCollectionSchema.id}::view`; // TODO: disabled
      if (relateeSchema.arityOne) {
        types.set(fieldId, {
          auth,
          hasFields: true,
          output: DX_RELATEE,
          schema: relateeSchema,
        });
      } else {
        types.set(fieldId, {
          acceptsArgs: true,
          auth,
          hasFields: true,
          output: DX_RELATEES,
          schema: relateeSchema,
        });
        types.set(`${fieldId}Count`, {
          auth,
        });
      }
    });

    queryCollFieldTypes.set(collSchema.id, types);
  });
}

function initMutationRootFieldTypes(schema) {
  const types = mutationRootFieldTypes;
  schema.forEachCollection((collSchema) => {
    const { singular } = collSchema.apiTerms;
    types.set(`create${collSchema.singularCapped}`, {
      acceptsArgs: true,
      auth: collSchema.auth.create || {
        or: [new Activity(collSchema.id, 'manage'), `${collSchema.id}::create_own`],
      },
      hasFields: true,
      output: DX_ITEM,
      requiredArgs: collSchema.mapRequiredFields((field) => field.id),
      schema: collSchema,
    });
    types.set(singular, {
      acceptsArgs: true,
      auth: collSchema.auth.update || new Activity(collSchema.id, 'update'),
      hasFields: true,
      output: DX_MUTATEE,
      requiredArgs: ['id'],
      schema: collSchema,
    });
    types.set(`delete${collSchema.pluralCapped}`, {
      auth: collSchema.auth.delete || new Activity(collSchema.id, 'manage'),
      acceptsArgs: true,
      hasFields: true,
      output: DX_MUTATION_RESULT,
      requiredArgs: ['ids'],
      schema: collSchema,
    });
  });
}

function initMutationCollFieldTypes(schema) {
  schema.forEachCollection((collSchema) => {
    const types = new Map();

    types.set('update', {
      auth: collSchema.auth.update || new Activity(collSchema.id, 'update'),
      acceptsArgs: true,
      hasFields: true,
      output: DX_ITEM,
      schema: collSchema,
    });
    types.set('delete', {
      auth: collSchema.auth.delete || new Activity(collSchema.id, 'manage'),
      hasFields: true,
      output: DX_MUTATION_RESULT,
    });

    // Add relatee getters and setters:
    collSchema.forEachRelatee((relatee) => {
      const { coCollection } = relatee;
      const { fieldIdCapped } = relatee;
      const update = coCollection.auth.update || `${coCollection.id}::update`;
      if (relatee.arityOne) {
        types.set(`set${fieldIdCapped}`, {
          acceptsArgs: true,
          auth: update,
          hasFields: true,
          output: DX_MUTATION_RESULT,
          requiredArgs: ['id'],
        });
        if (!relatee.isRequired) {
          types.set(`remove${fieldIdCapped}`, {
            auth: update,
            hasFields: true,
            output: DX_MUTATION_RESULT,
          });
        }
      } else {
        types.set(`add${fieldIdCapped}`, {
          acceptsArgs: true,
          auth: update,
          hasFields: true,
          output: DX_MUTATION_RESULT,
          requiredArgs: ['ids'],
        });
        if (relatee.coArityMany || !relatee.coRelatee.isRequired) {
          types.set(`remove${fieldIdCapped}`, {
            acceptsArgs: true,
            auth: update,
            hasFields: true,
            output: DX_MUTATION_RESULT,
            requiredArgs: ['ids'],
          });
        }
      }
    });

    mutationCollFieldTypes.set(collSchema.id, types);
  });
}

let fieldTypesArePrepped = false;

/**
 * @private
 * @param schema
 */
function initFieldTypes(schema) {
  if (!fieldTypesArePrepped) {
    initQueryRootFieldTypes(schema);
    initQueryCollFieldTypes(schema);
    initMutationRootFieldTypes(schema);
    initMutationCollFieldTypes(schema);
    fieldTypesArePrepped = true;
  }
}

// -- System Functions ----------- --- -- -

/**
 * Field metadata.
 * @typedef {object} DxQueryFieldMetadata
 * @property {boolean} acceptsArgs - True when this field accepts arguments.
 * @property {object.<string, *>} [args] - GraphQL field arguments as a string to value map.
 // * @property {AuthRule} [auth] - The auth-rule that hold on this field.
 * @property {object.<string, DxQueryFieldMetadata>} [fields] - Selection fields in the given
 *   dxQuery.
 * @property {boolean} hasFields - True when this field has sub-fields.
 * @property {string} output - The output type of this field.
 * @property {string} path -
 * @property {Array.<string>} [requiredArgs] - The required arguments for this field.
 * @property {CollectionSchema} [schema] - The schema for the collection this field is related to.
 * @property {object.<string, string>} [varArgs] - Arguments with variables.
 * @property {object.<string, DxVariableSpec>} [variables] - Variables in the given dxQuery.
 */

/**
 * @private
 * Builds and returns a dxQuery.
 * @param {DxQuerySpec} dxQuery - The dxQuery specification.
 * @param {boolean} isMutation - True when the query is a mutation.
 * @param {Map} rootFieldTypes
 * @param {boolean} forDxApi - True when the query is meant for the standard dxAPI.
 // * @param {AuthRule} [authRule] - The authorization rule to use instead of the rule inferred from
 *   the query.
 * @param {object} [options] - Options.
 * @param {boolean} [options.logDeserializer] - Log the deserializer.
 * @returns {DxQuery}
 */
function buildQuery(dxQuery, isMutation, rootFieldTypes, forDxApi) {
  /** @type DxQueryFieldMetadata */
  const metadata = {
    ...dxQuery, hasFields: true, output: DX_QUERY, path: ''
  };
  try {
    preprocess(
      metadata.fields,
      metadata.path,
      forDxApi ? rootFieldTypes : null,
      metadata.variables !== undefined,
      forDxApi,
    );
    return {
      // authRule: authRule || getAuthRule(metadata.fields),
      get authRule() {
        throw new Error('The "dxController.authRule" property is no longer supported');
      },
      get authConstraints() {
        throw new Error('The "dxController.authConstraints" property is no longer supported');
      },
      deserializer: getDeserializer(metadata) || ((x) => x),
      isDxQuery: true,
      isMutation,
      queryString: getQueryString(metadata, isMutation),
      varSpecs: metadata.variables || {},
      variables: {},
      metadata,
    };
  } catch (error) {
    const msg = `The queryBuilder failed: ${error.message}`;
    console.error(msg);
    console.error('The given query :', metadata);
    throw new Error(msg);
  }
}

// TODO: consider making immutable
function preprocess(fields, parentPath, fieldTypes, hasVariables, forDxApi = false) {
  forIn(fields, (field, fid) => {
    const path = parentPath === '' ? fid : `${parentPath}.${fid}`;

    // Provide proper field-specs for scalar field (such as `name: true`):
    if (isBoolean(field)) {
      if (!field) {
        throw new Error(`Unexpected "false" value for "${path}".`);
      }
      fields[fid] = field = {};
    }

    field.id = fid;
    field.path = path;

    if (forDxApi) {
      if (!fieldTypes.has(fid)) {
        throw new Error(`Unexpected field "${path}".`);
      }

      // Add additional type metadata in field-spec:
      Object.assign(field, fieldTypes.get(fid));

      // Assert various requirements:
      if (!field.hasFields && field.fields) {
        throw new Error(`Unexpected "fields" for "${path}".`);
      }
      if (field.hasFields && !field.fields) {
        throw new Error(`Expected "fields" for "${path}".`);
      }
      if (!field.acceptsArgs && (field.args || field.varArgs)) {
        throw new Error(`Unexpected arguments for "${path}".`);
      }
      if (field.requiredArgs) {
        field.requiredArgs.forEach((argId) => {
          if (!(field.args && field.args[argId]) && !(field.varArgs && field.varArgs[argId])) {
            throw new Error(`Expected "${argId}" argument for "${path}".`);
          }
        });
      }
      if (!hasVariables && field.varArgs) {
        throw new Error(`Unexpected variable arguments for "${path}".`);
      }
    }

    // Recursively pre-process fields:
    if (field.fields) {
      let nextFieldTypes;

      if (forDxApi) {
        if (field.output === DX_RELATEE || field.output === DX_RELATEES) {
          nextFieldTypes = queryCollFieldTypes.get(field.schema.coCollectionId);
        } else if (field.output === DX_MUTATEE) {
          nextFieldTypes = mutationCollFieldTypes.get(field.schema.id);
        } else if (field.output === DX_ITEM || field.output === DX_ITEMSET) {
          nextFieldTypes = queryCollFieldTypes.get(field.schema.id);
        } else if (field.output === DX_MUTATION_RESULT) {
          nextFieldTypes = dxMutationFieldType;
        } else if (field.output === DX_FILTER_RESULT) {
          nextFieldTypes = filterResponseTypesByCollId.get(field.schema.id);
        } else {
          throw new Error(`TODO: get nextFieldTypes for output type "${field.output}".`);
        }
      }

      // console.log(`- nextFieldTypes for '${field.id}' (${field.output}):\n`, [...nextFieldTypes.keys()]);
      preprocess(field.fields, path, nextFieldTypes, hasVariables, forDxApi);
    }
  });
}

const trimStringify = (json) => JSON.stringify(json).substr(0, 64);

/**
 * @private
 * Get the deserializer for the given query.
 * @param {DxQuerySpec} query - The dxQuery specification.
 * @param {boolean} log - Log the deserializer.
 * @return {Function} A function that takes the to be serialized data object as argument and returns
 *   the deserialized data.
 */
function getDeserializer(query, log = false) {
  if (log) {
    console.trace('>> getDeserializer()');
    const _deserializer = _getDeserializer(query, log, '  ');
    return (data) => {
      console.trace(`>> deserialize : ${trimStringify(data)}`);
      if (_deserializer) {
        // console.trace(` - _deserializer: ${_deserializer}`);
        const result = _deserializer(data, '  ');
        console.trace(`<< ${trimStringify(result)}`);
        return result;
      }

      console.trace('<< [same as input]');
      return data;
    };
  }

  const _deserializer = _getDeserializer(query);
  return (data) => (_deserializer ? _deserializer(data, '  ') : data);
}

function _getDeserializer(field, log = false, indent = '') {
  if (log) { console.trace(`${indent}>> _getDeserializer - ${field.id}, ${field.output}`); }
  const { output = null } = field;
  if (output === DX_PROP) {
    if (field.deserialize && log) {
      console.trace(`${indent}<< ${field.deserialize}`);
      return (value) => {
        console.trace(`${indent}>> deserialize scalar "${field.id}":`, value);
        const result = field.deserialize(value);
        console.trace(`${indent}   to:`, result);
        console.trace(`${indent}   using: "${field.deserialize}"`);
        return result;
      };
    }
    return field.deserialize;
  }
  if ([DX_ITEM, DX_MUTATEE, DX_QUERY, DX_RELATEE].includes(output)) {
    const deserializers = getDeserializers(field.fields, log, `${indent}  `);
    if (deserializers && deserializers.size > 0) {
      return makeObjectDeserializer(deserializers, log, indent);
    }
  } else if (output === DX_ITEMSET || output === DX_RELATEES) {
    const deserializers = getDeserializers(field.fields, log, `${indent}  `);
    if (deserializers && deserializers.size > 0) {
      const objectDeserializer = makeObjectDeserializer(deserializers, log, indent);
      if (log) {
        return (items, indent) => {
          console.trace(`${indent}>> deserialize items: ${trimStringify(items)}`);
          return items.map((item) => {
            const result = objectDeserializer(item, `${indent}  `);
            console.trace(`${indent}<< ${trimStringify(trimStringify(result))}`);
            return result;
          });
        };
      }

      return (items) => items.map(objectDeserializer);
    }
  }
  return null;
}

const makeObjectDeserializer = (deserializers, log, indent) => {
  if (log) {
    console.trace(`${indent}>> makeObjectDeserializer`);
    return ((dataObj, indent) => {
      console.trace(`${indent}>> deserialize object: ${trimStringify(dataObj)}`);
      const resultObj = { ...dataObj };
      deserializers.forEach((deserialize, fieldId) => {
        if (dataObj[fieldId] !== undefined) {
          const result = deserialize(dataObj[fieldId], `${indent}  `);
          console.trace(`${indent}  << ${fieldId}: ${trimStringify(result)}`);
          resultObj[fieldId] = result;
        }
      });
      console.trace(`${indent}<< ${trimStringify(resultObj)}`);
      return resultObj;
    });
  }

  return ((dataObj) => {
    const resultObj = { ...dataObj };
    deserializers.forEach((deserialize, fieldId) => {
      if (dataObj[fieldId] !== undefined) {
        resultObj[fieldId] = deserialize(dataObj[fieldId]);
      }
    });
    return resultObj;
  });
};

function getDeserializers(fields, log, indent) {
  if (log) { console.trace(`${indent}>> getDeserializers`); }
  let deserializers = null;
  forIn(fields, (field, fid) => {
    // if (log) { console.trace(`${indent} -> ${fid}`); }
    const deserializer = _getDeserializer(field, log, `${indent}  `);
    // if (log) { console.trace(`${indent} <- got: ${deserializer}`); }
    if (deserializer) {
      if (!deserializers) { deserializers = new Map(); }
      deserializers.set(fid, deserializer);
    }
  });
  return deserializers;
}

function getQueryString(query, isMutation) {
  const { operation, variables } = query;
  let qstr = isMutation ? 'mutation ' : 'query ';

  // Add operation:
  if (operation) {
    qstr = `${qstr}${operation}`;
  }

  // Add variables declaration:
  if (variables) {
    qstr = `${qstr}${getVariablesDeclarationString(variables, 98 - qstr.length)}`;
  }

  // Add fields:
  return `${qstr}{\n${getFieldsStr(query.fields, 1)}\n}`;
}

function getVariablesDeclarationString(variables, maxLength) {
  const varStrings = Object.keys(variables).map((name) => getVariableDeclarationString(variables[name], name));
  const slstr = `(${varStrings.join(', ')}) `;
  if (slstr.length <= maxLength) {
    return slstr;
  }

  return `(\n  ${varStrings.join(',\n  ')}\n)\n`;
}

function getVariableDeclarationString({ default: defaultValue, required = false, type }, name) {
  if (!type) {
    throw new Error(`Expected "type" property for variable "${name}".`);
  }
  let vstr = `$${name}: ${type}`;
  if (required) { vstr = `${vstr}!`; }
  if (defaultValue !== undefined) {
    // TODO: serialize non-trivial default value
    vstr = isString(defaultValue) ? `${vstr} = "${defaultValue}"` : `${vstr} = ${defaultValue}`;
  }
  return vstr;
}

function getFieldsStr(masterFields, indent) {
  return Object.keys(masterFields).map((fid) => {
    const { args, fields, varArgs } = masterFields[fid];
    const __ = '  '.repeat(indent);
    let qstring = `${__}${fid}`;

    // Add arguments:
    if (args || varArgs) {
      const argStrings = [];
      forIn(args, (value, arg) => {
        const valueStr = isString(value) ? `"${value}"` : value;
        argStrings.push(`${arg}: ${valueStr}`);
      });
      forIn(varArgs, (varId, arg) => {
        argStrings.push(`${arg}: $${varId}`);
      });
      if (argStrings.length > 0) {
        qstring = `${qstring}(${argStrings.join(', ')})`;
      }
    }

    // Recursively include fields:
    if (fields) {
      const fstr = getFieldsStr(fields, indent + 1);
      return `${qstring} {\n${fstr}\n${__}}`;
    }

    return qstring;
  }).join(',\n');
}

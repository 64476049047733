import PropTypes from 'prop-types';

/**
 * A [User](/auth/User) object.
 */
export const userPropTypes = {
  activities: PropTypes.arrayOf(PropTypes.string).isRequired,
  dxToken: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  info: PropTypes.object,
};

/**
 * A [User](/auth/User) object.
 */
export const userPropType = PropTypes.shape(userPropTypes);

import invariant from 'invariant';
import isObject from 'lodash/isObject';
import { dxConfig } from '../dxConfig';

// -- Config Selectors --------------- --- --  -

const providerConfigMap = dxConfig.get('duxis.auth.identityProviders');

export const getProviderConfig = (name) => {
  const config = providerConfigMap[name];
  if (config) { return config; }
  throw new Error(`There is no "${name}" provider config.`);
};

export const getEnabledProviderNames = () => Object.keys(providerConfigMap).filter((name) => providerConfigMap[name].enable);

// -- State Selectors --------------- --- --  -

export const getDxAuthState = (state) => state.dxAuth;

export const getDxAuthExpired = (state) => !getDxAuthState(state).authenticated
    || (getDxAuthState(state).expiresAt < (new Date().getTime() / 1000));

export const getDxAuthenticated = (state, checkExpiration = true) => {
  const authState = getDxAuthState(state);
  const expired = checkExpiration ? authState.expiresAt <= (new Date().getTime() / 1000) : false;
  return authState.authenticated && !expired;
};

export const getDxAuthError = (state) => getDxAuthState(state).dxError;
export const getDxAuthFailed = (state) => getDxAuthState(state).failed;
export const getDxAuthProviderId = (state) => getDxAuthState(state).providerId;
export const getDxAuthProviderState = (state) => getDxAuthState(state).providerState;
export const getDxAuthExpiresAt = (state) => (getDxAuthenticated(state) ? getDxAuthState(state).expiresAt : null);
export const getDxAuthTargetPath = (state) => getDxAuthState(state).targetPath || '/';

/**
 * Gets the current user object.
 *
 * When you inject a user object as a prop-type in a React component, you can use the
 * {@link userPropType} as prop-type specification.
 * @see userPropType
 * @param {object} state - The Redux state.
 * @returns {object} The current user identity object.
 */
export const getDxAuthUser = (state) => {
  invariant(isObject(state), `Expected object as state, got "${state}".`);
  return getDxAuthState(state).user;
};

/**
 * @param {object} state
 * @returns {string} The dxToken (provided by the dxAuth service).
 */
export const getDxAuthToken = (state) => {
  invariant(isObject(state), `Expected object as state, got "${state}".`);
  if (!getDxAuthenticated(state)) {
    const error = new Error('The current user is not (yet) authenticated.');
    error.status = 401;
    throw error;
  }
  return getDxAuthUser(state).dxToken;
};

import PropTypes from 'prop-types';

// TODO: add more dxSchema prop-types

// -- relateeSchemaPropTypes --------------- ---  --  -

export const relateeSchemaPropTypes = {
  coCollectionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  // TODO: add props
};

export const relateeSchemaPropType = PropTypes.shape(relateeSchemaPropTypes);

// -- Routing Selectors --------------- --- --  -

/**
 * The _location_ object in the _[react-router-redux](https://github.com/reactjs/react-router-redux)_
 * state.
 * @typedef {object} RoutingLocation
 * @property {string} hash
 * @property {string} key
 * @property {string} pathname - The path component of the location, e.g. `/manuals/latest` in
 *   `http://docs.duxis.io/manuals/latest`.
 * @property {string} search - The query-string component of the location.
 */

/**
 * Selects the _[react-router-redux](https://github.com/reactjs/react-router-redux)_ state.
 * @param {{}} state
 * @return {{ location: RoutingLocation }}
 * @see {@link selectRoutingLocation}
 */
export const getRoutingState = (state) => state.routing;

/**
 * Selects the _location_ object from the _[react-router-redux](https://github.com/reactjs/react-router-redux)_
 * state.
 * @param {{}} state
 * @return {RoutingLocation}
 */
export const getRoutingLocation = (state) => getRoutingState(state).location;

// -- Form Selectors --------------- --- --  -

/**
 * Selects the redux-form state.
 * @param {{ form: object }} state
 * @return {{}} redux-form state
 */
export const getFormState = (state) => state.form;

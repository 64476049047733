import {
  mutationBuilder,
  getUpdateArgs,
  getUpdateVars,
} from '../utils/dxf';

export const buildUpdateUserQuery = (fields, { collSchema }) => {
  const { last_login: lastLogin, ...varArgs } = getUpdateArgs(collSchema, { includeRelatees: true });
  const { last_login, ...rest } = getUpdateVars(collSchema, { includeRelatees: true });
  const updateVars = {
    ...rest,
    provider: { type: 'String!' },
    username: { type: 'String!' },
    roles: { type: '[String]' },
    scoped_rights: { type: '[String]' },
  };
  return mutationBuilder({
    variables: updateVars,
    fields: {
      user: {
        varArgs: { id: 'id' },
        fields: {
          update: {
            varArgs,
            fields,
          },
        },
      },
    },
  });
};

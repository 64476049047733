import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../propTypes';

// -- Styles --------------- --- --  -

/**
 * @private
 * The margins take care of always have a 2-pixel margin between sections.
 */
const baseStyles = css({
  alignItems: 'stretch',
  display: 'flex',
  flexFlow: 'column nowrap',
  margin: '1px -1px -1px -1px',
  '> .dx-section': { margin: '1px' },
});

// -- Component --------------- --- --  -

/**
 * Content-carrying child of Chapter.
 */
export const Module = ({ children, className, styles }) => (
  <div className={classnames('dx-module', className)} {...css(baseStyles, styles)}>{children}</div>
);

Module.propTypes = {
  /** The module content. */
  children: PropTypes.node,

  /** Optional additional class name. */
  className: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,
};

import {
  GraphQLBoolean,
  GraphQLID,
  GraphQLInt,
  GraphQLList,
  GraphQLNonNull,
  GraphQLObjectType,
} from 'graphql';
import isObject from 'lodash/isObject';
import { parseOrdering } from '../dxSchema/utils';
import { asyncForEach } from '../async';
import { itemsetInputFields } from './itemsetFields';

/**
 * GraphQL output object type for dxSchema collections.
 */
export class GraphQLCollection extends GraphQLObjectType {
  /**
   * @param {dxSchema/CollectionSchema} collSchema - The collection schema.
   * @param {object} [customFields] An object with custom output fields to add in output type.
   * @param {string} [name] If you provide custom fields, then you also need to provide a custom
   *   name for the resulting output type object.
   */
  constructor(collSchema, customFields, name) {
    if (customFields !== undefined && !isObject(customFields)) {
      throw new Error(`Expected an object as "customFields", got "${customFields}".`);
    }
    super({
      description: collSchema.description || collSchema.label,
      name: (customFields ? name : collSchema.singularCapped),
      fields: () => collectionFields(collSchema, customFields)
    });
  }
}

/**
 * @private
 * The fields for the collection items returned by queries.
 * @param {dxSchema/CollectionSchema} collection - The collection schema.
 * @param {object} [customFields] An object with custom output fields to add in output type.
 * @return {GraphQLInputObjectFieldMap}
 */
const collectionFields = (collection, customFields) => {
  const fields = (customFields ? ({ ...customFields }) : {});

  // Add scalar fields:
  collection.forEachField((field, fieldId) => {
    if (!field.excludeFromAPI) {
      const gType = field.gqlTypeObject();
      fields[fieldId] = {
        type: (fieldId === 'id' || field.isRequired) ? new GraphQLNonNull(gType) : gType,
        description: field.description || field.label,
        resolve: (item) => item[fieldId]
      };
    }
  });

  // Add relatee(s) fields:
  collection.forEachRelateeSchema((relatee, fieldId) => {
    if (relatee.arityOne) {
      fields[fieldId] = relateeField(relatee);
      fields[`has${relatee.fieldIdCapped}`] = hasRelateeField(relatee);
    } else {
      fields[fieldId] = relateesField(relatee);
      fields[`${fieldId}Count`] = relateesCountField(relatee);
      fields[`has${relatee.fieldIdCapped}`] = hasRelateesField(relatee);
    }
  });

  return fields;
};

/**
 * @private
 * A field that outputs the specified unary relatee of a given item.
 *
 * @example
 * query { group(id: "..") { project { id, name, ... } } }
 *
 * @param {dxSchema/RelateeSchema} relatee
 * @return {GraphQLFieldConfig}
 */
const relateeField = (relatee) => {
  const {
    coCollection, collection, description, fieldId, label
  } = relatee;
  const { controller } = collection;
  return {
    type: coCollection.gqlTypeObject(),
    description: description || label,
    resolve: async (item, args, ctx) => {
      await ctx.state.authorizer.authorizeRelateeOperation(relatee, 'view', ctx);
      return controller.getRelatee(collection.id, item.id, fieldId);
    }
  };
};

/**
 * @private
 * A field that outputs an array with the specified n-ary relatees of a given item. Pagination
 * and ordering arguments may be provided to retrieve a selected subset of relatees.
 *
 * @example
 * query { project(id: "..") { groups { id, name, ... } } }
 *
 * @example
 * query { project(id: "..") { groups(from: 10, limit: 5, ordering: "...") { id, name, ... } } }
 *
 * @param {dxSchema/RelateeSchema} relatee
 * @return {GraphQLFieldConfig}
 */
const relateesField = (relatee) => {
  const {
    coCollection, collection, description, fieldId, label
  } = relatee;
  const { controller } = collection;
  return {
    type: new GraphQLList(coCollection.gqlTypeObject()),
    description: description || label,
    args: itemsetInputFields,
    resolve: async (item, args, ctx) => {
      await ctx.state.authorizer.authorizeRelateeOperation(relatee, 'view', ctx);
      const { from, limit, ordering } = args;
      const options = { from, limit, ordering: parseOrdering(ordering) };
      return controller.getRelatees(collection.id, item.id, fieldId, options);
    }
  };
};

/**
 * @private
 * A field that returns the size an an n-ary relatees set.
 *
 * @example
 * query { project(id: "..") { groupsCount }
 *
 * @param {dxSchema/RelateeSchema} relatee
 * @return {GraphQLFieldConfig}
 */
const relateesCountField = (relatee) => {
  const { collection, fieldId } = relatee;
  const { controller } = collection;
  return {
    type: GraphQLInt,
    description: `The number of relations in ${collection.id}.${fieldId}.`,
    resolve: async (item, args, ctx) => {
      await ctx.state.authorizer.authorizeRelateeOperation(relatee, 'view', ctx);
      return controller.countRelatees(collection.id, item.id, fieldId);
    }
  };
};

/**
 * @private
 * A field for checking if a given item has the given relatee.
 *
 * @example
 * query { group(id: "..") { hasProject(id: "..") { success, reason } } }
 *
 * @param {dxSchema/RelateeSchema} relatee
 * @return {GraphQLFieldConfig}
 */
const hasRelateeField = (relatee) => {
  const { collection, fieldId } = relatee;
  const { controller } = collection;
  return {
    description: `Returns true if the ${collection.id} item has the given ${fieldId}.`,
    type: GraphQLBoolean,
    args: {
      id: {
        description: `The ID of the relatee to check for.`,
        type: new GraphQLNonNull(GraphQLID)
      }
    },
    resolve: async (item, args, ctx) => {
      await ctx.state.authorizer.authorizeRelateeOperation(relatee, 'view', ctx);
      return controller.hasRelatee(collection.id, item.id, fieldId, args.id);
    }
  };
};

/**
 * A field for checking if a given item has the given relatee.
 * @example
 * query { group(id: "..") { hasProject(id: "..") { success, reason } } }
 *
 * @param {dxSchema/RelateeSchema} relatee
 * @return {GraphQLFieldConfig}
 */
const hasRelateesField = (relatee) => {
  const { collection, fieldId } = relatee;
  const { controller } = collection;
  return {
    description: `Returns true if the ${collection.id} item has the given ${fieldId}.`,
    type: GraphQLBoolean,
    args: {
      ids: {
        description: `The IDs of the relatees to check for.`,
        type: new GraphQLList(GraphQLID)
      }
    },
    resolve: async (item, args, ctx) => {
      await ctx.state.authorizer.authorizeRelateeOperation(relatee, 'view', ctx);
      let hasRels = true;
      await asyncForEach(args.ids, async (id) => {
        hasRels = hasRels && await controller.hasRelatee(collection.id, item.id, fieldId, id);
      });
      return hasRels;
    }
  };
};

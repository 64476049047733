import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../propTypes';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  '> h2': {
    marginBottom: '24px',
    '&:first-of-type': { marginTop: 0 },
  },
  '> h2.dx-title': {
    margin: '0 0 12px 0',
  },
});

// -- Component --------------- --- --  -

/**
 * Assign _dxStyles.contentSection_ as styles prop to render a white section with standard content
 * padding, as shown in the following example.
 * @example
 * const ContentSection = () => {
 *   return (
 *     <Section styles={dxStyles.contentSection}>
 *       ...
 *     </Section>
 *   );
 * };
 */
export const Section = ({
  children, className, styles, title
}) => (
  <div className={classnames('dx-section', className)} {...css(defaultStyles, styles)}>
    {title && <h2 className="dx-title">{title}</h2>}
    {children}
  </div>
);

Section.propTypes = {
  /** The section content. */
  children: PropTypes.node,

  /** Optional additional class name. */
  className: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,

  /** @experimental Optional section title. */
  title: PropTypes.string,
};

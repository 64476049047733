import {
  dxMutationBuilder,
  dxQueryBuilder,
  getCreateArgs,
  getCreateVars,
  getUpdateArgs,
  getUpdateVars,
} from '../../utils/dxf';

import { dxSchema } from '../dxSchema';

// --  DMS item queries --------------- --- --  -

export const buildCreateItemQuery = (fields, { collSchema }) => {
  const createArgs = getCreateArgs(collSchema, { includeRelatees: true });
  const createVars = getCreateVars(collSchema, { includeRelatees: true });
  return dxMutationBuilder(dxSchema, {
    variables: createVars,
    fields: {
      [`create${collSchema.singularCapped}`]: {
        varArgs: createArgs,
        fields,
      },
    },
  });
};

export const buildDeleteItemQuery = ({ collSchema }) => dxMutationBuilder(dxSchema, {
  variables: { itemId: { type: `${collSchema.getField('id').gqlTypeString()}!` } },
  fields: {
    [collSchema.singular]: {
      varArgs: { id: 'itemId' },
      fields: {
        delete: {
          fields: { success: true, reason: true },
        },
      },
    },
  },
});

export const buildFetchItemQuery = (fields, { collSchema }) => dxQueryBuilder(dxSchema, {
  variables: { id: { type: collSchema.getField('id').gqlTypeString() } },
  fields: {
    [collSchema.singular]: {
      varArgs: { id: 'id' },
      fields,
    },
  },
});

export const buildUpdateItemQuery = (fields, { collSchema }) => dxMutationBuilder(dxSchema, {
  variables: getUpdateVars(collSchema, { includeRelatees: true }),
  fields: {
    [collSchema.singular]: {
      varArgs: { id: 'id' },
      fields: {
        update: {
          varArgs: getUpdateArgs(collSchema, { includeRelatees: true }),
          fields,
        },
      },
    },
  },
});

// -- DMS collection queries --------------- --- --  -

export const buildFetchPageQuery = (fields, { collSchema }) => dxQueryBuilder(dxSchema, {
  variables: {
    ids: { type: '[String]' },
    from: { type: 'Int' },
    limit: { type: 'Int' },
    ordering: { type: 'JSON' },
  },
  fields: {
    [collSchema.plural]: {
      varArgs: {
        ids: 'ids',
        from: 'from',
        limit: 'limit',
        ordering: 'ordering',
      },
      fields,
    },
    [`${collSchema.plural}Count`]: true,
  },
});

export const buildFilterQuery = (fields, { collSchema }) => dxQueryBuilder(dxSchema, {
  variables: {
    filter: { type: 'JSON' },
    from: { type: 'Int' },
    limit: { type: 'Int' },
    ordering: { type: 'JSON' },
  },
  fields: {
    [`filter${collSchema.pluralCapped}`]: {
      varArgs: {
        filter: 'filter',
        from: 'from',
        limit: 'limit',
        ordering: 'ordering',
      },
      fields: {
        filteredCount: true,
        from: true,
        limit: true,
        ordering: true,
        pagedCount: true,
        items: { fields },
      },
    },
    [`${collSchema.plural}Count`]: true,
  },
});

import { isString } from '../../utils/isString';

/**
 * Returns an pathname formed by the arguments, joining the parts together while making sure that
 * there are no consecutive slashes.
 * @param {Array.<string>} segments - The path parts to join.
 */
export const join = (...segments) => segments
  .reduce((result, segment) => {
    if (segment && isString(segment)) { return result.concat(segment.split('/')); }
    return result;
  }, [])
  .reduce((result, part, index) => {
    // retain initial slash:
    if (index === 0 && part === '') { return ['']; }
    // ignore empty and pointy parts:
    if (!part || part === '.') { return result; }
    // go "up" one directory:
    if (part === '..') {
      if (result.length > 0) { return result.slice(0, -1); }
      return [part];
    }
    // concat regular part:
    return result.concat(part);
  }, [])
  .join('/');

import React from 'react';
import PropTypes from 'prop-types';

const Check = ({
  backgroundColor, className, color, height, styles, width
}) => (
  <svg {...styles} className={className} fill={color} height={`${height}`} viewBox="0 0 24 24" width={`${width}`} xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill={backgroundColor} />
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </svg>
);

Check.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  styles: PropTypes.any,
  width: PropTypes.number,
};

Check.defaultProps = {
  backgroundColor: '#777',
  color: '#FFFFFF',
  height: 24,
  width: 24,
};

export default Check;

import PropTypes from 'prop-types';

export const dxQueryPropTypes = {
  // authRule: dxAuthRulePropType, This gives a console error...
  deserializer: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  // varSpecs: PropTypes.object.isRequired,
  // variables: PropTypes.object.isRequired,
};

export const dxQueryPropType = PropTypes.shape(dxQueryPropTypes);

/* eslint-disable camelcase */

import {
  DX_MODAL_ADD,
  DX_MODAL_CLEAR,
  DX_MODAL_REMOVE,
} from '../constants';

// -- Initial State --------------- --- --  -

const initialState = {
  modals: []
};

// -- Reducer --------------- --- --  -

/**
 * Derived from https://github.com/ErrorPro/react-redux-modal
 */
export const dxModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case DX_MODAL_ADD: {
      const { component, options = {}, id } = action;
      return {
        modals: [
          ...state.modals.filter((modal) => modal.id !== id),
          { component, id, options }
        ]
      };
    }

    case DX_MODAL_CLEAR: {
      return initialState;
    }

    case DX_MODAL_REMOVE: {
      const { id } = action;
      return {
        modals: state.modals.filter((modal) => modal.id !== id)
      };
    }

    default:
      return state;
  }
};

import invariant from 'invariant';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { isString } from '../../utils/isString';

import { DMSCollection } from './DMSCollection';

// -- Constants & Variables --------------- --- --  -

let idCounter = 0;

// -- DMSConfig Class --------------- --- --  -

/**
 * Represents a DMS configuration. Takes the raw configuration and generates additional settings
 * such as paths, queries, labels, etc.
 */
export class DMSConfig {
  /**
   * @param {DMSConfigOptions} rawConfig
   */
  constructor(rawConfig) {
    invariant(isObject(rawConfig), `Expected an object as source DMS-config, got "${rawConfig}".`);
    invariant(isString(rawConfig.apiUrl), `Expected a string as "apiUrl", got "${rawConfig.apiUrl}".`);
    invariant(isArray(rawConfig.collections), `Expected an array as "collections", got "${rawConfig.collections}".`);

    // Primary properties:
    this._apiUrl = rawConfig.apiUrl;
    // this._basePath = rawConfig.basePath;
    this._component = rawConfig.component;
    this._id = rawConfig.id || `DMS_${idCounter += 1}`;
    this._initialized = false;
    this._rawConfig = rawConfig;

    // Helper properties:
    this._collsById = new Map();
    this._basicItemFieldsByCollId = new Map();
  }

  // -- Initialization --------------- --- --  -

  /**
   * @param {string} path - The base path where the DMS collections overview is to be served.
   *   This property is used as value for the _path_ prop of the base _Switch_ > _Route_ component.
   */
  initialize(path) {
    invariant(isString(path), `Expected a string as "path", got "${path}".`);
    if (this._initialized) { return; }

    this._basePath = path;

    // First step of two-step process of initializing the collection configs - add dx-collection
    // schema, label, path and other basic properties:
    this._collections = this._rawConfig.collections.map((collConfig) => {
      const coll = new DMSCollection(collConfig, this);
      this._collsById.set(coll.id, coll);
      return coll;
    });

    // Also add unlisted collections that are related to a listed collection and not excluded in
    // the viewSchema:
    this._collections.forEach((coll) => {
      coll.collSchema.forEachRelatee((relateeSchema, fieldId) => {
        const excluded = coll.viewSchema
          && coll.viewSchema.exclude
          && coll.viewSchema.exclude.includes(fieldId);
        const coCollId = relateeSchema.coCollectionId;
        if (!excluded && !this._collsById.has(coCollId)) {
          const coColl = new DMSCollection({ id: coCollId }, this);
          this._collsById.set(coCollId, coColl);
          this._collections.push(coColl);
        }
      });
    });

    // Second step of two-step process of initializing the collection configs - add the queries:
    this._collections.forEach((coll) => coll.initialize());
  }

  getBasicItemFields(collId) {
    if (this._basicItemFieldsByCollId.has(collId)) {
      return this._basicItemFieldsByCollId.get(collId);
    }
    const coll = this._collsById.get(collId);
    const fields = coll.itemLabelFields.reduce((obj, field) => ({ ...obj, [field]: true }), {});
    fields.id = true;
    this._basicItemFieldsByCollId.set(collId, fields);
    return fields;
  }

  // -- Accessors --------------- --- --  -

  get apiUrl() { return this._apiUrl; }

  get basePath() { return this._basePath; }

  get component() { return this._component; }

  get collections() { return this._collections; }

  get id() { return this._id; }

  get isDMSConfig() { return true; }

  hasCollection(id) { return this._collsById.has(id); }

  getCollection(id) { return this._collsById.get(id); }
}

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { dxColors } from '../../styles';

import { stopIcon, deleteIcon } from './svgPaths';

// -- Constants --------------- --- --  -

const ICONS = {
  delete: deleteIcon,
  stop: stopIcon,
};

// -- Styles --------------- --- --  -

const iconStyles = css({
  cursor: 'pointer',
});

// -- Component --------------- --- --  -

export function ActionIcon(props) {
  const {
    className, color, height, width, type, onClick
  } = props;
  return (
    <span {...iconStyles} className={className} onClick={onClick}>
      <svg height={height} width={width} viewBox="0 0 40 40">
        <style type="text/css">
          {`.st0{fill:${color};}`}
        </style>
        { Array.isArray(ICONS[type])
          ? ICONS[type].map((p, i) => <path className="st0" key={i} d={p} />)
          : <path className="st0" d={ICONS[type]} />}
      </svg>
    </span>
  );
}

ActionIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  // icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  width: PropTypes.number,
};

ActionIcon.defaultProps = {
  className: '',
  color: dxColors.fgMain,
  height: 14,
  width: 14,
};

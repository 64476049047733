import PropTypes from 'prop-types';

import { orderingPropType } from './orderingPropType';

// -- ItemLoadState --------------- ---  --  -

/**
 * @see createItemLoadStateReducer
 * @param {Function} itemPropType - The prop-type of the items loaded by the load-state.
 */
export const itemLoadStatePropTypesOf = (itemPropType = PropTypes.object) => PropTypes.shape({
  available: PropTypes.bool.isRequired,
  availableId: PropTypes.func.isRequired,
  creating: PropTypes.bool.isRequired,
  id: PropTypes.string,
  item: itemPropType,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  newFields: PropTypes.object,
  reloading: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  updating: PropTypes.bool.isRequired,
});

/**
 * @see itemLoadStatePropTypesOf
 * @see createItemLoadStateReducer
 */
export const itemLoadStatePropType = itemLoadStatePropTypesOf(PropTypes.any);

// -- PageLoadState --------------- ---  --  -

/**
 * @see createPageLoadStateReducer
 * @param {Function} itemPropType - The prop-type of the items loaded by the load-state.
 *
 * @example
 * const propTypes = {
 *   membersPage: pageLoadStatePropTypesOf(PropTypes.instanceOf(Member), 'members'),
 * };
 */
export const pageLoadStatePropTypesOf = (itemPropType = PropTypes.object) => PropTypes.shape({
  action: PropTypes.object,
  available: PropTypes.bool.isRequired,
  from: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(itemPropType).isRequired,
  limit: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  ordering: orderingPropType,
  reloading: PropTypes.bool.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.any,
  total: PropTypes.number,
});

/**
 * @see pageLoadStatePropTypesOf
 * @see createPageLoadStateReducer
 */
export const pageLoadStatePropType = pageLoadStatePropTypesOf();

// -- SimpleLoadState --------------- ---  --  -

/**
 * @see createSimpleLoadStateReducer
 * @param {Function} dataPropType - The prop-type of the data loaded by the load-state.
 */
export const simpleLoadStatePropTypeOf = (dataPropType) => PropTypes.shape({
  available: PropTypes.bool.isRequired,
  id: PropTypes.string,
  data: dataPropType,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  reloading: PropTypes.bool.isRequired,
});

/**
 * @see createSimpleLoadStateReducer
 * @see createSimpleLoadStateReducer
 */
export const simpleLoadStatePropType = simpleLoadStatePropTypeOf(PropTypes.any);

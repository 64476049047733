import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { pageLoadStatePropTypesOf } from '../../propTypes';
import {
  getPageLoadStateFrom,
  getPageLoadStateItems,
  getPageLoadStateLimit,
  getPageLoadStateLoading,
  getPageLoadStateOrdering,
  getPageLoadStateTotal,
} from '../../selectors';
import { dxColors, dxStyles } from '../../styles';
import { ActionIcon } from '../actionBar';
import { Spinner } from '../Spinner';
import { Toolbar, ToolbarPagination } from '../toolbar';

// -- Styles --------------- --- --  -

const listStyles = css({
  listStyle: 'none',
  margin: '0 0 10px 0',
  padding: '0',
  '> li': {
    display: 'flex',
    padding: '10px 0',
    '> .item-label': {
      flexGrow: 1,
    },
  },
  '> li + li': dxStyles.thinBorderTop,
});

// -- Component --------------- --- --  -

/**
 * A generic component that renders a paginated list with selected items.
 * This list is used in the bulk actions modal
 */
export class SelectedItemsList extends Component {
  constructor(props) {
    super(props);
    this.handlePageTo = this.handlePageTo.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  handlePageTo(page) {
    const { loadState } = this.props;
    const limit = getPageLoadStateLimit(loadState);
    const ordering = getPageLoadStateOrdering(loadState);
    this.props.loadItems({ from: (page - 1) * limit, limit, ordering });
  }

  renderItems() {
    const { getItemLabel, loadState } = this.props;
    if (getPageLoadStateLoading(loadState)) {
      return <Spinner />;
    }
    return (
      <ul {...listStyles}>
        {getPageLoadStateItems(loadState).map((item) => {
          const { id } = item;
          const label = getItemLabel(item);
          return (
            <li className="dx-list-item" key={id}>
              <span className="item-label">{label}</span>
              <ActionIcon
                className="deselect-btn"
                color={dxColors.dangerRed}
                onClick={() => this.props.deselectItem(id)}
                type="stop"
              />
            </li>
          );
        })}
      </ul>
    );
  }

  renderPagination(from, limit, total) {
    const paginationProps = {
      activePage: Math.floor(from / limit) + 1,
      maxButtons: 0,
      pageCount: Math.max(1, Math.ceil(total / limit)),
      pageTo: this.handlePageTo,
    };
    return <ToolbarPagination noBorders {...paginationProps} />;
  }

  render() {
    const { loadState } = this.props;
    const from = getPageLoadStateFrom(loadState);
    const limit = getPageLoadStateLimit(loadState);
    const total = getPageLoadStateTotal(loadState);
    return (
      <div>
        {total > limit && (
          <Toolbar className={classnames('dx-items-list', { 'no-pagination': total <= limit })}>
            {this.renderPagination(from, limit, total)}
          </Toolbar>
        )}
        {this.renderItems()}
      </div>
    );
  }
}

SelectedItemsList.propTypes = {
  /**
   * A function that dispatches an action to select / unselect an item.
   * This function takes one argument: the item ID.
   */
  deselectItem: PropTypes.func,

  /**
   * A function that takes a selected item and returns the label to use for that item.
   * Options, default to `(item) => item.label`.
   */
  getItemLabel: PropTypes.func,

  /**
   * A function that dispatches an action to load a page of items. This function takes one
   * argument: an object with three properties:
   * 1) from: The index of the first item,
   * 2) limit: The maximum number of items in the page, and
   * 3) ordering: An optional ordering specification.
   * 4) ids: The list of id's of selected items
   */
  loadItems: PropTypes.func.isRequired,

  /**
   * The page-load-state that represents the items to show. See the _dxLoadState_ manual for more
   * details.
   * When _ItemComponent_ is not provided, then the default item renderer expects the following
   * item props:
   * - id: The item ID.
   * - label: The label.
   * - path: The path to the item details.
   */
  loadState: pageLoadStatePropTypesOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    path: PropTypes.string,
  })),
};

SelectedItemsList.defaultProps = {
  getItemLabel: (item) => item.label,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'react-bootstrap';

import { dxFormFieldControlPropTypes } from '../../../propTypes';

// -- checkboxControlPropTypes --------------- ---  --  -

export const checkboxControlPropTypes = {
  /**
   * The value for the `inline` prop on the (React-Bootstrap) <Checkbox/> component. When true,
   * then the <label/> component is not wrapped in a <div/>.
   */
  inline: PropTypes.bool,
};

// -- Component --------------- ---  --  -

export const CheckboxFFC = (props) => {
  /// noinspection JSUnusedLocalSymbols - remove all unneeded vars from otherProps
  const {
    baseValue, className, controlId, editMode, fieldSchema, input, label, ...otherProps
  } = props;

  const checkboxProps = {
    ...input, // redux-form props
    checked: input.value,
    className: `${className} dx-field`,
    ...otherProps,
  };
  if (!editMode) {
    checkboxProps.tabIndex = '-1';
    checkboxProps.disabled = true;
  }

  return <Checkbox {...checkboxProps}>{label}</Checkbox>;
};

CheckboxFFC.propTypes = {
  ...dxFormFieldControlPropTypes,
  ...checkboxControlPropTypes,
};

CheckboxFFC.defaultProps = {
  inline: false,
};

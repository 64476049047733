import { isString } from '../isString';

export class Report {
  constructor(report) {
    this.valid = true;
    this.errors = [];
    this.text = 'No errors';

    if (report) {
      this.addReport(report);
    }
  }

  addError(error) {
    if (!isString(error)) {
      throw new RangeError('Expected error string.');
    }
    this.errors.push(error);
    if (this.valid) {
      this.text = error;
      this.valid = false;
    } else {
      this.text = `${this.text} ${error}`;
    }
    return this;
  }

  addErrors(errors, text) {
    this.errors.push(...errors);
    if (this.valid) {
      this.text = text;
      this.valid = false;
    } else {
      this.text = `${this.text} ${text}`;
    }
    return this;
  }

  addReport(report) {
    if (!report.valid) {
      this.errors.push(...report.errors);
      if (this.valid) {
        this.text = report.text;
        this.valid = false;
      } else {
        this.text = `${this.text} ${report.text}`;
      }
    }
    return this;
  }

  try(fn) {
    try { fn(); } catch (error) {
      this.addError(error.message);
    }
  }
}

import invariant from 'invariant';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';
import { isString } from '../../utils/isString';

/**
 * Asserts that the given object is a bulk-action object.
 * @param {BulkAction} obj
 */
export const assertBulkActionObject = (obj) => {
  // console.log('>>> assertBulkActionObject -- obj:', obj);
  const {
    actionType, collectionId, divider, isDestructive, label, message, noLabel
  } = obj;
  const {
    render, size, title, yesLabel
  } = obj;
  invariant(isString(actionType), `Expected a string as "actionType", got "${actionType}".`);
  invariant(!collectionId || isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(!divider || isString(divider), `Expected a string as "divider", got "${divider}".`);
  invariant(!isDestructive || isBoolean(isDestructive), `Expected a boolean as "isDestructive", got "${isDestructive}".`);
  invariant(isString(label) || isFunction(label), `Expected a string or function as "label", got "${label}".`);
  invariant(!message || isString(message), `Expected a string as "message", got "${message}".`);
  invariant(!noLabel || isString(noLabel), `Expected a string as "noLabel", got "${noLabel}".`);
  invariant(!size || isString(size), `Expected a string as "size", got "${size}".`);
  invariant(!render || isFunction(render), `Expected a function as "render", got "${render}".`);
  invariant(!title || isString(title), `Expected a string as "title", got "${title}".`);
  invariant(!yesLabel || isString(yesLabel), `Expected a string as "yesLabel", got "${yesLabel}".`);
};

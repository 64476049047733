import { spawn } from 'redux-saga/effects';

import auth0Sagas from './auth0Sagas';
import authSagas from './authSagas';
import simplePasswordSagas from './simplePasswordSagas';

export function* dxAuthSagas() {
  yield spawn(authSagas);
  yield spawn(auth0Sagas);
  yield spawn(simplePasswordSagas);
}

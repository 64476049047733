/* eslint-disable camelcase */

import {
  DX_AUTH_AUTHENTICATE,
  DX_AUTH_AUTHENTICATED,
  DX_AUTH_FAILED,
  DX_AUTH_SIGNED_OUT,
  DX_AUTH_SET_PROVIDER_STATE,
} from '../constants';

// -- Initial State --------------- --- --  -

const initialState = {
  authenticated: false,
  dxError: undefined,
  expiresAt: undefined, // the unix-time (in seconds) at which the dxToken will expire
  failed: false,
  providerId: undefined,
  providerState: undefined, // arbitrary state set by frontend providers
  targetPath: undefined,
  user: undefined,
};

// -- Reducer --------------- --- --  -

export const dxAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case DX_AUTH_AUTHENTICATE: {
      const { providerId, targetPath } = action;
      if (state.providerId === providerId) {
        return state;
      }

      return {
        ...initialState,
        providerId,
        targetPath,
      };
    }

    case DX_AUTH_AUTHENTICATED: {
      const { expiresAt, user } = action;
      return {
        ...state,
        authenticated: true,
        dxError: null,
        expiresAt,
        user,
      };
    }

    case DX_AUTH_FAILED: {
      const { dxError } = action;
      return {
        ...state,
        authenticated: false,
        dxError,
        failed: true,
      };
    }

    case DX_AUTH_SET_PROVIDER_STATE: {
      const { providerState } = action;
      if (state.providerState === providerState) {
        return state;
      }

      return {
        ...state,
        providerState,
      };
    }

    case DX_AUTH_SIGNED_OUT: {
      const { targetPath } = action;
      return {
        ...initialState,
        targetPath,
      };
    }

    default:
      return state;
  }
};

/* global LOG_DX_AUTH */

import auth0 from 'auth0-js';
import invariant from 'invariant';
import { replace } from 'connected-react-router';
import {
  fork, call, cancel, put, select, takeEvery, delay
} from 'redux-saga/effects';
import isArray from 'lodash/isArray';
import { dxConfig } from '../../dxConfig';
import { isString } from '../../../utils/isString';
import { toString } from '../../../utils/toString';
import { toErrorString } from '../../../utils/toErrorString';

import {
  dxAuthenticatedAction, dxAuthFailedAction, dxAuthSignOutAction, dxAuthSignedOutAction
} from '../../actions';
import { User } from '../../auth/User';
import { DX_AUTH_AUTHENTICATE, DX_AUTH_FAILED, DX_AUTH_SIGN_OUT } from '../../constants';
import { auth0IdentifyQuery, fetchQuery } from '../../queries';
import { getDxAuthProviderId } from '../../selectors';

// -- Constants --------------- --- --  -

/**
 * @typedef {object} Auth0WebConfig - Consult the _dxAuth_ section in the manual for more details.
 * @property {string} audience
 * @property {string} clientID
 * @property {string} [connection]
 * @property {string} domain
 * @property {string} authCallbackRoute
 * @property {string} renewCallbackRoute
 * @property {string} responseType
 * @property {string} scope
 */

const authApiUrl = dxConfig.get('duxis.auth.apiUrl');
const PROVIDER = 'auth0';
const logAuth = LOG_DX_AUTH ? ((...args) => console.log('[dxAuth.auth0Sagas]', ...args)) : () => null;

let webAuth; let webAuthConfig; let
  enableRenew = false;

if (dxConfig.get('duxis.auth.identityProviders.auth0.enable', false)) {
  enableRenew = dxConfig.get('duxis.auth.identityProviders.auth0.enableRenew');
  webAuthConfig = dxConfig.get('duxis.auth.identityProviders.auth0.webAuth');
  const {
    audience, clientID, domain, responseType, scope
  } = webAuthConfig;
  // noinspection JSUnresolvedFunction
  webAuth = new auth0.WebAuth({
    audience, clientID, domain, responseType, scope
  });
}

// -- Sagas --------------- --- --  -

/**
 * The Auth0-based authentication master saga.
 */
export default function* () {
  if (dxConfig.get('duxis.auth.identityProviders.auth0.enable', false)) {
    logAuth('Install Auth0 sagas');
    yield takeEvery(DX_AUTH_AUTHENTICATE, authenticate);
    yield takeEvery(DX_AUTH_FAILED, clearLocalStorage);
    yield takeEvery(DX_AUTH_SIGN_OUT, signOut);
  }
}

/**
 * @param {string} providerId
 * @param {string} targetPath
 */
function* authenticate({ providerId, targetPath }) {
  logAuth('authenticate...');
  if (providerId !== PROVIDER) { return; }

  // Handle redirect from Auth0 following a completed sign-in:
  if (window.location.href.includes(webAuthConfig.authCallbackRoute)) {
    return yield call(handleSignInRedirect);
  }
  if (enableRenew) {
    yield call(renewAuth, targetPath, false);
  } else {
    yield call(signIn, targetPath);
  }
}

/**
 * Redirect the browser to the Auth0 lock, where the user can sign in according to the Auth0 client
 * configuration.
 * @param {string} targetPath - The path to return to when the user signed in (or failed to sign
 *   in). This path is prefixed with the protocol and application host to obtain the url to which
 *   the browser is redirected.
 */
const signIn = (targetPath) => {
  logAuth('redirecting to Auth0 lock...');
  localStorage.setItem('targetPath', targetPath);
  webAuth.authorize({
    redirectUri: `${window.location.protocol}//${window.location.host}${webAuthConfig.authCallbackRoute}`,
  });
};

/**
 * @warning Do *NOT* dispatch Redux action in between the reception of the redirect from Auth0 and
 *   the redirect to the target path. Doing so would retrigger this handler, but then when parsing
 *   the hash, the nonce no longer matches.
 *
 * @example <caption>Example <code>idTokenPayload</code></caption>
 * {
 *   name: 'Wouter Van den Broeck',
 *   given_name: 'Wouter',
 *   family_name: 'Van den Broeck',
 *   nickname: 'wouter.jm.vdb',
 *   picture: 'https://lh3.googleusercontent.com/-TYsauuDU3zI ...',
 *   gender: 'male',
 *   locale: 'nl',
 *   updated_at: '2017-08-09T19:37:15.293Z',
 *   iss: 'https://imec-apt.eu.auth0.com/',
 *   sub: 'google-oauth2|10578 ...',
 *   aud: 'xkdn70zCpdyQRLazG4y ...',
 *   exp: 1502343435,
 *   iat: 1502307435,
 *   nonce: '4K4GQeoTbEj6YNq ...',
 *   at_hash: 'kRd1R7wu1MN ...',
 *   http://auth.duxis.io/roles: [
 *     'pk2/role/panel_manager',
 *     'pk2/role/panel_admin',
 *   ],
 * }
 */
function* handleSignInRedirect() {
  logAuth(`Handle sign-in redirect from Auth0 ... [${new Date()}]`);
  let authResult;
  try {
    authResult = yield parseHash();
  } catch (error) {
    // noinspection JSUnresolvedVariable
    if (!error.errorDescription) {
      console.error('[auth0Sagas] parseHash failed with error that has no errorDescription property.');
      console.error(`[auth0Sagas] error: ${toErrorString(error)}`);
    }
    // noinspection JSUnresolvedVariable
    console.error(`The authentication failed. ${error.errorDescription} [${error.error}].`);
    return yield put(dxAuthFailedAction(error));
  }

  const { accessToken, expiresIn, idTokenPayload } = authResult;
  if (!accessToken) {
    return yield put(dxAuthFailedAction('Missing accessToken in authResult.'));
  }
  logAuth(`Auth0 user: ${toString(idTokenPayload)}`);
  const expiresAt = Math.floor(expiresIn + Date.now() / 1000); // epoch-time in seconds
  const targetPath = localStorage.getItem('targetPath');
  localStorage.removeItem('targetPath');
  yield call(identify, accessToken, expiresAt, targetPath, trimIdTokenPayload(idTokenPayload));
}

function* identify(accessToken, expiresAt, targetPath = '/', payload = {}) {
  logAuth(`Identifying the auth0 user ...  [${new Date()}]`);

  // identify the user in the dxAuth backend:
  const query = auth0IdentifyQuery;
  const url = authApiUrl;
  const variables = { accessToken, payload };
  const options = { authenticate: false, url, variables };
  const { cancelled, data, error } = yield fetchQuery(query, options);

  if (error) {
    return yield put(dxAuthFailedAction('Failed to identify the user.', error));
  }
  if (cancelled) {
    return yield put(dxAuthFailedAction('The auth0.identify query was cancelled.'));
  }

  // handle dxAuth response:
  if (!data) {
    const msg = 'The auth0.identify query yielded an empty data object.';
    return yield put(dxAuthFailedAction(msg));
  }

  logAuth('`auth0.identify` responded with:', toString(data.auth0.identify));
  const {
    dxToken, genericRights, id, reason, scopedRights, success
  } = data.auth0.identify;
  if (!success) { return yield put(dxAuthFailedAction(reason)); }

  invariant(isArray(genericRights), `The "genericRights" should be an array, got "${genericRights}".`);
  invariant(isString(dxToken), `The "dxToken" should be a string, got "${dxToken}".`);
  invariant(isString(id), `The "id" should be a string, got "${id}".`);

  const _payload = { ...(yield getUserInfo(accessToken)), ...payload };
  const user = new User({
    id, dxToken, genericRights, scopedRights, payload: _payload,
  });

  yield put(dxAuthenticatedAction(user, expiresAt));
  yield put(replace(targetPath));
  yield call(scheduleExpirationTask, expiresAt);
}

// -- Token Expiration Handling --------------- --- --  -

let expirationTask;

function* scheduleExpirationTask(expiresAt) {
  const ms = expiresAt * 1000 - Date.now() - 15000; // renew 15 sec before expiration
  logAuth(`scheduling token expiration handler - ms: ${ms}`);
  if (ms <= 0) { return; }
  expirationTask = yield fork(function* () {
    yield delay(ms);
    expirationTask = undefined;
    if (enableRenew) {
      yield call(renewAuth, window.location.pathname, true);
    } else {
      yield put(dxAuthSignOutAction());
    }
  });
}

function* renewAuth(targetPath, signedIn) {
  logAuth('Trying to renew...');
  try {
    const { accessToken, expiresIn, idTokenPayload } = yield call(renewAux);
    logAuth(`Renewed auth - idTokenPayload: ${toString(idTokenPayload)}`);
    const expiresAt = Math.floor(expiresIn + Date.now() / 1000);
    return yield call(identify, accessToken, expiresAt, targetPath, trimIdTokenPayload(idTokenPayload));
  } catch (error) {
    console.warn(`Renew error: ${toErrorString(error)}`);
    if (signedIn) {
      yield put(dxAuthSignOutAction());
    } else {
      yield call(signIn, targetPath);
    }
  }
}

/**
 * @return {Promise.<object>}
 */
const renewAux = async () => new Promise((resolve, reject) => {
  const renewOpts = {
    redirectUri: `${window.location.protocol}//${window.location.host}${webAuthConfig.renewCallbackRoute}`,
    usePostMessage: true,
    postMessageOrigin: `${window.location.protocol}//${window.location.host}`,
  };
  // noinspection JSUnresolvedFunction
  webAuth.renewAuth(
    renewOpts,
    (error, authResult) => {
      if (error) {
        // Example error: { error: 'login_required', errorDescription: 'Login required', state: 'mvTSCUBTEG42wEMKfbYghGIiPphgqVYm' }
        // noinspection JSUnresolvedVariable
        logAuth(`Got renew callback with error: "${error.errorDescription}".`);
        reject(error);
      } else {
        logAuth(`Got renew callback with authResult: "${toString(authResult)}".`);
        resolve(authResult);
      }
    }
  );
});

// -- Sign-out --------------- --- --  -

/**
 * Redirect to the `targetPath` provided to `dxAuthSignOutAction` or specified as
 * `duxis.auth.signOutTargetPath` in the setup config.
 * @param {string} [targetPath]
 */
function* signOut({ targetPath }) {
  if ((yield select(getDxAuthProviderId)) !== PROVIDER) { return; }
  let _targetPath = targetPath;
  if (!_targetPath) { _targetPath = dxConfig.get('duxis.auth.signOutTargetPath'); }
  const returnTo = `${window.location.protocol}//${window.location.hostname}${_targetPath}`;
  logAuth(`Signing out, redirecting to ${returnTo}`);
  yield call(clearLocalStorage);
  if (expirationTask) {
    yield cancel(expirationTask);
    expirationTask = undefined;
    logAuth('Cancelled expiration task');
  }
  yield call([webAuth, 'logout'], { returnTo });
  yield put(dxAuthSignedOutAction(PROVIDER));
}

// -- Local Helpers --------------- --- --  -

const trimIdTokenPayload = (payload) => {
  // noinspection JSUnusedLocalSymbols
  const {
    at_hash, aud, exp, 'http://auth.duxis.io/roles': roles, iat, iss, nonce, sub, updated_at, ...rest
  } = payload;
  return { issuer: iss, ...rest };
};

function clearLocalStorage() {
  localStorage.removeItem('targetPath');
}

/*
 * @example <caption>Example result of <code>parseHash(webAuth)</code></caption>
 * {
 *   accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Il ...',
 *   idToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Il ...',
 *   idTokenPayload: { ... },
 *   appStatus: null,
 *   refreshToken: null,
 *   state: 'mZcZYdUiee4nZM7_rJ ...',
 *   expiresIn: 7200,
 *   tokenType: 'Bearer',
 *   scope: 'openid profile test:test',
 * }
 */
const parseHash = async () => new Promise((resolve, reject) => {
  // noinspection JSUnresolvedFunction
  webAuth.parseHash((err, authResult) => {
    if (err) {
      console.error('parseHash failed...');
      console.error(err);
      reject(err);
    } else {
      resolve(authResult);
    }
  });
});

const getUserInfo = async (accessToken) => new Promise((resolve, reject) => {
  webAuth.client.userInfo(accessToken, (err, user) => {
    if (err) { reject(err); } else { resolve(user); }
  });
});

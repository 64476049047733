import classnames from 'classnames';
import { css } from 'glamor';
import makeHash from 'hash-sum';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import isFunction from 'lodash/isFunction';

import { dmsRelateeOptionsFetchAction } from '../../actions';
import addIcon from '../../assets/icons/add.svg';
import removeIcon from '../../assets/icons/stop.svg';
import { DMSCollection } from '../../dms';
import {
  dmsCollectionItemPropType,
  dxFormControlPropTypes,
  dxFormFieldViewPropTypes,
  pageLoadStatePropTypesOf,
  relateeSchemaPropType,
} from '../../propTypes';
import { getDMSItem, getDMSRelateesLoadState, getDMSCollection } from '../../selectors'; // getDMSCollection
import { dxColors, dxStyles } from '../../styles';
import { join } from '../../utils';
import { FormControlFFC } from '../fieldViews';

import { DMSAddRelateeDropdown } from './DMSAddRelateeDropdown';

// -- Styles --------------- --- --  -

const controlStyles = css(dxStyles.thinBorder, {
  height: 'auto !important',
  padding: '0 !important',
  '> ul': {
    listStyle: 'none',
    padding: '4px',
    margin: 0,
    '> li.dx-relatee, > li.dx-relatee-add': {
      display: 'flex',
      height: '20px',
      lineHeight: '20px',
      padding: '0 0 0 6px',
      ':hover, :active, :focus': {
        backgroundColor: dxColors.bgFocus,
        '> a': {
          color: dxColors.fgMain,
          textDecoration: 'none',
        },
      },
      '> a': {
        color: dxColors.fgMain,
        textDecoration: 'none',
      },
      '> .aux-label': {
        display: 'block',
        flexGrow: 1,
      },
      '> .add-label': {
        color: '#aaa',
        cursor: 'pointer',
        ':hover, :active, :focus': { color: dxColors.fgMain },
      },
      '> .add-btn, > .remove-btn': {
        cursor: 'pointer',
        height: '14px',
        margin: '3px 0',
        width: '14px',
      },
    },
  },
});

const formGroupStyles = css({ '> .form-control': { height: 'auto' } });

// -- RelateesControl Component --------------- --- --  -

/**
 * A relatee(s) field control for use in a dxForm.
 */
class RelateesControlComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropDownOpen: false };
    this.handleDropDownLoadItems = this.handleDropDownLoadItems.bind(this);
    this.handleDropDownClose = this.handleDropDownClose.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    if (!this.props.arityOne) {
      this.renderAddItem = this.renderAddItem.bind(this);
    }
    this.showDropDown = this.showDropDown.bind(this);
  }

  handleDropDownLoadItems({
    from, limit, ordering, searchString
  } = {}) {
    const {
      dmsCollection, item, loadState, relateeSchema, value
    } = this.props; // TODO: add "coRelateeDmsCollection" for Jovi's fix
    let filter;
    if (value) {
      if (this.props.arityOne) {
        filter = {
          filters: [{ type: 'hasValue', field: 'id', value: value.id }],
          operator: 'or',
          negate: true
        };
      } else {
        filter = {
          filters: value.map((target) => ({ type: 'hasValue', field: 'id', value: target.id })),
          operator: 'or',
          negate: true
        };
      }
    }
    if (searchString) {
      // Andoni's fix:
      const relateeCollectionId = relateeSchema.coCollectionId;
      // Default to filtering on id as this field is always present.
      let relateeItemLabelFields = ['id'];
      const relateeDmsCollection = dmsCollection.dmsConfig.getCollection(relateeCollectionId);
      // If there is a dmsCollection with specified itemLabelFields specified in the dmsConfig,
      // use these itemLabelFields to apply the hasText filter on.
      if (relateeDmsCollection && relateeDmsCollection.itemLabelFields) {
        relateeItemLabelFields = relateeDmsCollection.itemLabelFields;
      }
      const searchFilter = {
        operator: 'or',
        filters: relateeItemLabelFields.map((field) => ({
          type: 'hasText',
          field,
          string: searchString,
        })),
      };

      filter = filter ? { filters: [filter, searchFilter], operator: 'and' } : searchFilter;
    }
    const coColl = dmsCollection.dmsConfig.getCollection(relateeSchema.collectionId);
    if (coColl.viewSchema && coColl.viewSchema.fields) {
      const fld = coColl.viewSchema.fields.find((field) => field.id === relateeSchema.fieldId);
      if (fld && fld.filter) {
        if (!item.id) {
          throw new Error('Relatees with a filter should not be shown in create mode.');
        }
        const fieldFilter = isFunction(fld.filter) ? fld.filter(item.id) : fld.filter;
        if (filter) {
          if (filter.operator === 'and') {
            filter.filters.push(fieldFilter);
          } else {
            filter = { filters: [filter, fieldFilter], operator: 'and' };
          }
        } else {
          filter = fieldFilter;
        }
      }
    }
    const source = makeHash({ collectionId: relateeSchema.coCollectionId, filter });
    let inferredFrom = 0;
    let inferredLimit = 10;
    let inferredOrdering;
    if (source === loadState.source) {
      inferredFrom = loadState.from || inferredFrom;
      inferredLimit = loadState.limit || inferredLimit;
      inferredOrdering = loadState.ordering || inferredOrdering;
    }
    this.props.loadItems(
      relateeSchema.coCollectionId,
      filter,
      from || inferredFrom,
      limit || inferredLimit,
      ordering || inferredOrdering,
      source
    );
  }

  handleDropDownClose() {
    this.setState({ dropDownOpen: false });
  }

  // TODO: take item as argument ?
  handleRemoveItem(id) {
    this.props.onChange(this.props.arityOne ? null : this.props.value.filter((it) => it.id !== id));
  }

  // TODO: take item as argument ?
  handleSelectItem(id) {
    const item = this.props.loadState.items.find((it) => it.id === id);
    this.props.onChange(this.props.arityOne ? item : [...this.props.value, item]);
  }

  showDropDown() {
    this.setState({ dropDownOpen: true });
  }

  renderAddItem() {
    return (
      <li className="dx-relatee-add" key="__addItem__" onClick={this.showDropDown}>
        <div className="aux-label add-label">
          {`Add ${this.props.relateeSchema.coCollection.singular}`}
        </div>
        <img className="add-btn" src={addIcon} alt="Add" />
      </li>
    );
  }

  renderDropDown() {
    const { coCollection } = this.props.relateeSchema;
    return (
      <DMSAddRelateeDropdown
        loadItems={this.handleDropDownLoadItems}
        loadState={this.props.loadState}
        onToggle={this.handleDropDownClose}
        onSelect={this.handleSelectItem}
        plural={coCollection.plural}
        singular={coCollection.singular}
      />
    );
  }

  renderRelatee(item) {
    const { dmsCollection: { dmsConfig: { basePath } }, readOnly, relateeSchema: { coCollection: { id: relateeId } } } = this.props;
    return (
      <li className="dx-relatee" key={item.id}>
        <Link
          className="aux-label"
          to={join(basePath, relateeId, encodeURIComponent(item.id))}
        >
          {item.label}
        </Link>
        {!readOnly && (
          <img
            alt="Remove"
            className="remove-btn"
            onClick={() => this.handleRemoveItem(item.id)}
            src={removeIcon}
          />
        )}
      </li>
    );
  }

  renderActions() {
    const { arityOne, readOnly, value } = this.props;
    if (!readOnly && (arityOne && !value || !arityOne)) {
      if (this.state.dropDownOpen) {
        return (
          <React.Fragment>
            {this.renderAddItem()}
            {this.renderDropDown()}
          </React.Fragment>
        );
      }
      return this.renderAddItem();
    }
    return null;
  }

  renderRelatees() {
    if (this.props.value) {
      if (this.props.arityOne) {
        return this.renderRelatee(this.props.value);
      }
      return this.props.value.map((item) => this.renderRelatee(item));
    }
    return null;
  }

  render() {
    if (!this.props.dmsCollection) { return (<div />); }
    return (
      <div
        className={classnames('dms-relatees-control', this.props.className)}
        id={this.props.id}
        {...controlStyles}
      >
        <ul>
          {this.renderRelatees()}
          {this.renderActions()}
        </ul>
      </div>
    );
  }
}

RelateesControlComponent.propTypes = {
  ...dxFormControlPropTypes,

  /** Injected in the connect HOC. */
  arityOne: PropTypes.bool.isRequired,

  /** Injected in the connect HOC. */
  // coRelateeDmsCollection: PropTypes.instanceOf(DMSCollection).isRequired,  // Jovi's fix

  /** Injected in the connect HOC. */
  dmsCollection: PropTypes.instanceOf(DMSCollection).isRequired,

  /** To be provided in view en edit mode, but not in create mode, */
  item: PropTypes.shape({ id: PropTypes.string.isRequired }),

  /** Injected in the connect HOC. */
  loadItems: PropTypes.func.isRequired,

  /**
   * The page-load-state for the relatee option the user can choose from.
   * See the _dxLoadState_ manual for more details.
   */
  loadState: pageLoadStatePropTypesOf(dmsCollectionItemPropType).isRequired,

  /** TODO */
  readOnly: PropTypes.bool,

  /** To be provided. The relatee schema. */
  relateeSchema: relateeSchemaPropType.isRequired,
};

const RelateesControl = connect(
  (state, ownProps) => ({
    arityOne: ownProps.relateeSchema.arityOne,
    // TODO: the dmsCollections are not always available... temporarily pass as prop...
    dmsCollection: getDMSCollection(state, ownProps.relateeSchema.coCollectionId),
    // coRelateeDmsCollection: getDMSCollection(state, ownProps.relateeSchema.coCollectionId),  // Jovi's solution
    item: getDMSItem(state),
    loadState: getDMSRelateesLoadState(state),
  }),
  {
    loadItems: dmsRelateeOptionsFetchAction,
  }
)(RelateesControlComponent);

// -- RelateesFFC Component --------------- --- --  -

const RelateesFFC = (props) => <FormControlFFC {...props} componentClass={RelateesControl} />;

// -- DMSRelateesField Component --------------- --- --  -

export const DMSRelateesField = (props) => {
  const {
    editMode, fieldId, fieldProps, styles, value, ...otherProps
  } = props;
  const childProps = {
    baseValue: value,
    className: 'dx-relatees-field',
    component: RelateesFFC,
    controlId: `item-${fieldId}`,
    editMode,
    name: fieldId, // identifies the value in the form
    styles: { ...formGroupStyles, ...styles },
    ...otherProps,
    ...fieldProps,
  };
  return <Field {...childProps} />;
};

DMSRelateesField.propTypes = dxFormFieldViewPropTypes;

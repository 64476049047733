import forIn from 'lodash/forIn';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';

import { isString } from '../isString';

const fieldConstraints = {
  controlled: {
    // The value is set/updated by the controller. Client code cannot set or modify this value.
    // Controlled fields are never validated.
    validate: () => false,
  },
  enum: {
    // The value must be one the enumerated values.
    validate: (constraint, value) => constraint.some((entry) => entry.value === value)
  },
  maxLength: {
    validate: (constraint, value) => isString(value) && value.length <= constraint,
  },
  minLength: {
    validate: (constraint, value) => isString(value) && value.length >= constraint,
  },
  maxValue: {
    validate: (constraint, value) => isNumber(value) && value <= constraint,
  },
  minValue: {
    validate: (constraint, value) => isNumber(value) && value >= constraint,
  },
  pattern: {
    // The string value must match the given regex-pattern.
    validate: (constraint, value) => isString(value) && value.match(constraint),
  },
  allowNewTags: {
    validate: () => true,
  },
  immutable: {
    validate: () => true,
  },
  unique: {
    // TODO: to consider...
    validate: () => true,
  },
};

const collectionConstraints = {
  required: {
    validate: (constraint, fields, addError, opts) => {
      if (opts.assertRequired) {
        for (const fieldId of constraint) {
          if (isUndefined(fields[fieldId])) {
            addError(`The required field "${fieldId}" is missing`);
          }
        }
      }
    }
  }
};

export const addConstraints = (schema) => {
  forIn(fieldConstraints, ({ validate }, type) => {
    schema.addFieldConstraintValidator(type, validate);
  });
  forIn(collectionConstraints, ({ validate }, type) => {
    schema.addCollectionConstraintValidator(type, validate);
  });
};

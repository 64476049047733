import { css } from 'glamor';
import React from 'react';
import { dxColors, dxStyles } from '../react-frontend/styles';

// -- Styles --------------- --- --  -

const containerStyles = css(
  dxStyles.paddedPageElementStyles,
  {
    color: dxColors.fgLighter_2,
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'space-between',
    paddingTop: 12,
    paddingBottom: 24
  }
);

// -- Component --------------- --- --  -

export const Footer = () => (
  <footer {...containerStyles}>
    <div>
      &copy;
      {new Date().getFullYear()}
      , imec vzw
    </div>
  </footer>
);

import invariant from 'invariant';
import { createSelector } from 'reselect';
import isArray from 'lodash/isArray';
import { isString } from '../../utils/isString';

/**
 * @param {ItemsSelectionState} state
 * @return {boolean} True when the given state is .
 */
export const isItemsSelection = (state) => state.isItemsSelection;

/**
 * @param {ItemsSelectionState} state
 * @return {boolean} True when all items are selected.
 */
export const getItemsSelectionAllSelected = (state) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  return state.allSelected;
};

/**
 * @param {ItemsSelectionState} state
 * @return {number} The number of selected items, or NaN when all items are selected.
 */
export const getItemsSelectionCount = (state) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  return state.count;
};

/**
 * @param {ItemsSelectionState} state
 * @return {Object} An object with the selected item identifiers as keys and true as value.
 */
export const getItemsSelectionDict = (state) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  return state.selected;
};

/**
 * @param {ItemsSelectionState} state
 * @param {{ id: string }[]} items - The filtered items.
 * @return {Array.<string>} The list of selected item identifiers.
 */
export const getItemsSelectionIds = (state, items) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  invariant(!items || isArray(items), `Expected an array as "items", got "${items}".`);
  if (state.allSelected) {
    if (items) {
      return items.map((item) => item.id);
    }
    throw new Error('All are selected. Please provide the "items" argument to "getItemsSelectionIds".');
  }
  return Object.keys(getItemsSelectionDict(state));
};

/**
 * @param {ItemsSelectionState} state
 * @return {boolean} True when one of more items are currently selected.
 */
export const getItemsSelectionHasSelected = (state) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  return getItemsSelectionAllSelected(state) || getItemsSelectionCount(state) > 0;
};

/**
 * @param {ItemsSelectionState} state
 * @return {boolean} True when no items are selected.
 */
export const getItemsSelectionNoneSelected = createSelector(
  getItemsSelectionAllSelected,
  getItemsSelectionCount,
  (allSelected, count) => !allSelected && count === 0
);

/**
 * Applies the selection on the given items.
 * @param {ItemsSelectionState} state
 * @param {{ id: string }[]} items - The filtered items.
 */
export const applyItemsSelection = (state, items) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  invariant(isArray(items), `Expected an array as "items", got "${items}".`);
  if (getItemsSelectionAllSelected(state)) {
    return items;
  }
  if (getItemsSelectionNoneSelected(state)) {
    return [];
  }
  const dict = getItemsSelectionDict(state);
  return items.filter((item) => dict[item.id]);
};

/**
 * @param {ItemsSelectionState} state
 * @param {string} id - The item identifier to check.
 * @return {boolean} True when the item with the given identifier is currently selected.
 */
export const isItemSelected = (state, id) => {
  invariant(isItemsSelection(state), `Expected an itemsSelectionReducer state, got "${state}".`);
  invariant(isString(id), `Expected a string as "id", got "${id}".`);
  return getItemsSelectionAllSelected(state) || getItemsSelectionDict(state)[id] === true;
};

import { push, replace } from 'connected-react-router';
import {
  call, put, select, takeEvery
} from 'redux-saga/effects';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import { DxError } from '../../utils/dxf';

import {
  addModalAction,
  dmsItemCreateFailedAction,
  dmsItemCreateSuccessAction,
  dmsItemDeleteFailedAction,
  dmsItemDeleteSuccessAction,
  dmsItemDeselectAction,
  dmsItemFetchFailedAction,
  dmsItemFetchSuccessAction,
  dmsItemSelectAction,
  dmsItemUpdateFailedAction,
  dmsItemUpdateSuccessAction,
  dmsItemsBulkActionApplyAction,
  dmsPageFetchFailedAction,
  dmsPageFetchSuccessAction,
  dmsRelateeOptionsFetchFailedAction,
  dmsRelateeOptionsFetchSuccessAction,
  dmsSelectedItemsPageFetchFailedAction,
  dmsSelectedItemsPageFetchAction,
  dmsSelectedItemsPageFetchSuccessAction,
} from '../actions';
import { BulkActionsModal } from '../components/modals/BulkActionsModal';
import {
  DX_DMS_BULK_ACTION_APPLY,
  DX_DMS_BULK_ACTION_INIT,
  DX_DMS_ITEM_CREATE_COMMIT,
  DX_DMS_ITEM_CREATE_FAILED,
  DX_DMS_ITEM_CREATE_INIT,
  // DX_DMS_ITEM_CREATE_SUCCESS,
  DX_DMS_ITEM_DELETE,
  DX_DMS_ITEM_DELETE_FAILED,
  DX_DMS_ITEM_FETCH,
  DX_DMS_ITEM_FETCH_FAILED,
  DX_DMS_ITEM_UPDATE_COMMIT,
  DX_DMS_ITEM_UPDATE_FAILED,
  DX_DMS_PAGE_FETCH,
  DX_DMS_PAGE_FETCH_FAILED,
  DX_DMS_RELATEE_OPTIONS_FETCH,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH_FAILED,
} from '../constants';
import { fetchQuery } from '../queries';
import { showConfirmationModal } from '.';
import {
  getDMSConfig,
  getDMSItem,
  getDMSItemsAllSelected,
  getDMSItemsSelectedIds,
  getDMSItemsSelectionState,
  getDMSSelectedItemsPageState,
} from '../selectors';
import { join } from '../utils';

import { showErrorModal } from './dxModalSagas';

export function* dxDMSSagas() {
  yield takeEvery(DX_DMS_BULK_ACTION_INIT, function* (action) {
    // console.log('>> handle DX_DMS_BULK_ACTION_INIT', { action });
    const { bulkAction } = action;
    // See react-frontend/components/modals/BulkActionsModal for more information on the options
    // provided below:
    const modalOptions = {
      bulkAction,
      // TODO: consider moving the following to the bulk-action objects, and provide a generic saga in DxF
      applyAction: dmsItemsBulkActionApplyAction,
      deselectItemAction: dmsItemDeselectAction,
      getItemsSelectionState: getDMSItemsSelectionState,
      getPageLoadState: getDMSSelectedItemsPageState,
      loadItemsAction: dmsSelectedItemsPageFetchAction,
      selectItemAction: dmsItemSelectAction,
      size: bulkAction.size,
      title: bulkAction.title,
    };
    yield put(addModalAction(BulkActionsModal, modalOptions));
  });

  yield takeEvery(DX_DMS_BULK_ACTION_APPLY, function* (action) {
    // console.log('>> take DX_DMS_BULK_ACTION_APPLY', { action });
    const { bulkAction: { actionProps = {}, actionType }, data } = action;
    const allSelected = yield select(getDMSItemsAllSelected);
    if (allSelected) {
      yield put({
        type: actionType,
        ...actionProps,
        allSelected,
        data,
        filter: { type: 'all' },
      });
    } else {
      yield put({
        type: actionType,
        ...actionProps,
        allSelected,
        data,
        memberIds: yield select(getDMSItemsSelectedIds),
      });
    }
  });

  yield takeEvery(DX_DMS_ITEM_CREATE_INIT, function* (action) {
    const { collectionId } = action;
    const dmsConfig = yield select(getDMSConfig);
    const dmsColl = dmsConfig.getCollection(collectionId);
    yield put(push(dmsColl.createPath));
  });

  yield takeEvery(DX_DMS_ITEM_CREATE_COMMIT, function* (action) {
    const { collectionId, fields } = action;
    const dmsConfig = yield select(getDMSConfig);
    const dmsColl = dmsConfig.getCollection(collectionId);
    const query = dmsColl.createItemQuery;
    const variables = dmsColl.collSchema.filterFields(fields, 'create');
    const url = dmsConfig.apiUrl;
    const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
    if (complete) {
      const apiField = `create${dmsColl.collSchema.singularCapped}`;
      const item = addItemData(data[apiField], dmsColl, dmsConfig);
      yield put(replace(dmsColl.getItemPath(item.id)));
      yield put(dmsItemCreateSuccessAction(item, collectionId));
    } else if (error) {
      const dxError = new DxError('Failed to create the item.', error);
      yield put(dmsItemCreateFailedAction(fields, collectionId, dxError));
    }
  });

  yield takeEvery(DX_DMS_ITEM_DELETE, function* (action) {
    const { collectionId, id } = action;
    const dmsConfig = yield select(getDMSConfig);
    const dmsColl = dmsConfig.getCollection(collectionId);
    if (!dmsColl.silentDelete) {
      const confirm = yield showConfirmationModal(
        `Delete ${dmsColl.collSchema.labelSingular}`,
        `Are you sure you want to delete the item with ID "${id}"?`,
        {
          noLabel: 'No, thanks',
          yesLabel: 'Yes, please!',
        }
      );
      if (!confirm) {
        return undefined;
      }
    }
    const query = dmsColl.deleteItemQuery;
    const variables = { itemId: id };
    const url = dmsConfig.apiUrl;
    const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
    if (complete) {
      const { reason, success } = data[dmsColl.collSchema.singular].delete;
      if (success) {
        yield put(dmsItemDeleteSuccessAction(collectionId, id));
        yield put(replace(dmsColl.path));
      } else {
        const dxError = new DxError(`Failed to delete the item with ID "${id}".`, reason);
        yield put(dmsItemDeleteFailedAction(collectionId, id, dxError));
      }
    } else if (error) {
      const dxError = new DxError(`Failed to delete the item with ID "${id}".`, error);
      yield put(dmsItemDeleteFailedAction(collectionId, id, dxError));
    }
  });

  yield takeEvery(DX_DMS_ITEM_FETCH, function* (action) {
    const { collectionId, itemId } = action;
    const dmsConfig = yield select(getDMSConfig);
    const dmsColl = dmsConfig.getCollection(collectionId);
    const query = dmsColl.fetchItemQuery;
    const variables = { id: itemId };
    const url = dmsConfig.apiUrl;
    const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
    if (complete) {
      const apiField = Object.keys(query.metadata.fields)[0];
      const item = addItemData(data[apiField], dmsColl, dmsConfig);
      yield put(dmsItemFetchSuccessAction(dmsColl.id, item));
    } else if (error) {
      const dxError = new DxError('Failed to fetch the item details.', error);
      yield put(dmsItemFetchFailedAction(collectionId, itemId, dxError));
    }
  });

  yield takeEvery(DX_DMS_ITEM_UPDATE_COMMIT, function* (action) {
    // console.log(`saga ${DX_DMS_ITEM_UPDATE_COMMIT}`, { action });
    const { collectionId, itemId, fields } = action;
    const dmsConfig = yield select(getDMSConfig);
    const dmsColl = dmsConfig.getCollection(collectionId);
    const query = dmsColl.updateItemQuery;
    const includeRelatees = dmsColl.updateItemIncludeRelatees;
    const variables = { ...dmsColl.collSchema.filterFields(fields, 'update', { includeRelatees }), id: itemId };
    const url = dmsConfig.apiUrl;
    const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
    if (complete) {
      const prevItem = yield select(getDMSItem);
      const nxtItem = addItemData(data[dmsColl.collSchema.singular].update, dmsColl, dmsConfig);
      yield put(dmsItemUpdateSuccessAction({ ...prevItem, ...nxtItem }, collectionId));
    } else if (error) {
      const dxError = new DxError('Failed to update the item.', error);
      yield put(dmsItemUpdateFailedAction(itemId, fields, collectionId, dxError));
    }
  });

  yield takeEvery(DX_DMS_PAGE_FETCH, function* (action) {
    try {
      const {
        collectionId, from = 0, limit = 10, ordering
      } = action;
      const dmsConfig = yield select(getDMSConfig);
      const dmsColl = dmsConfig.getCollection(collectionId);
      const variables = { from, limit, ordering };
      const query = dmsColl.fetchPageQuery;
      const url = dmsConfig.apiUrl;
      const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
      if (complete) {
        const apiTerm = dmsColl.collSchema.plural;
        const items = addItemsData(data[apiTerm], dmsColl);
        const total = data[`${apiTerm}Count`];
        yield put(dmsPageFetchSuccessAction(items, total, collectionId, variables));
      } else if (error) {
        const dxError = new DxError('Failed to fetch the collection items.', error);
        yield put(dmsPageFetchFailedAction(collectionId, dxError, variables));
      }
    } catch (error) {
      console.error(`Error in dxDMSSagas (DX_DMS_PAGE_FETCH): ${error}`);
      console.error(error.stack);
      throw error;
    }
  });

  yield takeEvery(DX_DMS_RELATEE_OPTIONS_FETCH, function* (action) {
    const {
      collectionId, filter, from = 0, limit = 100, ordering, source
    } = action;
    const dmsConfig = yield select(getDMSConfig);
    const dmsColl = dmsConfig.getCollection(collectionId);
    const variables = {
      filter, from, limit, ordering
    };
    const query = dmsColl.filterQuery;
    const url = dmsConfig.apiUrl;
    const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
    if (complete) {
      const result = data[`filter${dmsColl.collSchema.pluralCapped}`];
      if (result.filteredCount > result.pagedCount) {
        console.warn('Not all relatee options are shown.');
      }
      yield put(dmsRelateeOptionsFetchSuccessAction(
        addItemsData(result.items, dmsColl) || [],
        result.filteredCount || 0,
        collectionId,
        filter,
        result.from,
        result.limit,
        result.ordering,
        source,
      ));
    } else if (error) {
      const dxError = new DxError('Failed to fetch the collection items.', error);
      yield put(dmsRelateeOptionsFetchFailedAction(
        dxError,
        collectionId,
        filter,
        from,
        limit,
        ordering,
        source,
      ));
    }
  });

  yield takeEvery(DX_DMS_SELECTED_ITEMS_PAGE_FETCH, function* (action) {
    // console.log('>>> handle DX_DMS_SELECTED_ITEMS_PAGE_FETCH -- action:', action);
    try {
      const {
        collectionId, from = 0, ids, limit = 10, ordering
      } = action;
      const dmsConfig = yield select(getDMSConfig);
      const dmsColl = dmsConfig.getCollection(collectionId);
      const variables = {
        from, limit, ordering, ids
      };
      const query = dmsColl.fetchPageQuery;
      const url = dmsConfig.apiUrl;
      const { complete, data, error } = yield call(fetchQuery, query, { variables, url });
      if (complete) {
        const apiTerm = dmsColl.collSchema.plural;
        const items = addItemsData(data[apiTerm], dmsColl);
        const metadata = {
          total: data[`${apiTerm}Count`],
          collectionId,
          pagedCount: ids.length,
          variables,
        };
        yield put(dmsSelectedItemsPageFetchSuccessAction(collectionId, items, metadata));
      } else if (error) {
        const dxError = new DxError('Failed to fetch the selected items.', error);
        yield put(dmsSelectedItemsPageFetchFailedAction(collectionId, dxError, variables));
      }
    } catch (error) {
      console.error(`Error in dxDMSSagas (DX_DMS_SELECTED_ITEMS_PAGE_FETCH): ${error}`);
      console.error(error.stack);
      throw error;
    }
  });

  // yield takeEvery(DX_DMS_ITEM_UPDATE, function* (action) {
  //   //console.log(DX_DMS_ITEM_UPDATE, { action });
  //   const { collectionId, id } = action;
  //   const basePath = yield select(getDMSBasePath);
  //   const redirectPath = join(basePath, collectionId, id, 'edit');
  //   yield put(push(redirectPath));
  // });

  yield takeEvery(
    [
      DX_DMS_ITEM_CREATE_FAILED,
      DX_DMS_ITEM_DELETE_FAILED,
      DX_DMS_ITEM_FETCH_FAILED,
      DX_DMS_ITEM_UPDATE_FAILED,
      DX_DMS_PAGE_FETCH_FAILED,
      DX_DMS_SELECTED_ITEMS_PAGE_FETCH_FAILED,
    ],
    function* (action) {
      yield showErrorModal(action.error);
    }
  );
}

// -- Helpers --------------- --- --  -

/**
 * @private
 * Non-destructively add 'label' property, and add item data in all relatees.
 * @param {{ id: string }} item
 * @param {DMSCollection} dmsColl
 * @param {DMSConfig} dmsConfig
 * @return {{ id: string }}
 */
const addItemData = (item, dmsColl, dmsConfig) => {
  item = { ...item, label: dmsColl.getItemLabel(...dmsColl.itemLabelFields.map((fi) => item[fi])) };
  dmsColl.collSchema.forEachRelatee((relatee, relateeId) => {
    if (item[relateeId]) {
      const coCollId = relatee.coCollectionId;
      if (dmsConfig.hasCollection(coCollId)) {
        const dmsCoColl = dmsConfig.getCollection(coCollId);
        if (relatee.arityMany) {
          item = { ...item, [relateeId]: addItemsData(item[relateeId], dmsCoColl) };
        } else if (item[relateeId]) {
          item = { ...item, [relateeId]: addItemsData([item[relateeId]], dmsCoColl)[0] };
        }
      } else {
        console.warn(`Missing collection config for "${coCollId}" in dmsConfig.`);
      }
    }
  });
  return item;
};

/**
 * @private
 * Non-destructively add 'label' and 'path' properties for each item.
 * @param {Array.<{ id: string }>} items
 * @param {DMSCollection} dmsColl
 * @returns {Array.<{ id: string }>}
 */
const addItemsData = (items, dmsColl) => {
  if (!items || items.length === 0) { return items; }
  const { getItemLabel, itemLabelFields } = dmsColl;
  if (!isFunction(getItemLabel)) {
    throw new Error(`Expected function as "getItemLabel", got "${getItemLabel}"`
      + ` for dmsColl "${dmsColl.id}" [DMSA_800].`);
  }
  if (!isArray(itemLabelFields)) {
    throw new Error(`Expected array as "itemLabelFields", got "${itemLabelFields}"`
      + ` for dmsColl "${dmsColl.id}" [DMSA_801].`);
  }
  return items.map((item) => ({
    ...item,
    label: getItemLabel(...itemLabelFields.map((field) => item[field])),
    path: join(dmsColl.path, encodeURIComponent(item.id)), // TODO: is encoding realy necessary?
  }));
};

/**
 * @param {PageLoadState} pageLoadState
 * @return {object} The Redux action object.
 */
export const getPageLoadStateAction = (pageLoadState) => pageLoadState.action;

/**
 * @param {PageLoadState} pageLoadState
 * @return {boolean} True when the page of items has been loaded or is being reloaded.
 */
export const getPageLoadStateAvailable = (pageLoadState) => pageLoadState.available;

/**
 * @param {PageLoadState} pageLoadState
 * @return {number} The index of the first item in the page.
 */
export const getPageLoadStateFrom = (pageLoadState) => pageLoadState.from;

/**
 * @param {PageLoadState} pageLoadState
 * @return {Array.<{ id: string }>} The loaded items.
 */
export const getPageLoadStateItems = (pageLoadState) => pageLoadState.items;

/**
 * @param {PageLoadState} pageLoadState
 * @return {number} The maximum number of items in a page.
 */
export const getPageLoadStateLimit = (pageLoadState) => pageLoadState.limit;

/**
 * @param {PageLoadState} pageLoadState
 * @return {boolean} True when the page of items has been loaded.
 */
export const getPageLoadStateLoaded = (pageLoadState) => pageLoadState.loaded;

/**
 * @param {PageLoadState} pageLoadState
 * @return {boolean} True when the page of items is being loaded.
 */
export const getPageLoadStateLoading = (pageLoadState) => pageLoadState.loading;

/**
 * @param {PageLoadState} pageLoadState
 * @return {Array.<cargo-universal/dxSchema/OrderField>} The ordering of the items.
 */
export const getPageLoadStateOrdering = (pageLoadState) => pageLoadState.ordering;

/**
 * @param {PageLoadState} pageLoadState
 * @return {boolean} True when the page of items is being reloaded. The previously
 *   loaded items remain available until the new page is received.
 */
export const getPageLoadStateReloading = (pageLoadState) => pageLoadState.reloading;

/**
 * @param {PageLoadState} pageLoadState
 * @return {*} A context-specific specification of the current source. This may be
 *   simply the collection ID, or an object with the collection ID and the applied filter. This
 *   source specification, together with the _from_, _limit_ and _ordering_ values (which should
 *   thus not be included in the _source_), can be used by components such as {@link ItemsList}
 *   to determine whether to dispatch a new fetch action or not.
 */
export const getPageLoadStateSource = (pageLoadState) => pageLoadState.source;

/**
 * @param {PageLoadState} pageLoadState
 * @return {number} The total number of items in the collection.
 */
export const getPageLoadStateTotal = (pageLoadState) => pageLoadState.total;

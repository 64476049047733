import invariant from 'invariant';
import forIn from 'lodash/forIn';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { isString } from '../isString';
import { toString } from '../toString';
import { DX_META_SCHEMA_URL_V2_0 } from './constants';

/**
 * Normalizes the given raw schema, i.e.:
 * - The `constraints` property of each collection will be an object and
 *   the `required` property of this `constraints` object with be an (empty) array.
 * - The `constraints` property of each relation relatee will be an object.
 * - The `constraints` property of each field will be an (empty) object.
 * - For each entry in `enum` field constraints, when no `value` is provided then the `id` also
 *   becomes the `value`, and when no value is provided for `label` then the `value` also becomes
 *   the `label`.
 * @param {RawDxSchema} schema
 * @return {RawDxSchema}
 */
export const normalizeRawSchema = (schema) => {
  forIn(schema.collections, (collSchema) => normalizeCollection(collSchema, schema));
  forIn(schema.relations, (relSchema, relId) => normalizeRelation(relSchema, relId, schema));
  return schema;
};

const normalizeCollection = (collSchema, { docType: { schema: metaSchema } }) => {
  // console.log('>>> normalizeCollection() -- collSchema:', collSchema);
  // Each collection should have an 'id' field.
  if (!collSchema.fields.id) {
    // console.error('- collSchema:', collSchema);
    throw new Error(`Missing required "id" field for collection "${collSchema.id}".`);
  }

  // The `auth` property should have an object as value.
  if (!isObject(collSchema.auth)) {
    collSchema.auth = {};
  }

  // Normalize auth.scopes specs:
  if (metaSchema === DX_META_SCHEMA_URL_V2_0) {
    if (isArray(collSchema.auth.scopes)) {
      for (const scope of collSchema.auth.scopes) {
        if (!scope.extends && !scope.key) {
          scope.key = 'id';
        }
      }
    } else {
      collSchema.auth.scopes = [];
    }
  }

  // The `constraints` property should have an object as value.
  if (!isObject(collSchema.constraints)) {
    collSchema.constraints = {};
  }

  // The "required" constraint should be an array:
  if (!isArray(collSchema.constraints.required)) {
    collSchema.constraints.required = [];
  }

  // The `indices` prop should be an array, the elements of which should be either field
  // identifiers of an array of field identifiers.
  if (isArray(collSchema.indices)) {
    collSchema.indices = collSchema.indices.reduce((result, index) => {
      if (isArray(index)) {
        for (const idx of index) {
          assertFieldId(collSchema, idx);
        }
        if (index.length === 0) {
          return result;
        }
        if (index.length === 1) {
          return [...result, index[0]];
        }

        return [...result, index];
      }
      if (isString(index)) {
        assertFieldId(collSchema, index);
        return [...result, index];
      }

      throw new Error('Expected strings or arrays of strings as `indices` for a collection, '
          + `instead got "${toString(index)}" for collection "${collSchema.id}".`);
    }, []);
  } else {
    collSchema.indices = [];
  }

  // Set default `last` value for the `nulls` property in the `defaultOrdering` declaration:
  if (collSchema.defaultOrdering) {
    collSchema.defaultOrdering.forEach((orderSchema) => {
      if (!orderSchema.nulls) {
        orderSchema.nulls = 'last';
      }
    });
  }

  forIn(collSchema.fields, (fieldSchema) => {
    // The `constraints` property should have an object as value.
    if (!isObject(fieldSchema.constraints)) {
      fieldSchema.constraints = {};
    }

    // Provide values for the optional `value` and `label` properties of `enum` constraints:
    if (fieldSchema.constraints.enum) {
      fieldSchema.constraints.enum.forEach((entry) => {
        if (entry.value === undefined) { entry.value = entry.id; }
        if (entry.label === undefined) { entry.label = entry.value.toString(); }
      });
    }
  });
};

const normalizeRelation = (relSchema, relId, { collections }) => {
  const { left, right, type } = relSchema;

  // The `constraints` property of the relatee schemas should have an object as value.
  if (!isObject(left.constraints)) { left.constraints = {}; }
  if (!isObject(right.constraints)) { right.constraints = {}; }

  if (!isObject(relSchema.auth)) { relSchema.auth = {}; }

  if (left.constraints.required) {
    console.warn('\nWARNING: The support for the `required` constraint on left-hand relatees is deprecated.');
  }
  if (left.constraints.required && right.constraints.required) {
    throw new Error('The `required` constraint can not hold on both relatees'
      + ' of the same relation.');
  }
  if (left.constraints.required && type !== 'duxis/oneToOne') {
    throw new Error('The `required` constraint is only supported for unary relatees.');
  }
  if (right.constraints.required && type === 'duxis/manyToMany') {
    throw new Error('The `required` constraint is only supported for unary relatees.');
  }
  const sameStore = collections[left.collection].store === collections[right.collection].store;
  if ((left.constraints.required || right.constraints.required) && !sameStore) {
    throw new Error('The `required` constraint is not supported for the inter-store'
      + ` "${relId}" relation.`);
  }
  if (left.arityOne && right.arityMany) {
    throw new Error('Many-to-one relations are not supported. Use one-to-many instead.');
  }
  if (relSchema.softDelete) {
    throw new Error('Soft-deleting relations is not supported.');
  }
};

const assertFieldId = (collSchema, value) => {
  if (!collSchema.fields[value]) {
    throw new Error('Expected field identifiers in the `indices` for a collection, '
      + `instead got "${toString(value)}" for collection "${collSchema.id}".`);
  }
};

// -- Ordering --------------- --- --  -

/**
 * Takes an object-based ordering specification and returns a string-based specification.
 * @param {Array<OrderField>|OrderingString} ordering
 * @returns {OrderingString}
 */
export const orderingToString = (ordering = []) => {
  if (isArray(ordering)) {
    console.warn(
      'This Duxis application is using the `orderingToString` utility to convert an '
      + 'array-based representation of a collection ordering in a string-based representation. '
      + 'This is probably a legacy case that might cause issues when using Duxis Foundation '
      + '1.14.0 in which the inconsistent use of both string-based and array-based orderings has '
      + 'been resolved. Duxis applications should not only use array-based representations of '
      + 'orderings.'
    );
    return ordering.map((os) => {
      const entries = [os.field];
      if (os.direction) { entries.push(os.direction); }
      if (os.nulls) { entries.push(`nulls ${os.nulls}`); }
      return entries.join(' ');
    }).join(', ');
  }
  // noinspection JSValidateTypes
  return ordering;
};

/**
 * Takes an ordering string and returns an ordering array.
 * @param {OrderingString} str - An `ordering` string.
 * @returns {Array.<OrderField>|undefined} An ordering specification that can be passed to the
 *   Controller.getCollection. method.
 */
export const parseOrdering = (str) => {
  if (isArray(str)) {
    // noinspection JSValidateTypes
    return str;
  }
  if (str === '') {
    return undefined;
  }
  if (isString(str)) {
    const ordering = [];
    str.split(/,\s*/).forEach((fieldStr) => {
      const fragments = fieldStr.split(/\s+/);
      const entry = {
        field: fragments[0],
        direction: 'asc',
        nulls: 'last',
      };
      if (fragments.length >= 2) {
        const fr1 = fragments[1];
        if (fr1 === 'asc' || fr1 === 'desc') {
          entry.direction = fr1;
          if (fragments.length >= 4) {
            const fr2 = fragments[2];
            const fr3 = fragments[3];
            if (fr2 === 'nulls' && (fr3 === 'first' || fr3 === 'last')) {
              entry.nulls = fr3;
            }
          }
        } else if (fr1 === 'nulls' && fragments.length >= 3) {
          const fr2 = fragments[2];
          if (fr2 === 'first' || fr2 === 'last') {
            entry.nulls = fr2;
          }
        }
      }
      ordering.push(entry);
    });
    return ordering;
  }
  return undefined;
};

/**
 * Compares two orderings.
 * @param {Ordering|string} oa
 * @param {Ordering|string} ob
 * @return {boolean}
 */
export const equalOrdering = (oa, ob) => {
  invariant(oa === undefined || oa === null || isArray(oa) || isString(oa),
    `Expected an array or a string as first argument for equalOrdering, got "${oa}".`);
  invariant(ob === undefined || ob === null || isArray(ob) || isString(ob),
    `Expected an array or a string as second argument for equalOrdering, got "${ob}".`);

  if (isString(oa)) { oa = parseOrdering(oa); }
  if (isString(ob)) { ob = parseOrdering(ob); }

  if (oa === ob) { return true; }
  if (!oa && isArray(ob)) {
    return ob.length === 0;
  }
  if (!ob && isArray(oa)) {
    return oa.length === 0;
  }

  return oa.length === ob.length && oa.every((fa, idx) => {
    const fb = ob[idx];
    return fa.field === fb.field
      && equalOrderingDirection(fa.direction, fb.direction)
      && equalOrderingNulls(fa.nulls, fb.nulls);
  });
};

/**
 * Compares two ordering "direction" values.
 * @param {string} va
 * @param {string} vb
 * @return {boolean}
 */
const equalOrderingDirection = (va, vb) => (va === 'desc' && vb === 'desc')
  || ((va === undefined || va === 'asc') && (vb === undefined || vb === 'asc'));

/**
 * Compares two ordering "nulls" values.
 * @param {string} va
 * @param {string} vb
 * @return {boolean}
 */
const equalOrderingNulls = (va, vb) => (va === 'first' && vb === 'first')
  || ((va === undefined || va === 'last') && (vb === undefined || vb === 'last'));

export const printRelation = (relation) => {
  const { left, right } = relation;
  let str = `${relation.id} - `;

  // let relatee;
  if (left.arityOne) {
    if (right.arityOne) {
      str = `${str}one|1 --- one|1 (FK)`;
    } else {
      str = `${str}mny|1 --- one|n (FK)`;
    }
  } else if (right.arityOne) {
    str = `${str}one|n --- mny|1 (FK)`;
  } else {
    str = `${str}mny|n --- mny|n (pivot)`;
  }
  return str;
};

export const printRelatee = (relatee) => {
  const { coRelatee } = relatee;
  let str = `${relatee.collection.id}.${relatee.fieldId} => ${relatee.relation.id} - `;
  if (relatee.isLeft) {
    if (relatee.arityOne) {
      if (coRelatee.arityOne) {
        str = `${str}one|1 --> one|1 (FK)`;
      } else {
        str = `${str}one|n <-- mny|1 (FK)`;
      }
    } else if (coRelatee.arityOne) {
      str = `${str}one|n --> mny|1 (FK)`;
    } else {
      str = `${str}mny|n --> mny|n (pivot)`;
    }
  } else if (relatee.arityOne) {
    if (coRelatee.arityOne) {
      str = `${str}one|1 <-- one|1 (FK)`;
    } else {
      str = `${str}one|n <-- mny|1 (FK)`;
    }
  } else if (coRelatee.arityOne) {
    str = `${str}one|n <-- mny|1 (FK)`;
  } else {
    str = `${str}mny|n <-- mny|n (pivot)`;
  }
  return str;
};

export const logRelatee = (relatee) => {
  console.info(printRelatee(relatee));
};

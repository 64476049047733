import PropTypes from 'prop-types';

import { DMSConfig } from '../dms';

import { bulkActionPropType } from './bulkActionPropTypes';
import { dxQueryPropType } from './dxQueryPropType';
import { itemLoadStatePropTypesOf, pageLoadStatePropTypesOf } from './loadStatePropTypes';
import { viewSchemaPropType } from './viewSchemaPropType';
import { itemsSelectionPropType } from './itemsSelectionPropTypes';

// -- dmsCollectionConfigPropType --------------- --- --  -

/**
 * User-provided configuration of a collection managed by dxDMS.
 */
export const dmsCollectionConfigPropTypes = {
  /** The bulk-commands. */
  bulkActions: PropTypes.arrayOf(bulkActionPropType).isRequired,

  /** When true new items for this collection cannot be created with the DMS. Defaults to false. */
  disableCreate: PropTypes.bool,

  /** When true items in this collection cannot be deleted with the DMS. Defaults to false. */
  disableDelete: PropTypes.bool,

  /** When true items in this collection cannot be updated with the DMS. Defaults to false. */
  disableUpdate: PropTypes.bool,

  /**
   * Optional function that takes the values for the fields specified in _itemLabelFields_ and
   * returns the label to use in the DMS. The default implementation tries to use the value of the
   * fields 'name', 'label', 'title', and as a last resort used the ID (which is pretty random).
   *
   * @example
   * {
   *   id: 'members',
   *   itemLabelFields: ['first_name', 'last_name'],
   *   getItemLabel: (firstName, lastName) => `${firstName} ${lastName}`,
   * }
   */
  getItemLabel: PropTypes.func,

  /** The collection ID. */
  id: PropTypes.string.isRequired,

  /** The fields from which the label is derived. */
  itemLabelFields: PropTypes.arrayOf(PropTypes.string),

  /**
   * Optional component that renders an item details page.
   * This component receives the props specified in {@link dmsItemComponentPropTypes}.
   */
  itemComponent: PropTypes.elementType,

  /**
   * Optional component that renders a page of items in a collection.
   * This component receives the props specified as {@link dmsCollectionComponentPropTypes}.
   */
  listComponent: PropTypes.elementType,

  /** When true the user does not need to confirm item deletions. Defaults to false. */
  silentDelete: PropTypes.bool,

  /**
   * Optional view-schema for item details view.
   */
  viewSchema: viewSchemaPropType,
};

/**
 * User-provided configuration of a collection managed by dxDMS.
 */
export const dmsCollectionConfigPropType = PropTypes.shape(dmsCollectionConfigPropTypes);

// -- dmsConfigPropType --------------- --- --  -

/**
 * User-provided configuration of the data managed by dxDMS.
 */
export const dmsConfigPropTypes = {
  /** The base URL of the dxAPI for accessing the data. */
  apiUrl: PropTypes.string.isRequired,

  /** Configuration of the collections to manage. */
  collections: PropTypes.arrayOf(dmsCollectionConfigPropType).isRequired,

  /**
   * Optional component that renders the collections overview page.
   * This component receives the props specified as {@link dmsCollectionsComponentPropTypes}.
   */
  component: PropTypes.elementType,
};

/**
 * User-provided configuration of the data managed by dxDMS.
 */
export const dmsConfigPropType = PropTypes.shape(dmsConfigPropTypes);

// -- dmsCollectionsPropType --------------- --- --  -

/**
 * The properties of a collection in a DMSConfig. These encompass the user-provided config
 * properties and properties added by the DMSConfig.
 */
export const dmsCollectionPropTypes = {
  ...dmsCollectionConfigPropTypes,

  /** A dxQuery for this collection. */
  createItemQuery: dxQueryPropType.isRequired,

  /** A dxQuery for this collection. */
  deleteItemQuery: dxQueryPropType.isRequired,

  /** A dxQuery for this collection. */
  fetchItemQuery: dxQueryPropType.isRequired,

  /** A dxQuery for this collection. */
  fetchPageQuery: dxQueryPropType.isRequired,

  /** A dxQuery for this collection. */
  filterQuery: dxQueryPropType.isRequired,

  /**
   * The base path for this collection, composed out of the _path_ provided to the DMSRoutes,
   * followed by the collection ID, e.g. `/custom/members`.
   */
  path: PropTypes.string,

  /** A dxQuery for this collection. */
  updateItemQuery: dxQueryPropType.isRequired,
};

/**
 * The properties of a collection in a DMSConfig. These encompass the user-provided config
 * properties and properties added by the DMSConfig.
 */
export const dmsCollectionPropType = PropTypes.shape(dmsCollectionPropTypes);

// -- dmsCollectionItemPropTypes --------------- --- --  -

/**
 * The props of the item object provided to the custom component that renders the details of an
 * item. Next to the three fixed props, the item object will also include all fields listed in the
 * _itemLabelFields_ options provided to the dms-configuration (See
 * {@link dmsCollectionConfigPropTypes}) of the collection to which this item belongs.
 */
export const dmsCollectionItemPropTypes = {
  /** The ID of the item. */
  id: PropTypes.string.isRequired,

  /**
   * The label for the item. Either derived using the _getItemLabel_ and _itemLabelFields_ options
   * provided to the dms-configuration (See {@link dmsCollectionConfigPropTypes}) of the collection
   * to which this item belongs, or the value of the _albel_, _name_, _title_ or _id_ fields of the
   * given item.
   */
  label: PropTypes.string.isRequired,

  /**
   * The path for viewing the item details.
   */
  path: PropTypes.string.isRequired,
};

export const dmsCollectionItemPropType = PropTypes.shape(dmsCollectionItemPropTypes);

// -- dmsCollectionsComponentPropTypes --------------- --- --  -

/**
 * The props provided to the custom component that renders the list of collections in a dxDMS.
 */
export const dmsCollectionsComponentPropTypes = {
  /** True when the user is authorized to view the collection. */
  canView: PropTypes.bool.isRequired,

  /** The DMSConfig object. */
  dmsConfig: PropTypes.instanceOf(DMSConfig).isRequired,
};

/**
 * The props provided to the custom component that renders the list of collections in a dxDMS.
 */
export const dmsCollectionsComponentPropType = PropTypes.shape(dmsCollectionsComponentPropTypes);

// -- dmsCollectionComponentPropTypes --------------- --- --  -

/**
 * The props provided to the component that renders a page of items in a collection.
 */
export const dmsCollectionComponentPropTypes = {
  /** True when the user is authorized to create new items in the collection. */
  canCreate: PropTypes.bool.isRequired,

  /** True when the user is authorized to delete items from the collection. */
  canDelete: PropTypes.bool.isRequired,

  /** True when the user is authorized to view the collection. */
  canView: PropTypes.bool.isRequired,

  /**
   * The DMS configuration for the collection to which the given item belongs.
   * Note that all properties in the corresponding collection object in the configuration provided
   * to the DMSConfig constructor, will be available in this collection object.
   */
  collection: dmsCollectionPropType.isRequired,

  /** Optional path of the page for creating a new item. */
  createPath: PropTypes.string,

  /**
   * A function that dispatches the action to delete the given item. This function should take two
   * arguments:
   * 1) The ID of the collection from which to delete the item.
   * 2) The item object.
   */
  deleteItem: PropTypes.func,

  /**
   * A function that dispatches an action to unselect an item.
   * This function takes one argument: the item ID.
   * This function should be provided when _bulkActions_ is provided.
   */
  deselectItem: PropTypes.func.isRequired,

  /**
   * A function that dispatches the action to load a page of items. This function takes one
   * argument: an object with three properties:
   * 1) The index of the first item,
   * 2) The maximum number of items in the page, and
   * 3) An optional ordering specification.
   */
  loadItems: PropTypes.func.isRequired,

  /**
   * The page-load-state that represents the items to show.
   * See the _dxLoadState_ manual for more details.
   */
  loadState: pageLoadStatePropTypesOf(dmsCollectionItemPropType).isRequired,

  /**
   * The items selection state. Should be provided when _bulkActions_ is provided.
   */
  selectionState: itemsSelectionPropType,

  /**
   * A function that dispatches an action to select an item.
   * This function takes one argument: the item ID.
   * This function should be provided when _bulkActions_ is provided.
   */
  selectItem: PropTypes.func.isRequired,

  /** Whether or not to show the breadcrumbs. Defaults to true. */
  showBreadcrumbs: PropTypes.bool,

  /** When true the user does not need to confirm item deletions. Defaults to false. */
  silentDelete: PropTypes.bool,
};

/**
 * The props provided to the component that renders a page of items in a collection.
 */
export const dmsCollectionComponentPropType = PropTypes.shape(dmsCollectionComponentPropTypes);

// -- dmsItemComponentPropType --------------- --- --  -

/**
 * The props provided to a custom component that renders the details of one item.
 */
export const dmsItemComponentPropTypes = {
  /** True when the user is authorized to create new items in the collection. */
  canCreate: PropTypes.bool.isRequired,

  /** True when the user is authorized to edit the items in the collection. */
  canUpdate: PropTypes.bool.isRequired,

  /** True when the user is authorized to view the items in the collection. */
  canView: PropTypes.bool.isRequired,

  /**
   * The DMS configuration for the collection to which the given item belongs.
   * Note that all properties in the corresponding collection object in the configuration provided
   * to the DMSConfig constructor, will be available in this collection object.
   */
  collection: dmsCollectionPropType.isRequired,

  /**
   * A function that dispatches the action to commit the new item in the store.
   * This function takes two arguments: 1) an object that contains the new item's fields,
   * and 2) the collection ID.
   * This action dispatcher should be provided when using the component in 'create' mode.
   */
  createCommit: PropTypes.func,

  /**
   * A function that dispatches an action to initialize the creation of a new item. This action
   * should be (one of) the createInitActionType(s) specified for the given _loadState_.
   * This function takes one arguments: the ID of the collection to which the new item belongs.
   * This action dispatcher should be provided when using the component in 'create' mode.
   */
  createInit: PropTypes.func,

  /** The item. Undefined while _loadState.available_ is not true. */
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // + all other item props for the given collection
  }),

  /** The ID of the item. Required when `mode` is `EDIT_MODE` or `CREATE_MODE`. */
  itemId: PropTypes.string,

  /** The item-load-state for the item. */
  loadState: itemLoadStatePropTypesOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    // + all other item props for the given collection
  })).isRequired,

  /** Either `VIEW_MODE` (default), `EDIT_MODE` or `CREATE_MODE` from `react-frontend/constants`. */
  mode: PropTypes.string,

  /** Whether or not to show the breadcrumbs. Defaults to true. */
  showBreadcrumbs: PropTypes.bool,

  /**
   * A function that dispatches the action to commit the updated item in the store.
   * This function takes three arguments: 1) the item ID, 2) an object with the updated fields,
   * and 3) the collection ID.
   * This action dispatcher should be provided when using the component in 'edit' mode.
   */
  updateCommit: PropTypes.func,

  /**
   * Optional specification of how the item details should be rendered. Defaults to showing all
   * fields.
   * @see {@link module:components/item/ItemForm:ViewSchema}
   */
  viewSchema: viewSchemaPropType,
};

/**
 * The props provided to a custom component that renders the details of one item.
 */
export const dmsItemComponentPropType = PropTypes.shape(dmsItemComponentPropTypes);

import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import invariant from 'invariant';
import { capitalize } from '../capitalize';
import { isString } from '../isString';

// -- RelateeSchema Class --------------- ---  --  -

/**
 * Represents one of the participants in a relation in a dxSchema.
 */
export class RelateeSchema {
  /**
   * @param {string} id - The ID for this relatee, typically either 'left' or 'right'.
   * @param {object} rawSchema - The raw JSON schema.
   * @param {Arity} arity - The arity of this relatee.
   * @param {boolean} isLeft - True if this is the left-side relatee.
   * @param {RelateeSchema} coRelateeSchema - The opposite relatee-schema in the relation.
   * @param {RelationSchema} relationSchema - The RelationSchema.
   * @param {DxSchemaBase} dxSchema - The dxSchema.
   */
  initAlpha(id, rawSchema, arity, isLeft, coRelateeSchema, relationSchema, dxSchema) {
    invariant(isString(id), `Expected a string as "id", got "${id}".`);
    invariant(isObject(rawSchema), `Expected an object as "rawSchema", got "${rawSchema}".`);
    invariant(arity.isArity, `Expected an Arity as "arity", got "${arity}".`);
    invariant(isBoolean(isLeft), `Expected a boolean as "isLeft", got "${isLeft}".`);
    invariant(coRelateeSchema.isRelateeSchema, `Expected a RelateeSchema as "coRelateeSchema", got "${coRelateeSchema}".`);
    invariant(relationSchema.isRelationSchema, `Expected a RelationSchema as "relationSchema", got "${relationSchema}".`);
    invariant(dxSchema.isDxSchema, `Expected a DxSchema as "dxSchema", got "${dxSchema}".`);

    this._arity = arity;
    this._collSchema = dxSchema.getCollection(rawSchema.collection);
    this._coRelateeSchema = coRelateeSchema;
    this._fieldIdCapped = capitalize(rawSchema.field);
    this._id = id;
    this._isLeft = isLeft;
    this._isRequired = Boolean(rawSchema.constraints && rawSchema.constraints.required);
    this._isRight = !isLeft;
    this._rawSchema = rawSchema;
    this._relSchema = relationSchema;
    this._schema = dxSchema;

    this._collSchema.addRelatee(this);
  }

  /**
   * Called after having called initAlpha for both relatees in the relation.
   */
  initBeta() {
    if (this._relSchema.isManyToMany) {
      // Case: many-to-many relation using intermediate table
      this._locallyManaged = this._relSchema.storeId === this._collSchema.storeId;
    } else {
      // Case: all one-to-one or one-to-many relations, which are implemented using a
      // foreign key, managed by the right=hand relatee
      this._locallyManaged = this.isRight || this._collSchema.storeId === this.coCollection.storeId;
    }
  }

  // -- Accessors --------------- ---  --  -

  /**
   * @returns {Arity} The arity, either `Arity.one` or `Arity.many`.
   */
  get arity() { return this._arity; }

  /**
   * @returns {boolean} True when this relatee has arity 'many'.
   */
  get arityMany() { return this._arity.isMany; }

  /**
   * @returns {boolean} True when this relatee has arity 'one'.
   */
  get arityOne() { return this._arity.isOne; }

  /**
   * @returns {Arity} The arity of the co-relatee, either `Arity.one` or `Arity.many`.
   */
  get coArity() { return this._coRelateeSchema._arity; }

  /**
   * @returns {boolean} True when the co-relatee has arity 'many'.
   */
  get coArityMany() { return this.coArity.isMany; }

  /**
   * @returns {boolean} True when the co-relatee has arity 'one'.
   */
  get coArityOne() { return this.coArity.isOne; }

  /**
   * @returns {CollectionSchema} The schema of the collection of the other relatee in the relation.
   */
  get coCollection() { return this._coRelateeSchema.collection; }

  /**
   * @returns {string} The id of the collection of the other relatee in the relation.
   */
  get coCollectionId() { return this._coRelateeSchema.collection.id; }

  /**
   * @returns {CollectionSchema} The schema of the collection of the other relatee in the relation.
   */
  get coCollectionSchema() { return this._coRelateeSchema.collection; }

  /**
   * @returns {Controller} The controller of the co-relatee collection.
   */
  get coController() { return this._coRelateeSchema.controller; }

  /**
   * @returns {string} The field id of the co-relatee.
   */
  get coFieldId() { return this._coRelateeSchema.fieldId; }

  /**
   * @returns {CollectionSchema} The schema of the collection for this relatee.
   */
  get collection() { return this._collSchema; }

  /**
   * @returns {string} The schema of the collection for this relatee.
   */
  get collectionId() { return this._collSchema.id; }

  /**
   * @returns {CollectionSchema} The schema of the collection for this relatee.
   */
  get collectionSchema() { return this._collSchema; }

  /**
   * @returns {object} The constraints for this relatee as specified in the schema.
   */
  get constraints() { return this._rawSchema.constraints; }

  /**
   * @returns {Controller} The controller of the relatee collection.
   */
  get controller() { return this._collSchema.controller; }

  /**
   * @returns {RelateeSchema} The schema of the other relatee in the relation.
   */
  get coRelatee() { return this._coRelateeSchema; }

  /**
   * @returns {string} The id of the other relatee in the relation.
   */
  get coRelateeId() { return this._coRelateeSchema.id; }

  /**
   * @returns {RelateeSchema} The schema of the other relatee in the relation.
   */
  get coRelateeSchema() { return this._coRelateeSchema; }

  /**
   * @returns {string} The description for this relatee as provided in the schema.
   */
  get description() { return this._rawSchema.description; }

  /**
   * @returns {string} The field id.
   */
  get fieldId() { return this._rawSchema.field; }

  /**
   * @returns {string} The field id, but with capital first letter.
   */
  get fieldIdCapped() { return this._fieldIdCapped; }

  /**
   * @return {string} The ID of this relatee.
   */
  get id() { return this._id; }

  /**
   * @return {boolean} True when this relatee is part of a relation among collections managed by
   *   different stores.
   */
  get interStore() { return this._relSchema.interStore; }

  /**
   * @returns {boolean} True if this is the left-hand relatee of the relation.
   */
  get isLeft() { return this._isLeft; }

  /**
   * @returns {boolean} True if this is the right-hand relatee of the relation.
   */
  get isRight() { return this._isRight; }

  /**
   * Shorthand for isRelateeSchema.
   * @return {boolean} True.
   */
  get isRelatee() { return true; }

  /**
   * @return {boolean} True.
   */
  get isRelateeSchema() { return true; }

  /**
   * @returns {boolean} True when the required constraint is specified for this relatee.
   */
  get isRequired() { return this._isRequired; }

  /**
   * @returns {string} The label for this relatee as provided in the schema.
   */
  get label() { return this._rawSchema.label; }

  /**
   * @return {boolean} True when the foreign key or the intermediate table is managed in the same
   *   store as this relatee's collection. Note that while the concrete controller of this
   *   relatee is thus capable of manipulating the relation, the related collection is managed by
   *   another controller. The relatee's controller is thus not capable of e.g. checking that a
   *   relatee exists or is soft-deleted, given its ID.
   */
  get locallyManaged() { return this._locallyManaged; }

  /**
   * @returns {object} The raw relatee-schema object.
   */
  get rawSchema() { return this._rawSchema; }

  /**
   * @returns {RelationSchema} The schema of the relation to which this relatee belongs.
   */
  get relation() { return this._relSchema; }

  /**
   * @returns {string} The ID of the relation to which this relatee belongs.
   */
  get relationId() { return this._relSchema.id; }

  /**
   * @returns {RelationSchema} The schema of the relation to which this relatee belongs.
   */
  get relationSchema() { return this._relSchema; }

  // -- Methods --------------- --- --  -

  /**
   * @throws {Error} When the this relatee does not have an arity of one.
   */
  assertArityOne() {
    if (!this.arityOne) {
      throw new Error(`The "${this.collectionId}.${this.fieldId}" relatee has arity > 1.`);
    }
  }

  /**
   * @throws {Error} When the this relatee has an arity of one.
   */
  assertArityMany() {
    if (!this.arityMany) {
      throw new Error(`The "${this.collectionId}.${this.fieldId}" relatee has arity one.`);
    }
  }

  /**
   * @returns {string} The GraphQL type.
   */
  gqlTypeString() {
    if (!this._gqlTypeString) {
      const typeString = 'ID';
      this._gqlTypeString = this.arityOne ? typeString : `[${typeString}]`;
      if (this.isRequired) { this._gqlTypeString = `${this._gqlTypeString}!`; }
    }
    return this._gqlTypeString;
  }

  /**
   * Convenience method that returns the first argument when this is a left-side relatee, or the
   * second argument if this is a right-side relatee.
   * @param {*} left
   * @param {*} right
   * @return {*}
   */
  pickSide(left, right) { return this._isLeft ? left : right; }

  validate(value) {
    return this._collSchema.schema.validateRelateeField(this.collectionId, this.fieldId, value);
  }
}

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Check from '../../assets/icons/Check';
import { dxColors } from '../../styles/dxColors';

// -- Styles --------------- --- --  -

const checkBoxStyles = css({
  cursor: 'pointer',
  margin: '14px 4px 14px 6px',
});

const checkedStyles = css({
  borderRadius: '2px',
  marginBottom: '1px',
});

const uncheckedStyles = css({
  border: `2px solid ${dxColors.thinBorder}`,
  borderRadius: '2px',
  cursor: 'pointer',
  display: 'block',
});

// -- Component --------------- --- --  -

class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    if (this.props.onChange) {
      this.props.onChange(this.props.checked);
    }
  }

  renderCheck() {
    const {
      backgroundColor, checked, className, color, size
    } = this.props;
    if (checked) {
      return (
        <Check
          backgroundColor={backgroundColor}
          className={className}
          color={color}
          height={size}
          styles={checkedStyles}
          width={size}
        />
      );
    }

    return (
      <span
        {...css(uncheckedStyles, { width: size, height: size })}
        className="chk-unchecked"
      />
    );
  }

  render() {
    const { size } = this.props;
    return (
      <span {...css(checkBoxStyles, { height: size, width: size })} onClick={this.onChange}>
        {this.renderCheck()}
      </span>
    );
  }
}

CheckBox.propTypes = {
  backgroundColor: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.number,
};

CheckBox.defaultProps = {
  backgroundColor: dxColors.bondiBlue,
  checked: false,
  color: '#fff',
  size: 14,
};

export default CheckBox;

import { GraphQLInt, GraphQLList, GraphQLString } from 'graphql';
import GraphQLJson from 'graphql-type-json';

export const idListField = {
  type: new GraphQLList(GraphQLString),
  description: 'Optional list of specific IDs to fetch',
  defaultValue: [],
};

export const pagingFromField = {
  type: GraphQLInt,
  description: 'The index of the first item in the requested/returned page.',
  defaultValue: 0,
};

export const pagingLimitField = {
  type: GraphQLInt,
  description: 'The maximum number of items to return. No limit when 0.',
  defaultValue: 0,
};

export const pagedCountField = {
  type: GraphQLInt,
  description: 'The number of items in the current page.',
};

/**
 * Common GraphQL.js input-type fields for a standard dxItemset, with arguments for the requested
 * `from`, `limit` and `ordering`. It also accepts an optional specific list of ids to request.
 */
export const itemsetInputFields = {
  ids: idListField,
  from: pagingFromField,
  limit: pagingLimitField,
  ordering: {
    type: GraphQLJson,
    description: 'A string-based or object-based ordering specification.',
  },
};

/**
 * Common GraphQL.js output-type fields for a standard dxItemset, with arguments for the actual
 * `from`, `limit` and `ordering`.
 */
export const itemsetOutputFields = {
  from: pagingFromField,
  limit: pagingLimitField,
  ordering: {
    type: GraphQLJson,
    description: 'Specifies how the items are ordered.',
  },
  pagedCount: pagedCountField,
};

import { getItemLoadStateId, getItemLoadStateItem } from './itemLoadStateSelectors';
import { getPageLoadStateItems } from './pageLoadStateSelectors';
import { getItemsSelectionAllSelected, getItemsSelectionIds } from './itemsSelectionSelectors';

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {DMSState}
 */
export const getDMSState = (state) => state.dxDMS;

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {DMSConfig}
 */
export const getDMSConfig = (state) => getDMSState(state).config;

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {string}
 */
export const getDMSApiUrl = (state) => {
  const config = getDMSConfig(state);
  return config ? config.apiUrl : undefined;
};

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {string}
 */
export const getDMSBasePath = (state) => {
  const config = getDMSConfig(state);
  return config ? config.basePath : undefined;
};

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {Array.<DMSCollection>}
 */
export const getDMSCollections = (state) => {
  const config = getDMSConfig(state);
  return config ? config.collections : undefined;
};

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @param {string} id
 * @return {DMSCollection}
 */
export const getDMSCollection = (state, id) => {
  const config = getDMSConfig(state);
  return config ? config.getCollection(id) : undefined;
};

/**
 * Returns the view-schema for the collection with the given ID, when provided in the initial
 * DMS config.
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @param {string} id - The collection ID.
 * @return {ViewSchema|undefined}
 */
export const getDMSViewSchema = (state, id) => getDMSCollection(state, id).viewSchema;

// -- Item State --------------- --- --  -

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {ItemLoadState}
 */
export const getDMSItemLoadState = (state) => getDMSState(state).item;

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {{ id: string }}
 */
export const getDMSItem = (state) => getItemLoadStateItem(getDMSItemLoadState(state));

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {string}
 */
export const getDMSItemId = (state) => getItemLoadStateId(getDMSItemLoadState(state));

// -- Items State --------------- --- --  -

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {DMSItemsState}
 */
export const getDMSItemsState = (state) => getDMSState(state).items;

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {PageLoadState}
 */
export const getDMSItemsPageState = (state) => getDMSItemsState(state).page;

// -- Items Selection State --------------- --- --  -

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {ItemsSelectionState} The items-selection-state.
 * @see createItemsSelectionReducer
 */
export const getDMSItemsSelectionState = (state) => getDMSItemsState(state).selection;

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {boolean} True when all items are selected.
 */
export const getDMSItemsAllSelected = (state) => getItemsSelectionAllSelected(getDMSItemsSelectionState(state));

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {Array.<string>} The selected item identifiers.
 * @throws When all items are selected.
 */
export const getDMSItemsSelectedIds = (state) => getItemsSelectionIds(getDMSItemsSelectionState(state));

// -- Selected Items Page --------------- --- --  -

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {PageLoadState}
 */
export const getDMSSelectedItemsPageState = (state) => getDMSItemsState(state).selectedItemsPage;

// -- Relatees State --------------- --- --  -

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {PageLoadState} - The relatees page-load-state.
 * @see createPageLoadStateReducer
 */
export const getDMSRelateesLoadState = (state) => getDMSState(state).relatees;

/**
 * @param {{ dxDMS: DMSState }} state - The Redux state.
 * @return {Array.<{ id: string }>} The currently loaded page of relatees.
 */
export const getDMSRelatees = (state) => getPageLoadStateItems(getDMSRelateesLoadState(state));

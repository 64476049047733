import PropTypes from 'prop-types';

import { cssPropType } from './cssPropType';
import { dxFilterPropType } from './dxFilterPropTypes';

/**
 * See {@link module:components/item/ItemForm.ViewSchema} (TODO: doctype declared in `ItemForm`)
 */
export const viewSchemaPropType = PropTypes.shape({
  // components: PropTypes.shape({}),

  /** Optional list of fields not to show. */
  exclude: PropTypes.arrayOf(PropTypes.string),

  /**
   * Optional field configurations. When this is not provided, all fields will be shown wuth the
   * default field controls.
   */
  fields: PropTypes.arrayOf(PropTypes.shape({
    /** An optional custom component that renders the field control (see dxFormFieldViewPropTypes). */
    component: PropTypes.func,

    /** True when this field can be edited by the user. */
    editable: PropTypes.bool,

    /**
     * Optional filter to apply on the relatee options shown in a {@link DMSRelateesField}.
     * You can provide a regular filter object, or you can (experimentally) provide a function.
     * This function takes the id of the item whose relatees are being rendered, and should return
     * a filter object.
     */
    filter: PropTypes.oneOfType([dxFilterPropType, PropTypes.func]),

    /** The field ID. */
    id: PropTypes.string.isRequired,

    /** The field label. */
    label: PropTypes.string,

    /**
     * Optional glamor styling, either a vanilla object like `{ color: 'red' }` or a precompiled
     * Glamor object like `css({ color: 'red' })`, or an array of such elements.
     */
    styles: cssPropType,
  })),
});

/**
 * @typedef {object} Arity
 * @property {boolean} isArity - True for Arity objects.
 * @property {boolean} isMany - True for the arity-many object.
 * @property {boolean} isOne - True for the arity-one object.
 */

/**
 * Represents arity many.
 * @type {Arity}
 */
export const many = {
  isArity: true,
  isMany: true,
  isOne: false,
};

/**
 * Represents arity one.
 * @type {Arity}
 */
export const one = {
  isArity: true,
  isMany: false,
  isOne: true,
};

import { FETCH_EXTERNAL_DXSCHEMA_START, FETCH_EXTERNAL_DXSCHEMA_SUCCESS, FETCH_EXTERNAL_DXSCHEMA_ERROR } from '../constants/actionTypes';

export const fetchExternalDxSchemaAction = () => ({
  type: FETCH_EXTERNAL_DXSCHEMA_START,
});

export const fetchExternalDxSchemaSuccessAction = (data) => ({
  data,
  type: FETCH_EXTERNAL_DXSCHEMA_SUCCESS,
});

export const fetchExternalDxSchemaErrorAction = (error) => ({
  error,
  type: FETCH_EXTERNAL_DXSCHEMA_ERROR,
});

import PropTypes from 'prop-types';
import React from 'react';

import { dxFormFieldControlPropTypes, dxFormInputControlPropTypes } from '../../../propTypes';

import { FormControlFFC } from './FormControlFFC';

// -- Component --------------- ---  --  -

/**
 * Form-field-component that uses the `input` element.
 * @param {object} props
 * @returns {Function}
 * @constructor
 */
export const InputFFC = (props) => <FormControlFFC {...props} componentClass="input" />;

InputFFC.propTypes = {
  ...dxFormFieldControlPropTypes,
  ...dxFormInputControlPropTypes,

  /**
   * When the current value is null, and this function is provided, then when the user clicks on the
   * empty form, the value is set to the result of calling this function.
   */
  getInitialValue: PropTypes.func,

  /**
   * The optional value for the `type` prop of the <input/> element.
   */
  type: PropTypes.string.isRequired,
};

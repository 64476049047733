import invariant from 'invariant';
import makeHash from 'hash-sum';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { isString } from '../../utils/isString';

import {
  DX_DMS_BULK_ACTION_APPLY,
  DX_DMS_BULK_ACTION_INIT,
  DX_DMS_ITEM_DESELECT,
  DX_DMS_ITEM_CREATE_COMMIT,
  DX_DMS_ITEM_CREATE_FAILED,
  DX_DMS_ITEM_CREATE_INIT,
  DX_DMS_ITEM_CREATE_SUCCESS,
  DX_DMS_ITEM_DELETE,
  DX_DMS_ITEM_DELETE_FAILED,
  DX_DMS_ITEM_DELETE_SUCCESS,
  DX_DMS_ITEM_FETCH,
  DX_DMS_ITEM_FETCH_FAILED,
  DX_DMS_ITEM_FETCH_SUCCESS,
  DX_DMS_ITEM_SELECT,
  DX_DMS_ITEM_UPDATE,
  DX_DMS_ITEM_UPDATE_COMMIT,
  DX_DMS_ITEM_UPDATE_FAILED,
  DX_DMS_ITEM_UPDATE_SUCCESS,
  DX_DMS_ITEMS_DESELECT_ALL,
  DX_DMS_ITEMS_SELECT_ALL,
  DX_DMS_MOUNT,
  DX_DMS_PAGE_FETCH,
  DX_DMS_PAGE_FETCH_FAILED,
  DX_DMS_PAGE_FETCH_SUCCESS,
  DX_DMS_RELATEE_OPTIONS_FETCH,
  DX_DMS_RELATEE_OPTIONS_FETCH_FAILED,
  DX_DMS_RELATEE_OPTIONS_FETCH_SUCCESS,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH_FAILED,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH_SUCCESS,
} from '../constants';
import { assertPageFetchArgs, assertPageFetchSuccessArgs } from '../utils/assertPageFetchActionArgs';
import { assertBulkActionObject } from '../utils/assertBulkActionObject';

// -- Fetch Collections --------------- --- --  -

export const dmsMountAction = (dmsConfig) => {
  invariant(dmsConfig.isDMSConfig, `Expected a DMSConfig, got "${dmsConfig}".`);
  return { type: DX_DMS_MOUNT, dmsConfig };
};

// -- Create Item --------------- --- --  -

/**
 * Creates an action to dispatch when the creation of a new item has been initialized. Its type
 * can be used as the _createInitActionType_ when using _createItemLoadStateReducer_.
 * @param {string} collectionId
 * @return {{type, collectionId: string}}
 */
export const dmsItemCreateInitAction = (collectionId) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  return { type: DX_DMS_ITEM_CREATE_INIT, collectionId };
};

export const dmsItemCreateCommitAction = (fields, collectionId) => {
  invariant(isObject(fields), `Expected an object as "fields", got "${fields}".`);
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  return { type: DX_DMS_ITEM_CREATE_COMMIT, collectionId, fields };
};

export const dmsItemCreateFailedAction = (fields, collectionId, error) => {
  invariant(isObject(fields), `Expected an object as "fields", got "${fields}".`);
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  return {
    type: DX_DMS_ITEM_CREATE_FAILED, collectionId, error, fields
  };
};

export const dmsItemCreateSuccessAction = (item, collectionId) => {
  invariant(isObject(item), `Expected an object as "item", got "${item}".`);
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  return { type: DX_DMS_ITEM_CREATE_SUCCESS, collectionId, item };
};

// -- Delete Item --------------- --- --  -

export const dmsItemDeleteAction = (collectionId, itemId) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isString(itemId), `Expected a string as "itemId", got "${itemId}".`);
  return { type: DX_DMS_ITEM_DELETE, collectionId, id: itemId };
};

export const dmsItemDeleteFailedAction = (collectionId, itemId, error) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isString(itemId), `Expected an object as "itemId", got "${itemId}".`);
  return {
    type: DX_DMS_ITEM_DELETE_FAILED, collectionId, error, id: itemId
  };
};

export const dmsItemDeleteSuccessAction = (collectionId, itemId) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isString(itemId), `Expected an object as "itemId", got "${itemId}".`);
  return { type: DX_DMS_ITEM_DELETE_SUCCESS, collectionId, id: itemId };
};

// -- Fetch Item --------------- --- --  -

export const dmsItemFetchAction = (collectionId, itemId) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isString(itemId), `Expected a string as "itemId", got "${itemId}".`);
  return {
    type: DX_DMS_ITEM_FETCH,
    collectionId,
    id: itemId, // required for item-load-state
    itemId,
  };
};

export const dmsItemFetchFailedAction = (collectionId, itemId, error) => {
  invariant(isString(itemId), `Expected string as "itemId", got "${itemId}".`);
  invariant(isString(collectionId), `Expected string as "collectionId", got "${collectionId}".`);
  return {
    type: DX_DMS_ITEM_FETCH_FAILED,
    collectionId,
    error,
    id: itemId,
  };
};

export const dmsItemFetchSuccessAction = (collectionId, item) => {
  invariant(isObject(item), `Expected object as "item", got "${item}".`);
  invariant(isString(collectionId), `Expected string as "collectionId", got "${collectionId}".`);
  return {
    type: DX_DMS_ITEM_FETCH_SUCCESS,
    collectionId,
    id: item.id, // required for item-load-state
    item,
  };
};

// -- Item Selection --------------- --- --  -

/**
 * Creates an action to dispatch when an item (in the items page) has been selected.
 * @param {String} id - the id of the item that was toggled
 * @return {{ type: string, id: string }}
 */
export const dmsItemSelectAction = (id) => {
  invariant(isString(id), `Expected a string as "id", got "${id}".`);
  return { type: DX_DMS_ITEM_SELECT, id };
};

/**
 * Creates an action to dispatch when an item (in the items page) has been deselected.
 * @param {String} id - the id of the item that was toggled
 * @return {{ type: string, id: string }}
 */
export const dmsItemDeselectAction = (id) => {
  invariant(isString(id), `Expected a string as "id", got "${id}".`);
  return { type: DX_DMS_ITEM_DESELECT, id };
};

/**
 * Creates an action to dispatch when "all items" have been selected.
 * @return {{ type: string }}
 */
export const dmsItemsSelectAllAction = () => ({ type: DX_DMS_ITEMS_SELECT_ALL });

/**
 * Creates an action to dispatch when "all items" have been deselected.
 * @return {{ type: string }}
 */
export const dmsItemsDeselectAllAction = () => ({ type: DX_DMS_ITEMS_DESELECT_ALL });

// -- Bulk Actions --------------- --- --  -

/**
 * Creates an action to open a bulk action dialog.
 *
 * @param {BulkAction} bulkAction
 * @returns {{ type: string, bulkAction: BulkAction }}
 */
export const dmsItemsBulkActionInitAction = (bulkAction) => {
  assertBulkActionObject(bulkAction);
  return { type: DX_DMS_BULK_ACTION_INIT, bulkAction };
};

/**
 * Creates an action to apply a bulk-action.
 *
 * @param {BulkAction} bulkAction
 * @param {any} [data] - optional value from additional step in custom component
 * @return {{ type: string, bulkAction: BulkAction, data: any }}
 */
export const dmsItemsBulkActionApplyAction = (bulkAction, data) => {
  assertBulkActionObject(bulkAction);
  return { type: DX_DMS_BULK_ACTION_APPLY, bulkAction, data };
};

// -- Actions for the paginated overview of the selected items --------------- --- --  -

/**
 * Creates an action to fetch a page of selected item to show in the bulk-action dialog.
 *
 * @param {string} collectionId
 * @param {Array.<string>} ids
 * @param {FetchPageActionMetadata} [options]
 * @returns {{ type: string, ids: Array.<string>, source: string, ...FetchPageActionOptions }}
 */
export const dmsSelectedItemsPageFetchAction = (collectionId, ids, options = {}) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isArray(ids), `Expected an array as "ids", got "${ids}".`);
  assertPageFetchArgs(options);
  return {
    type: DX_DMS_SELECTED_ITEMS_PAGE_FETCH,
    collectionId,
    ids,
    source: collectionId, // for use in page-load-state
    ...options,
  };
};

/**
 * @todo
 * @param {string} collectionId
 * @param {Array.<string} ids
 * @param {FetchPageActionMetadata} metadata
 * @param {*} error
 * @returns {{ type: string, collectionId: *, error: *, ids: *, source: * }}
 */
export const dmsSelectedItemsPageFetchFailedAction = (collectionId, ids, metadata, error) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  assertPageFetchArgs(metadata);
  return {
    type: DX_DMS_SELECTED_ITEMS_PAGE_FETCH_FAILED,
    collectionId,
    error,
    ids,
    source: collectionId, // for use in page-load-state
    ...metadata,
  };
};

/**
 * @param {string} collectionId
 * @param {Array.<{ id: string }>} items - The fetched items.
 * @param {FetchPageSuccessActionMetadata} metadata
 * @returns {{ type: string, items: Array.<{ id: string }> }}
 */
export const dmsSelectedItemsPageFetchSuccessAction = (collectionId, items, metadata) => {
  invariant(isArray(items), `Expected an array as "items", got "${items}".`);
  assertPageFetchSuccessArgs(items, metadata);
  return {
    type: DX_DMS_SELECTED_ITEMS_PAGE_FETCH_SUCCESS,
    collectionId,
    items,
    source: collectionId, // for use in page-load-state
    ...metadata,
  };
};

// -- Item Update --------------- --- --  -

// TODO: determine exact function
export const dmsItemUpdateAction = (collectionId, id) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  return { type: DX_DMS_ITEM_UPDATE, collectionId, id };
};

export const dmsItemUpdateCommitAction = (itemId, fields, collectionId) => {
  invariant(isString(itemId), `Expected string as "itemId", got "${itemId}".`);
  invariant(isObject(fields), `Expected an object as "fields", got "${fields}".`);
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  return {
    type: DX_DMS_ITEM_UPDATE_COMMIT,
    collectionId,
    fields,
    id: itemId, // for use in item-load-state
    itemId,
  };
};

export const dmsItemUpdateFailedAction = (itemId, fields, collectionId, error) => {
  invariant(isString(itemId), `Expected string as "itemId", got "${itemId}".`);
  invariant(isObject(fields), `Expected an object as "fields", got "${fields}".`);
  invariant(isString(collectionId), `Expected string as "collectionId", got "${collectionId}".`);
  return {
    type: DX_DMS_ITEM_UPDATE_FAILED,
    collectionId,
    error,
    fields,
    id: itemId, // for use in item-load-state
    itemId,
  };
};

export const dmsItemUpdateSuccessAction = (item, collectionId) => {
  invariant(isObject(item), `Expected object as "item", got "${item}".`);
  invariant(isString(collectionId), `Expected string as "collectionId", got "${collectionId}".`);
  return {
    type: DX_DMS_ITEM_UPDATE_SUCCESS,
    collectionId,
    id: item.id, // for use in item-load-state
    item,
  };
};

// -- Fetch a Page of Items --------------- --- --  -

export const dmsPageFetchAction = (collectionId, options = {}) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isObject(options), `Expected an object as "options", got "${options}".`);
  const { from, limit, ordering } = options;
  return {
    type: DX_DMS_PAGE_FETCH,
    collectionId,
    from,
    limit,
    ordering,
    source: collectionId, // for use in page-load-state
  };
};

export const dmsPageFetchFailedAction = (collectionId, error, variables) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isObject(variables), `Expected an object as "variables", got "${variables}".`);
  const { from, limit, ordering } = variables;
  return {
    type: DX_DMS_PAGE_FETCH_FAILED,
    collectionId,
    error,
    from,
    limit,
    ordering,
    source: collectionId, // for use in page-load-state
  };
};

export const dmsPageFetchSuccessAction = (items, total, collectionId, variables) => {
  invariant(isArray(items), `Expected an array as "items", got "${items}".`);
  invariant(isNumber(total), `Expected a number as "total", got "${total}".`);
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isObject(variables), `Expected an object as "variables", got "${variables}".`);
  const { from, limit, ordering } = variables;
  return {
    type: DX_DMS_PAGE_FETCH_SUCCESS,
    collectionId,
    from,
    items, // for use in page-load-state
    limit,
    ordering,
    source: collectionId, // see page-load-state
    total,
  };
};

// -- Fetch a Page of Relatee Options --------------- --- --  -

export const dmsRelateeOptionsFetchAction = (collectionId, filter, from, limit, ordering, source) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isNumber(from), `Expected a number as "from", got "${from}".`);
  invariant(isNumber(limit), `Expected a number as "limit", got "${limit}".`);
  invariant(!filter || isObject(filter), `Expected an object as "filter", got "${filter}".`);
  return {
    type: DX_DMS_RELATEE_OPTIONS_FETCH,
    collectionId,
    filter,
    from,
    limit,
    ordering,
    // added 'source calculation to DMSRelateesField, used || here to not break existing stuff (if this action was also used elsewhere)
    source: source || makeHash({ collectionId, filter }), // for use in page-load-state
  };
};

export const dmsRelateeOptionsFetchFailedAction = (error, collectionId, filter, from, limit, ordering, source) => {
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isNumber(from), `Expected a number as "from", got "${from}".`);
  invariant(isNumber(limit), `Expected a number as "limit", got "${limit}".`);
  invariant(isString(source), `Expected a string as "source", got "${source}".`);
  return {
    type: DX_DMS_RELATEE_OPTIONS_FETCH_FAILED,
    collectionId,
    error,
    filter,
    from,
    limit,
    ordering,
    source, // for use in page-load-state
  };
};

export const dmsRelateeOptionsFetchSuccessAction = (items, total, collectionId, filter, from, limit, ordering, source) => {
  invariant(isArray(items), `Expected an array as "items", got "${items}".`);
  invariant(isNumber(total), `Expected a number as "total", got "${total}".`);
  invariant(isString(collectionId), `Expected a string as "collectionId", got "${collectionId}".`);
  invariant(isNumber(from), `Expected a number as "from", got "${from}".`);
  invariant(isNumber(limit), `Expected a number as "limit", got "${limit}".`);
  invariant(isString(source), `Expected a string as "source", got "${source}".`);
  return {
    type: DX_DMS_RELATEE_OPTIONS_FETCH_SUCCESS,
    collectionId,
    filter,
    from,
    items,
    limit,
    ordering,
    source, // for use in page-load-state
    total,
  };
};

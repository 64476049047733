import { css } from 'glamor';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import isBoolean from 'lodash/isBoolean';
import { isString } from '../../../utils/isString';

import { DX_MODAL_CONFIRM } from '../../constants';

// -- Styles --------------- --- --  -

const buttonsBoxStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  '> *': {
    marginLeft: 10,
  },
});

// -- Component --------------- --- --  -

const ConfirmationModalComponent = ({
  content, noLabel, handleReject, handleConfirm, yesLabel
}) => (
  <div>
    {isString(content) ? <p>{content}</p> : content}
    <div {...buttonsBoxStyles}>
      <Button onClick={handleReject}>{noLabel}</Button>
      <Button autoFocus onClick={handleConfirm} bsStyle="danger">{yesLabel}</Button>
    </div>
  </div>
);

ConfirmationModalComponent.propTypes = {
  content: PropTypes.node, // to be provided by user
  handleConfirm: PropTypes.func.isRequired, // provided in connect HOC
  handleReject: PropTypes.func.isRequired, // provided in connect HOC
  noLabel: PropTypes.string, // optionally provided by user
  yesLabel: PropTypes.string, // optionally provided by user
};

ConfirmationModalComponent.defaultProps = {
  content: 'Are you sure you want to proceed?',
  noLabel: 'No',
  yesLabel: 'Yes',
};

// -- Container --------------- --- --  -

export const ConfirmationModal = connect(
  undefined,
  (dispatch, {
    modalId, onConfirm, onReject, removeModal
  }) => ({
    handleReject() {
      if (onReject) { onReject(); }
      dispatch(confirmAction(modalId, false));
      removeModal();
    },
    handleConfirm() {
      if (onConfirm) { onConfirm(); }
      dispatch(confirmAction(modalId, true));
      removeModal();
    }
  }),
  undefined, // use default mergeProps implementation
  {
    areOwnPropsEqual: (next, prev) => next.modalId === prev.modalId,
  }
)(ConfirmationModalComponent);

// -- Support --------------- --- --  -

/**
 * Action to dispatch when the user responds to a confirmation modal.
 * @param {string} id
 * @param {boolean} confirm
 * @return {{ type, confirm, id }}
 */
const confirmAction = (id, confirm) => {
  invariant(isString(id), `Expected a string as "id", got "${id}".`);
  invariant(isBoolean(confirm), `Expected a boolean as "confirm", got "${confirm}".`);
  return { type: DX_MODAL_CONFIRM, confirm, id };
};

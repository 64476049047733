import { dxAuthReducer } from './dxAuthReducer';
import { dxDMSReducer } from './dxDMSReducer';
import { dxModalReducer } from './dxModalReducer';
import { dxQueryReducer } from './dxQueryReducer';
import { dxTasksReducer } from './dxTasksReducer';

// The reducers object to install in the Redux store:
export const dxReducers = {
  dxAuth: dxAuthReducer,
  dxDMS: dxDMSReducer,
  dxModals: dxModalReducer,
  dxQueries: dxQueryReducer,
  dxTasks: dxTasksReducer,
};

export { initialDxQueryState } from './dxQueryReducer';
export * from './itemLoadStateReducer';
export * from './itemsSelectionReducer';
export * from './pageLoadStateReducer';
export * from './simpleLoadStateReducer';

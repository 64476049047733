import { dxConfig } from '../../dxConfig';

const providers = new Map();

export class AuthProvider {
  /**
   * @param {string} id - Unique provider identifier.
   */
  constructor(id) {
    this._id = id;
    providers.set(id, this);
  }

  /**
   * @return {string} Unique provider identifier
   */
  get id() { return this._id; }

  /**
   * Returns the config for this provider, which is to be provided in the
   * `duxis.auth.identityProviders.<id>` config setting, where `<id>` is replaced with the `id`
   * provided in the constructor.
   */
  get config() {
    return dxConfig.get(`duxis.auth.identityProviders.${this._id}`);
  }

  /**
   * @return {boolean} The value of the `enable` property in the `duxis.auth.identityProviders.<id>`
   *   config.
   */
  get enabled() {
    return Boolean(this.config.enable);
  }

  // -- Abstract Methods --------------- --- --  -

  getSignInComponent() {
    throw new Error(`Implement "getSignInComponent" for the "${this._id}" auth-provider.`);
  }
}

/**
 * Get the frontend identity provider with the given id.
 * @param {string} id
 */
export const getProvider = (id) => providers.get(id);

// TODO: react-bootstrap v0.33 prevents us from using
// core-js and regenerator-runtime packages (see front image)
// instead of this deprecated babel-polyfill package
import 'babel-polyfill';

import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ModalManager } from './react-frontend/components';
import { initializeQueryFetcher } from './react-frontend/queries';
import './react-frontend/styles/globals';
import { createStore } from './react-frontend/utils';
import { dxConfig } from './react-frontend';

import { App } from './components/App';
import { reducers } from './reducers';
import { sagas } from './sagas';

// -- Set-up --------------- --- --  -

// Initialize router history, redux store and query fetcher:
const history = createHistory();
const store = createStore(reducers, sagas, history);
initializeQueryFetcher({ store });

// -- Render --------------- --- --  -

ReactDOM.render(
  <Provider store={store}>
    <div>
      <ConnectedRouter history={history}>
        {dxConfig.get('duxis.ums.enable') && <App />}
      </ConnectedRouter>
      <ModalManager />
    </div>
  </Provider>,
  document.getElementById('app')
);

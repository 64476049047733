import {
  call, put, select, takeEvery
} from 'redux-saga/effects';
import { dxConfig } from '../react-frontend/dxConfig';
import { fetchQuery } from '../react-frontend/queries';
import { showErrorModal } from '../react-frontend/sagas';

import { fetchExternalItemsErrorAction, fetchExternalItemsSuccessAction } from '../actions/externalItems';
import { fetchExternalDxSchemaSuccessAction, fetchExternalDxSchemaErrorAction } from '../actions/externalSchema';
import { FETCH_EXTERNAL_ITEMS_START, FETCH_EXTERNAL_DXSCHEMA_START } from '../constants/actionTypes';
import { buildFetchExternalQuery } from '../queries/createFetchExternalQuery';
import { fetchExternalSchemaQuery } from '../queries/fetchExternalSchemaQuery';
import { getExternalCollection } from '../selectors/externalSchema';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

const APP_NAME = dxConfig.get('duxis.projectName');
if (!APP_NAME) {
  throw new Error('duxis.projectName needs to be configured in your config.');
}

let API_URL;
if (APP_NAME && dxConfig.has(`${APP_NAME}.hosts.dxApi`)) {
  API_URL = dxConfig.get(`${APP_NAME}.hosts.dxApi`);
}

function getLabelFieldForScope({ fields }) {
  if (fields.label) {
    return 'label';
  }
  if (fields.name) {
    return 'name';
  }
  if (fields.title) {
    return 'title';
  }
  return 'id';
}

export default function* () {
  yield takeEvery(FETCH_EXTERNAL_DXSCHEMA_START, function* () {
    try {
      const { data, error } = yield call(fetchQuery, fetchExternalSchemaQuery, { url: API_URL });
      if (error) {
        yield call(showErrorModal, error, 'Failed to fetch the external dxSchema.');
        yield put(fetchExternalDxSchemaErrorAction(error));
      } else {
        yield put(fetchExternalDxSchemaSuccessAction(data));
      }
    } catch (e) {
      yield call(showErrorModal, e, 'Failed to fetch the external dxSchema.');
      yield put(fetchExternalDxSchemaErrorAction(e));
    }
  });

  yield takeEvery(FETCH_EXTERNAL_ITEMS_START, function* ({
    value, from = 0, limit = 0, searchString, scopeId
  }) {
    try {
      const collection = yield select(getExternalCollection, scopeId);
      const labelField = getLabelFieldForScope(collection);
      const variables = { from, limit };
      variables.filter = { type: 'all' };

      let filter = { filters: [], operator: 'orNot' };
      if (value) {
        filter.filters.push({ type: 'hasValue', field: 'id', value });
        variables.filter = filter;
      }

      if (searchString) {
        const searchFilter = {
          operator: 'and',
          filters: [{
            type: 'hasText',
            field: labelField,
            string: searchString
          }],
        };
        filter = filter.filters.length > 0 ? { filter: { filters: [filter, searchFilter], operator: 'and' } } : searchFilter;
        variables.filter = filter;
      }
      const { complete, data, error } = yield call(fetchQuery, buildFetchExternalQuery(scopeId, labelField), { variables, url: API_URL });
      if (complete) {
        const result = data[`filter${capitalizeFirstLetter(scopeId)}`];
        if (result.filteredCount > result.pagedCount) {
          console.warn('Not all relatee options are shown.');
        }
        const items = (result.items || []).map(({ id, [labelField]: label }) => ({ value: id, label, id }));
        yield put(fetchExternalItemsSuccessAction(
          {
            scopeId,
            data: items,
            filteredCount: result.filteredCount,
            collectionId: scopeId,
            filter: {},
            from: result.from,
            limit: result.limit,
            ordering: result.ordering,
            source: ''
          }
        ));
      } else if (error) {
        yield call(showErrorModal, error, 'Failed to fetch the external items.');
        yield put(fetchExternalItemsErrorAction({ error, scopeId }));
      }
    } catch (e) {
      yield call(showErrorModal, e, 'Failed to fetch the external items.');
      yield put(fetchExternalItemsErrorAction({ error: e.message, scopeId }));
    }
  });
}

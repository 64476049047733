import { css } from 'glamor';

import { dxColors } from './dxColors';
import { paddedPageElementStyles, ralewayBold } from './dxStyles';

const itemGap = 24;

export const appNavStyles = css(paddedPageElementStyles, {
  background: 'transparent',
  border: 'none',
  borderRadius: 0,
  minHeight: 24,
  paddingTop: 14,
  paddingBottom: 10,
  marginBottom: 0,
  '> .container-fluid': {
    fontSize: '15px',
    lineheight: 'normal',
    margin: 0,
    padding: 0,
    '@media screen and (min-width: 680px)': {
      margin: `0 -${itemGap / 2}px`,
      padding: 0,
    },
    '> .navbar-header': {
      height: 24,
      margin: 0,
      padding: 0,
      '@media screen and (min-width: 680px)': {
        margin: 0,
        padding: 0,
      },
      '> .navbar-brand': {
        boxSizing: 'content-box',
        cursor: 'pointer',
        height: 24,
        margin: `0 ${itemGap / 2}px`,
        padding: 0,
        '> svg': {
          height: 'inherit',
        },
      },
      '> .navbar-toggle': {
        border: 0,
        borderRadius: 0,
        margin: '4px 0',
        padding: 0,
        width: 18,
        '> .icon-bar': {
          background: dxColors.fgMain,
          display: 'block',
          width: 18,
          height: 2,
        },
      },
    },
    '> .navbar-collapse.collapse': {
      '> ul.nav.navbar-nav': {
        '> li': [ralewayBold, {
          margin: `0 ${itemGap / 2}px`,
          '> a': {
            padding: '0 1px 0px 1px',
            lineHeight: '23px',
            borderBottom: '1px solid transparent',
            textDecoration: 'none',
            color: dxColors.fgMain,
            transition: '.3s ease-out',
          },
        }],
        '> .active': {
          '> a, > a:hover, > a:active, > a:focus': {
            color: dxColors.fgDarker_1,
            background: 'transparent',
            borderBottom: `1px solid ${dxColors.bondiBlue}`,
          },
        },
      },
      '&.in': {
        float: 'right',
        '> ul.nav.navbar-nav': {
          '> li': {
            margin: '6px auto 6px -1px',
            '> a': {
              lineHeight: '22px',
            },
          },
        },
      },
    },
  },
});

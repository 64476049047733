import PropTypes from 'prop-types';

/**
 * @see BulkAction
 */
export const bulkActionPropTypes = {
  /** The type for the Redux bulk action. */
  actionType: PropTypes.string.isRequired,

  /** Optional properties to add in the Redux bulk action. */
  actionProps: PropTypes.object,

  /**
   * Required identifier of the collection on which this action will be applied. Defaults to the
   * _collectionId_ prop provided to the _BulkActionsBar_, when this component is used.
   */
  collectionId: PropTypes.string.isRequired,

  /**
   * Optional 'left' or 'right' string to add a divider on the respective side of the action button
   * in the bulk-actions bar.
   */
  divider: PropTypes.string,

  /** Optional icon to show on the action button in the bulk-actions bar. */
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),

  /** True when the bulk action is destructive. */
  isDestructive: PropTypes.bool,

  /**
   * The bulk action label to use in the GUI. Either a string or a function that called with the
   * `actionProps` as sole argument, and that returns the label string.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,

  /** Optional message to show in the bulk-action model. */
  message: PropTypes.string,

  /** Optional label for the "Cancel Action" button. */
  noLabel: PropTypes.string,

  /** An optional function that returns a custom bulk action interface. */
  render: PropTypes.func,

  /**
   * Optional `small`, `medium` or `large` string to specify the size of the bulk-action modal.
   * Defaults to `medium`.
   */
  size: PropTypes.string,

  /** Optional title used in the bulk-action modal. */
  title: PropTypes.string,

  /** Optional label for the "Apply Action" button. */
  yesLabel: PropTypes.string,
};

export const bulkActionPropType = PropTypes.shape(bulkActionPropTypes);

// -- Query Contexts --------------- --- --  -

/** @type {string} */
export const DX_CONTEXT_DEFAULT = 'DX/CONTEXT/DEFAULT';

/** @type {string} */
export const DX_CONTEXT_TASKS = 'DX/CONTEXT/TASKS';

// -- View Modes --------------- --- --  -

/** @type {string} */
export const CREATE_MODE = 'DX_CREATE_MODE';

/** @type {string} */
export const EDIT_MODE = 'DX_EDIT_MODE';

/** @type {string} */
export const VIEW_MODE = 'DX_VIEW_MODE';

// -- load-state --------------- --- --  -

/** @type {string} */
// export const LOADED = 'DX/LOADED';

/** @type {string} */
// export const LOADING = 'DX/LOADING';

/** @type {string} */
// export const RELOADING = 'DX/RELOADING';

// -- SimplePassword Provider State --------------- --- --  -

/** @type {string} */
export const SIMPLE_PASSWORD_SIGN_IN_STATE = 'DX/SIMPLE_PASSWORD/SIGN_IN';

/** @type {string} */
export const SIMPLE_PASSWORD_USER_INPUT_STATE = 'DX/SIMPLE_PASSWORD/USER_INPUT';

// -- Misc --------------- --- --  -

/** @type {number} */
export const GOLDEN_RATIO = 0.6180469715698392;

/** @type {number} */
export const ARROW_LEFT_KEYCODE = 37;

/** @type {number} */
export const ARROW_UP_KEYCODE = 38;

/** @type {number} */
export const ARROW_RIGHT_KEYCODE = 39;

/** @type {number} */
export const ARROW_DOWN_KEYCODE = 40;

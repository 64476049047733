import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import { getDxAuthToken } from '../../selectors';
import { isString } from '../../../utils/isString';

import { cssPropType } from '../../propTypes';
import { dxColors } from '../../styles';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  border: 'none',
  fontWeight: 600,
  height: '40px',
  lineHeight: '40px',
  margin: '0 8px',
  padding: '0 20px',
  transition: 'all .25s, boxShadow 1s',
  ':first-child': { marginLeft: 0 },
  ':last-child': { marginRight: 0 },
  '> .dx-action-icon': {
    boxSizing: 'content-box',
    height: 18,
    margin: 'auto 0',
    padding: '0 2px',
    width: 18,
  },

  // -- Primary --------------- --- --  -
  '&.primary:not(.disabled)': {
    backgroundColor: dxColors.bondiBlue,
    '&, > .dx-action-label': { color: 'white' },
    ':hover, :active, :focus': {
      backgroundColor: dxColors.bondiBlueDarker_2,
      color: 'white',
    },
    ':hover, :focus': {
      boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.4)',
    },
  },

  // -- Secondary --------------- --- --  -
  '&.secondary:not(.disabled)': {
    backgroundColor: '#ccc',
    '&, > .dx-action-label': { color: '#333' },
    ':hover, :active, :focus': {
      backgroundColor: '#bbb',
      color: '#333',
      '> .dx-action-label': { color: '#000' },
    },
    ':hover, :focus': {
      boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.3)',
    },
  },

  // -- Disabled --------------- --- --  -
  ':disabled': {
    backgroundColor: '#DDD',
    color: '#999',
    '> .dx-action-label': { color: '#999' },
  },
});

const dropdownStyles = css({
  boxSizing: 'content-box',
  height: 30,
  '> button': [defaultStyles, {
    borderBottom: 'none',
    height: 30,
  }],
  '> __Dropdown.Toggle': {
    alignItems: 'center',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    display: 'flex',
    '&:hover, &:focus, &:active': { boxShadow: 'none' },
    '> span': { marginRight: 5 },
  },
  '> __Dropdown.Menu': {
    border: 'none',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    minWidth: 0,
    '> __*': {
      border: 'none',
      width: '100%',
      '&:hover': { border: 'none' },
    },
  },
});

// -- Component --------------- --- --  -

/**
 * A flexible type of button for use in an ActionBar. Action buttons are large buttons, typically
 * provided at the bottom-right of a section. You can use the _primary_ or _secondary_ attribute
 * to control their representation. Primary buttons draw more attention.
 *
 * @todo The button layout when using icons or menu items needs to be refined.
 *
 * To specify the behaviour of the button when it is pressed, either use the `link` prop to redirect
 * by means of the react/redux-router; or use the `href` prop to redirect the browser directly,
 * bypassing the react/redux-router; or use the `onClick` prop to specify the handler that should be
 * called.
 * @see Dropdown
 */
const ActionButtonComponent = (props) => {
  const {
    disabled, icon, label, styles
  } = props;
  const className = classnames(props.className, 'dx-action', {
    disabled,
    primary: props.primary,
    secondary: props.secondary,
  });

  if (props.children) {
    const dropDownProps = {
      className,
      disabled,
      id: Date.now(),
      onSelect: props.onSelect,
      role: 'menuitem',
    };
    return (
      <Dropdown {...dropDownProps} {...css(dropdownStyles, styles)}>
        <Dropdown.Toggle>
          {icon && <img className="dx-action-icon" src={icon} alt="dx-action-icon" />}
          {label && <span className="dx-action-label">{label}</span>}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  if (isString(props.link)) {
    return (
      <LinkContainer className={className} to={{ pathname: props.link }}>
        <Button disabled={disabled} {...css(defaultStyles, styles)}>
          {icon && <img className="dx-action-icon" src={icon} alt="dx-action-icon" />}
          {label && <span className="dx-action-label">{label}</span>}
        </Button>
      </LinkContainer>
    );
  }

  if (isString(props.indexLink)) {
    return (
      <IndexLinkContainer className={className} to={{ pathname: props.indexLink }}>
        <Button disabled={disabled} {...css(defaultStyles, styles)}>
          {icon && <img className="dx-action-icon" src={icon} alt="dx-action-icon" />}
          {label && <span className="dx-action-label">{label}</span>}
        </Button>
      </IndexLinkContainer>
    );
  }

  if (isString(props.href)) {
    return (
      <Button
        className={className}
        disabled={disabled}
        href={props.href}
        tabIndex="0"
        target={props.target}
        {...css(defaultStyles, styles)}
      >
        {icon && <img className="dx-action-icon" src={icon} alt="dx-action-icon" />}
        {label && <span className="dx-action-label">{label}</span>}
      </Button>
    );
  }

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={props.onClick}
      {...css(defaultStyles, styles)}
      type="button"
    >
      {icon && <img className="dx-action-icon" src={icon} alt="dx-action-icon" />}
      {label && <span className="dx-action-label">{label}</span>}
    </button>
  );
};

ActionButtonComponent.propTypes = {
  /**
   * The base of a protected URL to open when the user click on the button.  The user token will be
   * appended in the query part of the URL. The resulting URL will be use as `href` prop.
   */
  authHref: PropTypes.string,

  /**
   * When children are provided, a drop-down menu is shown. These children should be
   * `MenuItem` elements, one for each menu item. Also provide the `onSelect` prop which
   * will be called when an item is selected.
   */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, (child) => {
      // noinspection JSUnresolvedVariable
      if (child.type !== MenuItem) {
        error = new Error(`[${componentName}] children should be of type [MenuItem].`);
      }
    });
    return error;
  },

  /** Optional class. */
  className: PropTypes.string,

  /** Disable the button. */
  disabled: PropTypes.bool,

  /**
   * The URL to open when the user presses the button. The browser is redirected to this url,
   * meaning that the redirection is not handled by the react/redux-router.
   *
   * - To navigate inside the application, use the `link` or `indexLink` prop instead.
   * - To provide custom handling of click events, use the `onClick` prop to specify the handler.
   */
  href: PropTypes.string,

  /** Optional icon to show in the button. */
  icon: PropTypes.node,

  /**
   * The path to navigate to when the user presses the button. This navigation is handled by
   * redux-router. The button will stay highlighted (like a tab/nav-link) when the current location
   * exactly matches the specified link path.
   *
   * - To highlight the button when the current location contains the specified path use the
   *   `link` prop instead.
   * - To navigate outside the application, use the `href` prop instead.
   * - To provide custom handling of click events, use the `onClick` prop to specify the handler.
   */
  indexLink: PropTypes.string,

  /** The button label. */
  label: PropTypes.node,

  /**
   * The path to navigate to when the user presses the button. This navigation is handled by
   * redux-router. The button will stay highlighted (like a tab/nav-link) when the current location
   * contains the specified link path.
   *
   * - To match the location exactly, e.g. for index tabs, use the `indexLink` prop instead.
   * - To navigate outside the application, use the `href` prop instead.
   * - To provide custom handling of click events, use the `onClick` prop to specify the handler.
   */
  link: PropTypes.string,

  /**
   * The handler to call when the user presses the button.
   *
   * - To navigate inside the application, use the `link` or `indexLink` prop instead.
   * - To navigate outside the application, use the `href` prop instead.
   */
  onClick: PropTypes.func,

  /**
   * When children are provided, a drop-down menu is shown, and this handler is called when one of
   * the given options is selected.
   */
  onSelect: PropTypes.func,

  /** Either 'left' (default), 'middle' or 'right'. */
  placement: PropTypes.string,

  /** Optional primary action level. */
  primary: PropTypes.bool,

  /** Optional secondary action level. */
  secondary: PropTypes.bool,

  /** Optional Glamor styling. See [cssPropType](../../propTypes/cssPropType). */
  styles: cssPropType,

  /**
   * When the `href` prop is given, this optional prop can be used to set the target attribute.
   * @warning Beware security issues when using `_blank`.
   * @see https://developer.mozilla.org/en/docs/Web/HTML/Element/a
   */
  target: PropTypes.string,
};

ActionButtonComponent.defaultProps = {
  placement: 'left',
};

// -- Container --------------- --- --  -

export const ActionButton = connect(
  (state, ownProps) => {
    const { authHref } = ownProps;
    if (isString(authHref)) {
      return {
        href: `${authHref}${authHref.includes('?') ? '&' : '?'}jwt=${getDxAuthToken(state)}`,
      };
    }
    return {};
  }
)(ActionButtonComponent);

import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import {
  dxFormFieldViewPropTypes,
  dxFormInputControlPropTypes,
  dxFormSelectControlPropTypes,
} from '../../propTypes';

import { InputFFC } from './formFieldComponents/InputFFC';
import { SelectFFC } from './formFieldComponents/SelectFFC';

// -- Component --------------- ---  --  -

/**
 * A field for a single-line string.
 */
export class StringField extends React.Component {
  shouldComponentUpdate(nextProps) {
    // Should only re-render when value or editMode changes
    return this.props.value !== nextProps.value
      || this.props.editMode !== nextProps.editMode
      || this.props.enum !== nextProps.enum;
  }

  render() {
    const {
      editMode, fieldId, fieldProps, fieldSchema, value, ...otherProps
    } = this.props;
    const baseProps = {
      baseValue: value,
      editMode,
      name: fieldId,
      ...otherProps,
    };

    if (fieldSchema && fieldSchema.isEnum && editMode) {
      let options = fieldSchema.constraints.enum;
      if (!baseProps.required) {
        options = [{ id: 'unknown', label: 'Unknown', value: NULL_STRING }, ...options];
      }
      const props = {
        ...baseProps,
        className: `dx-string-field dx-select-field${fieldSchema.isHidden ? ' dx-hidden' : ''}`,
        component: SelectFFC,
        controlId: `select-${fieldId}`,
        options,
        parse: (string) => (string === NULL_STRING ? null : string),
        ...fieldProps,
      };
      return (<Field {...props} />);
    }

    const childProps = {
      ...baseProps,
      className: `dx-string-field ${fieldSchema.isHidden ? ' dx-hidden' : ''}`,
      component: InputFFC,
      controlId: `input-${fieldId}`,
      minLength: (isNumber(this.props.minLength) && this.props.minLength) || (fieldSchema && fieldSchema.constraints.minLength),
      maxLength: (isNumber(this.props.maxLength) && this.props.maxLength) || (fieldSchema && fieldSchema.constraints.maxLength),
      pattern: this.props.pattern || (fieldSchema && fieldSchema.constraints.pattern),
      type: 'text',
      ...fieldProps,
    };
    return (<Field {...childProps} />);
  }
}

StringField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...dxFormInputControlPropTypes,
  ...dxFormSelectControlPropTypes,

  editMode: PropTypes.bool,

  /** Override `maxLength` field schema constraint. */
  maxLength: PropTypes.number,

  /** Override `minLength` field schema constraint. */
  minLength: PropTypes.number,
};

StringField.defaultProps = {
  editMode: false,
};

// -- Support --------------- ---  --  -

/**
 * @private
 * Temporary string to use as value in the HTML select element when representing an optional enum
 * field for which no value is selected. The `parse` function replaces this value with a proper
 * null value when parsing the value in the HTML element.
 * @type {string}
 */
const NULL_STRING = '__null__';

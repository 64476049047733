import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

/**
 * The scalar types available for dxSchemas.
 * @type {Map}
 * @private
 */
const _types = new Map();

/**
 * @typedef {object} DxTypeSpec
 * @property {string} [description]
 * @property {Function} [deserialize] - Optional deserializer.
 * @property {Function} graphQLTypeObject - The corresponding GraphQL.js type object,
 *   e.g. GraphQLString.
 * @property {Function} graphQLTypeString - The corresponding GraphQL.js type string,
 *   e.g. 'String'.
 * @property {boolean} [orderable = false] - Can fields of this type be ordered or not.
 * @property {Function} [serialize] - Optional serializer.
 * @property {Function} validate - Predicate that returns true when the given value is a valid one
 *   for this type.
 */

/**
 * Represents scalar types available for use in dxSchemas.
 */
export class DxScalarType {
  /**
   * @param {string} typeId
   * @param {DxTypeSpec} typeSpec
   */
  constructor(typeId, typeSpec) {
    // Checks:
    if (!isString(typeId)) {
      throw new TypeError(`The "typeId" must be a string, got "${typeId}".`);
    }
    if (!typeSpec.validate) {
      throw new Error(`Missing type validator when declaring scalar type "${typeId}".`);
    }
    if (!typeSpec.graphQLTypeObject) {
      throw new Error(`Missing "graphQLTypeObject" when declaring scalar type "${typeId}".`);
    }
    if (!typeSpec.graphQLTypeString) {
      throw new Error(`Missing "graphQLTypeString" when declaring scalar type "${typeId}".`);
    }
    if (_types.has(typeId)) {
      throw new RangeError(`There is already a scalar type "${typeId}".`);
    }

    this._id = typeId;
    this._description = typeSpec.description;
    this.deserialize = ((value) => {
      if (value === undefined || value === null) { return null; }
      if (typeSpec.deserialize) { return typeSpec.deserialize(value); }
      return value;
    });
    this._graphQLTypeObject = typeSpec.graphQLTypeObject;
    this._graphQLTypeString = typeSpec.graphQLTypeString;
    this._label = typeSpec.label;
    this._isSerialized = isFunction(typeSpec.serialize);
    this._orderable = typeSpec.orderable || false;

    /**
     * @type {(function(*): *)} Serializes the given value.
     */
    this.serialize = typeSpec.serialize || ((value) => value);

    /**
     * @type {(function(*): boolean)} Validates the given value.
     */
    this.validate = typeSpec.validate;

    _types.set(typeId, this);
  }

  /**
   * @return {string} The type ID.
   */
  get id() { return this._id; }

  /**
   * @return {string} The type description.
   */
  get description() { return this._description; }

  /**
   * @return {GraphQLScalarType} The corresponding GraphQL.js type object, e.g. GraphQLString.
   */
  get graphQLTypeObject() { return this._graphQLTypeObject; }

  /**
   * @return {string} The corresponding GraphQL.js type string, e.g. 'String'.
   */
  get graphQLTypeString() { return this._graphQLTypeString; }

  /**
   * @return {boolean} True when this type is serialized.
   */
  get isSerialized() { return this._isSerialized; }

  /**
   * @return {string} The label.
   */
  get label() { return this._label; }

  /**
   * @return {boolean} True when fields of this type can be ordered.
   */
  get orderable() { return this._orderable; }
}

/**
 * @param {*} obj
 * @return {boolean} True if the given object is DxScalarType.
 */
DxScalarType.isDxScalarType = (obj) => obj instanceof DxScalarType;

/**
 * @param {string} typeId
 * @returns {DxScalarType} The scalar type specification.
 */
DxScalarType.getType = (typeId) => {
  if (!_types.has(typeId)) {
    throw new RangeError(`Unknown scalar type "${typeId}".`);
  }
  return _types.get(typeId);
};

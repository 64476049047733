import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { propTypes as formPropTypes, reduxForm } from 'redux-form';
import forIn from 'lodash/forIn';

import { CREATE_MODE, EDIT_MODE, VIEW_MODE } from '../../constants';
import { dxSchema } from '../../dxSchema';
import { cssPropType, viewSchemaPropType } from '../../propTypes';
import { dxColors } from '../../styles';
import { AlertSection } from '../AlertSection';
import { initFieldViews } from '../fieldViews';

// -- Styles --------------- --- --  -

/**
 * The default form styles. Override by providing custom styles (using Glamor) as the `formStyles`
 * prop.
 *
 * ### Notes:
 * - The `form` element has the class `dx-view-mode`, `dx-edit-mode` or `dx-create-mode`, depending
 *   on the value of the `mode` prop.
 */
const defaultFormStyles = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  '> .dx-field': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 2px 0',
    '&.dx-hidden': { display: 'none' },
    '> label': {
      fontWeight: 600,
      padding: '5px 0',
      margin: '0 8px 0 0',
      textAlign: 'right',
      minWidth: '120px',
      '::after': { content: '\u00a0:' },
    },

    // all modes:
    '&, > .dx-form-group': {
      '> input, > textarea, > select, .form-control': {
        backgroundColor: 'white',
        borderRadius: 0,
        boxShadow: 'none',
        color: `${dxColors.fgMain} !important`,
      },
      '> input': {
        border: 'none',
        padding: '4px 8px 0 8px',
        margin: '0 0 5px 0',
      },
      '> input:disabled, > textarea:disabled': {
        WebkitTextFillColor: dxColors.fgMain,
      },
      '> select': {
        borderColor: dxColors.bgDarker_1,
      },
      '> textarea': {
        border: `1px dotted ${dxColors.bgDarker_3}`,
        height: '80px',
        padding: '4px 8px',
      },
    },
    '&.dx-json-field': {
      '&, > .dx-form-group': {
        '> textarea': {
          fontFamily: 'monospace',
          fontSize: '90%',
          height: '110px',
        },
      },
    },

    // enabled mode:
    '> .dx-form-group': {
      display: 'flex',
      flexFlow: 'column nowrap',
      flexGrow: 1,
      '> input': {
        borderBottom: `1px dotted ${dxColors.bgDarker_3}`,
        ':hover, :active, :focus': { backgroundColor: dxColors.bgFocus },
      },
      '> select': {
      },
      '> textarea': {
        ':hover, :active, :focus': { backgroundColor: dxColors.bgFocus },
      },
      '> .form-control-feedback': { // validation feedback glyphicons
      },
      '> .help-block': {
        margin: 0,
        padding: '4px',
      },
    },

    // disabled mode (there is no `dx-form-group` in this mode):
    '&.disabled': {
      '> input, > textarea': {
        flexGrow: 1,
      },
      '> .dx-form-group': {
        '> .form-control': {
          cursor: 'default',
        },
      },
    },

    '&.dx-boolean-field.checkbox': {
      // Position the checkbox right of the label
      background: 'none',
      height: 'unset',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap', // see https://stackoverflow.com/questions/28916559/how-to-get-checkbox-label-to-be-on-the-left-of-checkbox
      ':before': { content: 'none' },
      ':after': { content: 'none' },
      '> label': {
        padding: 0,
        '> input[type="checkbox"], input[type="checkbox"], fieldset input[type="checkbox"]': {
          border: '1px solid transparent',
          float: 'right',
          height: '16px',
          margin: '3px -32px 3px 0',
          padding: '0',
          position: 'static',
          width: '16px',
          '*overflow': 'hidden',
        },
      },
    },
  },
});

// -- BaseForm Component --------------- --- --  -

const BaseForm = (props) => (
  <form
    className={`dx-item-form dx-${props.mode}`}
    onSubmit={props.handleSubmit}
    {...css(defaultFormStyles, props.formStyles)}
  >
    {props.children}
  </form>
);

BaseForm.propTypes = {
  /** @see https://redux-form.com/6.7.0/docs/api/ReduxForm.md/ */
  ...formPropTypes,

  /** Array of field-view elements such as <DateField/>, <StringField/>, <NumberField/>, etc. */
  children: PropTypes.node.isRequired,

  /**
   * The collection ID for the given item. This value is included in the props provided to the
   * validate function, which is required by `ItemDetails`.
   */
  collectionId: PropTypes.string.isRequired,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  formStyles: cssPropType,

  /**
   * Either `VIEW_MODE`, `EDIT_MODE` or `CREATE_MODE` from `react-frontend/constants`.
   * The default is: `VIEW_MODE`.
   */
  mode: PropTypes.string,
};

BaseForm.defaultProps = {
  mode: VIEW_MODE,
};

// -- ReduxedForm Component --------------- --- --  -

const ReduxedForm = reduxForm({
  enableReinitialize: true,
  // immutableProps: ['children', 'collectionId', 'formId', 'formStyles', 'handleSubmit', 'mode'],
  touchOnChange: true,
})(BaseForm);

// -- ItemForm Component --------------- --- --  -

/**
 * A component that renders (a subset of) the fields and/or relatees of an item. It can do so either
 * in view-mode, edit-mode or create-mode. In the first two modes, the field values of an existing
 * item are shown. In create-mode, an "empty" item is provided.
 *
 * This component uses `redux-form` to manage the values changes, validation, etc.
 *
 * It takes care of initializing the (field view) elements that will render the field values. The
 * default form will provide a form widget (field-view) for each field in the provided `fields`
 * prop (plus all required fields not included in the `fields` prop in create-mode). The order of
 * these widgets is unspecified.
 *
 * The manner in which the form is rendered can be customized by means of the `viewSchema` prop. If
 * you provide a `viewSchema` prop, then for each field specified in the `viewSchema` (plus all
 * required fields not included in the `viewSchema` prop in create-mode), a form widget (field-view)
 * will be included in the form. The order of these widgets is determined by the order of the field
 * specifications in the `viewSchema` prop.
 *
 * The default widget (field-view) used for each field depends on the _dxSchema_ type of the field.
 * These default field-views are provided in `react-frontend/components/fieldViews/`. The default
 * mapping is specified in the `defaultFieldViews` object in `react-frontend/components/fieldViews/initFieldViews.js`.
 * You can specify a custom widget by means of the `component` property of a field specification in
 * the viewSchema.
 */
export class ItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: null,
      fields: null,
      itemId: null,
      initialValues: null,
      mode: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.collId) {
      throw new Error('The "collId" prop for the "ItemForm" component is no longer supported.');
    }
    const {
      collectionId, fields, itemId, mode
    } = props;
    const update = state.fields !== fields || state.itemId !== itemId || state.mode !== mode;
    if (update) {
      const collSchema = dxSchema.getCollection(collectionId);
      const { elements, initialValues } = initFieldViews(collSchema, fields, mode, {
        viewSchema: props.viewSchema,
        relateeProps: props.relateeProps,
      });
      return {
        elements, fields, itemId, initialValues, mode
      };
    }
    return null;
  }

  async onSubmit(fields) {
    const {
      collectionId, createCommit, itemId, mode, updateCommit
    } = this.props;
    if (mode === VIEW_MODE) { return null; }
    if (mode === EDIT_MODE) {
      return updateCommit(itemId, fields, collectionId);
    }
    if (mode === CREATE_MODE) {
      return createCommit(fields, collectionId);
    }
    throw new Error(`Unexpected mode "${mode}".`);
  }

  onSubmitSuccess() {
  }

  render() {
    const {
      collectionId, formId, mode, ...otherProps
    } = this.props;
    if (mode === CREATE_MODE && !this.props.createCommit) {
      return <AlertSection error="The 'createCommit' prop should be provided in 'create' mode." />;
    }
    if (mode === EDIT_MODE && !this.props.updateCommit) {
      return <AlertSection error="The 'updateCommit' prop should be provided in 'edit' mode." />;
    }
    const formProps = {
      ...otherProps,
      collectionId,
      form: formId, // required for redux-form
      initialValues: this.state.initialValues || {}, // for redux-form
      mode,
      onSubmit: this.onSubmit, // required for redux-form
      onSubmitFail, // for redux-form
      onSubmitSuccess: this.onSubmitSuccess, // for redux-form
      validate,
    };
    return (
      <ReduxedForm {...formProps}>
        {this.state.elements || []}
      </ReduxedForm>
    );
  }
}

ItemForm.propTypes = {
  /**
   * The collection ID for the given item.
   * This value is included in the props provided to the validate function, which is required by
   * `ItemDetails`.
   */
  collectionId: PropTypes.string.isRequired,

  /** @deprecated Use "collectionId" instead. */
  collId: PropTypes.oneOf([]),

  /**
   * A function that dispatches an action to commit the new item in the store.
   * This can be injected into the parent component via redux mapDispatchToProps.
   * This function takes two arguments: 1) an object that contains the new item's fields,
   * and 2) the collection ID.
   * This action dispatcher should be provided when using this component in 'create' mode.
   * @see https://react-redux.js.org/using-react-redux/connect-mapdispatch#pass-down-action-dispatching-logic-to-unconnected-child-components
   */
  createCommit: PropTypes.func,

  /** The item fields (properties) to view or edit. */
  fields: PropTypes.object,

  /**
   * This value is used as the `form` config value for the `reduxForm(config)` decorator.
   * @see http://redux-form.com/6.7.0/docs/api/ReduxForm.md/
   */
  formId: PropTypes.string.isRequired,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  formStyles: cssPropType,

  /** The ID of the item, required when `mode` is `EDIT_MODE` or `CREATE_MODE`. */
  itemId: PropTypes.string,

  /**
   * Either `VIEW_MODE` (default), `EDIT_MODE` or `CREATE_MODE` from `react-frontend/constants`.
   */
  mode: PropTypes.string,

  /** Optional props to inject in relatee views. */
  relateeProps: PropTypes.object,

  /**
   * A function that dispatches an action to commit the updated item in the store.
   * This can be injected into the parent component via redux mapDispatchToProps.
   * This function takes three arguments: 1) the item ID, 2) an object with the updated fields,
   * and 3) the collection ID.
   * This action dispatcher should be provided when using the component in 'edit' mode.
   * @see https://react-redux.js.org/using-react-redux/connect-mapdispatch#pass-down-action-dispatching-logic-to-unconnected-child-components
   */
  updateCommit: PropTypes.func,

  /**
   * Optional specification of how the item details should be rendered. @see {@link ViewSchema}
   */
  viewSchema: viewSchemaPropType,
};

ItemForm.defaultProps = {
  fields: {},
  mode: VIEW_MODE,
};

const onSubmitFail = (errors, dispatch, submitError) => {
  console.error('Failed to save the update:');
  console.error(' - errors:', errors);
  console.error(' - submitError:', submitError);
};

// -- support --------------- --- --  -

/**
 * The value for the `validate` option for the `reduxForm()` form decorator generator in the
 * `redux-form` package. This synchronous validation function takes the form values and props passed
 * into the decorated form. If validation passes, it should return {}. If validation fails, it
 * should return the validation errors in the form `{ field1: <String>, field2: <String> }`.
 *
 * @param {object} values
 * @param {object} props
 * @returns {object.<String, String>} Object
 */
const validate = (values, props) => {
  const { collectionId } = props;
  const errors = {};
  forIn(values, (value, fieldId) => {
    const report = dxSchema.validateField(collectionId, fieldId, value);
    if (!report.valid) { errors[fieldId] = report.text; }
  });
  return errors;
};

import { equalShallow } from '../utils/equalShallow';
import { ofType } from './loadStateSupport';

// -- PageLoadState --------------- ---  --  -

/**
 * @typedef {object} PageLoadState
 *
 * A load-state serves three purposes:
 * 1) It represents the resource-loading-related state in the Redux store;
 * 2) It provides the state transition methods;
 * 3) It can be used as a 'pure' prop that provides convenience properties such as `available`;
 *
 * @property {object} [action] - The action that caused the transition to the current state.
 *   This action is undefined for the initial state.
 * @property {boolean} available - True when the page of items has been loaded or is being reloaded.
 * @property {number} from - The index of the first item in the page.
 * @property {Array.<{id: string}>} items - The items in the page.
 * @property {number} limit - The number of items in the page.
 * @property {Array.<cargo-universal/dxSchema/OrderField>} ordering - The ordering of the items.
 * @property {*} source - A context-specific specification of the current source. This may be
 *   simply the collection ID, or an object with the collection ID and the applied filter. This
 *   source specification, together with the _from_, _limit_ and _ordering_ values (which should
 *   thus not be included in the _source_), can be used by components such as {@link ItemsList}
 *   to determine whether to dispatch a new fetch action or not.
 * @property {number} total - The total number of items in the collection.
 *
 * @property {boolean} loaded - True when the page of items has been loaded.
 * @property {boolean} loading - True when the page of items is being loaded.
 * @property {boolean} reloading - True when the page of items is being reloaded. The previously
 *   loaded items remain available until the new page is received.
 *
 * @property {Function.<Array.<{id: string}>, options>} updateItems - Takes an items array and an
 *   optional options object and  returns the (immutably) updated `loaded` load-state. The options
 *   object may provide one of the following properties: from, limit, ordering, total, and source.
 *   This function can be used when you want to temporarily update the state, for instance to do
 *   optimistic updates.
 *
 * @property {Function([number], [number], [string]):PageLoadState} handleLoad - To be called when a
 *   page of items is being loaded. Returns the `loading` load-state. Optionally takes the page
 *   settings (from, limit, ordering) or reuses the current page settings.
 *   This private method should not be called by client code.
 *
 * @property {Function(object[], number, number, string, number):PageLoadState} handleReceive -
 *   Takes the loaded items array, the _from_, _limit_ and _ordering_ page settings, and the total
 *   number of items in the collection. Returns the `loaded` load-state when this item is being
 *   reloaded or updated in this load-state.
 *   This private method should not be called by client code.
 *
 * @property {Function():PageLoadState} handleInvalidate - To be called when the currently loaded page
 *   of items is no longer valid, for instance because a new item was created, or an item was
 *   updated. The from/limit/ordering page settings are retained.
 *   This private method should not be called by client code.
 *
 * @see createPageLoadStateReducer
 * @see DxPropTypes.pageLoadState
 * @see DxPropTypes.pageLoadStateOf
 * @see getPageLoadStateAvailable
 * @see getPageLoadStateFrom
 * @see getPageLoadStateItems
 * @see getPageLoadStateLimit
 * @see getPageLoadStateLoaded
 * @see getPageLoadStateLoading
 * @see getPageLoadStateOrdering
 * @see getPageLoadStateReloading
 * @see getPageLoadStateTotal
 */

/**
 * @private
 * @param {object|void} action - The action that caused this state.
 * @param {number} [from] - The index of the first element in the page.
 * @param {number} [limit] - The number of items in the page. Zero means no limit.
 * @param {Array.<cargo-universal/dxSchema/OrderField>} [ordering] - The ordering specification.
 * @return {PageLoadState}
 */
const initialState = (action, from = 0, limit = 10, ordering) => ({
  action,
  available: false,
  from,
  items: [],
  limit,
  loaded: false,
  loading: false,
  ordering,
  reloading: false,
  source: 'default',
  state: 'reset',
  total: 0,
  updateItems() { return this; },
  handleLoad(nxtAction, nxtFrom = from, nxtLimit = limit, nxtOrdering = ordering, nxtSource = this.source, nxtTotal = this.total) {
    // console.log('[pageLoadState] initialState > load');
    return loadingState(nxtAction, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal);
  },
  handleReceive(nxtAction, items, nxtFrom = from, nxtLimit = limit, nxtOrdering = ordering, nxtSource = this.source, nxtTotal = this.total) {
    return loadedState(nxtAction, items, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal);
  },
  handleInvalidate() { return this; },
});

/**
 * @private
 * @param {object} action - The action that caused this state.
 * @param {number} from - The index of the first element in the page.
 * @param {number} limit - The number of items in the page. Zero means no limit.
 * @param {Array.<cargo-universal/dxSchema/OrderField>} ordering - The ordering specification.
 * @param {string} [source] - Optional state source.
 * @param {number} total - The total number of items in the itemset.
 * @return {PageLoadState}
 */
const loadingState = (action, from, limit, ordering, source, total) => ({
  action,
  available: false,
  from,
  items: [],
  limit,
  loaded: false,
  loading: true,
  ordering,
  reloading: false,
  source,
  state: 'loading',
  total,
  updateItems() { return this; },
  handleLoad(nxtAction, nxtFrom = from, nxtLimit = limit, nxtOrdering = ordering, nxtSource = source, nxtTotal = total) {
    if (nxtFrom === from && nxtLimit === limit && nxtOrdering === ordering && equalShallow(nxtSource, source) && nxtTotal === total) {
      return this; // already loading this page
    }

    return loadingState(nxtAction, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal);
  },
  handleReceive(nxtAction, nxtFrom = from, items, nxtLimit = limit, nxtOrdering = ordering, nxtSource = source, nxtTotal = total) {
    return loadedState(nxtAction, items, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal);
  },
  handleInvalidate() { return this; },
});

/**
 * @private
 * @param {object} action - The action that caused this state.
 * @param {Array.<{ id: string }>} items - The loaded items.
 * @param {number} [from] - The index of the first element in the page.
 * @param {number} [limit] - The number of items in the page. Zero means no limit.
 * @param {Array.<cargo-universal/dxSchema/OrderField>} [ordering] - The ordering specification.
 * @param {string} [source] - Optional state source.
 * @param {number} total - The total number of items in the itemset.
 * @return {PageLoadState}
 */
const loadedState = (action, items, from, limit, ordering, source, total) => ({
  action,
  available: true,
  from,
  items,
  limit,
  loaded: true,
  loading: false,
  ordering,
  reloading: false,
  source,
  state: 'loaded',
  total,
  updateItems(nxtItems, opts = {}) {
    return loadedState(
      action,
      nxtItems,
      opts.from || from,
      opts.limit || limit,
      opts.ordering || ordering,
      opts.source || source,
      opts.total || total,
    );
  },
  handleLoad(nxtAction, nxtFrom = from, nxtLimit = limit, nxtOrdering = ordering, nxtSource = source, nxtTotal = total) {
    if (nxtFrom === from && nxtLimit === limit && nxtOrdering === ordering && equalShallow(nxtSource, source) && nxtTotal === total) {
      return reloadingState(nxtAction, items, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal); // reload this page
    }

    return loadingState(nxtAction, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal); // load different page
  },
  handleReceive(nxtAction, nxtFrom = from, nxtItems, nxtLimit = limit, nxtOrdering = ordering, nxtSource = source, nxtTotal = total) {
    return loadedState(nxtAction, nxtItems, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal);
  },
  handleInvalidate(nxtAction) { return initialState(nxtAction, from, limit, ordering); },
});

/**
 * @private
 * @param {object} action - The action that caused this state.
 * @param {Array.<{ id: string }>} items - The reloaded items.
 * @param {number} from - The index of the first element in the page.
 * @param {number} limit - The number of items in the page. Zero means no limit.
 * @param {Array.<cargo-universal/dxSchema/OrderField>} ordering - The ordering specification.
 * @param {string} [source] - Optional state source.
 * @param {number} total - The total number of items.
 * @return {PageLoadState}
 */
const reloadingState = (action, items, from, limit, ordering, source, total) => ({
  action,
  available: true,
  from,
  items,
  limit,
  loaded: false,
  loading: false,
  ordering,
  reloading: true,
  source,
  state: 'reloading',
  total,
  updateItems(nxtItems, opts = {}) {
    return reloadingState(
      action,
      nxtItems,
      opts.from || from,
      opts.limit || limit,
      opts.ordering || ordering,
      opts.source || source,
      opts.total || total,
    );
  },
  handleLoad(nxtAction, nxtFrom = from, nxtLimit = limit, nxtOrdering = ordering, nxtSource = source, nxtTotal = total) {
    if (nxtFrom === from && nxtLimit === limit && nxtOrdering === ordering && equalShallow(nxtSource, source) && nxtTotal === total) {
      return this; // already reloading this page
    }

    return loadingState(nxtAction, nxtFrom, nxtLimit, nxtOrdering, nxtSource, nxtTotal); // load different page
  },
  handleReceive(nxtAction, nxtItems, nxtFrom = from, nxtLimit = limit, nxtOrdering = ordering, nxtSource = source, nxtTotal = total) {
    return loadedState(nxtAction, nxtFrom, nxtItems, nxtLimit, nxtOrdering, nxtSource, nxtTotal);
  },
  handleInvalidate() { return this; },
});

// -- Reducer --------------- --- --  -

/**
 * Returns a Redux reducer that takes as input a {@link PageLoadState} object and a Redux action,
 * and returns a {@link PageLoadState} object.
 * See _dxLoadState_ manual for more details.
 *
 * @param {string|Function} loadActionType - The type of the action that is dispatched when a page
 *   of items is being fetched, or a function that takes an action object and returns true when it
 *   is such an action. The actions must have the following properties:
 *    - `from` {number} - The index of the first item in the page.
 *    - `limit` {number} - The number of items in the page.
 *    - `ordering` {*} - The ordering of the items.
 *    - 'source' {*} - Optional state source. When provided it is shallowly compared with the
 *         previous source to determine if this action constitutes a request for a "new" page of
 *         items or is rather a "reload" of the current page. This is useful in cases where
 *         additional constraints, such as search terms, determine the actual content of the page.
 *         By representing these constraints in this source, actions with a different "source" will
 *         be properly recognized as a proper load instead of a reload.
 * @param {string|Function} receiveActionType - The type of the action that is dispatched when
 *   receiving a page of items, or a function that takes an action object and returns true when it
 *   is such an action. The actions must have the following properties:
 *    - `from` {number} - The actual index of the first item in the page.
 *    - `limit` {number} - The actual number of items in the page.
 *    - `ordering` {Ordering} - The actual [ordering](cargo-universal/dxSchema/typedefs) of the items.
 *    - `total` {number} - The total number of items in the (constrained) collection from which the
 *         page of items was sourced.
 * @param {object} [options]
 * @param {number} [options.from = 0] - The index of the first element in the page.
 * @param {string|Function} [options.invalidateActionType] - The action type that is dispatched when
 *   the loaded page of items should be reloaded, or a function that takes an action object and
 *   returns true when the load-state should be invalidated in response to the given action.
 * @param {number} [options.limit = 20] - The number of items in the page. Zero means no limit.
 *   Defaults to 20.
 * @param {Array.<cargo-universal/dxSchema/OrderField>} [options.ordering] - The ordering
 *   specification. When not provided, query resolvers are expected to use the default ordering
 *   specified in the dxSchema.
 * @param {string|Function} [options.resetActionType] - The action type (or predicate) for which
 *   the load-state should be reset to its initial state.
 *
 * @return {Function(object, object)} The Redux state reducer.
 *
 * @see PageLoadState
 * @see createItemLoadStateReducer
 * @see createSimpleLoadStateReducer
 */
export const createPageLoadStateReducer = (loadActionType, receiveActionType, options = {}) => {
  const {
    from = 0,
    invalidateActionType,
    limit = 10,
    ordering = [],
    resetActionType,
  } = options;
  const _initialPageLoadState = initialState(undefined, from, limit, ordering);

  return (state = _initialPageLoadState, action) => {
    // console.log('[PageLoadStateReducer] action:', action);
    if (ofType(action, loadActionType)) {
      return state.handleLoad(action, action.from, action.limit, action.ordering, action.source, action.total);
    }
    if (ofType(action, receiveActionType)) {
      return state.handleReceive(
        action,
        action.from,
        action.items,
        action.limit,
        action.ordering,
        action.source,
        action.total,
      );
    }
    if (ofType(action, invalidateActionType)) {
      return state.handleInvalidate(action, state.from, state.limit, state.ordering);
    }
    if (ofType(action, resetActionType)) {
      return _initialPageLoadState;
    }
    return state;
  };
};

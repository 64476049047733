import { spawn } from 'redux-saga/effects';

import { dxAuthSagas } from './dxAuth';
import { dxDMSSagas } from './dxDMSSagas';

export const dxSagas = function* () {
  yield spawn(dxAuthSagas);
  yield spawn(dxDMSSagas);
};

export {
  removeModal,
  showConfirmationModal,
  showErrorModal,
  showMessageModal,
  showProgressModal,
} from './dxModalSagas';

export * from './helpers';

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import { getDxAuthToken } from '../../selectors';
import { isString } from '../../../utils/isString';

import { cssPropType } from '../../propTypes';
import { dxStyles } from '../../styles';

// -- Styles --------------- --- --  -

const buttonStyles = css({
  borderBottom: 'none',
  borderBottomWidth: 0,
  boxSizing: 'content-box',
  height: 30,
});

const dropdownStyles = css({
  boxSizing: 'content-box',
  height: 31,
  '> button': [dxStyles.button, {
    borderBottom: 'none',
    height: 30,
  }],
  '> __Dropdown.Toggle': {
    alignItems: 'center',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    display: 'flex',
    '&:focus, &:focus:active, &:active': {
      // outline: 'none',
      boxShadow: 'none',
    },
    '> span': [dxStyles.buttonLabel, { marginRight: 5 }],
  },
  '> __Dropdown.Menu': {
    border: 'none',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    minWidth: 0,
    '> __*': {
      border: 'none',
      width: '100%',
      ':hover, :active, :focus': { border: 'none' },
    },
  },
});

// -- Component --------------- --- --  -

const propTypes = {
  /**
   * The base of a protected URL to open when the user click on the button.  The user token will be
   * appended in the query part of the URL. The resulting URL will be use as `href` prop.
   */
  authHref: PropTypes.string,

  /**
   * When children are provided, a drop-down menu is shown. These children should be
   * `ToolbarMenuItem` elements, one for each menu item. Also provide the `onSelect` prop which
   * will be called when an item is selected.
   */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, (child) => {
      // noinspection JSUnresolvedVariable
      if (!child.type.isToolbarMenuItem) {
        error = new Error(`[${componentName}] children should be of type [ToolbarMenuItem].`);
      }
    });
    return error;
  },

  /** Optional class. */
  className: PropTypes.string,

  /** Disable the button. */
  disabled: PropTypes.bool,

  /**
   * The URL to open when the user presses the button. The browser is redirected to this url,
   * meaning that the redirection is not handled by the react/redux-router.
   *
   * - To navigate inside the application, use the `link` or `indexLink` prop instead.
   * - To provide custom handling of click events, use the `onClick` prop to specify the handler.
   */
  href: PropTypes.string,

  /** Optional icon to show in the button. */
  icon: PropTypes.node,

  /**
   * The path to navigate to when the user presses the button. This navigation is handled by
   * redux-router. The button will stay highlighted (like a tab/nav-link) when the current location
   * exactly matches the specified link path.
   *
   * - To highlight the button when the current location contains the specified path use the
   *   `link` prop instead.
   * - To navigate outside the application, use the `href` prop instead.
   * - To provide custom handling of click events, use the `onClick` prop to specify the handler.
   */
  indexLink: PropTypes.string,

  /** The button label. */
  label: PropTypes.node,

  /**
   * The path to navigate to when the user presses the button. This navigation is handled by
   * redux-router. The button will stay highlighted (like a tab/nav-link) when the current location
   * contains the specified link path.
   *
   * - To match the location exactly, e.g. for index tabs, use the `indexLink` prop instead.
   * - To navigate outside the application, use the `href` prop instead.
   * - To provide custom handling of click events, use the `onClick` prop to specify the handler.
   */
  link: PropTypes.string,

  /**
   * The handler to call when the user presses the button.
   *
   * - To navigate inside the application, use the `link` or `indexLink` prop instead.
   * - To navigate outside the application, use the `href` prop instead.
   */
  onClick: PropTypes.func,

  /**
   * When children are provided, a drop-down menu is shown, and this handler is called when one of
   * the given options is selected.
   */
  onSelect: PropTypes.func,

  /** Either 'left' (default), 'middle' or 'right'. */
  placement: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../../propTypes/cssPropType). */
  styles: cssPropType,

  /**
   * When the `href` prop is given, this optional prop can be used to set the target attribute.
   * @warning Beware security issues when using `_blank`.
   * @see https://developer.mozilla.org/en/docs/Web/HTML/Element/a
   */
  target: PropTypes.string,

  /** @deprecated */
  title: PropTypes.oneOf([]),
};

const defaultProps = {
  placement: 'left',
};

/**
 * @constructor
 * To specify the behaviour of the button when it is pressed, either use the `link` prop to redirect
 * by means of the react/redux-router; or use the `href` prop to redirect the browser directly,
 * bypassing the react/redux-router; or use the `onClick` prop to specify the handler that should be
 * called.
 * @see Dropdown
 * @todo Refactor in `ToolbarItemProps`, `ToolbarMenu` and `ToolbarButton`.
 */
const ToolbarButtonComponent = (props) => {
  if (props.title) {
    throw new Error('The ToolbarButton "title" prop is no longer supported. Use "label" instead.');
  }

  const {
    children, className, disabled, icon, label, styles
  } = props;

  if (children) {
    const dropDownProps = {
      className,
      disabled,
      id: Date.now(),
      onSelect: props.onSelect,
      role: 'menuitem',
    };
    return (
      <Dropdown {...dropDownProps} {...css(dropdownStyles, styles)}>
        <Dropdown.Toggle>
          {icon && <img className="button-icon" src={icon} alt="button-icon" />}
          {label && <span className="button-label">{label}</span>}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  if (isString(props.link)) {
    return (
      <LinkContainer className={className} to={{ pathname: props.link }}>
        <Button disabled={disabled} {...css(dxStyles.button, buttonStyles, styles)}>
          {icon && <img className="button-icon" src={icon} alt="button-icon" />}
          {label && <span className="button-label">{label}</span>}
        </Button>
      </LinkContainer>
    );
  }

  if (isString(props.indexLink)) {
    return (
      <IndexLinkContainer className={className} to={{ pathname: props.indexLink }}>
        <Button disabled={disabled} {...css(dxStyles.button, buttonStyles, styles)}>
          {icon && <img className="button-icon" src={icon} alt="button-icon" />}
          {label && <span className="button-label">{label}</span>}
        </Button>
      </IndexLinkContainer>
    );
  }

  if (isString(props.href)) {
    return (
      <Button
        className={className}
        disabled={disabled}
        href={props.href}
        tabIndex="0"
        target={props.target}
        {...css(dxStyles.button, buttonStyles, styles)}
      >
        {icon && <img className="button-icon" src={icon} alt="button-icon" />}
        {label && <span className="button-label">{label}</span>}
      </Button>
    );
  }

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={props.onClick}
      {...css(dxStyles.button, buttonStyles, styles)}
      type="button"
    >
      {icon && <img className="button-icon" src={icon} alt="button-icon" />}
      {label && <span className="button-label">{label}</span>}
    </button>
  );
};

ToolbarButtonComponent.propTypes = propTypes;
ToolbarButtonComponent.defaultProps = defaultProps;

// -- Container --------------- --- --  -

export const ToolbarButton = connect(
  (state, ownProps) => {
    const { authHref } = ownProps;
    if (isString(authHref)) {
      return {
        href: `${authHref}${authHref.includes('?') ? '&' : '?'}jwt=${getDxAuthToken(state)}`,
      };
    }
    return {};
  }
)(ToolbarButtonComponent);

import { GraphQLScalarType } from 'graphql';
import { GraphQLError } from 'graphql/error';
import { Kind } from 'graphql/language';

/**
 * The GraphQLDate scalar type that is exported
 *
 * Modified from from https://github.com/excitement-engineer/graphql-iso-date/edit/master/src/index.js
 * @type {GraphQLScalarType}
 */
export const GraphQLDate = new GraphQLScalarType({
  name: 'Date',
  description: 'Date compatible with DxSchema.',
  serialize: (value) => {
    if (value === null) { return null; }
    if (!(value instanceof Date)) {
      throw new TypeError(`Expected Date instance but got object with type "${typeof value}" and value ${String(value)}`);
    }
    return value.toISOString();
  },
  parseValue: (value) => {
    if (value === null || value === undefined) { return null; }
    if (!(typeof value === 'string' || value instanceof String)) {
      throw new TypeError(`Expected a String but got object with type "${typeof value}" and value ${String(value)}`);
    }
    return new Date(value);
  },
  parseLiteral: (ast) => {
    if (ast.kind !== Kind.STRING) {
      throw new GraphQLError(`Can only parse strings to a Date but got kind ${ast.kind}`);
    }
    return new Date(ast.value);
  }
});

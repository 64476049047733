import { css } from 'glamor';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../../propTypes';
import { dxColors, dxStyles } from '../../styles';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  boxSizing: 'content-box',
  display: 'flex',
  height: '22px',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  '> li': {
    height: '22px',
    lineHeight: '22px',
    '> a': {
      boxShadow: 'none',
      height: '22px',
      padding: '0 4px',
      textDecoration: 'none',
      // '> span': { fontWeight: '600' },
    },
  },
  '> li.arrow': {
    fontSize: '24px',
    '> a': {
      '> span': {
        position: 'relative',
        top: '-2px',
      },
    },
  },
  '> li.arrow.enabled': {
    cursor: 'pointer',
    transition: 'background-color .5s',
    ':hover, :active, :focus': { backgroundColor: dxColors.bgFocus },
    '> a': {
      color: dxColors.fgMain,
      cursor: 'pointer',
    },
  },
  '> li.arrow.disabled': {
    color: dxColors.fgDisabled,
    ':hover, :active, :focus': { backgroundColor: 'white' },
    '> a': {
      color: dxColors.fgDisabled,
      cursor: 'default',
      '> span': { color: dxColors.fgDisabled },
    },
  },
  '> li.page-index': {
    boxSizing: 'border-box',
    display: 'flex',
    fontSize: '15px',
    justifyContent: 'center',
    minWidth: 70,
    padding: '0 4px',
    '> span': {
      color: dxColors.fgLighter_3,
      fontWeight: '600',
      position: 'relative',
      top: '-1px',
    },
  },
  '&.borders': {
    height: '30px',
    '> li': [dxStyles.thinBorderLeft, dxStyles.thinBorderTop, {
      height: '30px',
      lineHeight: '30px',
      borderRadius: 0,
      '> a': {
        height: '30px',
        padding: '0 12px',
      },
    }],
    ':last-child': dxStyles.thinBorderRight,
  },
});

// -- Component --------------- --- --  -

/**
 * Pagination widget for use in a toolbar (or even outside of toolbar).
 */
export const ToolbarPagination = (props) => {
  const {
    activePage, noBorders, pageCount, pageTo, styles
  } = props;
  const aProps = { role: 'button', href: '#' };

  const handlePageTo = (event, page) => {
    if (page !== activePage && page >= 1 && page <= pageCount) {
      pageTo(page);
    }
    event.preventDefault();
  };

  return (
    <ul
      className={classnames('dx-pagination', { borders: !noBorders })}
      {...css(defaultStyles, styles)}
    >
      <li className={classnames('arrow', activePage > 1 ? 'enabled' : 'disabled')}>
        <a onClick={(e) => handlePageTo(e, 1)} {...aProps}>
          <span aria-label="First">«</span>
        </a>
      </li>
      <li className={classnames('arrow', activePage > 1 ? 'enabled' : 'disabled')}>
        <a onClick={(e) => handlePageTo(e, activePage - 1)} {...aProps}>
          <span aria-label="Previous">‹</span>
        </a>
      </li>
      <li className={classnames('page-index')}>
        {/* <li {...pageIndexStyles}> */}
        <span aria-label="Current page">{`${activePage} of ${pageCount}`}</span>
      </li>
      <li className={classnames('arrow', activePage < pageCount ? 'enabled' : 'disabled')}>
        <a onClick={(e) => handlePageTo(e, activePage + 1)} {...aProps}>
          <span aria-label="Next">›</span>
        </a>
      </li>
      <li className={classnames('arrow', activePage < pageCount ? 'enabled' : 'disabled')}>
        <a onClick={(e) => handlePageTo(e, pageCount)} {...aProps}>
          <span aria-label="Last">»</span>
        </a>
      </li>
    </ul>
  );
};

ToolbarPagination.propTypes = {
  /** One-based active page index, i.e. pass 1 for the first page, 2 for the second, etc. */
  activePage: PropTypes.number.isRequired,

  /** See react-bootstrap > Pagination > maxButtons */
  maxButtons: PropTypes.number.isRequired,

  /** Do not draw borders. */
  noBorders: PropTypes.bool.isRequired,

  /** The number of pages. */
  pageCount: PropTypes.number.isRequired,

  /** Handler called with 1 argument: the (one-based) page index to show. */
  pageTo: PropTypes.func.isRequired,

  /** The placement of the title in the toolbar. */
  placement: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../../propTypes/cssPropType). */
  styles: cssPropType,
};

ToolbarPagination.defaultProps = {
  placement: 'right',
};

import PropTypes from 'prop-types';

export const itemsSelectionPropTypes = {
  /**
   * True when all items are selected.
   * Use the _getItemsSelectionAllSelected_ selector.
   */
  allSelected: PropTypes.bool.isRequired,

  /**
   * The number of selected items or NaN when all are selected.
   * Use the _getItemsSelectionCount_ selector.
   */
  count: PropTypes.number.isRequired,

  /** True. Use the _isItemsSelection_ selector. */
  isItemsSelection: PropTypes.bool.isRequired,

  /**
   * An object that has true for each selected item identifier key.
   * Use the _getItemsSelectionNoneSelected_, _getItemsSelectionHasSelected_,
   * _getItemsSelectionDict_, _getItemsSelectionIds_, _applyItemsSelection_ and _isItemSelected_
   * selectors.
   */
  selected: PropTypes.object.isRequired,
};

export const itemsSelectionPropType = PropTypes.shape(itemsSelectionPropTypes);

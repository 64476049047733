import React, { Component } from 'react';
import { Field } from 'redux-form';

import { dxFormFieldViewPropTypes } from '../../propTypes';

import { CheckboxFFC, checkboxControlPropTypes } from './formFieldComponents/CheckboxFFC';

// -- Component --------------- ---  --  -

/**
 * A field for a single-line string.
 */
export class BooleanField extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.editMode !== nextProps.editMode;
  }

  render() {
    const {
      fieldId, fieldProps, fieldSchema, value, ...otherProps
    } = this.props;
    const childProps = {
      baseValue: value,
      className: `dx-boolean-field${fieldSchema.isHidden ? ' dx-hidden' : ''}`,
      component: CheckboxFFC,
      controlId: `input-${fieldId}`,
      name: fieldId, // identifies the value in the form
      parse,
      ...otherProps,
      ...fieldProps,
    };
    return <Field {...childProps} />;
  }
}

BooleanField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...checkboxControlPropTypes,
};

BooleanField.defaultProps = {
  editMode: false,
};

// -- Support --------------- ---  --  -

const parse = (value) => Boolean(value);

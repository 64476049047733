import { spawn } from 'redux-saga/effects';

import externalItemsSaga from './externalItems';

/**
 * This generator function is run by the redux-saga middleware. Fork or spawn your sagas from this
 * main saga.
 *
 * @example
 * import { spawn } from 'redux-saga/effects';
 *
 * import { fooSaga, barSaga } from './examples';
 *
 * export default function* () {
 *   yield spawn(fooSaga);
 *   yield spawn(barSaga);
 * }
 */
export const sagas = function* () {
  yield spawn(externalItemsSaga);
};

import { DX_TASKS_FETCH, DX_TASKS_RECEIVE } from '../constants';

// -- Main Reducer ------------------- --- --  -

const initialState = {
  canView: false,
  latestUpdate: null,
  isLoaded: false,
  isLoading: true,
  tasks: []
};

export const dxTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case DX_TASKS_FETCH:
      return fetchTasks(state, action);

    case DX_TASKS_RECEIVE:
      return receiveTasks(state, action);

    default:
      return state;
  }
};

const fetchTasks = (state) => ({
  ...state,
  isLoaded: false,
  isLoading: true,
});

const receiveTasks = (state, { tasks }) => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  latestUpdate: Date.now(),
  tasks,
});

import {
  mutationBuilder,
  getUpdateArgs,
  getUpdateVars,
} from '../utils/dxf';

export const buildUpdateClientQuery = ({
  id, created_by, created_on, updated_by, updated_on, scoped_rights, roles, ...fields
}, { collSchema }) => {
  const varArgs = getUpdateArgs(collSchema, { includeRelatees: true });
  const originalUpdateVars = getUpdateVars(collSchema, { includeRelatees: true });
  const updateVars = {
    ...originalUpdateVars,
    name: { type: 'String!' },
    roles: { type: '[String]' },
    scoped_rights: { type: '[String]' },
  };
  return mutationBuilder({
    variables: updateVars,
    fields: {
      client: {
        varArgs: { id: 'id' },
        fields: {
          update: {
            varArgs,
            fields,
          },
        },
      },
    },
  });
};

import PropTypes from 'prop-types';
import React from 'react';
import { connectAdvanced } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { DxError } from '../../../utils/dxf';
import { immute } from '../../../utils/immute';
import { toString } from '../../../utils/toString';

import { dmsMountAction } from '../../actions';
import { AlertSection } from '../AlertSection';
import { CREATE_MODE, EDIT_MODE, VIEW_MODE } from '../../constants';
import { DMSConfig } from '../../dms/DMSConfig';
import { getDMSConfig } from '../../selectors';
import { inject, join } from '../../utils';

import { DMSCollectionChapter } from './DMSCollectionChapter';
import { DMSCollectionsChapter } from './DMSCollectionsChapter';
import { DMSItemChapter } from './DMSItemChapter';

// -- Component --------------- --- --  -

/**
 * TODO: some docs would be nice
 * TODO: add componentDidCatch
 */
class DMSRoutesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: new DxError(error, info) });
  }

  render() {
    if (this.state.hasError) { return <AlertSection error={this.state.error} />; }
    if (this.props.dmsError) { return <AlertSection error={this.props.dmsError} />; }

    const { config } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={config.basePath}
          component={inject(DMSCollectionsChapter, { dmsConfig: config })}
        />
        {config.collections.reduce((routes, collection) => {
          const { id } = collection;
          return routes.concat([
            <Route
              component={inject(DMSCollectionChapter, { collection })}
              exact
              key={`${id}-0`}
              path={collection.path}
            />,
            <Route
              component={inject(DMSItemChapter, { collection, mode: CREATE_MODE })}
              exact
              key={`${id}-1`}
              path={collection.createPath}
            />,
            <Route
              component={inject(DMSItemChapter, (ownProps) => ({
                collection,
                itemId: decodeURIComponent(ownProps.match.params.id),
                mode: VIEW_MODE,
              }))}
              exact
              key={`${id}-2`}
              path={join(collection.path, ':id')}
            />,
            <Route
              component={inject(DMSItemChapter, (ownProps) => ({
                collection,
                itemId: decodeURIComponent(ownProps.match.params.id),
                mode: EDIT_MODE,
              }))}
              exact
              key={`${id}-3`}
              path={join(collection.path, ':id', 'edit')}
            />
          ]);
        }, [])}
      </Switch>
    );
  }
}

DMSRoutesComponent.propTypes = {
  /** To be provided. */
  config: PropTypes.instanceOf(DMSConfig).isRequired,

  /** DMSRoutes error message to show. */
  dmsError: PropTypes.string,
};

// -- Container --------------- --- --  -

export const DMSRoutes = withRouter(connectAdvanced(
  (dispatch) => {
    // Memoized props to pass to wrapped component:
    const result = { config: null };

    return (state, nextProps) => {
      const { config, path } = nextProps;
      if (!config || !config.isDMSConfig) {
        return {
          ...nextProps,
          dmsError: `The DMSRoutes requires a DMSConfig instance as "config" prop, got "${toString(config)}".`,
        };
      }

      if (!path) {
        return {
          ...nextProps,
          dmsError: `The DMSRoutes requires a string as "path" prop, got "${toString(path)}".`,
        };
      }

      if (config !== getDMSConfig(state)) {
        config.initialize(path);
        dispatch(dmsMountAction(config));
        // Note that the Redux store is now updated. The state object we have been given above is
        // thus out-of-date.
      }

      return immute(result, nextProps);
    };
  },
  {
    getDisplayName: (name) => name,
    methodName: 'connectDMSRoutes',
    shouldHandleStateChanges: false,
  }
)(DMSRoutesComponent));

import isObject from 'lodash/isObject';
import {
  AuthorizerBase,
  impliedByScopedActivityTypes,
  isActivity,
  normalizeContext,
  NotAuthorizedError,
} from '../../utils/auth';
import { dxSchema } from '../dxSchema';

import { getDxAuthUser } from '../selectors';

const ANONYMOUS_ACTIVITY = 'dx/anonymous';

// -- Authorizer Class --------------- --- --  -

export class Authorizer extends AuthorizerBase {
  /** @inheritDoc */
  isAuthorized(user, rule, context) {
    try {
      this.authorize(user, rule, context);
      return true;
    } catch (error) {
      console.warn('>>> isAuthorized --', { error });
      return false;
    }
  }

  /** @inheritDoc */
  authorizeGenericActivity(user, activity, context) {
    // context.explain('>>> Authorizer.authorizeGenericActivity() --', { activity });
    if (activity === ANONYMOUS_ACTIVITY) {
      return context.explain('|< authorized for anonymous activity');
    }
    if (user.isAuthorizedForGenericActivity(activity)) {
      return context.explain('|< authorized for', activity);
    }

    const msg = `Not authorized for activity "${activity}".`;
    context.explain('|!', msg);
    throw new NotAuthorizedError(msg);
  }

  /** @inheritDoc */
  authorizeScopedActivity(user, activity, scope, topic, context) {
    // context.explain('|>> Authorizer.authorizeScopedActivity() --', { activity, scope, topic });
    if (!isActivity(activity)) {
      throw new Error(`Expected an Activity, got "${activity}".`);
    }
    const activityTypes = impliedByScopedActivityTypes[activity.activityType];
    // context.explain('|- activityTypes:', activityTypes);
    for (const activityType of activityTypes) {
      const impliedByActivity = `${activity.collectionId}::${activityType}`;
      // context.explain('|- impliedByActivity:', impliedByActivity);
      if (user.isAuthorizedForGenericActivity(impliedByActivity)) {
        return context.explain('|< authorized scoped collection activity generically');
      }
      if (user.isAuthorizedForScopedActivity(scope, topic, impliedByActivity)) {
        return context.explain('|< authorized scoped collection activity');
      }
    }
    const msg = `Not authorized for scoped collection activity "${activity}".`;
    context.explain('|!', msg);
    throw new NotAuthorizedError(msg);
  }
}

// -- Authorizer Singleton --------------- --- --  -

/**
 * The authorizer singleton.
 * @type {Authorizer}
 */
Authorizer.singleton = new Authorizer(dxSchema);

/**
 * Update the authorizer singleton. This should only be used for testing purposes.
 *
 * @param {DxSchemaBase} customDxSchema
 */
Authorizer.initializeSingleton = (customDxSchema) => {
  Authorizer.singleton = new Authorizer(customDxSchema);
};

// -- authorize and isAuthorized functions --------------- --- --  -

/**
 * Throws an error when the current user is not authorized to perform the given authorization rule.
 *
 * @see {@link react-frontend/auth/isAuthorized}
 * @param {User|state} user - The user object (use getDxAuthUser to get this object).
 * @param {AuthRule} rule
 * @param {object} [context]
 * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
 * @throws When the user is not authorized.
 */
export const authorize = (user, rule, context = {}) => {
  // console.log('>>> authorize() --', { user, rule, context });
  if (!user) {
    throw new NotAuthorizedError('The user is undefined.');
  }
  if (!isObject(context)) {
    throw new Error(`Expected an object as "context", got "${context}".`
      + 'Note that the "authorize" function no longer accepts multiple rules. '
      + 'Use an "{ or: [rules...] }" object to specify a rule disjunction or '
      + 'an "{ and: [rules...] }" object to specify a rule conjunction.');
  }
  normalizeContext(context);
  context.explain('>>> authorize() --', { user, rule, context });
  // noinspection JSUnresolvedVariable
  if (user.dxAuth) {
    // Case: the Redux state is given instead of a User object
    return Authorizer.singleton.authorize(getDxAuthUser(user), rule, context);
  }

  return Authorizer.singleton.authorize(user, rule, context);
};

/**
 * Checks if the given user is authorized to perform the given authorization rule.
 *
 * @see {@link react-frontend/auth/authorize}
 * @param {User|state} user - The user object (use getDxAuthUser to get this object).
 * @param {AuthRule} rule
 * @param {object} [context]
 * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
 * @return {boolean}
 */
export const isAuthorized = (user, rule, context = {}) => {
  // console.log('>>> isAuthorized() --', { user, rule, context });
  try {
    Authorizer.singleton.authorize(user, rule, context);
    return true;
  } catch (error) {
    // Expected error
    //
    // instanceof doesn't work, appears to be a Babel error
    // @link https://stackoverflow.com/a/33837088/327074
    // if (error instanceof NotAuthorizedError) {
    //   return false;
    // }
    //
    // just check on a manually added 'name' instead
    if (error.name === 'NotAuthorizedError') {
      return false;
    }
    console.warn('>>> isAuthorized() error --', {
      error, user, rule, context
    });
    return false;
  }
};

/**
 * This file should be imported once in the main Duxis application, typically in `main.jsx`.
 */

import { css } from 'glamor';

import { dxColors } from './dxColors';

// -- Raleway Font Loading --------------- --- --  -
//
// We do not use Google's font hosting serving because they still serve v1 instead of v2.
/*
  The unicode-range CSS descriptor sets the specific range of characters to be used from a font
  defined by @font-face and made available for use on the current page. If the page doesn't use
  any character in this range, the font is not downloaded; if it uses at least one, the whole
  font is downloaded. This prevents loading of unused fonts and allows us to add for example the light italic without using it.

  U+0000-00FF = control characters, basic Latin, latin-1 supplement
  U+0131 = small letter I dotless
  U+E0FF = Private use codepoint, required char, not assigned to a character
  U+EFFD = Private use area, reserved
  U+F000 = Private use area, reserved

  more info and overview of latin characters: http://jrgraphix.net/r/Unicode/00A0-00FF
*/

const fontSrc = (basePath) => [
  `url('${basePath}.woff2') format('woff2')`, /* Modern Browsers */
  `url('${basePath}.woff') format('woff')`, /* Modern Browsers */
  `url('${basePath}.ttf') format('truetype')`,
].join(', ');

css.fontFace({
  fontFamily: 'Raleway Light',
  fontStyle: 'normal',
  src: fontSrc('/dx-static/raleway/Raleway-Light'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway Light Italic',
  fontStyle: 'italic',
  src: fontSrc('/dx-static/raleway/Raleway-LightItalic'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  src: fontSrc('/dx-static/raleway/Raleway-Regular'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway Italic',
  fontStyle: 'italic',
  src: fontSrc('/dx-static/raleway/Raleway-Italic'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway Bold',
  fontStyle: 'bold',
  src: fontSrc('/dx-static/raleway/Raleway-Bold'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway Bold Italic',
  fontStyle: 'italic',
  src: fontSrc('/dx-static/raleway/Raleway-BoldItalic'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway Black',
  fontStyle: 'normal',
  src: fontSrc('/dx-static/raleway/Raleway-Black'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

css.fontFace({
  fontFamily: 'Raleway Black Italic',
  fontStyle: 'italic',
  src: fontSrc('/dx-static/raleway/Raleway-BlackItalic'),
  textRendering: 'optimizeLegibility',
  unicodeRange: 'U+0000-00FF, U+0131, ... U+E0FF, U+EFFD, U+F000',
});

// -- Global Styles --------------- --- --  -

css.global('*, *:before, *:after', {
  boxSizing: 'inherit',
});

css.global('body', {
  backgroundColor: 'rgb(236, 237, 238)',
  boxSizing: 'border-box !important',
  color: dxColors.fgMain,
  // fontFamily: 'Raleway, sans-serif',
  // fontSize: '14px',
  margin: 0,
  padding: 0,
  WebkitFontSmoothing: 'antialiased',
});

css.global('a', {
  cursor: 'pointer',
  ':active': { boxShadow: 'none' },
});

css.global('blockquote', {
  borderLeftColor: dxColors.bgLighter_1,
});

css.global('dd', {
  fontSize: '14px',
});

css.global('ul, ol', {
  paddingLeft: 32,
});

css.global('b, strong', {
  fontFamily: 'Raleway Bold',
  fontStyle: 'normal',
  fontWeight: 'bold',
});

css.global('i, em', {
  fontStyle: 'italic',
  fontWeight: 'normal',
});

css.global('label', {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
});

css.global('.label', {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
});

// textarea: make sure the resize only works vertical
css.global('textarea', {
  fontSize: '14px',
  lineHeight: '20px',
  resize: 'vertical',
});

// textarea: make sure the resize only works vertical
css.global('input', {
  border: `1px solid ${dxColors.thinBorder}`,
  borderRadius: 0,
  boxShadow: 'none',
  fontSize: '14px',
  lineHeight: '20px',
});

import PropTypes from 'prop-types';
import React from 'react';

import { DMSConfig } from '../../dms';
import { Chapter } from '../Chapter';
import { ChapterHeader } from '../ChapterHeader';
import { BreadcrumbsSection } from '../BreadcrumbsSection';
import { ItemsList } from '../items';
import { Module } from '../Module';

// -- Component --------------- --- --  -

/**
 * The list of collections as defined in the dxSchema if also set in the DMS config
 */
export class DMSCollectionsChapter extends React.Component {
  renderList() {
    const { dmsConfig } = this.props;
    const { collections } = dmsConfig;
    let listProps = {
      canView: true, // TODO
    };
    if (dmsConfig.component) {
      listProps = { ...listProps, dmsConfig };
      return <dmsConfig.component {...listProps} />;
    }

    listProps = {
      ...listProps,
      canCreate: false,
      canDelete: false,
      canUpdate: false,
      // ItemComponent: DMSCollectionsItem,
      itemLabel: 'collection',
      loadItems: () => {},
      loadState: { // This is a fake load-state... - TODO: move to DMSConfig
        available: true,
        from: 0,
        items: collections,
        limit: collections.length,
        loaded: true,
        loading: false,
        ordering: [],
        reloading: false,
        source: 'dms-config',
        total: collections.length,
      },
      createPath: '/create',
      renderItems: this.renderItems,
    };
    return <ItemsList {...listProps} />;
  }

  render() {
    return (
      <Chapter chapterColor="white">
        <ChapterHeader title="User Management System" />
        <Module>
          <BreadcrumbsSection items={[{ label: 'Collections' }]} />
          {this.renderList()}
        </Module>
      </Chapter>
    );
  }
}

DMSCollectionsChapter.propTypes = {
  /** The DMSConfig object. */
  dmsConfig: PropTypes.instanceOf(DMSConfig).isRequired,
};

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Section } from './Section';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  backgroundColor: 'white',
  padding: '6px 32px',
  '> .aux-crumbs': {
    '> .aux-separator': {
      margin: '0 8px',
    },
  },
});

// -- Component --------------- --- --  -

export const BreadcrumbsSection = (props) => (
  <Section className="dms-breadcrumb" styles={defaultStyles}>
    <span className="aux-crumbs">
      {props.items.reduce((result, item, idx) => {
        if (idx > 0) {
          result.push(<span className="aux-separator" key={`arrow-${idx}`}>&rarr;</span>);
        }
        if (item.url) {
          result.push(<Link key={`link-${idx}`} to={item.url}>{item.label}</Link>);
        } else {
          result.push(item.label);
        }
        return result;
      }, [])}
    </span>
  </Section>
);

BreadcrumbsSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

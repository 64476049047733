import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, FormControl, MenuItem } from 'react-bootstrap';
import { dxColors } from '../../react-frontend/styles';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  display: 'block',
  margin: '8px 0',
  '> a > input': {
    background: dxColors.bgLighter_3,
    boxShadow: 'none',
    boxSizing: 'border-box',
    padding: '0px 8px!important',
  },
  '> ul': {
    marginTop: 0,
    maxHeight: '292px', // Shows 10 menu items
    overflowY: 'auto',
    width: '100%',
    '> li': { padding: '2px 8px' },
    '> .dx-relatee-option': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ':hover, :active, :focus': {
        backgroundColor: dxColors.bgFocus,
        cursor: 'pointer',
      },
    },
  }
});

// -- RelateeDropdown Component --------------- --- --  -

export class RelateeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    event.preventDefault();
    const { loadItems, keyword, value } = this.props;
    if (event.currentTarget.value && event.currentTarget.value.length > 1) {
      loadItems({
        scopeId: keyword, from: 0, searchString: event.currentTarget.value, value
      });
    } else {
      loadItems({ scopeId: keyword, from: 0, value });
    }
  }

  renderMenuItems() {
    const { keyword, loading, items } = this.props;
    if (loading) {
      return <li className="tip">Loading...</li>;
    }
    if (items.length > 0) {
      return items.map((item) => (
        <MenuItem className="dx-relatee-option" eventKey={item.id} key={item.id}>
          {item.label}
        </MenuItem>
      ));
    }
    return <li className="tip">{`No ${keyword} found.`}</li>;
  }

  render() {
    const { onSelect, onToggle, keyword } = this.props;
    return (
      <Dropdown
        defaultOpen
        className="dx-relatee-dropdown"
        id={`dx-dropdown-add-${keyword}`}
        key="__dropdown__"
        onSelect={onSelect}
        onToggle={onToggle}
        {...defaultStyles}
      >
        <Dropdown.Toggle noCaret useAnchor>
          <FormControl
            autoComplete="off"
            autoFocus
            name={`textSearch_${keyword}`}
            onChange={this.handleInputChange}
            placeholder={`Type to search ${keyword}..`}
            type="text"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu id="dx-add-relatee-menu">{this.renderMenuItems()}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

RelateeDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  keyword: PropTypes.string,
  loading: PropTypes.bool,
  loadItems: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.string,
};

import {
  mutationBuilder,
  getCreateArgs,
  getCreateVars,
} from '../utils/dxf';

export const buildCreateUserQuery = ({
  id, created_by, created_on, updated_by, updated_on, scoped_rights, roles, ...fields
}, { collSchema }) => {
  const { last_login: lastLogin, ...varArgs } = getCreateArgs(collSchema, { includeRelatees: true });
  const { last_login, ...rest } = getCreateVars(collSchema, { includeRelatees: true });
  const createVars = {
    ...rest,
    provider: { type: 'String!' },
    username: { type: 'String!' },
    roles: { type: '[String]' },
    scoped_rights: { type: '[String]' },
  };
  return mutationBuilder({
    variables: createVars,
    fields: {
      createUser: {
        varArgs,
        fields,
      },
    },
  });
};

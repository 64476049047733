import { css } from 'glamor';

import { dxColors } from './dxColors';

// -- Raleway Font Families --------------- --- --  -

export const ralewayLightFamily = 'Raleway Light';
export const ralewayLightItalicFamily = 'Raleway Light Italic';

export const ralewayRegularFamily = 'Raleway';
export const ralewayRegularItalicFamily = 'Raleway Italic';

export const ralewayMediumFamily = 'Raleway Bold';
export const ralewayMediumItalicFamily = 'Raleway Bold Italic';

export const ralewayBoldFamily = 'Raleway Bold';
export const ralewayBoldItalicFamily = 'Raleway Bold Italic';

// -- Raleway Font Styles --------------- --- --  -

export const ralewayLight = css({ fontFamily: 'Raleway Light' });
export const ralewayItalic = css({ fontFamily: 'Raleway Italic' });
export const ralewayRegular = css({ fontFamily: 'Raleway' });
export const ralewayBold = css({ fontFamily: 'Raleway Bold' });
export const ralewayBlack = css({ fontFamily: 'Raleway Black' });

// -- Widget Borders --------------- --- --  -

export const thinBorder = css({
  border: `1px solid ${dxColors.thinBorder}`,
  borderRadius: 0,
});
export const thinBorderLeft = css({
  borderLeft: `1px solid ${dxColors.thinBorder}`,
  borderRadius: 0,
});
export const thinBorderRight = css({
  borderRight: `1px solid ${dxColors.thinBorder}`,
  borderRadius: 0,
});
export const thinBorderTop = css({
  borderTop: `1px solid ${dxColors.thinBorder}`,
  borderRadius: 0,
});
export const thinBorderBottom = css({
  borderBottom: `1px solid ${dxColors.thinBorder}`,
  borderRadius: 0,
});

// -- Shadows --------------- --- --  -

export const boxShadowDown = css({
  boxShadow: '0px 2px 4px -2px rgba(0, 0, 0, 0.3)',
});

export const boxShadowAngled = css({
  boxShadow: '1px 2px 8px -1px rgba(0, 0, 0, 0.2)',
});

export const boxShadowUp = css({
  boxShadow: '1px -2px 4px -2px rgba(0, 0, 0, 0.3)',
});

// -- Page & Section Styles --------------- --- --  -

export const paddedPageElementStyles = css({
  paddingLeft: '4.2%',
  paddingRight: '4.2%',
  '@media screen and (min-width: 680px)': {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

export const sectionPadding = css(paddedPageElementStyles, { padding: 32 });

export const contentSection = css(sectionPadding, { background: 'white' });

// -- Button --------------- --- --  -

export const buttonLabel = css({
  boxSizing: 'content-box',
  color: dxColors.fgLighter_2,
  fontWeight: 600,
  height: 30,
  lineHeight: '30px', // full button body height
  margin: '0 auto',
  overflow: 'hidden',
  padding: 0,
  textDecoration: 'none',
});

export const button = css(thinBorder, {
  alignContent: 'center',
  alignItems: 'center',
  background: 'white',
  borderRadius: 0,
  boxSizing: 'content-box',
  cursor: 'pointer',
  display: 'flex',
  height: 30,
  padding: '0 6px',
  ':hover, :active, :focus': {
    backgroundColor: dxColors.bgFocus,
    boxShadow: 'none',
  },
  ':disabled': {
    cursor: 'default',
    opacity: 0.4,
    ':hover': { backgroundColor: 'white' },
  },
  '> .button-icon': {
    boxSizing: 'content-box',
    height: 18,
    margin: 'auto 0',
    padding: '0 2px',
    width: 18,
  },
  '> .button-label': [buttonLabel, { padding: '0 6px' }],
});

import get from 'lodash/get';
// eslint-disable-next-line import/no-unresolved
import dxConfigData from 'dxConfigData';

if (!dxConfigData) {
  throw new Error('The dxConfig data is empty. Please make sure that your `index.html` loads the'
    + ' `/dxConfigData.js` script. Please consult the dxConfig documentation for more details.');
}

/**
 * This object provides access to the setup config. To enable, set _duxis.frontend.dxConfig.enable_
 * to _true_, and use _dxConfig.get()_ to get a config value. To also have access to the _dxSchema_,
 * set the _duxis.frontend.dxConfig.includeSchema_ setup config to _true_, and use
 * _dxConfig.dxSchema_ to get it.
 *
 * See the description in the change log for version 1.2.0 for more details.
 * See _react-service/loadFrontendConfig_.
 */
export const dxConfig = {
  /**
   * Returns the value for the given configuration property path.
   * @param {string} path - Reference to a config property expressed as a dotted path.
   * @param {*} [defaultValue] - The default value to return when the config property is not defined.
   * @return {*}
   * @throws {RangeError} When the given _path_ is null or undefined.
   * @throws {Error} When the given configuration property is not declared in the configuration
   *   files, and no default value is given.
   *
   * @example
   * // Given the following as `config.js` in one of the directories in SETUP_PATHS:
   * module.exports = {
   *   foo: {
   *     bar: 123.456
   *   }
   * }
   *
   * // And given the following as `frontend-mask.js` in one of the directories in SETUP_PATHS:
   * module.exports = {
   *   foo: {
   *     bar: true
   *   }
   * };
   *
   * // Then in your frontend code, you can do the following:
   * const bar = config.get('foo.bar');  // bar = 123.456
   * const other = config.get('foo.other');  // throws error
   */
  get(path, defaultValue) {
    if (path === null || path === undefined) {
      throw new RangeError('Calling config.get with null or undefined parameter.');
    }
    const value = get(dxConfigData, path);
    if (value === undefined) {
      if (defaultValue === undefined) {
        throw new Error(`Configuration "${path}" is undefined.`);
      } else {
        return defaultValue;
      }
    }
    return value;
  },

  /**
   * Returns true when the given configuration property path is declared in the configuration files.
   * @param {string} path - Reference to a config path expressed as a dotted path,
   * @returns {boolean}
   * @throws {RangeError} When the given parameter is null or undefined.
   *
   * @example
   * // Given the following as `config.js` in one of the directories in SETUP_PATHS:
   * module.exports = {
   *   foo: {
   *     bar: 123.456
   *   }
   * }
   *
   * // And given the following as `frontend-mask.js` in one of the directories in SETUP_PATHS:
   * module.exports = {
   *   foo: {
   *     bar: true
   *   }
   * };
   *
   * // Then in your frontend code, you can do the following:
   *
   * const hasBar = config.has('foo.bar');  // hasBar = true
   * const hasOther = config.has('foo.other');  // hasOther = false
   */
  has(path) {
    if (path === null || path === undefined) {
      throw new RangeError('Calling config.has with null or undefined parameter.');
    }
    return get(dxConfigData, path) !== undefined;
  },

  /**
   * @return {object} The source dxConfig data.
   */
  data() { return dxConfigData; }
};

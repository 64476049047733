import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { equalOrdering } from '../../utils/dxSchema/utils';
import { equalShallow } from '../../utils/equalShallow';

import { ofType } from './loadStateSupport';

// -- PageLoadState --------------- ---  --  -

/**
 * @typedef {object} PageLoadActionState
 * @property {number} [from] - The index of the first item in the page.
 * @property {Array.<{id: string}>} [items] - The items in the page.
 * @property {number} [limit] - The number of items in the page.
 * @property {Array.<cargo-universal/dxSchema/OrderField>} [ordering] - The ordering of the items.
 * @property {*} source - Optionally identifies the source of the current collection. This may be
 *   simply the collection ID, an object with the collection ID and the applied filter, or any other
 *   arbitrary value that can be shallowly compared to another source in order to distinguish
 *   different collections. This source should thus **not** include the _from_, _limit_ and
 *   _ordering_ values.
 * @property {number} [total] - The total number of items in the collection.
 */

/**
 * @typedef {object} PageLoadState
 *
 * A load-state serves three purposes:
 * 1) It represents the resource-loading-related state in the Redux store;
 * 2) It provides the state transition methods;
 * 3) It can be used as a 'pure' prop that provides convenience properties such as `available`;
 *
 * @property {PageLoadActionState} [action] - The action that caused the transition to the current
 *   state. This action is undefined for the initial state.
 * @property {boolean} available - True when the page of items has been loaded or is being reloaded.
 * @property {number} from - The index of the first item in the page.
 * @property {Array.<{ id: string }>} items - The items in the currently loaded page.
 * @property {number} limit - The maximum number of items in a page.
 * @property {Array.<cargo-universal/dxSchema/OrderField>} ordering - The ordering of the items.
 * @property {*} source - Optionally identifies the source of the current collection. This may be
 *   simply the collection ID, an object with the collection ID and the applied filter, or any other
 *   arbitrary value that can be shallowly compared to another source in order to distinguish
 *   different collections. This source should thus **not** include the _from_, _limit_ and
 *   _ordering_ values.
 * @property {number} total - The total number of items in the collection.
 *
 * @property {boolean} loaded - True when the page of items has been loaded.
 * @property {boolean} loading - True when the page of items is being loaded.
 * @property {boolean} reloading - True when the page of items is being reloaded. The previously
 *   loaded items remain available until the new page is received.
 *
 * @property {Function.<Array.<{id: string}>, options>} updateItems - Takes an items array and an
 *   optional options object and  returns the (immutably) updated `loaded` load-state. The options
 *   object may provide one of the following properties: from, limit, ordering, total, and source.
 *   This function can be used when you want to temporarily update the state, for instance to do
 *   optimistic updates.
 *
 * @property {Function([number], [number], [string]):PageLoadState} handleLoad - To be called when a
 *   page of items is being loaded. Returns the `loading` load-state. Optionally takes the page
 *   settings (from, limit, ordering) or reuses the current page settings.
 *   This private method should not be called by client code.
 *
 * @property {Function(object[], number, number, string, number):PageLoadState} handleReceive -
 *   Takes the loaded items array, the _from_, _limit_ and _ordering_ page settings, and the total
 *   number of items in the collection. Returns the `loaded` load-state when this item is being
 *   reloaded or updated in this load-state.
 *   This private method should not be called by client code.
 *
 * @property {Function():PageLoadState} handleInvalidate - To be called when the currently loaded
 *   page of items is no longer valid, for instance because a new item was created, or an item was
 *   updated. The from/limit/ordering page settings are retained.
 *   This private method should not be called by client code.
 *
 * @see createPageLoadStateReducer
 * @see DxPropTypes.pageLoadStateOf
 * @see getPageLoadStateAvailable
 * @see getPageLoadStateFrom
 * @see getPageLoadStateItems
 * @see getPageLoadStateLimit
 * @see getPageLoadStateLoaded
 * @see getPageLoadStateLoading
 * @see getPageLoadStateOrdering
 * @see getPageLoadStateReloading
 * @see getPageLoadStateTotal
 */

/**
 * @type {PageLoadActionState}
 */
const defaultState = {
  from: 0,
  limit: 10,
  ordering: undefined,
  source: undefined,
  total: 0,
};

const pickActionState = (action) => pick(
  // exclude undefined values so they don't override existing
  // didn't use lodash omitBy library as it is going to be removed in v5
  pickBy(action, (val) => !isUndefined(val)),
  [
    'from',
    'items',
    'limit',
    'ordering',
    'source',
    'total',
  ]
);

/**
 * @private
 * @param {PageLoadState} state - The previous state.
 * @param {PageLoadActionState|void} [action] - Optional initial action.
 * @return {PageLoadState}
 */
const initialState = (state, action) => ({
  ...state,
  ...pickActionState(action),
  action,

  // reset state:
  source: undefined,
  total: 0,

  // meta-data:
  available: false,
  items: [],
  loaded: false,
  loading: false,
  reloading: false,
  state: 'initial',

  // methods:
  updateItems() {
    return this;
  },
  handleLoad(nxtAction) {
    return loadingState(this, nxtAction);
  },
  handleReceive(nxtAction) {
    return loadedState(this, nxtAction);
  },
  handleInvalidate(nxtAction) {
    return initialState(this, nxtAction);
  },
});

/**
 * @private
 * @param {PageLoadState} state - The previous state.
 * @param {PageLoadActionState} action - The action that caused the transition to this state.
 *   Notes: Normally when loading a new item set, the total number of items is not known beforehand.
 *     However, when loading a new page from the same set, the total number of items is already
 *     known and can be retained.
 * @return {PageLoadState}
 */
const loadingState = (state, action) => ({
  ...state,
  ...pickActionState(action),
  action,

  // meta-data:
  available: false,
  loaded: false,
  loading: true,
  reloading: false,
  state: 'loading',

  // methods:
  updateItems() {
    return this;
  },
  handleLoad(nxtAction) {
    return samePage(this, nxtAction) ? this : loadingState(this, nxtAction);
  },
  handleReceive(nxtAction) {
    return loadedState(this, nxtAction);
  },
  handleInvalidate() { return this; },
});

/**
 * @private
 * @param {PageLoadState} state - The previous state.
 * @param {PageLoadActionState} action - The action that caused the transition to this state.
 * @return {PageLoadState}
 */
const loadedState = (state, action) => ({
  ...state,
  ...pickActionState(action),
  action,

  // meta-data:
  available: true,
  loaded: true,
  loading: false,
  reloading: false,
  state: 'loaded',

  // methods:
  updateItems(nxtItems, opts = {}) {
    // Update the action to match the implicit action:
    return loadedState(this, { ...action, items: nxtItems, ...opts });
  },
  handleLoad(nxtAction) {
    if (samePage(this, nxtAction)) {
      return reloadingState(this, nxtAction);
    }
    if (sameSet(this, nxtAction)) {
      return loadingState({ ...this, items: [] }, nxtAction);
    }

    return loadingState({ ...this, items: [], total: 0 }, nxtAction);
  },
  handleReceive(nxtAction) {
    // ignore unexpected page
    return samePage(this, nxtAction) ? loadedState(this, nxtAction) : this;
  },
  handleInvalidate(nxtAction) {
    // Do some magic here to get the retain the given action as 'action' property:
    return initialState(this, nxtAction);
  },
});

/**
 * @private
 * @param {PageLoadState} state - The previous state.
 * @param {PageLoadActionState} action - The action that caused the transition to this state.
 * @return {PageLoadState}
 */
const reloadingState = (state, action) => ({
  ...state,
  ...pickActionState(action),
  action,

  // meta-data:
  available: true,
  loaded: false,
  loading: false,
  reloading: true,
  state: 'reloading',

  // methods:
  updateItems(nxtItems, opts = {}) {
    // Update the action to match the implicit action:
    return reloadingState(this, { ...action, items: nxtItems, ...opts });
  },
  handleLoad(nxtAction) {
    // return samePage(this, nxtAction) ? reloadingState(this, nxtAction) : loadingState(this, nxtAction);
    if (samePage(this, nxtAction)) {
      return reloadingState(this, nxtAction);
    }
    if (sameSet(this, nxtAction)) {
      return loadingState({ ...this, items: [] }, nxtAction);
    }

    return loadingState({ ...this, items: [], total: 0 }, nxtAction);
  },
  handleReceive(nxtAction) {
    // ignore unexpected page
    return samePage(this, nxtAction) ? loadedState(this, nxtAction) : this;
  },
  handleInvalidate() {
    return this;
  },
});

// -- Reducer --------------- --- --  -

/**
 * Returns a Redux reducer that takes as input a {@link PageLoadState} object and a Redux action,
 * and returns a {@link PageLoadState} object.
 * See _dxLoadState_ manual for more details.
 *
 * @param {string|Array.<string>|Function.<string>:boolean} loadActionType - The type of the action
 *   that is dispatched when a page of items is being fetched, or a function that takes an action
 *   object and returns true when it is such an action. The actions must have the following
 *   properties:
 *    - `from` {number} - The index of the first item in the page.
 *    - `limit` {number} - The number of items in the page.
 *    - `ordering` {*} - The ordering of the items.
 *    - 'source' {*} - Optional state source. When provided it is shallowly compared with the
 *         previous source to determine if this action constitutes a request for a "new" page of
 *         items or is rather a "reload" of the current page. This is useful in cases where
 *         additional constraints, such as search terms, determine the actual content of the page.
 *         By representing these constraints in this source, actions with a different "source" will
 *         be properly recognized as a proper load instead of a reload.
 * @param {string|Array.<string>|Function.<string>:boolean} receiveActionType - The type of the
 *   action that is dispatched when receiving a page of items, or a function that takes an action
 *   object and returns true when it is such an action. The actions must have the following
 *   properties:
 *    - `from` {number} - The actual index of the first item in the page.
 *    - `limit` {number} - The actual number of items in the page.
 *    - `ordering` {Ordering} - The actual [ordering](cargo-universal/dxSchema/typedefs) of the items.
 *    - `total` {number} - The total number of items in the (constrained) collection from which the
 *         page of items was sourced.
 * @param {object} [options]
 * @param {string|Array.<string>|Function.<string>:boolean} [options.invalidateActionType] - The
 *   action type that is dispatched when the loaded page of items should be reloaded, or a function
 *   that takes an action object and returns true when the load-state should be invalidated in
 *   response to the given action.
 * @param {number} [options.limit = 20] - The number of items in the page. Zero means no limit.
 *   Defaults to 20.
 * @param {Array.<cargo-universal/dxSchema/OrderField>} [options.ordering] - The ordering
 *   specification. When not provided, query resolvers are expected to use the default ordering
 *   specified in the dxSchema.
 * @param {string|Array.<string>|Function.<string>:boolean} [options.resetActionType] - The action
 *   type (or predicate) for which the load-state should be reset to its initial state.
 *
 * @return {Function(object, object)} The Redux state reducer.
 *
 * @see PageLoadState
 * @see createItemLoadStateReducer
 * @see createSimpleLoadStateReducer
 *
 * @example
 * const pageLoadStateReducer = createPageLoadStateReducer(LOAD_PROJECTS, RECEIVE_PROJECTS);
 */
export const createPageLoadStateReducer = (loadActionType, receiveActionType, options = {}) => {
  const {
    invalidateActionType,
    limit = defaultState.limit,
    ordering = defaultState.ordering,
    resetActionType,
  } = options;
  const _defaultState = {
    ...defaultState,
    limit,
    ordering,
  };

  return (state = initialState(_defaultState), action) => {
    // console.log('>>> PageLoadStateReducer() --', { state, action });
    if (ofType(action, loadActionType)) {
      return state.handleLoad(action);
    }
    if (ofType(action, receiveActionType)) {
      return state.handleReceive(action);
    }
    if (ofType(action, invalidateActionType)) {
      return state.handleInvalidate(action);
    }
    if (ofType(action, resetActionType)) {
      return initialState(state, action);
    }
    return state;
  };
};

// -- Utilities --------------- --- --  -

const sameSet = (state, action) => (
  // For relatees it is possible that the ordering is specified when the data is returned
  // This should only be the case for an initial page load
  (
    !action.ordering
      || !state.ordering
      || equalOrdering(action.ordering, state.ordering)
  ) && (
    action.source === undefined
      || state.source === undefined
      || equalShallow(action.source, state.source)
  ));

const samePage = (state, action) => (
  sameSet(state, action)
    && (action.from === undefined || state.from === undefined || action.from === state.from)
    && (action.limit === undefined || state.limit === undefined || action.limit === state.limit)
);

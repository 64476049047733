import forIn from 'lodash/forIn';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { toString } from '../toString';
import { isString } from '../isString';
import { capitalize } from '../capitalize';
import { FieldSchema } from './FieldSchema';
import { Report } from './Report';

import { GraphQLCollection } from '../graphql/GraphQLCollection';

/**
 * @callback forEachRelationCallback
 * @param {RelateeSchema} relateeSchema
 * @param {string} fieldId
 */

/**
 * Represents a collection in a dxSchema.
 */
export class CollectionSchema {
  /**
   * @param {string} id - The ID for this collection.
   * @param {object} rawSchema - The raw JSON schema.
   * @param {DxSchemaBase} schema - The Schema object.
   */
  constructor(id, rawSchema, schema) {
    this._id = id;
    this._singularCapped = capitalize(rawSchema.apiTerms.singular);
    this._pluralCapped = capitalize(rawSchema.apiTerms.plural);
    if (rawSchema.label) {
      this._label = rawSchema.label;
      this._labelSingular = rawSchema.label.endsWith('s')
        ? rawSchema.label.substr(0, rawSchema.label.length - 1)
        : this._singularCapped;
    } else {
      this._label = this._pluralCapped;
      this._labelSingular = this._singularCapped;
    }
    this._rawSchema = rawSchema;
    this._schema = schema;
    this._propFields = new Map();
    this._requiredFields = new Set();
    this._relatees = new Map(); // relation fields

    forIn(rawSchema.fields, (rawFieldSchema, fieldId) => {
      const required = this.constraints.required.includes(fieldId);
      const fieldSchema = new FieldSchema(fieldId, rawFieldSchema, required, this);
      this._propFields.set(fieldId, fieldSchema);
      if (required) { this._requiredFields.add(fieldSchema); }
    });
    this._controller = null;
  }

  /**
   * Registers a relation in which this relation participates.
   * @param {RelateeSchema} relateeSchema
   */
  addRelatee(relateeSchema) {
    if (!relateeSchema.isRelateeSchema) {
      throw new Error(`Expected a RelateeSchema, got ${relateeSchema}.`);
    }
    const { fieldId } = relateeSchema;
    if (this._relatees.has(fieldId)) {
      throw new Error(`Collection "${this.id}" already has a relation field named "${fieldId}".`);
    }
    if (this._propFields.has(fieldId)) {
      throw new Error(`Collection "${this.id}" already has a field named "${fieldId}".`);
    }
    this._relatees.set(fieldId, relateeSchema);
  }

  // -- Schema Accessors --------------- ---  --  -

  /**
   * @return {object} An object `{ singular: {string}, plural: {string} }`.
   */
  get apiTerms() { return this._rawSchema.apiTerms; }

  /**
   * @returns {object} The auth specs.
   */
  get auth() { return this._rawSchema.auth; }

  /**
   * @return {Array.<{}>} The collection constraints specified in the dxSchema.
   */
  get constraints() { return this._rawSchema.constraints; }

  /**
   * @param {Controller} controller
   */
  set controller(controller) { this._controller = controller; }

  /**
   * @return {Controller} The dxController.
   */
  get controller() { return this._controller; }

  /**
   * @return {Ordering} The default ordering provided in the dxSchema.
   */
  get defaultOrdering() { return this._rawSchema.defaultOrdering; }

  /**
   * @return {string} The collection description provided in the dxSchema.
   */
  get description() { return this._rawSchema.description; }

  /**
   * @returns {boolean} True when this collection should not be included in the dxAPI.
   */
  get excludeFromAPI() { return Boolean(this._rawSchema.excludeFromAPI); }

  // get fields() { return this._rawSchema.fields; }

  /**
   * @return {string} The collection ID.
   */
  get id() { return this._id; }

  /**
   * @return {string|Array.<string>} A list of field identifiers or arrays of identifiers for which
   *   indices could be created in concrete data stores in order to improve query efficiency. It is
   *   not necessary to include 'id' as an index: it is indexed by default.
   */
  get indices() { return this._rawSchema.indices; }

  /**
   * @return {boolean} True when explicit "legacy" activities were specified for this collection.
   * TODO: LEGACY_ACTIVITIES
   */
  get hasLegacyActivities() {
    return this.schema.schema === 'http://duxis.io/schemas/dx/0.0.2/main.json'
      || this.schema.schema === 'http://duxis.io/schemas/dx/0.3/main.json'
      || this.auth.view !== undefined;
  }

  /**
   * @return {boolean} Always true for CollectionSchema instances.
   */
  get isCollectionSchema() { return true; }

  /**
   * @return {string} The human-readable collection label provided in the dxSchema.
   */
  get label() { return this._label; }

  /**
   * @return {string} A human-readable label for a single item in the collection.
   */
  get labelSingular() { return this._labelSingular; }

  /**
   * @return {string} Plural term for API usage.
   */
  get plural() { return this._rawSchema.apiTerms.plural; }

  /**
   * @return {string} Plural capitalized term for API usage.
   */
  get pluralCapped() { return this._pluralCapped; }

  /**
   * @returns {object} The raw collection-schema object.
   */
  get rawSchema() { return this._rawSchema; }

  /**
   * @return {DxSchemaBase} The dxSchema to which this collection belongs.
   */
  get schema() { return this._schema; }

  /**
   * @return {string} Singular term for API usage.
   */
  get singular() { return this._rawSchema.apiTerms.singular; }

  /**
   * @return {string} Singular capitalized term for API usage.
   */
  get singularCapped() { return this._singularCapped; }

  /**
   * @returns {string} The field that flags deletions, if applicable
   */
  get softDelete() { return this._rawSchema.softDelete; }

  /**
   * @returns {string} The store id provided in the dxSchema.
   */
  get storeId() { return this._rawSchema.store; }

  /**
   * @returns {string} The collection type, e.g. 'dx/auth/activity'.
   */
  get type() { return this._rawSchema.type; }

  // -- Field Accessors --------------- ---  --  -

  /**
   * @return {Array.<string>} The field IDs in this collection.
   */
  get fieldIds() { return [...this._propFields.keys()]; }

  /**
   * @return {Array.<FieldSchema>} The schemas for all fields in this collection.
   */
  get fieldSchemas() { return [...this._propFields.values()]; }

  /**
   * @return {Array.<FieldSchema>} The schemas for all fields in this collection.
   */
  get fields() { return [...this._propFields.values()]; }

  /**
   * @param {string} fieldId
   * @returns {boolean} True when the collection has a field with the given id.
   */
  hasField(fieldId) {
    return this._propFields.has(fieldId);
  }

  get hasFields() { return this._propFields.size > 0; }

  /**
   * @return {boolean} True when this collection has relatees.
   */
  get hasRelatees() { return this._relatees.size > 0; }

  /**
   * @return {Map<string, RelateeSchema>}
   */
  get relatees() {
    console.warn('The CollectionSchema.relatees method has an inconsistent implementation '
      + 'and will be modified in a future release. Please use relateesNxt, forEachRelatee or '
      + 'getRelatee instead.');
    return this._relatees;
  }

  /**
   * @return {Array.<RelateeSchema>}
   */
  get relateesNxt() { return [...this._relatees.values()]; }

  /**
   * @return {Map<string, RelateeSchema>}
   */
  get relateeSchemas() {
    console.warn('The CollectionSchema.relateeSchemas method has an inconsistent implementation '
      + 'and will be modified in a future release. Please use forEachRelatee or getRelatee instead.');
    return this._relatees;
  }

  /**
   * @return {Array.<RelateeSchema>}
   */
  get relateeSchemasNxt() { return [...this._relatees.values()]; }

  /**
   * Throws an error when there is no field with the given id.
   * @param {string} fieldId
   */
  assertField(fieldId) {
    if (!this.hasField(fieldId)) {
      throw new RangeError(`There is no "${fieldId}" field in "${this._id}".`);
    }
  }

  /**
   * @param {string} fieldId
   * @returns {FieldSchema} The schema for the field with the given id.
   */
  getFieldSchema(fieldId) {
    this.assertField(fieldId);
    return this._propFields.get(fieldId);
  }

  /**
   * @param {string} fieldId
   * @returns {FieldSchema} The schema for the field with the given id.
   */
  getField(fieldId) {
    this.assertField(fieldId);
    return this._propFields.get(fieldId);
  }

  /**
   * Calls the given callback function once for each field in this collection, passing two
   * arguments: 1) the field schema, and 2) the field id.
   * @param {DxSchema_ForEachFieldCallback} callback - Function to execute for each element.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  forEachField(callback, thisArg) {
    this._propFields.forEach(callback, thisArg);
  }

  /**
   * Calls the given async delegate function once for each field in this collection, passing two
   * arguments: 1) the field schema, and 2) the field id.
   * @param {DxSchema_ForEachFieldCallback} delegate - Function to execute for each element.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  async asyncForEachField(delegate, thisArg) {
    for (const [fieldId, fieldSchema] of this._propFields) {
      await delegate.call(thisArg, fieldSchema, fieldId);
    }
  }

  mapFieldSchemas(mapper) {
    return [...this._propFields.values()].map(mapper);
  }

  mapFields(mapper) {
    return [...this._propFields.values()].map(mapper);
  }

  /**
   * @callback reduceFieldsCallback
   * @param {*} accumulator - The accumulated value previously returned in the last invocation of
   *            the callback, or initialValue, if supplied. (See below.)
   * @param {FieldSchema} fieldSchema - The current field schema to reduce.
   */

  /**
   * @param {reduceFieldsCallback} callback - Function to execute on each field schema.
   * @param {*} [initialValue]
   */
  reduceFields(callback, initialValue) {
    return [...this._propFields.values()].reduce(callback, initialValue);
  }

  getEditableFieldSchemas() {
    return this.fields.filter((field) => field.isEditable);
  }

  getEditableFields() {
    return this.fields.filter((field) => field.isEditable);
  }

  forEachRequiredField(callback, thisArg) {
    this._requiredFields.forEach(callback, thisArg);
  }

  /**
   * Maps the mapper over the required field schemas.
   * @param {Function} mapper - Takes a FieldSchema as sole argument.
   * @returns {Array}
   */
  mapRequiredFields(mapper) {
    return [...this._requiredFields.values()].map(mapper);
  }

  // -- Relatee Accessors --------------- ---  --  -

  /**
   * @param {string} fieldId
   * @returns {boolean} True when the collection has a relatee with the given id.
   */
  hasRelatee(fieldId) {
    return this._relatees.has(fieldId);
  }

  /**
   * Throws an error when there is no relatee with the given field id.
   * @param {string} fieldId
   */
  assertRelatee(fieldId) {
    if (!this.hasRelatee(fieldId)) {
      throw new RangeError(`There is no "${fieldId}" relatee in "${this._id}".`);
    }
  }

  /**
   * @param {string} fieldId - The id of the relatee field.
   * @throws {Error} When the given relatee does not have an arity of one or does not exist.
   */
  assertArityOne(fieldId) {
    this.getRelatee(fieldId).assertArityOne();
  }

  /**
   * @param {string} fieldId - The id of the relatee field.
   * @throws {Error} When the given relatee has an arity of one or does not exist.
   */
  assertArityMany(fieldId) {
    this.getRelatee(fieldId).assertArityMany();
  }

  /**
   * @param {string} fieldId
   * @returns {RelateeSchema} The schema for the relation with the given id.
   */
  getRelateeSchema(fieldId) {
    this.assertRelatee(fieldId);
    return this._relatees.get(fieldId);
  }

  /**
   * @param {string} fieldId
   * @returns {RelateeSchema} The schema for the relation with the given id.
   */
  getRelatee(fieldId) {
    this.assertRelatee(fieldId);
    return this._relatees.get(fieldId);
  }

  /**
   * Calls the given callback function once for each relatee for this collection, passing two
   * arguments: 1) the relatee schema, and 2) the field id.
   * @param {forEachRelationCallback} callback - Function to execute for each relatee.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  forEachRelateeSchema(callback, thisArg) {
    this._relatees.forEach(callback, thisArg);
  }

  /**
   * Calls the given callback function once for each relatee for this collection, passing two
   * arguments: 1) the {@link RelateeSchema}, and 2) the field id.
   * @param {forEachRelationCallback} callback - Function to execute for each relatee.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  forEachRelatee(callback, thisArg) {
    this._relatees.forEach(callback, thisArg);
  }

  /**
   * Calls the given async delegate function once for each relatee for this collection, passing two
   * arguments: 1) the relatee schema, and 2) the field id.
   * @param {forEachRelationCallback} delegate - Function to execute for each relatee.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  async asyncForEachRelateeSchema(delegate, thisArg) {
    for (const [fieldId, relateeSchema] of this._relatees) {
      await delegate.call(thisArg, relateeSchema, fieldId);
    }
  }

  /**
   * Calls the given async delegate function once for each relatee for this collection, passing two
   * arguments: 1) the relatee schema, and 2) the field id.
   * @param {forEachRelationCallback} delegate - Function to execute for each relatee.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  async asyncForEachRelatee(delegate, thisArg) {
    for (const [fieldId, relateeSchema] of this._relatees) {
      await delegate.call(thisArg, relateeSchema, fieldId);
    }
  }

  /**
   * Gets the relatees for the relations to the given co-collection.
   * @param {string|CollectionSchema} collId - The related collection or its ID.
   * @return {RelateeSchema[]}
   */
  getRelateesTo(collId) {
    if (collId.isCollectionSchema) {
      collId = collId.id;
    } else if (!isString(collId)) {
      throw new Error(`Expected a string or CollectionSchema as "collId", got "${collId}" [in CollectionSchema.getRelateesTo].`);
    }
    return [...this._relatees.values()].reduce((result, relatee) => (relatee.coCollectionId === collId ? [...result, relatee] : result), []);
  }

  // -- Relations --------------- ---  --  -

  /**
   * Gets the relations to the given co-collection.
   * @param {string|CollectionSchema} collId - The related collection or its ID.
   * @return {RelationSchema[]}
   */
  getRelationsTo(collId) {
    if (collId.isCollectionSchema) {
      collId = collId.id;
    } else if (!isString(collId)) {
      throw new Error(`Expected a string or CollectionSchema as "collId", got "${collId}" [in CollectionSchema.getRelationsTo].`);
    }
    return [...this._relatees.values()].reduce((result, relatee) => (relatee.coCollectionId === collId ? [...result, relatee.relation] : result), []);
  }

  // -- Scope Accessors --------------- ---  --  -

  /**
   * @typedef {object} AuthScopeDeclaration
   * @property {string} id - The scope identifier, which must be unique in the dxSchema.
   * @property {string} [extends] - Required for scope extensions. Identifies the parent scope of
   *   this scope extension.
   * @property {string} [field] - Required for scope extensions. Identifies the relatee field
   *   through which this scope extension is related with its parent scope.
   * @property {string} [key = 'id'] - The field that identifies the topic, defaults to `id`.
   */

  /**
   * @returns {string} The role a user gets when creating an instance of this collection.
   */
  get creatorRole() { return this.auth.creatorRole; }

  /**
   * @returns {Array<AuthScopeDeclaration>} The authScopes.
   */
  get authScopes() { return this.auth.scopes; }

  /**
   * @returns {boolean} True when this collection has auth scopes.
   */
  get hasScopes() {
    return this.authScopes && this.authScopes.length > 0;
  }

  /**
   * @returns {boolean} True when this collection has a rootScope.
   */
  get hasRootScope() {
    return this.hasScopes && this.authScopes[0].extends === undefined;
  }

  /**
   * @returns {boolean} True when this collection has scope extensions.
   */
  get hasExtendedScopes() {
    return this.hasScopes && this.authScopes[0].extends !== undefined;
  }

  // -- Utilities --------------- ---  --  -

  /**
   * For each field in the schema for which there is no value in the given fields object and for
   * which no `controlled` constraint applies, an entry is added in the fields object, using the
   * default value, if one is specified in the schema, or null if not.
   * @param {object.<string, *>} fields - field-id to field-value mapping
   * @returns {object.<string, *>} fields - field-id to field-value mapping
   */
  normalizeFields(fields) {
    if (!fields) {
      throw new Error('CollectionSchema.normalizeFields received null/undefined `fields` argument.');
    }
    this.forEachField((field, fieldId) => {
      if (fields[fieldId] === undefined && !field.isControlled && field.defaultValue !== undefined) {
        fields[fieldId] = field.defaultValue;
      }
    });
    return fields;
  }

  /**
   * Validates the given ordering specification.
   * @param {Array.<OrderField>} ordering - the ordering specification.
   * @returns {Report}
   */
  validateOrdering(ordering) {
    const report = new Report();
    try {
      // TODO: consider validating against json-schema

      // assert that the fields exist and are orderable:
      const fields = {};
      const validateField = (field) => {
        if (!this.hasField(field)) {
          report.addError(`Unknown ordering field "${field}" in "${this.id}".`);
        } else if (!this.getField(field).orderable) {
          report.addError(`The field "${field}" is not orderable in "${this.id}".`);
        } else if (fields[field]) {
          report.addError(`Duplicate ordering field "${field}" in "${this.id}".`);
        } else {
          fields[field] = true;
        }
      };
      ordering.forEach((clause) => {
        if (!isObject(clause)) {
          report.addError('An order clause should be an object, got '
            + `"${toString(clause)}" in "${this.id}".`);
        } else {
          const { field: fieldId, direction, nulls } = clause;
          if (!fieldId) {
            report.addError('An order clause object should contain the "field" property');
          } else {
            validateField(fieldId);
          }
          if (direction && direction !== 'asc' && direction !== 'desc') {
            report.addError(`The ordering direction for "${fieldId}" should be either "asc" or `
              + `"desc", instead got "${direction}" in "${this.id}".`);
          }
          if (nulls && nulls !== 'first' && nulls !== 'last') {
            report.addError(`The nulls ordering for "${fieldId}" should be either "first" or `
              + `"last", instead got "${nulls}" in "${this.id}".`);
          }
        }
      });
    } catch (error) {
      report.addError(error.message);
    }
    return report;
  }

  /**
   * Throws an error if the given ordering spec is not valid.
   * @param {Array.<OrderField>} ordering - the ordering specification.
   */
  assertOrdering(ordering) {
    const report = this.validateOrdering(ordering);
    if (!report.valid) {
      throw new Error(`The ordering is not valid. ${report.text}`);
    }
  }

  /**
   * Helper function that, given a set of fields (typically obtained from redux-form on submit),
   * returns a new set from which are removed:
   * - property fields that should not be submitted to the server, such as controlled fields;
   * - relation fields;
   *
   * @param {object} inFields
   * @param {string} mode - either 'create' (when creating a new item) or 'update' (when updating an
   *   existing item.
   * @param {Object<{ includeRelatees: bool }>} [options] - optional settings - includeRelatees (default true)
   * @returns {{}} The fields to submit to the item update API.
   */
  filterFields(inFields, mode, options = {}) {
    const { includeRelatees = true } = options;
    const outFields = {};
    forIn(inFields, (value, fieldId) => {
      if (this.hasField(fieldId)) {
        const field = this.getField(fieldId);
        if (mode === 'create') {
          if (!field.isControlled) {
            outFields[fieldId] = value;
          }
        } else if (mode === 'update') {
          if (field.isEditable) {
            outFields[fieldId] = value;
          }
        } else {
          throw new RangeError(`Unexpected mode "${mode}".`);
        }
      } else if (includeRelatees && this.hasRelatee(fieldId)) {
        // TODO: check if relatees can be added here and require additional properties like isEditable
        const relatee = this.getRelatee(fieldId);
        // Exclude inter-store relatee fields when in update mode
        if (!(relatee.interStore && mode === 'update') && value) {
          if (relatee.arityOne) {
            if (isString(value)) {
              outFields[fieldId] = value;
            } else if (isObject(value) && isString(value.id)) {
              outFields[fieldId] = value.id;
            } else {
              throw new Error('Expected a string or item object (with `id` field) as value '
                + `for the unary relatee "${this.id}.${fieldId}", instead got "${toString(value)}".`);
            }
          } else if (isArray(value)) {
            outFields[fieldId] = value.map((val) => {
              if (isString(val)) {
                return val;
              }
              if (isObject(val) && isString(val.id)) {
                return val.id;
              }

              throw new Error('Expected an array of strings or item objects as value for the '
                  + `n-ary relatee "${this.id}.${fieldId}", but got "${toString(val)}" as one of `
                  + 'the values in the given array.');
            });
          } else {
            throw new Error('Expected an array of strings or item objects as value for the '
              + `n-ary relatee "${this.id}.${fieldId}", instead got "${toString(value)}".`);
          }
        }
      }
    });
    return outFields;
  }

  // -- GraphQL & PropTypes --------------- ---  --  -

  /**
   * @todo generate propTypes from schema
   * @returns {{}}
   */
  getPropTypes() {
    return {};
  }

  /**
   * @returns {string} The GraphQL type.
   */
  gqlTypeString() {
    return this.singularCapped;
  }

  /**
   * Creates a GraphQL Object Type for the given dxSchema collection.
   * @param {object} [customFields] An object with custom output fields to add in output type.
   * @param {string} [name] If you provide custom fields, then you also need to provide a custom
   *   name for the resulting output type object.
   * @returns {GraphQLCollection}
   */
  gqlTypeObject(customFields, name) {
    if (customFields !== undefined && !isObject(customFields)) {
      throw new Error(`Expected an object as "customFields", got "${customFields}".`);
    }
    if (customFields) {
      if (!isString(name)) {
        throw new Error('When you provide custom fields, then you also need to provide a custom '
          + 'name for the resulting output type object.');
      }
      // Do not memoize collection types with custom fields.
      return new GraphQLCollection(this, customFields, name);
    }

    if (!this._graphQLTypeObject) {
      // Memoize the resulting type object.
      this._graphQLTypeObject = new GraphQLCollection(this);
    }
    return this._graphQLTypeObject;
  }
}

CollectionSchema.is = (obj) => (obj instanceof CollectionSchema);

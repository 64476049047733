import { css } from 'glamor';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../../propTypes';
import { dxColors } from '../../styles';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  alignItems: 'flex-end',
  border: 'none',
  color: dxColors.fgMain,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '> div': {
    alignItems: 'flex-end',
    display: 'flex',
  },
  '> div.left-group': { justifyContent: 'flex-start', flexGrow: 2, marginRight: 16 },
  '> div.middle-group': { justifyContent: 'center' },
  '> div.right-group': { justifyContent: 'flex-end' },
});

// -- Component --------------- --- --  -

/**
 * Base class for toolbars.
 * @constructor
 * @see ModuleToolbar
 * @see SectionToolbar
 */
export const Toolbar = ({ children, className, styles }) => (
  <div
    className={classnames('dx-toolbar', className)}
    {...css(defaultStyles, styles)}
  >
    <div className="left-group">{filterChildren(children, 'left')}</div>
    <div className="middle-group">{filterChildren(children, 'middle')}</div>
    <div className="right-group">{filterChildren(children, 'right')}</div>
  </div>
);

Toolbar.propTypes = {
  children: PropTypes.node,

  /** Custom class name. */
  className: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../../propTypes/cssPropType). */
  styles: cssPropType,
};

// -- Helpers --------------- --- --  -

/**
 * WARNING:
 * When there is only one child, `children.filter` will fail. `React.Children.count()` can be used
 * to check the number of children, and when there's one, `React.Children.only()` can be use to get
 * this child. However, when there is only one child, and the children list was previously processed
 * with `React.Children.map()`, then `React.Children.only()` throws. Such processing takes place
 * in `ModuleToolbar`, where it is used to inject styles in the child's props. In other cases, the
 * children are not passed through `React.Children.map()`. It is unclear how to distinguish between
 * the two cases. To avoid further mayhem in this method, we first pass the children through a
 * useless application of `React.Children.map()` to make sure that we have a uniform type of
 * children and can process them accordingly.
 *
 * @param children
 * @param filter
 * @returns {*}
 */
const filterChildren = (children, filter) => React.Children
  .map(children, (ch) => ch) // this is not useless, see notes above
  .filter((ch) => {
    if (ch.props && ch.props.placement) {
      return ch.props.placement && ch.props.placement === filter;
    }

    return filter === 'left';
  });

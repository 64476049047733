import isObject from 'lodash/isObject';
import { isString } from '../isString';
import { isActivity } from './Activity';
import { authRuleToString } from './utils';

// -- ScopedAuthRule Class --------------- --- --  -

/**
 * Represents a scoped access rule.
 */
export class ScopedAuthRule {
  /**
   * @param {AuthRule} [rule] - The unscoped access rule in the form of an activity
   *   identifier or an array of such identifiers.
   * @param {string} collectionId - The collection where we want to be authorized on.
   * @param {string} itemId - The identifier of the "topic" item in the given collection.
   */
  constructor(rule, collectionId, itemId) {
    const acceptableRule = isString(rule)
      || (isObject(rule) && (
        (rule.and && rule.and.every((subRule) => isString(subRule)))
        || (rule.or && rule.or.every((subRule) => isString(subRule)))
      ))
      || isActivity(rule);
    // console.log('- acceptableRule:', acceptableRule);
    if (!acceptableRule) {
      throw new Error(
        'The rule should an activity ID, a "{ and: [...activities] }" or "{ or: [...activities] }" '
        + 'composition of activities, or an Activity object, '
        + `got a(n) ${typeof rule}.`
      );
    }
    if (!isString(collectionId)) {
      throw new Error(`The scope should be a string, received a(n) ${typeof collectionId}.`);
    }
    if (!isString(itemId)) {
      throw new Error(`The topic should be a string, received a(n) ${typeof itemId}. Perhaps you `
        + 'forgot to specify an id or the specified entity relates to no rootScopes.');
    }
    this._rule = rule;
    this._scope = collectionId;
    this._topic = itemId;
  }

  /**
   * @return {boolean} True for all ScopedAuthRule instances.
   */
  get isScopedAuthRule() { return true; }

  /**
   * @return {string|string[]} The (unscoped) access rule.
   */
  get rule() { return this._rule; }

  /**
   * @return {string} The identifier for our scope, most off the time a name off a collection.
   */
  get scope() { return this._scope; }

  /**
   * @return {string} The identifier of the item for which this  scoped auth rule is specified.
   *   This topic must be an item from one of the collections in the given scope.
   */
  get topic() { return this._topic; }

  toString() {
    return `<ScopedAuthRule ${this.scope} ${this.topic} ${authRuleToString(this.rule)}>`;
  }
}

// -- Support Functions --------------- --- --  -

/**
 * @param {*} value
 * @returns {boolean} True when the given value is a ScopedAuthRule instance.
 */
export const isScopedAuthRule = (value) => (value instanceof ScopedAuthRule);

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, FormControl, MenuItem } from 'react-bootstrap';
import { dxColors } from '../../styles';

import { dmsCollectionItemPropType, pageLoadStatePropTypesOf } from '../../propTypes';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  display: 'block',
  margin: '8px 0',
  '> a > input': {
    background: dxColors.bgLighter_3,
    boxShadow: 'none',
    boxSizing: 'border-box',
    padding: '0px 8px!important',
  },
  '> ul': {
    marginTop: 0,
    maxHeight: '292px', // Shows 10 menu items
    overflowY: 'auto',
    width: '100%',
    '> li': { padding: '2px 8px' },
    '> .dx-relatee-option': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ':hover, :active, :focus': {
        backgroundColor: dxColors.bgFocus,
        cursor: 'pointer',
      },
    },
  }
});

// -- DMSAddRelateeDropdown Component --------------- --- --  -

export class DMSAddRelateeDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.props.loadItems({ from: 0 });
  }

  handleInputChange(event) {
    event.preventDefault();
    if (this.input.value && this.input.value.length > 1) {
      this.props.loadItems({ from: 0, searchString: this.input.value });
    } else {
      this.props.loadItems({ from: 0 });
    }
  }

  renderMenuItems() {
    const { loadState } = this.props;
    if (!loadState.available) {
      return <li className="tip">Loading...</li>;
    }
    if (loadState.items.length > 0) {
      return loadState.items.map((item) => (
        <MenuItem className="dx-relatee-option" eventKey={item.id} key={item.id}>
          {item.label}
        </MenuItem>
      ));
    }
    return <li className="tip">{`No ${this.props.plural} found.`}</li>;
  }

  render() {
    return (
      <Dropdown
        defaultOpen
        className="dx-relatee-dropdown"
        id={`dx-dropdown-add-${this.props.singular}`}
        key="__dropdown__"
        onSelect={this.props.onSelect}
        onToggle={this.props.onToggle}
        {...defaultStyles}
      >
        <Dropdown.Toggle noCaret useAnchor>
          <FormControl
            autoComplete="off"
            autoFocus
            inputRef={(ref) => { this.input = ref; }}
            name={`textSearch_${this.props.singular}`}
            onChange={this.handleInputChange}
            placeholder={`Type to search ${this.props.singular}..`}
            type="text"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu id="dx-add-relatee-menu">{this.renderMenuItems()}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

DMSAddRelateeDropdown.propTypes = {
  loadItems: PropTypes.func.isRequired,
  loadState: pageLoadStatePropTypesOf(dmsCollectionItemPropType).isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  plural: PropTypes.string.isRequired,
  singular: PropTypes.string.isRequired,
};

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../propTypes';
import { dxColors, dxStyles } from '../styles';

// -- Styles --------------- --- --  -

const headerStyles = css(dxStyles.paddedPageElementStyles, {
  backgroundColor: 'white',
  boxSizing: 'border-box',
  paddingTop: 16,
  paddingBottom: 12,
  '> .titlebox': {
    display: 'flex',
    '> .prefix': {
      color: dxColors.fgLighter_2,
      fontFamily: 'Raleway Bold',
      marginRight: 12,
      paddingTop: 9,
      textTransform: 'uppercase',
    },
    '> .title': {
      fontSize: '30px',
      margin: '2px 0',
    },
  },
});

// -- Component --------------- --- --  -

export const ChapterHeader = ({
  children, styles, title, titlePrefix
}) => (
  <div className="dx-chapter-header" {...css(headerStyles, styles)}>
    <div className="titlebox">
      {titlePrefix && <span className="prefix">{titlePrefix}</span>}
      <h1 className="title">{title}</h1>
    </div>
    {children}
  </div>
);

ChapterHeader.propTypes = {
  children: PropTypes.node, // an optional ModuleToolbar

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,

  title: PropTypes.string.isRequired,

  titlePrefix: PropTypes.string,
};

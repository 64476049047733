import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import isObject from 'lodash/isObject';
import { DxError } from '../../utils/dxf';
import { toString } from '../../utils/toString';

import { cssPropType } from '../propTypes';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  marginBottom: '32px',
  '> div': {
    '> h3': {
      fontSize: '1.2em',
      margin: '16px 0 8px 0',
    },
    '> pre': {
      background: 'none',
      border: 'none',
      // fontSize: '85%',
      lineHeight: '1em',
      padding: 0,
      margin: 0,
    },
    '> pre > code': {
      color: '#002299',
      fontSize: '85%',
    },
  },
  '> .dx-reason': { fontWeight: '600' },
  '> .dx-variables, > .dx-values, > .dx-stack, > .dx-component-stack': { fontSize: '0.85em' },
});

// -- Component --------------- --- --  -

/**
 * Renders a DxError object.
 *
 * @todo Add support for the info object passed to the 'componentDidCatch' handler in a React component.
 */
export const DxErrorDetails = (props) => {
  const { className, styles } = props;
  let { error } = props;

  // Normalize dxError:
  if (error === undefined) {
    console.error('Showing DxError with undefined dxError...');
    // debugger;
  }
  if (!DxError.isDxError(error)) { error = new DxError(error); }

  const { values, variables } = error;
  return (
    <div className={classnames('dx-error', className)} {...css(defaultStyles, styles)}>
      <div className="dx-reason">
        <p>{error.reason || 'Something went wrong.'}</p>
        {error.details && (
          <ol>
            {error.details.map((detail, index) => {
              const _detail = (isObject(detail) && detail.message) ? detail.message : detail;
              return <li key={index}>{toString(_detail, { maxStrLen: 0 })}</li>;
            })}
          </ol>
        )}
      </div>
      {error.queryString && (
        <div>
          <h3>Query:</h3>
          <pre><code>{error.getAnnotatedQuery()}</code></pre>
        </div>
      )}
      {variables && (
        <div className="dx-variables">
          <h3>Variables:</h3>
          <ul>
            {Object.keys(variables).map((key) => (
              <li key={key}>
                <b>
                  {key}
                  :&nbsp;
                </b>
                {toString(variables[key], { maxStrLen: 0 })}
              </li>
            ))}
          </ul>
        </div>
      )}
      {values && (
        <div className="dx-values">
          <h3>Metadata:</h3>
          <ul>
            {Object.keys(values).map((key) => (
              <li key={key}>
                <b>
                  {key}
                  :&nbsp;
                </b>
                {toString(values[key], { maxStrLen: 0 })}
              </li>
            ))}
          </ul>
        </div>
      )}
      {error.stack && (
        <div className="dx-stack">
          <h3>Stack:</h3>
          <pre>{error.stack}</pre>
        </div>
      )}
      {error.componentStack && (
        <div className="dx-component-stack">
          <h3>Component Stack:</h3>
          <pre>{error.componentStack}</pre>
        </div>
      )}
    </div>
  );
};

DxErrorDetails.propTypes = {
  /** Optional additional class name. */
  className: PropTypes.string,

  /** The error object or string. */
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(DxError),
    PropTypes.instanceOf(Error),
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,
};

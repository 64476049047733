import { AuthProvider } from './AuthProvider';

// -- Auth0Provider --------------- --- --  -

/**
 * The configuration for this provider is to be provided in the `duxis.auth.identityProviders.auth0`
 * config setting.
 *
 * @see cargo-react/sagas/dxAuth/auth0Sagas
 */
class Auth0Provider extends AuthProvider {
  constructor() {
    super('auth0');
  }

  getSignInComponent() {
    return null; // the signing-in is delegated to Auth0.
  }
}

/**
 * @type Auth0Provider
 */
export const auth0Provider = new Auth0Provider();

import { connectAdvanced } from 'react-redux';
import isFunction from 'lodash/isFunction';
import { equalShallow } from '../../utils/equalShallow';

/**
 * This higher-order component takes 1) a component constructor, 2) an object with extra props to
 * inject in the props passed to the wrapped component (or a function that takes the incoming props
 * and returns the extra props).
 * @param {function} component - A component constructor.
 * @param {object} extraProps - An object with extra props to inject in the props passed to the
 *   wrapped component.
 * @return {function} A component constructor.
 */
export const inject = (component, extraProps) => connectAdvanced(
  selectorFactory,
  {
    extraProps,
    getDisplayName: (name) => name,
    methodName: 'inject',
    shouldHandleStateChanges: false,
  }
)(component);

const selectorFactory = (dispatch, { extraProps }) => {
  // memoized props to pass to wrapped component:
  let result = {};

  // the previous incoming props:
  let props = {};

  return (state, nextProps) => {
    if (!equalShallow(nextProps, props)) {
      props = nextProps;
      result = { ...props, ...(isFunction(extraProps) ? extraProps(nextProps) : extraProps) };
    }
    return result;
  };
};

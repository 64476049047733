import { ofType } from './loadStateSupport';

// -- ItemLoadState --------------- ---  --  -

export const CREATING = 'ITEM_LOAD_STATE/CREATING';
export const INITIAL = 'ITEM_LOAD_STATE/INITIAL';
export const LOADED = 'ITEM_LOAD_STATE/LOADED';
export const LOADING = 'ITEM_LOAD_STATE/LOADING';
export const RELOADING = 'ITEM_LOAD_STATE/RELOADING';
export const UPDATING = 'ITEM_LOAD_STATE/UPDATING';

/**
 * @typedef {object} ItemLoadState
 *
 * A load-state serves three purposes:
 * 1) It represents the resource-loading-related state in the Redux store;
 * 2) It provides the state transition methods;
 * 3) It can be used as a 'pure' prop that provides convenience properties such as _available_;
 *
 * @property {string} id - The item identifier.
 * @property {object} item - The loaded item object.
 * @property {string} state - Either {@link INITIAL}, {@link CREATING}, {@link LOADING},
 *   {@link LOADED}, {@link RELOADING}, or {@link UPDATING}.
 *
 * @property {boolean} creating - True when a new item is being created.
 * @property {boolean} loaded - True when the item is loaded and available.
 * @property {boolean} loading - True when the item is being loaded.
 * @property {boolean} reloading - True when the item is being reloaded. The currently loaded
 *   item remains available.
 * @property {boolean} updating - True when the item is being updated. The currently loaded
 *   item remains available.
 *
 * @property {boolean} available - True when the page of items has been loaded or is being reloaded
 *   or updated.
 * @property {Function(string):boolean} availableId - Takes an item identifier and returns true when
 *   this identifies the currently available item.
 * @property {object} newFields - The new field values when updating the item.
 *
 * @property {Function():ItemLoadState} handleCreateFailed - Called when the creation of an
 *   item failed. This private method should not be called by client code.
 * @property {Function():ItemLoadState} handleCreateInit - Called when the creation of an item
 *   is initiated. This private method should not be called by client code.
 * @property {Function(object):ItemLoadState} handleCreateSuccess - Called when the creation of a
 *   new item succeeded. This private method should not be called by client code.
 * @property {Function():ItemLoadState} handleCreateCancel - Called when the creation of a
 *   new item was cancelled. This private method should not be called by client code.
 *
 * @property {Function(string):ItemLoadState} handleLoad - Called when an item is being loaded.
 *   This private method should not be called by client code.
 * @property {Function(object):ItemLoadState} handleReceive - Called when an item was loaded.
 *   This private method should not be called by client code.
 *
 * @property {Function(string):ItemLoadState} handleUpdateCommit - Called when an item is being
 *   updated. This private method should not be called by client code.
 * @property {Function(string):ItemLoadState} handleUpdateFailed - Called when an item could not be
 *   updated. This private method should not be called by client code.
 * @property {Function(object):ItemLoadState} handleUpdateSuccess - called when an item was
 *   successfully updated. This private method should not be called by client code.
 * @property {Function(string):ItemLoadState} handleUpdateCancel - called when the updating of the
 *   item was cancelled. This private method should not be called by client code.
 */

/**
 * The base item-load-state prototype. See _dxLoadState_ manual for more details.
 * @type {ItemLoadState}
 */
export const baseLoadState = {
  available: false,
  creating: false,
  id: undefined,
  item: undefined,
  loaded: false,
  loading: false,
  newFields: undefined,
  reloading: false,
  state: undefined,
  updating: false,
  availableId() { return false; },
  handleCreateCancel() { return this; }, // ignore unexpected create-cancel action
  handleCreateFailed() { return this; }, // ignore unexpected create-failed action
  handleCreateInit() { return this; }, // ignore unexpected create-init action
  handleCreateSuccess() { return this; }, // ignore unexpected create-success action
  handleReceive() { return this; }, // ignore unexpected receive action
  handleUpdateCancel() { return this; }, // ignore unexpected update-cancel action
  handleUpdateCommit() { return this; }, // ignore unexpected update-commit action
  handleUpdateFailed() { return this; }, // ignore unexpected update-failed action
  handleUpdateSuccess() { return this; }, // ignore unexpected update-success action
};

/**
 * The initial item-load-state to use in your Redux state.
 * @type {ItemLoadState}
 */
const initialState = {
  ...baseLoadState,
  state: INITIAL,
  handleCreateInit() { return creatingState; },
  handleLoad(nxtId) { return loading(nxtId); },
  handleReceive(nxtItem) { return loaded(nxtItem); },
};

/**
 * @type {ItemLoadState}
 */
const creatingState = {
  ...baseLoadState,
  creating: true,
  state: CREATING,
  handleCreateCancel() { return initialState; },
  handleCreateSuccess(item) { return loaded(item); },
  handleLoad(nxtId) { return loading(nxtId); },
};

/**
 * @param {string} id
 * @return {ItemLoadState}
 */
const loading = (id) => ({
  ...baseLoadState,
  id,
  loading: true,
  state: LOADING,
  handleCreateInit() { return creatingState; },
  handleLoad(nxtId) { return nxtId === id ? this : loading(nxtId); },
  handleReceive(nxtItem) { return nxtItem.id === id ? loaded(nxtItem) : this; },
});

/**
 * @type {ItemLoadState}
 */
const loadedPrototype = {
  ...baseLoadState,
  available: true,
  availableId(id) { return id === this.item.id; },
  handleCreateInit() { return creatingState; },
  handleLoad(id) { return id === this.id ? reloading(this.item) : loading(id); },
  handleUpdateCommit(id, fields) {
    return id === this.id ? updating(this.item, fields) : this;
  },
  handleUpdateSuccess(nxtItem) {
    // Note: Allow updates without preceding "update-commit" action.
    return this.id === nxtItem.id ? loaded({ ...this.item, ...nxtItem }) : this;
  },
};

/**
 * @param {object} item
 * @return {ItemLoadState}
 */
const loaded = (item) => ({
  ...loadedPrototype,
  id: item.id,
  item,
  loaded: true,
  state: LOADED,
});

/**
 * @param {object} item
 * @return {ItemLoadState}
 */
const reloading = (item) => ({
  ...loadedPrototype,
  id: item.id,
  item,
  reloading: true,
  state: RELOADING,
  handleLoad(nxtId) { return nxtId === this.id ? this : loading(nxtId); },
  handleReceive(nxtItem) { return nxtItem.id === this.id ? loaded(nxtItem) : this; },
});

/**
 * @param {object} item - The current item fields.
 * @param {object} fields - The new field values.
 * @return {ItemLoadState}
 */
const updating = (item, fields) => ({
  ...loadedPrototype,
  id: item.id,
  item,
  newFields: fields,
  updating: true,
  state: UPDATING,
  handleUpdateCancel(id) { return id === item.id ? loaded(item) : this; }
});

// -- Reducer --------------- ---  --  -

/**
 * Returns a Redux reducer that takes as input an {@link ItemLoadState} object and a Redux action,
 * and returns an {@link ItemLoadState} object.
 * See _dxLoadState_ manual for more details.
 *
 * @param {string|Function} fetchActionType - The type of the action that is dispatched when an item
 *   is being fetched, or a function that takes an action object and returns true when it
 *   is such an action.
 *   The action should have an `id` property with the resource identifier.
 * @param {string|Function} receiveActionType - The type of the action that is dispatched when
 *   receiving the fetched item, or a function that takes an action object and returns true when it
 *   is such an action.
 *   The action should have an `item` property having the item object as value.
 * @param {object} [options]
 *
 * @param {string|Function} [options.createInitActionType] - The type of the action that is
 *   dispatched when the creation of a new item is initiated, or a function that takes an action
 *   object and returns true when it is such an action.
 * @param {string|Function} [options.createFailedActionType] - The type of the action that is
 *   dispatched when the new item could not be created, or a function that takes an action
 *   object and returns true when it is such an action.
 * @param {string|Function} [options.createSuccessActionType] - The type of the action that is
 *   dispatched when the new item was successfully created, or a function that takes an
 *   action object and returns true when it is such an action.
 *   The action should have an `item` property having the item object as value.
 * @param {string|Function} [options.createCancelActionType] - The type of the action that is
 *   dispatched when the creation of an item was cancelled, or a function that takes an action
 *   object and returns true when it is such an action.
 *   The action should have the resource identifier as `id` property.
 *
 * @param {string|Function} [options.updateCommitActionType] - The type of the action that is
 *   dispatched when the updated item should be committed, or a function that takes an action object
 *   and returns true when it is such an action.
 *   The action should have an `id` property with the resource identifier.
 * @param {string|Function} [options.updateFailedActionType] - The type of the action that is
 *   dispatched when the updated item could not be committed, or a function that takes an action
 *   object and returns true when it is such an action.
 *   The action should have the resource identifier as `id` property.
 * @param {string|Function} [options.updateSuccessActionType] - The type of the action that is
 *   dispatched when the updated item was successfully committed, or a function that takes an
 *   action object and returns true when it is such an action.
 *   The action should have the resource identifier as `id` property.
 * @param {string|Function} [options.updateCancelActionType] - The type of the action that is
 *   dispatched when the updating of an item was cancelled, or a function that takes an action
 *   object and returns true when it is such an action.
 *   The action should have the resource identifier as `id` property.
 *
 * @param {string|Function} [options.resetActionType] - The action type (or predicate) for which
 *   the load-state should be reset to its initial state.
 *
 * @returns {Function(object, object)} The Redux state reducer.
 *
 * @see ItemLoadState
 * @see createPageLoadStateReducer
 * @see createSimpleLoadStateReducer
 */
export const createItemLoadStateReducer = (fetchActionType, receiveActionType, options = {}) => {
  const {
    createCancelActionType,
    createFailedActionType,
    createInitActionType,
    createSuccessActionType,
    resetActionType,
    updateCancelActionType,
    updateCommitActionType,
    updateFailedActionType,
    updateSuccessActionType,
  } = options;

  // Check if co-dependent action types have been provided:
  if (createInitActionType && !createSuccessActionType) {
    console.warn('When you provide the "createInitActionType" option for '
      + '"createItemLoadStateReducer" then you should also provide "createSuccessActionType".');
  }
  if (createSuccessActionType && !createInitActionType) {
    console.warn('When you provide the "createSuccessActionType" option for '
      + '"createItemLoadStateReducer" then you should also provide "createInitActionType".');
  }
  // Note: update-commit is co-dependent on update-success, but not the inverse
  if (updateCommitActionType && !updateSuccessActionType) {
    console.warn('When you provide the "updateCommitActionType" option for '
      + '"createItemLoadStateReducer" then you should also provide "updateSuccessActionType".');
  }

  return (state = initialState, action) => {
    if (ofType(action, createInitActionType)) {
      return state.handleCreateInit();
    }
    if (ofType(action, createFailedActionType)) {
      return state.handleCreateFailed();
    }
    if (ofType(action, createSuccessActionType)) {
      assertItem(action);
      return state.handleCreateSuccess(action.item);
    }
    if (ofType(action, createCancelActionType)) {
      return state.handleCreateCancel();
    }
    if (ofType(action, fetchActionType)) {
      assertId(action);
      return state.handleLoad(action.id);
    }
    if (ofType(action, receiveActionType)) {
      assertItem(action);
      return state.handleReceive(action.item);
    }
    if (ofType(action, updateCommitActionType)) {
      assertId(action);
      if (!action.fields) {
        console.warn(`Missing "fields" property in update-commit-action "${action.type}" `
          + 'in ItemLoadStateReducer.');
      }
      return state.handleUpdateCommit(action.id, action.fields);
    }
    if (ofType(action, updateFailedActionType)) {
      assertId(action);
      return state.handleUpdateFailed(action.id);
    }
    if (ofType(action, updateSuccessActionType)) {
      assertItem(action);
      return state.handleUpdateSuccess(action.item);
    }
    if (ofType(action, updateCancelActionType)) {
      assertId(action);
      return state.handleUpdateCancel(action.id);
    }
    if (ofType(action, resetActionType)) {
      return initialState;
    }
    return state;
  };
};

const assertId = (action) => {
  if (!action.id) {
    throw new Error(`Missing "id" property in "${action.type}" action for ItemLoadStateReducer.`);
  }
};

const assertItem = (action) => {
  if (!action.item) {
    throw new Error(`Missing "item" property in "${action.type}" action for ItemLoadStateReducer.`);
  }
};

import { put, take } from 'redux-saga/effects';
import { uuid } from '../../utils/uuid';

import {
  removeModalAction,
  showConfirmationModalAction,
  showErrorModalAction,
  showMessageModalAction,
  showProgressModalAction,
} from '../actions/dxModalActions';
import { DX_MODAL_CONFIRM, DX_MODAL_REMOVE } from '../constants';

// -- Modal Helpers --------------- ---  --  -

/**
 * Close the modal popup with the given id.
 * @param {string} id - The ID of the modal popup to remove.
 */
export function* removeModal(id) {
  yield put(removeModalAction(id));
}

/**
 * Show a confirmation modal and return true when the user confirms.
 * @param {string} title
 * @param {*} content - Content that can be rendered in React component.
 * @param {object} [options]
 * @param {string} [options.id]
 * @param {string} [options.noLabel = 'No']
 * @param {Function} [options.onConfirm]
 * @param {string} [options.yesLabel = 'Yes']
 * @return {boolean}
 *
 * @example
 * function* mySaga() {
 *   const confirmed = yield call(showConfirmationModal, 'Are you sure?', 'Really?');
 *   if (confirmed) {
 *     // OK, proceed
 *   }
 *   else {
 *     // Cancel!
 *   }
 * }
 */
export function* showConfirmationModal(title, content, options = {}) {
  options.id = options.id || uuid();
  yield put(showConfirmationModalAction(title, content, options));
  const action = yield take(({ id, type }) => type === DX_MODAL_CONFIRM && options.id === id);
  return action.confirm;
}

/**
 * Show an error modal and return when the modal is closed.
 * @param {DxErrorArgument} error - See `/utils/dxf/DxError`.
 * @param {object} [options] - Options injected in the props of the `ErrorModal` component.
 * @param {string} [id] - The modal ID to use, in case you need to close the modal programmatically.
 * @example
 * function* mySaga() {
 *   yield showErrorModal('Something went wrong.');
 *   console.info('closed');
 * }
 */
export function* showErrorModal(error, options, id) {
  yield put(showErrorModalAction(error, options));
  yield take((action) => action.type === DX_MODAL_REMOVE && id === action.id);
}

/**
 * Show a message modal and return when the modal is closed.
 * @param {string} title
 * @param {string} message
 * @param {object} [options]
 * @param {string} [options.id]
 * @param {Function} [options.onClose]
 * @return {boolean}
 */
export function* showMessageModal(title, message, options = {}) {
  options.id = options.id || uuid();
  yield put(showMessageModalAction(title, message, options));
  yield take((action) => action.type === DX_MODAL_REMOVE && options.id === action.id);
}

/**
 * Show a progress modal and return the modal ID that can be passed when calling
 * @param message
 * @param options
 * @return {*}
 */
export function* showProgressModal(message, options = {}) {
  options.id = options.id || uuid();
  yield put(showProgressModalAction(message, options));
  return options.id;
}

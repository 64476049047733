import { FETCH_EXTERNAL_DXSCHEMA_START, FETCH_EXTERNAL_DXSCHEMA_SUCCESS, FETCH_EXTERNAL_DXSCHEMA_ERROR } from '../constants/actionTypes';
import { DxSchemaBase } from '../utils/dxSchema/DxSchemaBase';

const initialState = {
  dxSchema: {},
  error: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXTERNAL_DXSCHEMA_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_EXTERNAL_DXSCHEMA_SUCCESS: {
      const { data: { dxSchema } } = action;
      return {
        ...state,
        dxSchema: new DxSchemaBase(dxSchema, { validate: false }),
        loading: false,
      };
    }
    case FETCH_EXTERNAL_DXSCHEMA_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    default:
      return state;
  }
};

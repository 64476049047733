import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import { Activity, isActivity, isCollectionActivityString } from './Activity';
import { NotAuthorizedError } from './NotAuthorizedError';
import { isScopedAuthRule } from './ScopedAuthRule';
import { authRuleToString } from './utils';

// -- Exported Functions --------------- --- --  -

export const normalizeContext = (context) => {
  if (!isObject) {
    throw new Error(`Expected an object as "context", got "${context}".`);
  }
  if (!isFunction(context.explain)) {
    context.explain = context.explain ? ((...args) => console.log(...args)) : (() => null);
  }
};

// -- AuthorizerBase Class --------------- --- --  -

/**
 * Abstract base class for the concrete Authorizer classes in `cargo-service/auth` and
 * `react-frontend/auth`. These (singleton) classes are responsible for authorizing users, i.e.
 * asserting that a given user can access the resource protected according to the given auth-rule.
 *
 * @abstract
 */
export class AuthorizerBase {
  /**
   * @param {DxSchemaBase} dxSchema
   */
  constructor(dxSchema) {
    if (!dxSchema || !dxSchema.isDxSchema) {
      throw new Error(`Expected a dxSchema, got "${dxSchema}".`);
    }
    this._dxSchema = dxSchema;
  }

  /**
   * @returns {DxSchemaBase} The dxSchema for this authorizer.
   */
  get dxSchema() { return this._dxSchema; }

  /**
   * @returns {boolean} True for all authorizer instances.
   */
  get isAuthorizer() { return true; }

  /**
   * @protected
   * @param {User} user
   * @param {AuthRule} rule
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when some other unexpected error occurred.
   */
  authorize(user, rule, context = {}) {
    if (!rule) {
      throw new NotAuthorizedError('Cannot authorize undefined auth rule.');
    }
    normalizeContext(context);
    context.explain('>>> AuthorizerBase authorize', { user, rule, context });
    if (isCollectionActivityString(rule)) {
      this.authorizeActivity(user, new Activity(rule), context);
    } else if (isString(rule)) {
      this.authorizeGenericActivity(user, rule, context);
    } else if (isArray(rule)) {
      this.authorizeArray(user, rule, context);
    } else if (isActivity(rule)) {
      this.authorizeActivity(user, rule, context);
    } else if (isScopedAuthRule(rule)) {
      this.authorizeScopedRule(user, rule, context);
    } else if (isFunction(rule)) {
      this.authorizeCallback(user, rule, context);
    } else if (isObject(rule) && (rule.and || rule.or)) {
      this.authorizeJunction(user, rule, context);
    } else {
      const msg = `Malformed authorization rule "${authRuleToString(rule)}" [AUTH-003].`;
      context.explain('|!', msg);
      throw new Error(msg);
    }
  }

  /**
   * @abstract
   * Returns true when the given user is authorized for the given auth specification.
   * @param {User} user
   * @param {AuthRule} rule
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @returns {boolean} True when the given user is authorized for the given auth specification.
   */
  isAuthorized() {
    throw new Error('Missing implementation for Authorizer.isAuthorized().');
  }

  /**
   * @abstract
   * @param {User} user
   * @param {AuthRule} rule
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when some other unexpected error occurred.
   */
  authorizeGenericActivity() {
    throw new Error('Missing implementation for Authorizer.authorizeGenericActivity().');
  }

  /**
   * Authorizes the given callback rule by calling it. If it returns true, the authorization
   * succeeds, else it fails.
   *
   * @abstract
   * @param {User} user
   * @param {function} callback
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when some other unexpected error occurred.
   */
  authorizeCallback() {
    throw new Error('Missing implementation for Authorizer.authorizeCallback().');
  }

  // /**
  //  * Authorize a scoped authorization rule.
  //  * @private
  //  * @param {User} user
  //  * @param {ScopedAuthRule} scopedRule
  //  * @param {object} [context]
  //  * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
  //  * @throws A NotAuthorizedError when the user is not authorized.
  //  * @throws An Error when some other unexpected error occurred.
  //  */
  // authorizeScopedRule(user, scopedRule, context) {
  //   throw new Error('Missing implementation for Authorizer.authorizeScopedRule().');
  // }

  /**
   * Authorize a v2 collection activity in the form of an _Activity_ instance.
   *
   * @protected
   * @param {User} user
   * @param {Activity} activity
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @throws An error with status 403 when the user is not authorized.
   * @throws An Error when some other unexpected error occurred.
   */
  authorizeActivity(user, activity, context) {
    context.explain('|> Case: generic collection-activity');
    // const collSchema = this.dxSchema.getCollection(activity.collectionId);
    // console.log('- activity.impliedByActivities:', activity.impliedByActivities);
    for (const impliedByActivity of activity.impliedByActivities) {
      context.explain('|-> checking generic rule:', impliedByActivity);
      try {
        this.authorizeGenericActivity(user, impliedByActivity, context);
        return;
      } catch (error) { /* continue */ }
    }
    const msg = `Not authorized for generic activity type "${activity.activityType}"`
      + ` on collection "${activity.collectionId}".`;
    context.explain('|!', msg);
    throw new NotAuthorizedError(msg);
  }

  /**
   * Rules arrays are no longer supported.
   * @param {User} user
   * @param {Array} rules
   */
  authorizeArray(user, rules) {
    if (rules[0] === 'or') {
      throw new Error('Use an "{ or: [rules...] }" object to specify a rule disjunction. '
        + `instead of "${authRuleToString(rules)}".`);
    } else {
      throw new Error('Use an "{ and: [rules...] }" object to specify a rule conjunction '
        + `instead of "${authRuleToString(rules)}".`);
    }
  }

  /**
   * @abstract
   * @param {User} user
   * @param {AuthRule} rule
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when some other unexpected error occurred.
   */
  authorizeJunction(user, rule, context) {
    if (rule.and) {
      if (rule.or) {
        throw new Error(`Expected either "and" or "or" property in a rule junction, got both in "${rule}".`);
      }
      if (!isArray(rule.and)) {
        throw new Error(`Expected an array of rules as value for "rule.and", got "${rule.and}".`);
      }
      context.explain('|> Case: conjunction --', authRuleToString(rule));
      for (const subRule of rule.and) {
        this.authorize(user, subRule, context);
      }
    } else if (rule.or) {
      if (!isArray(rule.or)) {
        throw new Error(`Expected an array of rules as value for "rule.or", got "${rule.or}".`);
      }
      context.explain('|> Case: disjunction --', authRuleToString(rule));
      for (const subRule of rule.or) {
        try {
          this.authorize(user, subRule, context);
          return;
        } catch (error) { /* continue */ }
      }
      throw new NotAuthorizedError(`Not authorized for ${authRuleToString(rule)}.`);
    } else {
      throw new Error(`Expected and "and" or "or" property in a rule junction, got "${rule}".`);
    }
  }

  // -- authorizeScopedRule ---------- --- --  -

  /**
   * Authorize a scoped authorization rule.
   * @protected
   * @param {User} user
   * @param {ScopedAuthRule} scopedRule
   * @param {object} [context]
   * @param {boolean|function} [context.explain] If true, explain is replaced with a logging function
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when the rule is malformed or when some other unexpected error occurred.
   */
  authorizeScopedRule(user, scopedRule, context) {
    context.explain('|> Case: scoped-rule:', authRuleToString(scopedRule));
    // Get the subRule (e.g. 'projects/view'), the scope and the topic:
    const { rule, scope, topic } = scopedRule;

    // Delegate to recursive sub-rule authorizer:
    const isLegacy = this.dxSchema.getCollection(scope).hasLegacyActivities;
    context.explain('|- isLegacy:', isLegacy);
    this.authorizeScopedRuleAux(user, rule, scope, topic, isLegacy, context);
  }

  authorizeScopedRuleAux(user, rule, scope, topic, isLegacy, context) {
    context.explain('|>> authorizeScopedRuleAux() --', {
      rule, scope, topic, isLegacy
    });

    // reject unsupported array rule:
    if (isArray(rule)) {
      const mesg = rule[0] === 'or'
        ? 'Use an "{ or: [rules...] }" object to specify a rule disjunction.'
        : 'Use an "{ and: [rules...] }" object to specify a rule conjunction.';
      context.explain('|!', mesg);
      throw new NotAuthorizedError(mesg);
    }

    if (isString(rule)) {
      return this.authorizeScopedStringRule(user, rule, scope, topic, isLegacy, context);
    }

    if (isActivity(rule)) {
      if (isLegacy) {
        const mesg = `Unexpected collection-activity rule "${authRuleToString(rule)}"`
          + ` on topic "${topic}" in scope "${scope}" with legacy activities.`;
        context.explain('|!', mesg);
        throw new NotAuthorizedError(mesg);
      }
      return this.authorizeScopedActivity(user, rule, scope, topic, context);
    }

    if (isObject(rule) && (rule.and || rule.or)) {
      return this.authorizeScopedJunctionRule(user, rule, scope, topic, isLegacy, context);
    }

    const msg = `Got the following unexpected sub-rule while authorizing a scoped auth rule: ${
      authRuleToString(rule)}`;
    context.explain('|!', msg);
    throw new NotAuthorizedError(msg);
  }

  authorizeScopedStringRule(user, rule, scope, topic, isLegacy, context) {
    context.explain(`|> Case: string rule "${rule}"`);
    if (isLegacy) {
      return this.authorizeScopedLegacyActivity(user, rule, scope, topic, context);
    }
    if (isCollectionActivityString(rule)) {
      return this.authorizeScopedActivity(user, new Activity(rule), scope, topic, context);
    }
    const msg = `Expected a collection activity string as scoped string rule "${rule}", `
      + ` on topic "${topic}" in scope "${scope}".`;
    context.explain('|!', msg);
    throw new NotAuthorizedError(msg);
  }

  authorizeScopedJunctionRule(user, rule, scope, topic, isLegacy, context) {
    context.explain(`|> Case: junction rule "${authRuleToString(rule)}"`);
    if (rule.and) {
      if (rule.or) {
        const msg = 'Expected either "and" or "or" property in a rule junction, '
          + `got both in "${authRuleToString(rule)}".`;
        context.explain('|!', msg);
        throw new Error(msg);
      }
      for (const subRule of rule.and) {
        this.authorizeScopedRuleAux(user, subRule, scope, topic, isLegacy, context);
      }
    } else {
      for (const subRule of rule.or) {
        try {
          return this.authorizeScopedRuleAux(user, subRule, scope, topic, isLegacy, context);
        } catch (error) { /* continue */ }
      }
      const msg = `Not authorized for ${authRuleToString(rule)}.`;
      throw new NotAuthorizedError(msg);
    }
  }

  /**
   * @abstract
   * @param {User} user
   * @param {Activity} activity
   * @param {string} scope - The root scope collection identifier.
   * @param {string} topic - The topic item identifier.
   * @param {object} context
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when the rule is malformed or when some other unexpected error occurred.
   */
  authorizeScopedActivity() {
    throw new Error('Missing implementation for Authorizer.authorizeScopedActivity().');
  }

  /**
   * @abstract
   * @param {User} user
   * @param {string} activity - The legacy activity identifier.
   * @param {string} scope - The root scope collection identifier.
   * @param {string} topic - The topic item identifier.
   * @param {object} context
   * @throws A NotAuthorizedError when the user is not authorized.
   * @throws An Error when the rule is malformed or when some other unexpected error occurred.
   */
  authorizeScopedLegacyActivity() {
    throw new Error('Missing implementation for Authorizer.authorizeScopedLegacyActivity().');
  }
}

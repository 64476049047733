import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import isNumber from 'lodash/isNumber';

import { dxFormFieldViewPropTypes, dxFormInputControlPropTypes } from '../../propTypes';

import { InputFFC } from './formFieldComponents/InputFFC';

// -- Component --------------- ---  --  -

export class IdField extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.editMode !== nextProps.editMode;
  }

  render() {
    // console.log('[IdField] render');
    const {
      fieldId, fieldProps, fieldSchema, maxLength, minLength, pattern, value, ...otherProps
    } = this.props;

    const childProps = {
      baseValue: value,
      className: 'dx-string-field',
      component: InputFFC,
      id: `input-${fieldId}`,
      maxLength: (isNumber(maxLength) && maxLength) || (fieldSchema && fieldSchema.constraints.maxLength),
      minLength: (isNumber(minLength) && minLength) || (fieldSchema && fieldSchema.constraints.minLength),
      name: fieldId,
      pattern: pattern || (fieldSchema && fieldSchema.constraints.pattern),
      type: 'text',
      ...otherProps,
      ...fieldProps,
    };

    return <Field {...childProps} />;
  }
}

IdField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...dxFormInputControlPropTypes,

  editMode: PropTypes.bool,

  /** Override `maxLength` field schema constraint. */
  maxLength: PropTypes.number,

  /** Override `minLength` field schema constraint. */
  minLength: PropTypes.number,

  /** Override `pattern` field schema constraint. */
  pattern: PropTypes.string,
};

IdField.defaultProps = {
  editMode: false,
};

/**
 * @param {ItemLoadState} itemLoadState
 * @return {boolean} True when the page of items has been loaded or is being reloaded or updated.
 */
export const getItemLoadStateAvailable = (itemLoadState) => itemLoadState.available;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {boolean} True when a new item is being created.
 */
export const getItemLoadStateCreating = (itemLoadState) => itemLoadState.creating;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {string} The item identifier.
 */
export const getItemLoadStateId = (itemLoadState) => itemLoadState.id;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {object} The loaded item object.
 */
export const getItemLoadStateItem = (itemLoadState) => itemLoadState.item;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {boolean} True when the item is loaded and available.
 */
export const getItemLoadStateLoaded = (itemLoadState) => itemLoadState.loaded;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {boolean} True when the item is being loaded.
 */
export const getItemLoadStateLoading = (itemLoadState) => itemLoadState.loading;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {object} The new fields when updating the item.
 */
export const getItemLoadStateNewFields = (itemLoadState) => itemLoadState.newFields;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {boolean} True when the item is being reloaded. The currently loaded
 *   item remains available.
 */
export const getItemLoadStateReloading = (itemLoadState) => itemLoadState.reloading;

/**
 * @param {ItemLoadState} itemLoadState
 * @return {boolean} True when the item is being updated. The currently loaded
 *   item remains available.
 */
export const getItemLoadStateUpdating = (itemLoadState) => itemLoadState.updating;

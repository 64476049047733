import { DxSchemaBase } from '../utils/dxSchema/DxSchemaBase';

// Note this was originally generated by the WebpackDuxisPlugin
import config from '../dxSchemaConfig';

if (!config) {
  throw new Error('The `config` object is undefined [in react-frontend/dxSchema].');
}

if (!config.schema) {
  console.warn(
    'The `react-frontend/dxSchema` module was imported, but `config.schema` is undefined. '
    + 'Make sure that `includeSchema` is `true` in the options for the `WebpackDuxisPlugin` '
    + 'in your `webpack.config.js` file.'
  );
}

export const dxSchema = new DxSchemaBase(config.schema, { validate: false });

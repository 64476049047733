import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import invariant from 'invariant';
import {
  Activity, isActivity, ScopedAuthRule, UserBase
} from '../../utils/auth';

import { isAuthorized } from './Authorizer';

// -- Setup --------------- --- --  -

let infoDeprecatedReported = false;

// -- User Class --------------- --- --  -

export class User extends UserBase {
  /**
   * @param {string} id - The ID of the user in the Duxis context.
   * @param {string} dxToken - The dxAuth token.
   * @param {Array<string>} genericRights - The activities this user is generically authorized for.
   * @param {ScopedRights} [scopedRights = {}] - The scoped authorization rules for this user.
   * @param {{}} [payload] - Arbitrary payload, the exact content of which depends on the identity
   *   provider and other parameters such as the OAuth-scope. When using the Auth0 identity
   *   provider, the content of this object is the payload of the ID-token.
   *
   * @todo: Consider refactoring the arguments.
   */
  constructor({
    id, dxToken, genericRights = [], scopedRights = {}, payload = {}
  }) {
    super(id);
    invariant(isString(dxToken), `Expected string as "dxToken", got "${dxToken}".`);
    invariant(isArray(genericRights), `Expected array as "genericRights", got "${genericRights}".`);
    invariant(isObject(scopedRights), `Expected object as "scopedRights", got "${scopedRights}".`);
    invariant(isObject(payload), `Expected object as "payload", got "${payload}".`);

    this._dxToken = dxToken;
    this._genericRights = genericRights;
    this._scopedRights = scopedRights;
    this._payload = payload;
  }

  // -- Accessors --------------- --- --  -

  /**
   * @return {string} The dxAuth token.
   */
  get dxToken() { return this._dxToken; }

  /**
   * @return {string} The generic rights for this user.
   */
  get genericRights() { return this._genericRights; }

  /**
   * @deprecated
   */
  get info() {
    if (!infoDeprecatedReported) {
      infoDeprecatedReported = true;
      console.warn('The "User.info" property is deprecated, use "User.payload" instead.');
    }
    return this._payload;
  }

  /**
   * @return {object} User details, the exact content of which depends on the identity provider and
   *   other parameters such as the OAuth-scope. When using the Auth0 identity provider, the content
   *   of this object is the payload of the ID-token.
   */
  get payload() { return this._payload; }

  /**
   * @return {ScopedRights} The scoped rights for this user.
   */
  get scopedRights() { return this._scopedRights; }

  // -- Access Control Methods --------------- --- --  -

  /**
   * @abstract
   * @param {string|Activity} activity - The activity to check the authorization for.
   * @returns {boolean} True when this user is authorized for the activity with the given index.
   */
  isAuthorizedForGenericActivity(activity) {
    // console.log(`>>> User.isAuthorizedForGenericActivity(${activity})`);
    if (isString(activity)) {
      return this.genericRights.includes(activity);
    }
    if (isActivity(activity)) {
      return this.genericRights.includes(activity.activityString);
    }

    throw new Error(`Expected string or Activity as "activity", got "${activity}".`);
  }

  /**
   * @abstract
   * @param {string} scope - The ID of the root scope collection to check the authorization for.
   * @param {string} topic - The topic or topics we want to take action upon.
   * @param {string|Activity} activity - The activity to check the authorization for.
   * @return {boolean} Returns if the user is authorized for the activity with the given activity in
   *   the given context for the given topic.
   */
  isAuthorizedForScopedActivity(scope, topic, activity) {
    // console.log(`>>> User.isAuthorizedForScopedActivity(${scope}, ${topic}, ${activity})`);
    const scopeRights = this.scopedRights[scope];
    if (scopeRights) {
      const topicRights = scopeRights[topic];
      // console.log('- topicRights:', topicRights);
      if (topicRights) {
        if (isString(activity)) {
          return topicRights.includes(activity);
        }
        if (isActivity(activity)) {
          // console.log('- activity.activityString:', activity.activityString);
          return topicRights.includes(activity.activityString);
        }

        throw new Error(`Expected string or Activity as "activity", got "${activity}".`);
      }
    }
    return false;
  }

  /**
   * @param {AuthRule} rule
   * @return {boolean} True when this user is authorized for the given rule.
   */
  can(rule) {
    // console.log('[can]', { rule });
    const ctx = { explain: false }; // set to true for additional debug info
    return isAuthorized(this, rule, ctx);
  }

  /**
   * @param {CollectionSchema} collSchema
   * @return {boolean} True when this user is authorized to create items in the given collection.
   */
  canCreate(collSchema) {
    /*
    return isAuthorized(this, collSchema.hasLegacyActivities
      ? collSchema.auth.create
      : {
        or: [
          new Activity(collSchema.id, 'manage'),
          new Activity(collSchema.id, 'create_own'),
        ],
      },
    );
    */

    const rule = collSchema.hasLegacyActivities
      ? collSchema.auth.create
      : {
        or: [
          new Activity(collSchema.id, 'manage'),
          new Activity(collSchema.id, 'create_own'),
        ],
      };
    return this.can(rule);
  }

  /**
   * @param {CollectionSchema} collSchema
   * @param {string} [itemId] Optional item identifier. When give, also asserts scoped rights.
   * @return {boolean} True when this user is authorized to delete items from the given collection.
   */
  canDelete(collSchema, itemId) {
    /*
    if (collSchema.hasLegacyActivities) {
      return isAuthorized(this, itemId
        ? new ScopedAuthRule(collSchema.auth.delete, collSchema.id, itemId)
        : collSchema.auth.delete);
    }
    return isAuthorized(this, itemId
      ? new ScopedAuthRule(new Activity(collSchema.id, 'manage'), collSchema.id, itemId)
      : new Activity(collSchema.id, 'manage'));
    */

    const rule = collSchema.hasLegacyActivities
      ? collSchema.auth.delete
      : new Activity(collSchema.id, 'manage');
    return this.can(itemId ? new ScopedAuthRule(rule, collSchema.id, itemId) : rule);
  }

  /**
   * @param {CollectionSchema} collSchema
   * @param {string} [itemId] Optional item identifier. When give, also asserts scoped rights.
   * @return {boolean} True when this user is authorized to manage items from the given collection.
   */
  canManage(collSchema, itemId) {
    /*
    if (collSchema.hasLegacyActivities) {
      const rule = { and: [collSchema.auth.create, collSchema.auth.delete] };
      return itemId
        ? isAuthorized(this, new ScopedAuthRule(rule, collSchema.id, itemId))
        : isAuthorized(this, rule);
    }
    return isAuthorized(this, itemId
      ? new ScopedAuthRule(new Activity(collSchema.id, 'manage'), collSchema.id, itemId)
      : new Activity(collSchema.id, 'manage'));
    */

    const rule = collSchema.hasLegacyActivities
      ? { and: [collSchema.auth.create, collSchema.auth.delete] }
      : new Activity(collSchema.id, 'manage');
    return this.can(itemId ? new ScopedAuthRule(rule, collSchema.id, itemId) : rule);
  }

  /**
   * @param {CollectionSchema} collSchema
   * @param {string} [itemId] Optional item identifier. When give, also asserts scoped rights.
   * @return {boolean} True when this user is authorized to update the items in the given collection.
   */
  canUpdate(collSchema, itemId) {
    /*
    if (collSchema.hasLegacyActivities) {
      return isAuthorized(this, itemId
        ? new ScopedAuthRule(collSchema.auth.update, collSchema.id, itemId)
        : collSchema.auth.update);
    }
    return isAuthorized(this, itemId
      ? new ScopedAuthRule(new Activity(collSchema.id, 'update'), collSchema.id, itemId)
      : new Activity(collSchema.id, 'update'));
    */

    const rule = collSchema.hasLegacyActivities
      ? collSchema.auth.update
      : new Activity(collSchema.id, 'update');
    return this.can(itemId ? new ScopedAuthRule(rule, collSchema.id, itemId) : rule);
  }

  /**
   * @param {CollectionSchema} collSchema
   * @param {string} [itemId] Optional item identifier. When give, also asserts scoped rights.
   * @return {boolean} True when this user is authorized to view the items in the given collection.
   */
  canView(collSchema, itemId) {
    /*
    if (collSchema.hasLegacyActivities) {
      console.log('[hasLegacyActivities = true]', { itemId, collSchema });
      return isAuthorized(this, itemId
        ? new ScopedAuthRule(collSchema.auth.view, collSchema.id, itemId)
        : collSchema.auth.view, { explain: true });
    }
    console.log('[hasLegacyActivities = false]', { itemId, collSchema });
    return isAuthorized(this, itemId
      ? new ScopedAuthRule(new Activity(collSchema.id, 'view'), collSchema.id, itemId)
      : new Activity(collSchema.id, 'view'), { explain: true });
    */

    const rule = collSchema.hasLegacyActivities
      ? collSchema.auth.view
      : new Activity(collSchema.id, 'view');
    return this.can(itemId ? new ScopedAuthRule(rule, collSchema.id, itemId) : rule);
  }
}

// -- dxQuery --------------- --- --  -

export const DX_QUERY_POSTED = 'DX_QUERY_POSTED';
export const DX_QUERY_CANCELLED = 'DX_QUERY_CANCELLED';
export const DX_QUERY_FAILED = 'DX_QUERY_FAILED';
export const DX_QUERY_SUCCESS = 'DX_QUERY_SUCCESS';

export const DX_QUERY_CONTEXT_PENDING = 'DX_QUERY_CONTEXT_PENDING';
export const DX_QUERY_CONTEXT_COMPLETE = 'DX_QUERY_CONTEXT_COMPLETE';

// -- dxAuth --------------- --- --  -

export const DX_AUTH_AUTHENTICATE = 'DX_AUTH_AUTHENTICATE';
export const DX_AUTH_AUTHENTICATED = 'DX_AUTH_AUTHENTICATED';
export const DX_AUTH_FAILED = 'DX_AUTH_FAILED';
export const DX_AUTH_SET_PROVIDER_STATE = 'DX_AUTH_SET_PROVIDER_STATE';
export const DX_AUTH_SIGN_OUT = 'DX_AUTH_SIGN_OUT';
export const DX_AUTH_SIGNED_OUT = 'DX_AUTH_SIGNED_OUT';

// -- dxDMS --------------- --- --  -

export const DX_DMS_ITEM_FETCH = 'DX_DMS_ITEM_FETCH';
export const DX_DMS_ITEM_FETCH_FAILED = 'DX_DMS_ITEM_FETCH_FAILED';
export const DX_DMS_ITEM_FETCH_SUCCESS = 'DX_DMS_ITEM_FETCH_SUCCESS';

export const DX_DMS_ITEM_UPDATE = 'DX_DMS_ITEM_UPDATE';
export const DX_DMS_ITEM_UPDATE_COMMIT = 'DX_DMS_ITEM_UPDATE_COMMIT';
export const DX_DMS_ITEM_UPDATE_FAILED = 'DX_DMS_ITEM_UPDATE_FAILED';
export const DX_DMS_ITEM_UPDATE_SUCCESS = 'DX_DMS_ITEM_UPDATE_SUCCESS';

export const DX_DMS_ITEM_CREATE_COMMIT = 'DX_DMS_ITEM_CREATE_COMMIT';
export const DX_DMS_ITEM_CREATE_FAILED = 'DX_DMS_ITEM_CREATE_FAILED';
export const DX_DMS_ITEM_CREATE_INIT = 'DX_DMS_ITEM_CREATE_INIT';
export const DX_DMS_ITEM_CREATE_SUCCESS = 'DX_DMS_ITEM_CREATE_SUCCESS';

export const DX_DMS_ITEM_DELETE = 'DX_DMS_ITEM_DELETE';
export const DX_DMS_ITEM_DELETE_FAILED = 'DX_DMS_ITEM_DELETE_FAILED';
export const DX_DMS_ITEM_DELETE_SUCCESS = 'DX_DMS_ITEM_DELETE_SUCCESS';

export const DX_DMS_ITEM_DESELECT = 'DX_DMS_ITEM_DESELECT';
export const DX_DMS_ITEM_SELECT = 'DX_DMS_ITEM_SELECT';

export const DX_DMS_ITEMS_DESELECT_ALL = 'DX_DMS_ITEMS_DESELECT_ALL';
export const DX_DMS_ITEMS_SELECT_ALL = 'DX_DMS_ITEMS_SELECT_ALL';

export const DX_DMS_BULK_ACTION_INIT = 'DX_DMS_BULK_ACTION_INIT';
export const DX_DMS_BULK_ACTION_APPLY = 'DX_DMS_BULK_ACTION_APPLY';

export const DX_DMS_MOUNT = 'DX_DMS_MOUNT';

export const DX_DMS_PAGE_FETCH = 'DX_DMS_PAGE_FETCH';
export const DX_DMS_PAGE_FETCH_FAILED = 'DX_DMS_PAGE_FETCH_FAILED';
export const DX_DMS_PAGE_FETCH_SUCCESS = 'DX_DMS_PAGE_FETCH_SUCCESS';

export const DX_DMS_RELATEE_OPTIONS_FETCH = 'DX_DMS_RELATEE_OPTIONS_FETCH';
export const DX_DMS_RELATEE_OPTIONS_FETCH_FAILED = 'DX_DMS_RELATEE_OPTIONS_FETCH_FAILED';
export const DX_DMS_RELATEE_OPTIONS_FETCH_SUCCESS = 'DX_DMS_RELATEE_OPTIONS_FETCH_SUCCESS';

export const DX_DMS_SELECTED_ITEMS_PAGE_FETCH = 'DX_DMS_SELECTED_ITEMS_PAGE_FETCH';
export const DX_DMS_SELECTED_ITEMS_PAGE_FETCH_FAILED = 'DX_DMS_SELECTED_ITEMS_PAGE_FETCH_FAILED';
export const DX_DMS_SELECTED_ITEMS_PAGE_FETCH_SUCCESS = 'DX_DMS_SELECTED_ITEMS_PAGE_FETCH_SUCCESS';

// -- dxTasks --------------- --- --  -

export const DX_TASKS_FETCH = 'DX_TASKS_FETCH';
export const DX_TASKS_RECEIVE = 'DX_TASKS_RECEIVE';

// -- dxModals --------------- --- --  -

export const DX_MODAL_ADD = 'DX_MODAL_ADD';
export const DX_MODAL_CLEAR = 'DX_MODAL_CLEAR';
export const DX_MODAL_CONFIRM = 'DX_MODAL_CONFIRM';
export const DX_MODAL_REMOVE = 'DX_MODAL_REMOVE';

// -- Items and Itemsets --------------- --- --  -

export const DX_ITEM_CREATE_COMMIT = 'DX_ITEM_CREATE_COMMIT';
export const DX_ITEM_EDIT_COMMIT = 'DX_ITEM_EDIT_COMMIT';

export default {
  schema: {
    docType: { id: 'dx/auth/schema.json', schema: 'http://duxis.io/schemas/dx/2.0/schema.json' },
    collections: {
      auth_activities: {
        description: 'Represents an activity or resource for which users can be separately authorized.',
        type: 'dx/auth/activity',
        label: 'Activities',
        store: 'authStore',
        apiTerms: { singular: 'activity', plural: 'activities' },
        fields: {
          id: { type: 'duxis/string', label: 'Item ID', constraints: { immutable: true, unique: true } },
          index: {
            description: 'The index of the activity used to create a compact representation of the rights of a user.', type: 'duxis/int', label: 'Index', constraints: { immutable: true, unique: true }
          },
          description: {
            type: 'duxis/string', label: 'Description', filtering: { searchableText: true }, orderable: false, constraints: {}
          },
          data: {
            description: 'Arbitrary data.', type: 'duxis/json', label: 'Arbitrary Data', constraints: {}
          },
          created_by: { type: 'duxis/string', label: 'Created by', constraints: { controlled: 'duxis/createdBy', maxLength: 64 } },
          created_on: { type: 'duxis/date', label: 'Created on', constraints: { controlled: 'duxis/createdOn' } },
          updated_by: { type: 'duxis/string', label: 'Updated by', constraints: { controlled: 'duxis/updatedBy', maxLength: 64 } },
          updated_on: { type: 'duxis/date', label: 'Updated on', constraints: { controlled: 'duxis/updatedOn' } }
        },
        constraints: { required: ['id', 'index'] },
        defaultOrdering: [{ field: 'id', nulls: 'last' }],
        auth: { scopes: [] },
        indices: []
      },
      auth_clients: {
        type: 'dx/auth/client',
        label: 'Clients',
        store: 'authStore',
        apiTerms: { singular: 'client', plural: 'clients' },
        fields: {
          id: { type: 'duxis/uuid', label: 'Item ID', constraints: { controlled: 'duxis/itemId' } },
          name: { type: 'duxis/string', label: 'Name.', constraints: { unique: true } },
          description: { type: 'duxis/string', label: 'Description', constraints: {} },
          data: {
            description: 'Arbitrary data.', type: 'duxis/json', label: 'Arbitrary Data', constraints: {}
          },
          created_by: { type: 'duxis/string', label: 'Created by', constraints: { controlled: 'duxis/createdBy', maxLength: 64 } },
          created_on: { type: 'duxis/date', label: 'Created on', constraints: { controlled: 'duxis/createdOn' } },
          updated_by: { type: 'duxis/string', label: 'Updated by', constraints: { controlled: 'duxis/updatedBy', maxLength: 64 } },
          updated_on: { type: 'duxis/date', label: 'Updated on', constraints: { controlled: 'duxis/updatedOn' } }
        },
        constraints: { required: ['name'] },
        defaultOrdering: [{ field: 'name', nulls: 'last' }],
        auth: { scopes: [] },
        indices: []
      },
      auth_roles: {
        description: 'Represents a group of activities for which users can be authorized.',
        type: 'dx/auth/role',
        label: 'Roles',
        store: 'authStore',
        apiTerms: { singular: 'role', plural: 'roles' },
        fields: {
          id: { type: 'duxis/string', label: 'Item ID', constraints: { immutable: true, unique: true } },
          description: {
            type: 'duxis/string', label: 'Description', filtering: { searchableText: true }, constraints: {}
          },
          data: {
            description: 'Arbitrary data.', type: 'duxis/json', label: 'Arbitrary Data', constraints: {}
          },
          created_by: { type: 'duxis/string', label: 'Created by', constraints: { controlled: 'duxis/createdBy', maxLength: 64 } },
          created_on: { type: 'duxis/date', label: 'Created on', constraints: { controlled: 'duxis/createdOn' } },
          updated_by: { type: 'duxis/string', label: 'Updated by', constraints: { controlled: 'duxis/updatedBy', maxLength: 64 } },
          updated_on: { type: 'duxis/date', label: 'Updated on', constraints: { controlled: 'duxis/updatedOn' } }
        },
        constraints: { required: ['id'] },
        defaultOrdering: [{ field: 'id', nulls: 'last' }],
        auth: { scopes: [] },
        indices: []
      },
      auth_users: {
        type: 'dx/auth/user',
        label: 'Users',
        store: 'authStore',
        apiTerms: { singular: 'user', plural: 'users' },
        fields: {
          id: { type: 'duxis/uuid', label: 'Item ID', constraints: { controlled: 'duxis/itemId' } },
          provider: {
            constraints: {},
            defaultValue: 'auth0',
            hidden: true,
            label: 'Authentication Type',
            type: 'duxis/string',
            typeName: 'AuthType',
          },
          enabled: {
            description: 'False when this account is disabled.', hidden: true, type: 'duxis/boolean', label: 'Enabled', defaultValue: true, constraints: {}
          },
          username: { type: 'duxis/string', label: 'Email', constraints: { unique: true } },
          data: {
            description: 'Arbitrary data.', type: 'duxis/json', label: 'Arbitrary Data', constraints: {}
          },
          last_login: { type: 'duxis/date', label: 'Last Login', constraints: {} },
          created_by: { type: 'duxis/string', label: 'Created by', constraints: { controlled: 'duxis/createdBy', maxLength: 64 } },
          created_on: { type: 'duxis/date', label: 'Created on', constraints: { controlled: 'duxis/createdOn' } },
          updated_by: { type: 'duxis/string', label: 'Updated by', constraints: { controlled: 'duxis/updatedBy', maxLength: 64 } },
          updated_on: { type: 'duxis/date', label: 'Updated on', constraints: { controlled: 'duxis/updatedOn' } }
        },
        constraints: { required: ['id', 'provider', 'username'] },
        defaultOrdering: [{ field: 'username', nulls: 'last' }],
        auth: { scopes: [] },
        indices: []
      },
      auth_scoped_rights: {
        description: 'Represents the rights a user can perform on a certain item.',
        type: 'dx/auth/scoped_right',
        label: 'Scoped rights',
        store: 'authStore',
        apiTerms: { singular: 'scopedRight', plural: 'scopedRights' },
        fields: {
          id: { type: 'duxis/string', label: 'Item ID', constraints: { immutable: true, unique: true } },
          name: {
            type: 'duxis/string', label: 'Name', constraints: { unique: true }, hidden: true
          },
          item_id: { type: 'duxis/string', label: 'Item', constraints: {} }
        },
        constraints: { required: ['name', 'item_id'] },
        defaultOrdering: [{ field: 'name', nulls: 'last' }],
        auth: { scopes: [] },
        indices: []
      },
      auth_scopes: {
        type: 'dx/auth/scope',
        label: 'Scopes',
        store: 'authStore',
        apiTerms: { singular: 'scope', plural: 'scopes' },
        fields: {
          id: { type: 'duxis/string', label: 'Item ID', constraints: { unique: true } },
          description: {
            type: 'duxis/string', label: 'Description', filtering: { searchableText: true }, constraints: {}
          },
          data: {
            description: 'Arbitrary data.', type: 'duxis/json', label: 'Arbitrary Data', constraints: {}
          },
          created_by: { type: 'duxis/string', label: 'Created by', constraints: { controlled: 'duxis/createdBy', maxLength: 64 } },
          created_on: { type: 'duxis/date', label: 'Created on', constraints: { controlled: 'duxis/createdOn' } },
          updated_by: { type: 'duxis/string', label: 'Updated by', constraints: { controlled: 'duxis/updatedBy', maxLength: 64 } },
          updated_on: { type: 'duxis/date', label: 'Updated on', constraints: { controlled: 'duxis/updatedOn' } }
        },
        constraints: { required: ['id'] },
        defaultOrdering: [{ field: 'id', nulls: 'last' }],
        auth: { scopes: [] },
        indices: []
      }
    },
    relations: {
      auth_activities_roles: {
        type: 'duxis/manyToMany',
        store: 'authStore',
        left: {
          collection: 'auth_activities', field: 'roles', label: 'Roles', constraints: {}
        },
        right: {
          collection: 'auth_roles', field: 'activities', label: 'Activities', constraints: {}
        },
        auth: {}
      },
      auth_roles_scopes: {
        type: 'duxis/oneToMany',
        store: 'authStore',
        left: {
          collection: 'auth_roles', field: 'scopes', label: 'Scopes', constraints: {}
        },
        right: {
          collection: 'auth_scopes', field: 'role', label: 'Role', constraints: {}
        },
        auth: {}
      },
      auth_roles_users: {
        type: 'duxis/manyToMany',
        store: 'authStore',
        left: {
          collection: 'auth_roles', field: 'users', label: 'Users', constraints: {}
        },
        right: {
          collection: 'auth_users', field: 'roles', label: 'Roles', constraints: {}
        },
        auth: {}
      },
      auth_roles_clients: {
        type: 'duxis/manyToMany',
        store: 'authStore',
        left: {
          collection: 'auth_roles', field: 'clients', label: 'Clients', constraints: {}
        },
        right: {
          collection: 'auth_clients', field: 'roles', label: 'Roles', constraints: {}
        },
        auth: {}
      },
      auth_scoped_rights_users: {
        type: 'duxis/manyToMany',
        store: 'authStore',
        left: {
          collection: 'auth_scoped_rights', field: 'users', label: 'Users', constraints: {}
        },
        right: {
          collection: 'auth_users', field: 'scoped_rights', label: 'Scoped rights', constraints: {}
        },
        auth: {}
      },
      auth_scoped_rights_clients: {
        type: 'duxis/manyToMany',
        store: 'authStore',
        left: {
          collection: 'auth_scoped_rights', field: 'clients', label: 'Clients', constraints: {}
        },
        right: {
          collection: 'auth_clients', field: 'scoped_rights', label: 'Scoped rights', constraints: {}
        },
        auth: {}
      },
      auth_scope_scoped_rights: {
        type: 'duxis/oneToMany',
        store: 'authStore',
        left: {
          collection: 'auth_scopes', field: 'scoped_rights', label: 'Scoped rights', constraints: {}
        },
        right: {
          collection: 'auth_scoped_rights', field: 'scope', label: 'Scope', constraints: { required: true }
        },
        auth: {}
      },
      auth_role_scoped_rights: {
        type: 'duxis/oneToMany',
        store: 'authStore',
        left: {
          collection: 'auth_roles', field: 'scoped_rights', label: 'Scoped rights', constraints: {}
        },
        right: {
          collection: 'auth_scoped_rights', field: 'role', label: 'Role', constraints: { required: true }
        },
        auth: {}
      }
    }
  },
  anonActivities: []
};

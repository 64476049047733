import { dxConfig } from './react-frontend';
import { DMSConfig } from './react-frontend/dms';

import { buildCreateUserQuery } from './queries/createUserQuery';
import { buildUpdateUserQuery } from './queries/updateUserQuery';
import { buildCreateClientQuery } from './queries/createClientQuery';
import { buildUpdateClientQuery } from './queries/updateClientQuery';
import ExternalItemSelect from './components/custom/ExternalItemSelect';

export const dmsConfig = new DMSConfig({
  apiUrl: dxConfig.get('duxis.auth.apiUrl'),
  collections: [
    {
      id: 'auth_activities',
      disableDelete: true,
      disableCreate: true,
      disableUpdate: true,
      hideListItem: true,
      viewSchema: {
        exclude: [
          'created_by',
          'created_on',
          'updated_by',
          'updated_on',
        ],
      },
    },
    {
      hideListItem: true,
      id: 'auth_clients',
      queries: {
        createItem: buildCreateClientQuery,
        updateItem: buildUpdateClientQuery,
      },
      showListItemDelete: true,
      viewSchema: {
        exclude: [
          'created_by',
          'created_on',
          'updated_by',
          'updated_on',
        ],
        includeRelatees: true,
      },
    },
    {
      id: 'auth_roles',
      disableUpdate: true,
      viewSchema: {
        exclude: [
          'id',
          'created_by',
          'created_on',
          'updated_by',
          'updated_on',
        ],
      },
    },
    {
      id: 'auth_scoped_rights',
      itemLabelFields: ['name'],
      getItemLabel: (name) => `${name}`,
      showListItemDelete: true,
      viewSchema: {
        exclude: ['id'],
        fields: [
          { id: 'name' },
          { id: 'scope' },
          { id: 'clients' },
          { id: 'users' },
          { id: 'role' },
          { id: 'item_id', component: ExternalItemSelect },
        ],
        includeRelatees: true,
      }
    },
    {
      id: 'auth_scopes',
      disableCreate: true,
      disableDelete: true,
      disableUpdate: true,
      hideListItem: true,
      viewSchema: {
        exclude: [
          'created_by',
          'created_on',
          'updated_by',
          'updated_on',
        ],
      },
    },
    {
      id: 'auth_users',
      showListItemDelete: true,
      itemLabelFields: ['username'],
      getItemLabel: (username) => `${username}`,
      queries: {
        createItem: buildCreateUserQuery,
        updateItem: buildUpdateUserQuery,
      },
      viewSchema: {
        exclude: [
          'created_by',
          'created_on',
          'last_login',
          'id',
          'updated_by',
          'updated_on',
        ],
      },
    },
  ],
});

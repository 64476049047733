import PropTypes from 'prop-types';
import React from 'react';

import { dxFormFieldControlPropTypes, dxFormSelectControlPropTypes } from '../../../propTypes';

import { FormControlFFC } from './FormControlFFC';

// -- Component --------------- ---  --  -

/**
 * Form-field-component that uses the `select` element.
 * @param {object} props
 * @returns {Function}
 * @constructor
 */
export const SelectFFC = (props) => {
  const { options, ...otherProps } = props;
  return (
    <FormControlFFC {...otherProps} componentClass="select">
      {options.map(({ id, label, value }) => (
        <option key={id} value={value}>{label || value}</option>
      ))}
    </FormControlFFC>
  );
};

SelectFFC.propTypes = {
  ...dxFormFieldControlPropTypes,
  ...dxFormSelectControlPropTypes,

  /**
   * The name-value pairs to use in the <option/> elements.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
};

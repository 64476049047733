import { FETCH_EXTERNAL_ITEMS_START, FETCH_EXTERNAL_ITEMS_SUCCESS, FETCH_EXTERNAL_ITEMS_ERROR } from '../constants/actionTypes';

export const fetchExternalItemsAction = ({
  from, limit, searchString, scopeId, value
}) => ({
  from,
  limit,
  scopeId,
  searchString,
  type: FETCH_EXTERNAL_ITEMS_START,
  value,
});

export const fetchExternalItemsSuccessAction = ({
  filteredCount, collectionId, filter, from, limit, ordering, source, data, scopeId
}) => ({
  collectionId,
  items: data,
  filter,
  filteredCount,
  from,
  limit,
  ordering,
  scopeId,
  source,
  type: FETCH_EXTERNAL_ITEMS_SUCCESS,
});

export const fetchExternalItemsErrorAction = ({ error, scopeId }) => ({
  error,
  scopeId,
  type: FETCH_EXTERNAL_ITEMS_ERROR,
});

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { isString } from '../../../utils/isString';

// -- Styles --------------- --- --  -

export const buttonsBoxStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  '> *': { marginLeft: 10 },
});

// -- Component --------------- --- --  -

export class MessageModal extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  handleClose() {
    if (this.props.onClose) { this.props.onClose(); }
    this.props.removeModal();
  }

  render() {
    const { message } = this.props;
    return (
      <div>
        {isString(message) ? <p>{message}</p> : message}
        <div {...buttonsBoxStyles}>
          <Button autoFocus onClick={this.handleClose} bsStyle="danger">Close</Button>
        </div>
      </div>
    );
  }
}

MessageModal.propTypes = {
  /** The message to show in the modal window. */
  message: PropTypes.node.isRequired,

  /** Called when the modal window is closed. */
  onClose: PropTypes.func,

  /** Injected by the Modal parent. */
  removeModal: PropTypes.func.isRequired,
};

/**
 * Thrown when the user is not authorized.
 */
export class NotAuthorizedError extends Error {
  /**
   * @param {string} message
   */
  constructor(message = 'Not Authorized.') {
    super(message);
    this.status = 403;
    this.name = 'NotAuthorizedError';
  }
}

import forIn from 'lodash/forIn';
import isArray from 'lodash/isArray';

import { asyncForIn } from '../async';

// First require 'dxTypes'
import './dxTypes';

import { blankSchema } from './blankSchema';
import { CollectionSchema } from './CollectionSchema';
import { DX_META_SCHEMA_URL_V2_0 } from './constants';
import { addConstraints } from './constraints';
import { RelationSchema } from './RelationSchema';
import { Report } from './Report';
import { normalizeRawSchema } from './utils';

/**
 * @typedef {object} ValidateItemsOpts
 * @property {boolean} [assertRequired = true] - When true then the presence of the required fields
 *   is asserted.
 */

/**
 * @private
 * @type {ValidateItemsOpts}
 */
const defaultValidateItemOpts = { assertRequired: true };

/**
 * Common base class for the service (backend) [DxSchema](cargo-service/DxSchema) class
 * and the frontend [dxSchema](react-frontend/dxSchema) object.
 */
export class DxSchemaBase {
  /**
   * @param {object} rawSchema
   * @param {boolean} [validate = true] Validate the schema.
   */
  constructor(rawSchema, { validate = true } = {}) {
    this._collections = new Map();
    this._relations = new Map();

    // Initialize data validators:
    this._fieldConstraintValidators = new Map();
    this._collConstraintValidators = new Map();
    addConstraints(this);

    // Initialize the schema as linked object structure:
    if (!rawSchema) {
      if (process.env.NODE_ENV !== 'test') {
        console.info('# Using a blank dxSchema (in DxSchemaBase)');
      }
      rawSchema = blankSchema;
    }

    /** @type {RawDxSchema} */
    this._rawSchema = normalizeRawSchema(rawSchema);

    forIn(rawSchema.collections, (rawCollSchema, id) => {
      this._collections.set(id, new CollectionSchema(id, rawCollSchema, this));
    });
    forIn(rawSchema.relations, (rawRelSchema, id) => {
      this._relations.set(id, new RelationSchema(id, rawRelSchema, this));
    });
    if (validate) {
      const report = this.validate();
      if (!report.valid) {
        const msg = `The initialized schema is not valid. ${report.text}`;
        console.error(msg);
        throw new Error(msg);
      }
    }
  }

  // -- Initializers --------------- ---  --  -

  addFieldConstraintValidator(constraint, validator) {
    this._fieldConstraintValidators.set(constraint, validator);
  }

  addCollectionConstraintValidator(constraint, validator) {
    this._collConstraintValidators.set(constraint, validator);
  }

  // -- Basic Getters --------------- ---  --  -

  /**
   * @return {boolean} True.
   */
  get isDxSchema() { return true; }

  get docType() { return this._rawSchema.docType; }

  /**
   * @returns {object} The raw schema object.
   */
  get rawSchema() { return this._rawSchema; }

  // -- Accessors --------------- ---  --  -

  /**
   * @returns {string[]} An array with the collection ids.
   */
  get collectionIds() { return [...this._collections.keys()]; }

  /**
   * @returns {Array.<CollectionSchema>} The collections in this dxSchema.
   */
  get collections() { return [...this._collections.values()]; }

  /**
   * @param {string} collId
   * @returns {boolean} True when the schema has a collection with the given id.
   */
  hasCollection(collId) {
    return this._collections.has(collId);
  }

  /**
   * Throws an error when there is no collection with the given id.
   * @param {string} collId
   */
  assertCollection(collId) {
    if (!this.hasCollection(collId)) {
      throw new RangeError(`There is no collection with ID "${collId}".`);
    }
  }

  /**
   * @param {string} collId
   * @returns {CollectionSchema} The schema for the collection with the given id.
   */
  getCollectionSchema(collId) {
    return this.getCollection(collId);
  }

  /**
   * @param {string} collId
   * @returns {CollectionSchema} The schema for the collection with the given id.
   */
  getCollection(collId) {
    this.assertCollection(collId);
    return this._collections.get(collId);
  }

  /**
   * @callback ForEachCollectionCallback
   * @param {CollectionSchema} collection
   * @param {string} id - The collection identifier.
   */

  /**
   * Calls the given callback function once for each collection in the schema, passing two
   * arguments: 1) the collection schema, and 2) the collection id.
   * @param {ForEachCollectionCallback} callback - Function to execute for each collection.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  forEachCollection(callback, thisArg) {
    this._collections.forEach(callback, thisArg);
  }

  /**
   * Calls the given asynchronous callback function once for each collection in the schema, passing
   * two arguments: 1) the collection schema, and 2) the collection id.
   * @param {ForEachCollectionCallback} callback - Asynchronous function to execute for each
   *   collection.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   * @return {DxSchemaBase} This dxSchema.
   */
  async asyncForEachCollection(callback) {
    await asyncForIn(this._collections, callback);
    return this;
  }

  reduceCollectionSchemas(callback, initialValue) {
    return [...this._collections.values()].reduce(callback, initialValue);
  }

  reduceCollections(callback, initialValue) {
    return [...this._collections.values()].reduce(callback, initialValue);
  }

  // -- Field accessors --------------- ---  --  -

  /**
   * @param {string} collId
   * @param {string} fieldId
   * @returns {FieldSchema} The schema for the given field.
   */
  getFieldSchema(collId, fieldId) {
    return this.getCollection(collId).getField(fieldId);
  }

  /**
   * @param {string} collId
   * @param {string} fieldId
   * @returns {FieldSchema} The schema for the given field.
   */
  getField(collId, fieldId) {
    return this.getCollection(collId).getField(fieldId);
  }

  /**
   * Calls the given callback function once for each field in the given collection, passing two
   * arguments: 1) the field schema, and 2) the field id.
   * @param {string} collId - The collection identifier.
   * @param {Function.<FieldSchema, string>} callback - Function to execute for each element.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  forEachField(collId, callback, thisArg) {
    this.getCollection(collId).forEachField(callback, thisArg);
  }

  /**
   * Calls the given async delegate function once for each field in the given collection, passing two
   * arguments: 1) the field schema, and 2) the field id.
   * @param {string} collId - The collection identifier.
   * @param {Function} delegate - Function to execute for each element.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  async asyncForEachField(collId, delegate, thisArg) {
    await this.getCollection(collId).asyncForEachField(delegate, thisArg);
  }

  /**
   * @param {string} collId
   * @param {string} fieldId
   * @returns {boolean} True when the given field is required.
   */
  isRequired(collId, fieldId) {
    return this.getField(collId, fieldId).isRequired;
  }

  // -- Relations accessors --------------- ---  --  -

  /**
   * @param {string} relId
   * @returns {boolean} True when the schema has a relation with the given id.
   */
  hasRelation(relId) {
    return this._relations.has(relId);
  }

  /**
   * Throws an error when there is no relation with the given id.
   * @param {string} relId
   */
  assertRelation(relId) {
    if (!this.hasRelation(relId)) {
      throw new RangeError(`There is no relation with id "${relId}".`);
    }
  }

  /**
   * @param {string} collId - The collection id.
   * @param {string} fieldId - The id of the relatee field.
   * @throws {Error} When the given relatee does not have an arity of one or does not exist.
   */
  assertArityOne(collId, fieldId) {
    this.getRelatee(collId, fieldId).assertArityOne();
  }

  /**
   * @param {string} collId - The collection id.
   * @param {string} fieldId - The id of the relatee field.
   * @throws {Error} When the given relatee has an arity of one or does not exist.
   */
  assertArityMany(collId, fieldId) {
    this.getRelatee(collId, fieldId).assertArityMany();
  }

  /**
   * @param {string} relId
   * @returns {RelationSchema} The schema for the given field.
   */
  getRelationSchema(relId) {
    this.assertRelation(relId);
    return this._relations.get(relId);
  }

  /**
   * @param {string} relId
   * @returns {RelationSchema} The schema for the given field.
   */
  getRelation(relId) {
    this.assertRelation(relId);
    return this._relations.get(relId);
  }

  /**
   * @param {string} collId - The collection id.
   * @param {string} fieldId - The id of the relatee field.
   * @return {RelateeSchema}
   */
  getRelateeSchema(collId, fieldId) {
    return this.getCollection(collId).getRelatee(fieldId);
  }

  /**
   * @param {string} collId - The collection id.
   * @param {string} fieldId - The id of the relatee field.
   * @return {RelateeSchema}
   */
  getRelatee(collId, fieldId) {
    return this.getCollection(collId).getRelatee(fieldId);
  }

  /**
   * @callback ForEachRelationCallback
   * @param {RelationSchema} relation
   * @param {string} id - The relation identifier.
   */

  /**
   * Calls the given callback function once for each relation in the schema, passing two arguments:
   * 1) the relation schema, and 2) the relation id.
   * @param {ForEachRelationCallback} callback - Function to execute for each relation.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   * @return {DxSchemaBase} This dxSchema.
   */
  forEachRelation(callback, thisArg) {
    this._relations.forEach(callback, thisArg);
    return this;
  }

  /**
   * Calls the given asynchronous callback function once for each relation in the schema, passing
   * two arguments: 1) the relation schema, and 2) the relation id.
   * @param {ForEachRelationCallback} callback - Asynchronous function to execute for each relation.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   * @return {DxSchemaBase} This dxSchema.
   */
  async asyncForEachRelation(callback) {
    await asyncForIn(this._relations, callback);
    return this;
  }

  // -- Methods --------------- ---  --  -

  /**
   * For each field in the schema for which there is no value in the given fields object and for
   * which no `controlled` constraint applies, an entry is added in the fields object, using the
   * default value, if one is specified in the schema, or null if not.
   * @param {string} collId - The collection id.
   * @param {object.<string, *>} fields - field-id to field-value mapping
   * @returns {object.<string, *>} fields - field-id to field-value mapping
   */
  normalizeFields(collId, fields) {
    return this.getCollection(collId).normalizeFields(fields);
  }

  /**
   * Serialize the items according to their field types.
   * @param {string} collId - The collection id.
   * @param {Array.<object.<string, *>>} items - array of field-id to field-value mapping
   * @returns {Array.<object.<string, *>>} items - array of field-id to field-value mapping
   */
  serializeItems(collId, items) {
    return items.map((fields) => this.serializeFields(collId, fields));
  }

  /**
   * Deserialize the items according to their field types.
   * @param {string} collId - The collection id.
   * @param {Array.<object.<string, *>>} items - array of field-id to field-value mapping
   * @returns {Array.<object.<string, *>>} items - array of field-id to field-value mapping
   */
  deserializeItems(collId, items) {
    return items.map((fields) => this.deserializeFields(collId, fields));
  }

  /**
   * Serialize the fields according to their type. This implementation relies on the JSON
   * serialization, except for types JSON serialization doesn't know how to deal with.
   * @param {string} collId - The collection id.
   * @param {object.<string, *>} fields - field-id to field-value mapping
   * @returns {object.<string, *>} fields - field-id to field-value mapping
   */
  serializeFields(collId, fields) {
    const results = {};
    forIn(fields, (value, fieldId) => {
      results[fieldId] = this.getField(collId, fieldId).serialize(value);
    });
    return results;
  }

  /**
   * Deserialize the fields according to their type. This implementation relies on the JSON
   * serialization, except for types JSON serialization doesn't know how to deal with.
   * @param {string} collId - The collection id.
   * @param {object.<string, *>} fields - field-id to field-value mapping
   * @returns {object.<string, *>} fields - field-id to field-value mapping
   */
  deserializeFields(collId, fields) {
    const coll = this.getCollection(collId);
    const results = {};
    forIn(fields, (value, fieldId) => {
      if (coll.hasField(fieldId)) {
        results[fieldId] = coll.getField(fieldId).deserialize(value);
      } else if (coll.hasRelatee(fieldId)) {
        const relatee = coll.getRelatee(fieldId);
        results[fieldId] = this.deserializeFields(relatee.collectionId, value);
      } else {
        throw new Error(`Encountered unexpected property field "${fieldId}".`);
      }
    });
    return results;
  }

  /**
   * Validates this schema.
   * @param {Report} [report] Existing report to append to.
   * @returns {Report}
   */
  validate(report) {
    report = report || new Report();

    this.forEachCollection((collSchema, collId) => {
      // assert that either no or all legacy activities are specified for each collection:
      const hasView = collSchema.auth.view !== undefined;
      const hasCreate = collSchema.auth.create !== undefined;
      const hasUpdate = collSchema.auth.update !== undefined;
      const hasDelete = collSchema.auth.delete !== undefined;
      if (hasView || hasCreate || hasUpdate || hasDelete) {
        if (!hasView || !hasCreate || !hasUpdate || !hasDelete) {
          report.addReport('Expected either all four or no legacy activity identifiers'
            + ` for the "${collId}" collection.`);
        }
      }

      // Check the default orderings:
      if (collSchema.defaultOrdering) {
        report.addReport(this.validateOrdering(collId, collSchema.defaultOrdering));
      }
    });

    this.forEachRelation((relSchema, relId) => {
      // assert that either no or all legacy activities are specified for each relation:
      const hasView = relSchema.auth.view !== undefined;
      const hasManage = relSchema.auth.manage !== undefined;
      if (hasView || hasManage) {
        if (!hasView || !hasManage) {
          report.addError('Expected either both or no legacy activity identifiers'
            + ` for the "${relId}" relation.`);
        }
      }
    });

    this.validateScopes(report);
    return report;
  }

  /**
   * Validates a given field value. Controlled fields are ignored. Null values are accepted, except
   * for required fields.
   * @param {string} collId - the collection id
   * @param {string} fieldId - the field id
   * @param {*} value - the field value
   * @param {ValidateItemsOpts} [opts] - the options
   * @param {Report} [report] - When given then error are added in this report, else a new report
   *   is instantiated and returned.
   * @returns {Report}
   */
  validateField(collId, fieldId, value, opts, report) {
    report = report || new Report();
    const coll = this.getCollection(collId);
    if (coll.hasField(fieldId)) { // a regular field
      return this.validatePropField(collId, fieldId, value, opts, report);
    }
    if (coll.hasRelatee(fieldId)) {
      return this.validateRelateeField(collId, fieldId, value, opts, report);
    }

    throw new Error(`Unexpected field "${fieldId}" to validate.`);
  }

  validatePropField(collId, fieldId, value, opts, report) {
    report = report || new Report();
    const field = this.getField(collId, fieldId);

    // do not validate controlled fields:
    if (field.isControlled) { return report; }

    // do not validate null values, unless the field is required:
    const required = field.isRequired;
    if (value === null && !required) { return report; }

    // validate field type:
    if (!field.dxType.validate(value)) {
      if (required) {
        report.addError(`Required value must be a ${field.dxType.label}.`);
      } else if (value === undefined) {
        report.addError(`Value must be a ${field.dxType.id} or null, got undefined value.`);
      } else {
        report.addError(`Value must be a ${field.dxType.id} or null, got "${typeof value}"`);
      }
    }

    // validate field constraints:
    forIn(field.constraints, (constraint, constraintId) => {
      if (!this._fieldConstraintValidators.has(constraintId)) {
        report.addError(`Unexpected constraint "${constraintId}" in field "${fieldId}" of collection "${collId}".`);
      }
      if (!this._fieldConstraintValidators.get(constraintId)(constraint, value, opts)) {
        report.addError(`Violates the "${constraintId}" constraint.`);
      }
    });

    return report;
  }

  validateRelateeField(collId, fieldId, value, opts, report) {
    report = report || new Report();
    const relateeSchema = this.getRelatee(collId, fieldId);

    // do not validate null values, unless the field is required:
    const required = relateeSchema.isRequired;
    if (value === null && !required) { return report; }

    if (required && (value === undefined || value === null)) {
      report.addError(`"${fieldId}" is required`);
    }

    if (relateeSchema.arityMany && !isArray(value)) {
      report.addError(`"${fieldId}" must be an array`);
    }

    return report;
  }

  /**
   * Validates an item for a given collection in the given DxSchema.
   * @param {string} collId - the collection id
   * @param {object} fields - the item fields
   * @param {ValidateItemsOpts} [opts] - the options
   * @returns {Report} An object with for each
   */
  validateItem(collId, fields, opts) {
    opts = opts ? ({ ...defaultValidateItemOpts, ...opts }) : defaultValidateItemOpts;
    const report = new Report();
    try {
      const collSchema = this.getCollection(collId);

      // validate fields:
      forIn(fields, (value, fieldId) => {
        const fieldReport = this.validateField(collId, fieldId, value, opts);
        if (!fieldReport.valid) {
          report.addErrors(fieldReport.errors, `Invalid field "${fieldId}": ${fieldReport.text}`);
        }
      });

      // validate collection constraints:
      forIn(collSchema.constraints, (constraint, constraintId) => {
        if (!this._collConstraintValidators.has(constraintId)) {
          throw new Error(`Unexpected constraint "${constraintId}".`);
        }
        const addError = (msg) => report.addError(`${msg} for collection "${collId}".`);
        this._collConstraintValidators.get(constraintId)(constraint, fields, addError, opts);
      });
    } catch (error) {
      report.addError(error.message);
    }
    return report;
  }

  /**
   * Validates if the scopes are well formed.
   */
  validateScopes() {
    if (this.docType.schema === DX_META_SCHEMA_URL_V2_0) {
      const scopeIds = new Set();
      this.forEachCollection((coll) => {
        coll.authScopes.forEach(({
          extends: extends_, field, id, key
        }) => {
          if (scopeIds.has(id)) {
            throw new Error(`Found multiple scopes with the same id "${id}".`);
          }
          scopeIds.add(id);
          if (extends_) {
            if (!this.hasCollection(extends_)) {
              throw new Error(`Found an unknown parent collection "${extends_}"`
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
            if (extends_ === coll.id) {
              console.warn(`Self-referencing scope extensions are untested.`);
            }
            if (!field) {
              throw new Error('Missing `field`'
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
            if (!coll.hasRelatee(field)) {
              throw new Error(`Found an unknown field "${field}"`
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
            const relateeSchema = coll.getRelatee(field);
            if (relateeSchema.coCollectionId !== extends_) {
              throw new Error(`Found an inconsistent field "${field}"`
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
            if (!relateeSchema.relation.isOneToMany) {
              throw new Error(`Expected a one-to-many relation`
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
            if (!relateeSchema.constraints.required) {
              throw new Error(`Expected a relatee with a "required" constraint`
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
            const parentColl = relateeSchema.coCollection;
            if (!parentColl.hasScopes) {
              throw new Error('Found no scopes in the parent collection'
                + ` for the scope extension "${id}" for collection "${coll.id}".`);
            }
          } else {
            if (coll.authScopes.length > 1) {
              throw new Error(`Found more than one (root) scopes for collection "${coll.id}".`);
            }
            if (!coll.hasField(key)) {
              throw new Error(`Found an unknown key "${key}"`
                + ` in the root scope for collection "${coll.id}".`);
            }
            const fieldSchema = coll.getField(key);
            if (key !== 'id' && !fieldSchema.isUnique) {
              throw new Error(`Found a key ("${key}") without a "unique" constraint`
                + ` in the root scope "${id}" for collection "${coll.id}".`);
            }
          }
        });
      });
    }
  }

  /**
   * @param {string} collId - the collection id
   * @param {Array.<OrderField>} ordering - the ordering specification
   * @returns {Report}
   */
  validateOrdering(collId, ordering) {
    return this.getCollection(collId).validateOrdering(ordering);
  }

  /**
   * Throws an error if the given ordering spec is not valid.
   * @param {string} collId - the collection id
   * @param {Array.<OrderField>} ordering - the ordering specification
   */
  assertOrdering(collId, ordering) {
    return this.getCollection(collId).assertOrdering(ordering);
  }

  /**
   * Helper function that, given a set of fields (typically obtained from redux-form on submit),
   * returns a new set from which the fields that should not be submitted to the server, are removed.
   * @param {string} collId - the collection id
   * @param {object} inFields
   * @param {string} mode - either 'create' (when creating a new item) or 'update' (when updating an
   *   existing item.
   * @returns {object}
   */
  filterFields(collId, inFields, mode) {
    return this.getCollection(collId).filterFields(inFields, mode);
  }
}

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { dxFormFieldViewPropTypes, dxFormInputControlPropTypes } from '../../propTypes';

import { InputFFC } from './formFieldComponents/InputFFC';

// -- Component --------------- ---  --  -

/**
 * A field for an email address.
 */
export class EmailField extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.editMode !== nextProps.editMode;
  }

  render() {
    // console.log('[EmailField] render');
    const {
      fieldId, fieldProps, fieldSchema, value, ...otherProps
    } = this.props;

    const childProps = {
      baseValue: value,
      className: 'dx-field dx-email-field',
      component: InputFFC,
      controlId: `input-${fieldId}`,
      inputMode: 'email',
      name: fieldId, // identifies the value in the form
      type: 'email',
      ...otherProps,
      ...fieldProps,
    };

    return <Field {...childProps} />;
  }
}

EmailField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...dxFormInputControlPropTypes,
};

EmailField.defaultProps = {
  editMode: false,
};

/* eslint-disable no-async-promise-executor */
/* eslint-disable prefer-promise-reject-errors */
/* global LOG_DX_AUTH */

import { replace } from 'connected-react-router';
import {
  call, put, select, takeEvery, delay
} from 'redux-saga/effects';
import { dxConfig } from '../../dxConfig';

import {
  dxAuthenticatedAction,
  dxAuthFailedAction,
  dxAuthSetProviderStateAction,
  dxAuthSignedOutAction,
} from '../../actions';
import { User } from '../../auth/User';
import {
  DX_AUTH_AUTHENTICATE,
  DX_AUTH_FAILED,
  DX_AUTH_SET_PROVIDER_STATE,
  DX_AUTH_SIGN_OUT,
  SIMPLE_PASSWORD_SIGN_IN_STATE,
  SIMPLE_PASSWORD_USER_INPUT_STATE,
} from '../../constants';
import { fetchQuery, refreshTokenQuery, simpleAuthenticateQuery } from '../../queries';
import { getDxAuthProviderId, getDxAuthProviderState, getDxAuthTargetPath } from '../../selectors';

// -- Constants --------------- --- --  -

const authApiUrl = dxConfig.get('duxis.auth.apiUrl');
const PROVIDER = 'simplePassword';
const logAuth = LOG_DX_AUTH ? ((...args) => console.log('[dxAuth.simplePasswordSagas]', ...args)) : (() => null);

// -- Sagas --------------- --- --  -

/**
 * The sinple-password-based authentication master saga.
 */
export default function* () {
  if (dxConfig.get('duxis.auth.identityProviders.simplePassword.enable', false)) {
    if (dxConfig.prodMode) {
      throw new Error('The simplePassword identity provider should not be used in production.');
    }
    logAuth('Enable simplePassword sagas');
    yield takeEvery(DX_AUTH_AUTHENTICATE, authenticate);
    yield takeEvery(
      (action) => action.type === DX_AUTH_SET_PROVIDER_STATE
          && action.providerState.id === SIMPLE_PASSWORD_SIGN_IN_STATE,
      signIn
    );
    yield takeEvery(DX_AUTH_FAILED, clearLocalStorage);
    yield takeEvery(DX_AUTH_SIGN_OUT, signOut);
  }
}

function* authenticate({ providerId, targetPath }) {
  if (providerId !== PROVIDER) { return; }
  const providerState = yield select(getDxAuthProviderState);
  if (providerState) { return; }
  try {
    logAuth('Trying to restore the user...');
    const { user, expiresAt } = yield call(restoreUser);
    yield put(dxAuthenticatedAction(user, expiresAt));
    yield put(replace(targetPath));
    yield call(scheduleExpirationHandler, expiresAt);
  } catch (error) {
    logAuth(`Failed to restore the user. ${error.message}`);
    yield put(dxAuthSetProviderStateAction('simplePassword', SIMPLE_PASSWORD_USER_INPUT_STATE));
  }
}

// function* signIn({ providerId, providerState: { password, username } }) {
function* signIn(action) {
  if (!action) { throw new Error('action is undefined'); }
  const { providerId, providerState: { password, username } } = action;
  if (providerId !== PROVIDER) { return; }
  if (!username) { throw new Error('The username action parameter is missing.'); }
  if (!password) { throw new Error('The password action parameter is missing.'); }
  logAuth('Signing in...');
  try {
    const query = simpleAuthenticateQuery;
    const url = authApiUrl;
    const variables = { username, password };
    const options = { authenticate: false, url, variables };
    const {
      cancelled, data, error, tokenExpired
    } = yield fetchQuery(query, options);
    if (error) {
      return yield put(dxAuthFailedAction('Failed to authenticate the user.', error));
    }
    if (cancelled) {
      if (tokenExpired) {
        return yield put(dxAuthFailedAction(
          'The simple-password authenticate query was cancelled because the token expired.'
          + ' This should not have happened. Please notify the developers.'
        ));
      }
      return yield put(dxAuthFailedAction('The simple-password authenticate query was cancelled.'));
    }
    if (!data) {
      return yield put(dxAuthFailedAction('The simple-authenticate query yielded an empty data object.'));
    }
    const {
      expiresAt, genericRights, id, dxToken, reason, success, scopedRights
    } = data.simpleAuthenticate;
    if (!success) {
      return yield put(dxAuthFailedAction(reason));
    }

    const user = new User({
      id, dxToken, genericRights, scopedRights, payload: { username }
    });

    yield call(storeUser, id, dxToken, username);
    yield put(dxAuthenticatedAction(user, expiresAt));
    yield put(replace(yield select(getDxAuthTargetPath)));
    yield call(scheduleExpirationHandler, expiresAt);
  } catch (error) {
    const msg = 'The simplePassword.signIn saga failed.';
    console.error(msg, error);
    yield put(dxAuthFailedAction(msg, error));
  }
}

// -- Token Expiration Handling --------------- --- --  -

/**
 * @private
 * @param {number} currExpiresAt - The current expiration time.
 */
function* scheduleExpirationHandler(currExpiresAt) {
  const ms = currExpiresAt * 1000 - Date.now() - 15000; // renew 15 sec before expiration
  logAuth(`scheduling token expiration handler - ms: ${ms}`);
  if (ms <= 0) { return; }
  yield call(delay, ms);
  try {
    const { expiresAt } = yield call(restoreUser);
    yield call(scheduleExpirationHandler, expiresAt);
  } catch (error) {
    logAuth(`Failed to restore the user. ${error.message}`);
  }
}

// -- Sign-out --------------- --- --  -

/**
 * Redirect to the `targetPath` provided to `dxAuthSignOutAction` or specified as
 * `duxis.auth.signOutTargetPath` in the setup config.
 * @param {string} [targetPath]
 */
function* signOut({ targetPath }) {
  if ((yield select(getDxAuthProviderId)) !== PROVIDER) { return; }
  if (targetPath === undefined) {
    targetPath = dxConfig.get('duxis.auth.signOutTargetPath');
  }
  logAuth(`Signing out, redirecting to ${targetPath}`);
  yield call(clearLocalStorage);
  yield put(replace(targetPath));
  yield put(dxAuthSignedOutAction(PROVIDER));
}

// -- Local Helpers --------------- --- --  -

function clearLocalStorage() {
  localStorage.removeItem('dxUser');
}

const restoreUser = async () => new Promise(async (resolve, reject) => {
  logAuth('Restoring user...');
  const storedUser = JSON.parse(localStorage.getItem('dxUser'));
  if (!storedUser) { return reject('There is no stored user.'); }

  let { dxToken } = storedUser;
  const { id, username } = storedUser;

  // get fresh token:
  const options = { dxToken, url: authApiUrl };
  const { cancelled, data, error } = await fetchQuery(refreshTokenQuery, options);
  if (error) {
    return reject(new Error(`Failed to refresh the token. ${error}`));
  }
  if (cancelled) {
    return reject(new Error('The refresh-token query was cancelled.'));
  }
  if (!data) {
    return reject(new Error('The refresh-token query yielded an empty data object.'));
  }
  const { reason, success } = data.refreshToken;
  if (!success) {
    return reject(new Error(`The refresh-token query was not successful. ${reason}`));
  }

  const { expiresAt, genericRights, scopedRights } = data.refreshToken;
  ({ dxToken } = data.refreshToken);
  storeUser(id, dxToken, username);

  const user = new User({
    id, dxToken, genericRights, scopedRights, payload: { username }
  });

  resolve({ user, dxToken, expiresAt });
});

/**
 * store user locally
 */
const storeUser = (id, dxToken, username) => {
  try {
    localStorage.setItem('dxUser', JSON.stringify({ id, dxToken, username }));
  } catch (error) {
    console.warn(`Could not store the user in the local storage. ${error.message}`);
    console.warn(error);
  }
};

import isObject from 'lodash/isObject';

// -- Local Support --------------- ---  --  -

const normalizeOptions = (options = {}) => {
  // support legacy usage
  if (!isObject(options)) {
    throw new Error(`Expected an object as "options", got "${options}".`);
  }
  if (!options.exclude) return { ...options, exclude: [] };
  return options;
};

/**
 * @typedef {object} dxQueryUtilOptions
 * @property {Array.<string>} [exclude] - Optional list of field ID to exclude.
 */

// -- View Fields/Vars/Varargs --------------- ---  --  -

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getViewFields = (collectionSchema, options) => {
  const { exclude } = normalizeOptions(options);
  const fields = {};
  collectionSchema.forEachField((field, fieldId) => {
    if (!exclude.includes(fieldId)) {
      fields[fieldId] = true;
    }
  });
  return fields;
};

// -- Update Fields/Vars/Varargs --------------- ---  --  -

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getUpdateFields = (collectionSchema, options) => {
  const { exclude } = normalizeOptions(options);
  const fields = {};
  collectionSchema.forEachField((field, fieldId) => {
    if (field.isEditable && !exclude.includes(fieldId)) {
      fields[fieldId] = true;
    }
  });
  return fields;
};

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getUpdateVars = (collectionSchema, options) => {
  const { exclude, includeRelatees = true } = normalizeOptions(options);
  const vars = { id: { type: `${collectionSchema.getField('id').gqlTypeString()}!` } };
  collectionSchema.forEachField((field, fieldId) => {
    if (field.isEditable && !exclude.includes(fieldId)) {
      vars[fieldId] = { type: field.gqlTypeString() };
    }
  });
  if (includeRelatees) {
    // TODO: check if need to get isEditable etc. like regular fields
    collectionSchema.forEachRelatee((relatee, fieldId) => {
      if (!exclude.includes(fieldId)) {
        vars[fieldId] = { type: relatee.gqlTypeString() };
      }
    });
  }
  return vars;
};

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getUpdateArgs = (collectionSchema, options) => {
  const { exclude, includeRelatees = true } = normalizeOptions(options);
  const args = {};
  collectionSchema.forEachField((field, fieldId) => {
    if (field.isEditable && !exclude.includes(fieldId)) {
      args[fieldId] = fieldId;
    }
  });
  if (includeRelatees) {
    // TODO: check if need to get isEditable etc. like regular fields
    collectionSchema.forEachRelatee((relatee, fieldId) => {
      if (!exclude.includes(fieldId)) {
        args[fieldId] = fieldId;
      }
    });
  }
  return args;
};

// -- Create Fields/Vars/Varargs --------------- ---  --  -

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getCreateFields = (collectionSchema, options) => {
  const { exclude, includeRelatees = true } = normalizeOptions(options);
  const fields = {};
  collectionSchema.forEachField((field, fieldId) => {
    if (!field.isControlled && !exclude.includes(fieldId)) {
      fields[fieldId] = true;
    }
  });
  if (includeRelatees) {
    collectionSchema.forEachRelatee((relatee, fieldId) => {
      if (!exclude.includes(fieldId)) {
        fields[fieldId] = true;
      }
    });
  }
  return fields;
};

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getCreateVars = (collectionSchema, options) => {
  const { exclude, includeRelatees = true } = normalizeOptions(options);
  const vars = {};
  collectionSchema.forEachField((field, fieldId) => {
    if (!field.isControlled && !exclude.includes(fieldId)) {
      vars[fieldId] = { type: field.gqlTypeString() };
    }
  });
  if (includeRelatees) {
    collectionSchema.forEachRelatee((relatee, fieldId) => {
      if (!exclude.includes(fieldId)) {
        vars[fieldId] = { type: relatee.gqlTypeString() };
      }
    });
  }
  return vars;
};

/**
 * @param {CollectionSchema} collectionSchema
 * @param {dxQueryUtilOptions} options
 * @return {{}}
 */
export const getCreateArgs = (collectionSchema, options) => {
  const { exclude, includeRelatees = true } = normalizeOptions(options);
  const args = {};
  collectionSchema.forEachField((field, fieldId) => {
    if (!field.isControlled && !exclude.includes(fieldId)) {
      args[fieldId] = fieldId;
    }
  });
  if (includeRelatees) {
    collectionSchema.forEachRelatee((relatee, fieldId) => {
      if (!exclude.includes(fieldId)) {
        args[fieldId] = fieldId;
      }
    });
  }
  return args;
};

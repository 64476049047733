import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import { isString } from '../utils/isString';

export const ofType = (action, type) => {
  if (!isObject(action)) { return false; }
  if (isString(type)) { return action.type === type; }
  if (isArray(type)) { return type.includes(action.type); }
  if (isFunction(type)) { return type(action); }
};

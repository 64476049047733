import {
  mutationBuilder,
  getCreateArgs,
  getCreateVars,
} from '../utils/dxf';

export const buildCreateClientQuery = ({
  id, created_by, created_on, updated_by, updated_on, scoped_rights, roles, ...fields
}, { collSchema }) => {
  const varArgs = getCreateArgs(collSchema, { includeRelatees: true });
  const originalCreateVars = getCreateVars(collSchema, { includeRelatees: true });
  const createVars = {
    ...originalCreateVars,
    name: { type: 'String!' },
    roles: { type: '[String]' },
    scoped_rights: { type: '[String]' },
  };
  return mutationBuilder({
    variables: createVars,
    fields: {
      createClient: {
        varArgs,
        fields,
      },
    },
  });
};

import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import { isString } from '../../utils/isString';

/**
 * Checks if the given Redux action object is of the specified type.
 * @param {{ type: string }} action - The Redux action object.
 * @param {string|Array.<string>|Function.<string>:boolean} [type] - Either the required action type
 *   string, an array of potential action type strings, or a function that takes the given action
 *   type string and returns the boolean value that _ofType_ should return;
 * @return {boolean}
 */
export const ofType = (action, type) => {
  if (!type) { return false; }
  if (!isObject(action)) { return false; }
  if (!isString(action.type)) {
    throw new Error(`Expected a string as "type" in the given action, got "${action.type}".`);
  }
  if (isString(type)) { return action.type === type; }
  if (isArray(type)) { return type.includes(action.type); }
  if (isFunction(type)) { return type(action); }
};

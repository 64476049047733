const invariant = require('invariant');
const isBoolean = require('lodash/isBoolean');
const isObject = require('lodash/isObject');
const isString = require('lodash/isString');
const { equalShallow } = require('./equalShallow');

/**
 * "Immute" is short for "immutably mutate", which is what this utility tries to help you with.
 * You can call this utility with two or three arguments.
 *
 * - When you provide two, then they are interpreted as a "current" value and a potential "new"
 *   value. These values are shallowly compared using {@link equalShallow}. When they are shallowly
 *   equal, then the first argument is returned, else the second is returned.
 * - When three arguments are given, then the first is interpreted as a "current" object, the
 *   second is interpreted as the name of a property of that object, while the
 *   third is interpreted as the new value for that property. This new value is compared with the
 *   original property, and when they are shallowly equal then the original object is returned. Else
 *   a shallow clone of the given object with the new property is returned.
 *
 * @param {Array} args -
 * @return {*}
 * @see equalShallow
 */
export const immute = (...args) => {
  if (args.length === 2) {
    return equalShallow(args[0], args[1]) ? args[0] : args[1];
  }
  if (args.length === 3) {
    if (isBoolean(args[2]) && args[2] === true) {
      // debugging enabled
      console.info('# immute debugging:');
      console.info('  - curr:', args[0]);
      console.info('  - next:', args[1]);
      console.info('  - equalShallow:', equalShallow(args[0], args[1]));
      return equalShallow(args[0], args[1]) ? args[0] : args[1];
    }

    invariant(isObject(args[0]), 'Expected an object as the first of three arguments given to '
        + `immute, instead got "${args[0]}".`);
    invariant(isString(args[1]), 'Expected a string as the second of three arguments given to '
        + `immute, instead got "${args[1]}".`);
    return equalShallow(args[0][args[1]], args[2]) ? args[0] : { ...args[0], [args[1]]: args[2] };
  }
  throw new Error(`The "immute" function expects 2 or 3 arguments, instead got "${args}".`);
};

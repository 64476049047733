export const stopIcon = 'M29.1,9.7L20,18.8l-9.1-9.1c-0.4-0.4-1.1-0.4-1.5-0.1S9,10.6,9.4,11.1l9.1,9.1l-9.1,9.1c-0.4,0.4-0.4,1.1,0,1.4\n'
  + '\tc0.4,0.4,1.1,0.4,1.4,0l9.1-9.1l9.1,9.1c0.4,0.4,1.1,0.4,1.4,0c0.4-0.4,0.4-1.1,0-1.4l-9.1-9.1l9.1-9.1c0.4-0.4,0.4-1.1,0.1-1.5\n'
  + '\tC30.3,9.2,29.5,9.2,29.1,9.7z';

export const deleteIcon = [
  'M25.2,5.6V4.1c0-0.8-0.7-1.5-1.5-1.5h-7.4c-0.8,0-1.5,0.7-1.5,1.5v1.4H7.2c-0.4,0-0.7,0.3-0.7,0.7v0\n'
  + '\tc0,0.4,0.3,0.7,0.7,0.7h7.6h10.4h7.6c0.4,0,0.8-0.3,0.8-0.7v0c0-0.4-0.3-0.7-0.8-0.7H25.2z M16.3,5.6V4.1h7.4v1.4H16.3z',
  'M8.9,9.9l1.9,25.1c0,0.5,0.5,0.9,1,0.9h16.3c0.5,0,1-0.4,1-0.9l1.9-25.1H8.9z M24.9,11.4l-1.2,23h-7.3l-1.2-23\n'
  + '\tH24.9z M10.6,11.4h4.1l1.2,23h-3.5L10.6,11.4z M27.7,34.4h-3.5l1.2-23h4.1L27.7,34.4z'
];

import PropTypes from 'prop-types';
import React from 'react';

import { dxFormFieldControlPropTypes } from '../../../propTypes';

import { FormControlFFC } from './FormControlFFC';

// -- textAreaControlPropTypes --------------- ---  --  -

export const textAreaControlPropTypes = {
  /**
   * The optional value for the `cols` prop of the <textarea/> element.
   */
  cols: PropTypes.number,

  /**
   * The optional value for the `maxlength` prop of the <textarea/> element.
   */
  maxLength: PropTypes.number,

  /**
   * The optional value for the `minlength` prop of the <textarea/> element.
   */
  minLength: PropTypes.number,

  /**
   * The optional value for the `rows` prop of the <textarea/> element.
   */
  rows: PropTypes.number,
};

// -- Component --------------- ---  --  -

/**
 * Form-field-component that uses the `textarea` element.
 * @param {object} props
 * @returns {Function}
 * @constructor
 */
export const TextareaFFC = (props) => <FormControlFFC {...props} componentClass="textarea" />;
TextareaFFC.propTypes = {
  ...dxFormFieldControlPropTypes,
  ...textAreaControlPropTypes,
};

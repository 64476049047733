import invariant from 'invariant';

import { isString } from '../isString';

// -- UserBase Class --------------- --- --  -

/**
 * Abstract base class for the concrete _User_ classes in `cargo-service/auth` and
 * `react-frontend/auth`.
 *
 * @abstract
 */
export class UserBase {
  /**
   * @param {string} id - The user identifier.
   */
  constructor(id) {
    invariant(isString(id), `Expected a string as "id", got "${id}".`);
    this._id = id;
  }

  /**
   * @return {string} The user's ID.
   */
  get id() { return this._id; }

  /**
   * @return {boolean} True.
   */
  get isUser() { return true; }

  // -- Access Control Methods --------------- --- --  -

  /** @abstract */
  isAuthorizedForGenericActivity() {
    throw new Error('Missing implementation for User.isAuthorizedForGenericActivity().');
  }

  /** @abstract */
  isAuthorizedForScopedActivity() {
    throw new Error('Missing implementation for User.isAuthorizedForScopedActivity().');
  }

  /**
   * @abstract
   * @param {CollectionSchema} collection - The scoped collection we want the authorized topics
   *   for.
   * @param {CollectionSchema} rootCollection - The root scope collection.
   * @param {int|{ and: Array<int>|undefined, or: Array<int>|undefined }} activityIndices - The
   *   activity indices the user must be authorized for, either an single integer index, or an
   *   object with an "and" or "or" property that have an array of indices as value.
   * @param {object} [options]
   * @param {Function} [options.explain] - Optional explain function.
   * @return {Array<string>} All topic IDs from the given scope for which the user is
   *   authorized for the given activity.
   */
  getScopedTopics() {
    throw new Error('Missing implementation for User.getScopedTopics().');
  }
}

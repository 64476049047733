/**
 * An empty raw schema.
 * @type {RawDxSchema}
 */
export const blankSchema = {
  docType: {
    id: 'dxSchema/blank',
    schema: 'http://duxis.io/schemas/dx/2.0/schema.json',
  },
  collections: {},
  relations: {},
};

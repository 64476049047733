import { createSelector } from 'reselect';

const getExternalDxSchema = (state) => state.externalSchema.dxSchema;

const getExternalCollections = createSelector(
  getExternalDxSchema,
  (schema) => schema.collections,
);

export const getExternalCollection = (state, id) => (id && getExternalCollections(state)[id]) || {};

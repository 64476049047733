import axios from 'axios';
import { isString } from '../../utils/isString';

/**
 * Dispatches HTTP requests with (optional) authentication header.
 *
 * ## REST Error Handling
 *
 * When the server responds to a request with a status code that falls out of the range of 2xx, then
 * the method throws an Error object that has a `response` property with the Axios response object
 * as value. The following properties might be interesting:
 *
 * - error.response.status : The response status, e.g. 404, 500, etc.
 * - error.response.statusText : Generic error message, e.g. 'Internal Server Error'.
 * - error.response.headers : The headers of the response.
 * - error.response.data.code : The server-side error code.
 * - error.response.data.errno : The server-side error errno.
 * - error.response.data.message : The server-side error message.
 * - error.response.data.stack : The server-side error stack.
 * - error.response.data.type : The server-side error type.
 * - error.response.config.method : The request method.
 * - error.response.config.url : The request url.
 * - error.response.config.data : The request body.
 * - error.response.config.headers : The headers of the request.
 * - error.response.request.path : The request path.
 */
class DxRequest {
  /**
   * @private
   * Initialize the request singleton. This should be called once during startup.
   * @param {Function} getUser - A (thunk) function that returns the user.
   * @param {Function} getToken - A (thunk) function that returns the jwt-token for the current
   *   (authenticated) user.
   */
  initialize(getUser, getToken) {
    this._getUser = getUser;
    this._getToken = getToken;
  }

  /**
   * Dispatches a DELETE HTTP request using Axios.
   * @param {string} route - The API route.
   * @param {boolean|string} [auth = false] - When true or a string then add an Authorization header
   *   in the request. Use the given string as token or use the token in the Redux store.
   * @returns {object}
   */
  async del(route, auth) {
    return axios.delete(route, this._config(auth));
  }

  /**
   * Dispatches a GET HTTP request using Axios.
   * @param {string} route - The API route.
   * @param {boolean|string} [auth = false] - When true or a string then add an Authorization header
   *   in the request. Use the given string as token or use the token in the Redux store.
   * @returns {object}
   */
  async get(route, auth) {
    return axios.get(route, this._config(auth));
  }

  /**
   * Dispatches a HEAD HTTP request using Axios.
   * @param {string} route - The API route.
   * @param {boolean|string} [auth = false] - When true or a string then add an Authorization header
   *   in the request. Use the given string as token or use the token in the Redux store.
   * @returns {object}
   */
  async head(route, auth) {
    return axios.head(route, this._config(auth));
  }

  /**
   * Dispatches a PATCH HTTP request using Axios.
   * @param {string} route - The API route.
   * @param {*} body - The data to send in the body of the request.
   * @param {boolean|string} [auth = false] - When true or a string then add an Authorization header
   *   in the request. Use the given string as token or use the token in the Redux store.
   * @returns {object}
   */
  async patch(route, body, auth) {
    return axios.patch(route, body, this._config(auth));
  }

  /**
   * Dispatches a POST HTTP request using Axios.
   * @param {string} route - The API route.
   * @param {*} data - The data to send in the body of the request.
   * @param {boolean|string} [auth = false] - When true or a string then add an Authorization header
   *   in the request. Use the given string as token or use the token in the Redux store.
   * @returns {object}
   */
  async post(route, data, auth) {
    return axios.post(route, data, this._config(auth));
  }

  /**
   * Dispatches a PUT HTTP request using Axios.
   * @param {string} route - The API route.
   * @param {*} data - The data to send in the body of the request.
   * @param {boolean|string} [auth = false] - When true or a string then add an Authorization header
   *   in the request. Use the given string as token or use the token in the Redux store.
   * @returns {object}
   */
  async put(route, data, auth) {
    return axios.put(route, data, this._config(auth));
  }

  /**
   * @private
   * Returns the Axios config object.
   * @param {boolean|string} [auth = false] - When true and the current user is authenticated, then
   *   use the user's token, or when a string is given, then use the given string as token.
   * @returns {object|null}
   */
  _config(auth) {
    let token;
    if (isString(auth)) { token = auth; } else if (auth) {
      const user = this._getUser();
      if (user && user.isUser) { token = user.dxToken; }
    }
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };
    }
    return null;
  }
}

export const dxRequest = new DxRequest();

export const del = dxRequest.del.bind(dxRequest);
export const get = dxRequest.get.bind(dxRequest);
export const head = dxRequest.head.bind(dxRequest);
export const patch = dxRequest.patch.bind(dxRequest);
export const post = dxRequest.post.bind(dxRequest);
export const put = dxRequest.put.bind(dxRequest);

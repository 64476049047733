/**
 * @typedef {object} DxModalsState
 * @property {Array.<ModalState>} modals
 */

/**
 * @typedef {object} DxModalOptions
 * @property {boolean} [closeOnOutsideClick = false]
 * @property {boolean} [hideCloseButton = false]
 * @property {boolean} [hideTitleBar = false]
 * @property {string} [size = 'medium'] - Either `small`, `medium` or `large`.
 * @property {string} [title]
 */

/**
 * @typedef {object} ModalState
 * @property {string} id
 * @property {DxModalOptions} options
 */

/**
 * @param {{ dxModals: DxModalsState }} state - The Redux state.
 * @returns {DxModalsState}
 */
export const getDxModalsState = (state) => state.dxModals;

/**
 * @param {{ dxModals: DxModalsState }} state - The Redux state.
 * @returns {Array.<ModalState>} Array of modal state objects.
 */
export const getDxModals = (state) => getDxModalsState(state).modals;

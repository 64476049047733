import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../propTypes';
import { dxStyles } from '../styles';

// -- Styles --------------- --- --  -

const defaultStyles = css(dxStyles.boxShadowAngled, {
  borderRadius: 0,
  boxSizing: 'border-box',
  margin: 0,
  '@media screen and (min-width: 680px)': { margin: '0 32px', },
});

// -- Component --------------- --- --  -

/**
 * Generic Chapter dxComponent.
 * Use the `styles` prop to customize the styling.
 * Other props are set on the resulting div.
 */
export const Chapter = ({
  chapterColor, children, className, styles, ...otherProps
}) => (
  <div
    className={classnames('dx-chapter', className)}
    {...otherProps}
    {...css(defaultStyles, chapterColor && { borderTop: `2px solid ${chapterColor}` }, styles)}
  >
    {children}
  </div>
);

Chapter.propTypes = {
  chapterColor: PropTypes.string,

  /** The chapter content. */
  children: PropTypes.node,

  /** Optional additional class name. */
  className: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,
};

import PropTypes from 'prop-types';

// -- dxAtomicFilterPropTypes --------------- ---  --  -

export const dxAtomicFilterPropTypes = {
  type: PropTypes.string.isRequired,
};

export const dxAtomicFilterPropType = PropTypes.shape(dxAtomicFilterPropTypes);

// -- dxCompositeFilterPropTypes --------------- ---  --  -

export const dxCompositeFilterPropTypes = {
  operator: PropTypes.oneOf(['and', 'andNot', 'or', 'orNot']).isRequired,
};

export const dxCompositeFilterPropType = PropTypes.shape(dxCompositeFilterPropTypes);

// -- dxFilterPropTypes --------------- ---  --  -

export const dxFilterPropType = PropTypes.oneOfType([
  dxAtomicFilterPropType,
  dxCompositeFilterPropType,
]);

// Now assign the filters prop in the composite:
dxCompositeFilterPropTypes.filters = PropTypes.arrayOf(dxFilterPropType).isRequired;

import { css } from 'glamor';
import { PropTypes } from 'prop-types';
import React from 'react';

import { Section } from './Section';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  backgroundColor: 'white',
  padding: '6px 32px',
});

// -- Component --------------- --- --  -

export const DescriptionSection = ({ description }) => (description ? (
  <Section className="dms-description" styles={defaultStyles}>
    <h3 className="description-header">{description}</h3>
  </Section>
) : null);

DescriptionSection.propTypes = {
  description: PropTypes.string,
};

import color from 'color';

// -- base colors --------------- --- --  -

export const bondiBlue = '#3F98BD';

const bgMain = color('#dddddb').darken(0.03);
const bgDarker_1 = color(bgMain).darken(0.06);
const bgDarker_2 = color(bgMain).darken(0.13);
const bgDarker_3 = color(bgMain).darken(0.21);
const bgLighter_1 = color(bgMain).lighten(0.05);
const bgLighter_2 = color(bgMain).lighten(0.10);
const bgLighter_3 = color(bgMain).lighten(0.15);

const fgMain = color('#3C3C3B');
const fgDisabled = fgMain.lighten(2.5);
const fgDarker_1 = color(fgMain).darken(0.06);
const fgDarker_2 = color(fgMain).darken(0.13);
const fgDarker_3 = color(fgMain).darken(0.21);
const fgLighter_1 = color(fgMain).lighten(0.40);
const fgLighter_2 = color(fgMain).lighten(0.80);
const fgLighter_3 = color(fgMain).lighten(1.2);

const bgFocus = color(bondiBlue).desaturate(0.5).lighten(0.90);
const bgFocusDarker_1 = color(bondiBlue).desaturate(0.5).lighten(0.80);
const fgFocus = color(bondiBlue).desaturate(0.2).lighten(0.4);
const borderFocus = fgFocus;

const dangerRed = color('#CF5B55');
const whiteBright = color('#FFFFFF');

// -- dxColors object --------------- --- --  -

/*
  bgDarker_1: 'rgb(202, 202, 199)'
  bgDarker_2: 'rgb(188, 188, 184)'
  bgDarker_3: 'rgb(171, 171, 166)'
  bgFocus: 'rgb(236, 241, 243)'
  bgFocusDarker_1: 'rgb(220, 230, 234)'
  bgLighter_1: 'rgb(225, 225, 223)'
  bgLighter_2: 'rgb(235, 235, 234)'
  bgLighter_3: 'rgb(246, 246, 245)'
  bgMain: 'rgb(215, 215, 212)'
  bondiBlue: 'rgb(63, 152, 189)'
  bondiBlueDarker_1: 'hsl(197.60000000000002, 50%, 46%)'
  bondiBlueDarker_2: 'hsl(197.60000000000002, 50%, 42.5%)'
  borderFocus: 'rgb(145, 189, 208)'
  dangerRed: 'rgb(207, 91, 85)'
  fgDarker_1: 'rgb(56, 56, 56)'
  fgDarker_2: 'rgb(52, 52, 51)'
  fgDarker_3: 'rgb(47, 47, 47)'
  fgDisabled: 'rgb(209, 209, 208)'
  fgFocus: 'rgb(145, 189, 208)'
  fgLighter_1: 'rgb(84, 84, 83)'
  fgLighter_2: 'rgb(108, 108, 106)'
  fgLighter_3: 'rgb(132, 132, 130)'
  fgMain: 'rgb(60, 60, 59)'
  imec5Color1: '#1582BE'
  imec5Color2: '#DC00CE'
  imec5Color3: '#CC5900'
  imec5Color4: '#728400'
  imec5Color5: '#008C77'
  imecSGColor1: '#90298D'
  imecSGColor2: '#1582BE'
  imecSGColor3: '#36337D'
  imecSGColor4: '#99BDE4'
  imecSGColor5: '#C778AD'
  imecSGColor6: '#52BDC2'
  mediumBorder: 'rgb(208, 208, 206)'
  thinBorder: 'rgb(202, 202, 199)'
 */
export const dxColors = {
  bondiBlue: color(bondiBlue).rgb().string(),
  bondiBlueDarker_1: color(bondiBlue).darken(0.07).string(),
  bondiBlueDarker_2: color(bondiBlue).darken(0.14).string(),

  bgMain: bgMain.rgb().string(),
  bgDarker_1: bgDarker_1.rgb().string(),
  bgDarker_2: bgDarker_2.rgb().string(),
  bgDarker_3: bgDarker_3.rgb().string(),
  bgLighter_1: bgLighter_1.rgb().string(),
  bgLighter_2: bgLighter_2.rgb().string(),
  bgLighter_3: bgLighter_3.rgb().string(),
  bgFocus: bgFocus.rgb().string(),
  bgFocusDarker_1: bgFocusDarker_1.rgb().string(),

  fgMain: fgMain.rgb().string(),
  fgDisabled: fgDisabled.rgb().string(),
  fgDarker_1: fgDarker_1.rgb().string(),
  fgDarker_2: fgDarker_2.rgb().string(),
  fgDarker_3: fgDarker_3.rgb().string(),
  fgLighter_1: fgLighter_1.rgb().string(),
  fgLighter_2: fgLighter_2.rgb().string(),
  fgLighter_3: fgLighter_3.rgb().string(),

  fgFocus: fgFocus.rgb().string(),

  dangerRed: dangerRed.rgb().string(),
  whiteBright: whiteBright.rgb().string(),

  borderFocus: borderFocus.rgb().string(),

  imecSGColor1: '#90298D', // purple-ish
  imecSGColor2: '#1582BE', // bondi-blue
  imecSGColor3: '#36337D', // dark-blue
  imecSGColor4: '#99BDE4', // light-blue
  imecSGColor5: '#C778AD', // pink-ish
  imecSGColor6: '#52BDC2', // cyan-ish

  // Five-color palette obtained through scaling in H of HSL space, starting with Bondi Blue
  // HSL(241.4, 96.9, 51.7) (obtained using {@link http://colormine.org/convert/rgb-to-lch}) and
  // adding 72º to H to get the other colors (using @{link http://www.hsluv.org }.
  imec5Color1: '#1582BE', // bondi-blue
  imec5Color2: '#DC00CE', // purple
  imec5Color3: '#CC5900', // orange
  imec5Color4: '#728400', // green
  imec5Color5: '#008C77', // cyan

  mediumBorder: bgMain.darken(0.03).rgb().string(),
  thinBorder: bgMain.darken(0.06).rgb().string(),
};

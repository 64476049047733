import invariant from 'invariant';
import React from 'react';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import { isString } from '../../../../utils/isString';
import { capitalize } from '../../../../utils/capitalize';

import { CREATE_MODE, EDIT_MODE, VIEW_MODE } from '../../../constants';
import { BooleanField } from '../BooleanField';
import { DateField } from '../DateField';
import { IntField } from '../IntField';
import { JsonField } from '../JsonField';
import { NumberField } from '../NumberField';
import { StringField } from '../StringField';
import { TextField } from '../TextField';
import { DMSRelateesField } from '../../dms/DMSRelateesField';

// -- Constants --------------- ---  --  -

/**
 * The default field components.
 */
export const defaultFieldViews = {
  'duxis/boolean': BooleanField,
  'duxis/date': DateField,
  // 'duxis/email': EmailField,
  'duxis/float': NumberField,
  'duxis/int': IntField,
  'duxis/json': JsonField,
  'duxis/string': StringField,
  'duxis/text': TextField,
  'duxis/uuid': StringField,
};

// -- initFieldViews --------------- ---  --  -

/**
 * Initializes the field-view components and initial values.
 *
 * Views and values are provided for the fields selected according to the following pseudocode:
 * <pre>
 * 01 if a view-schema is provided:
 * 02   if the view-schema has a 'fields' option:
 * 03     include all listed fields and relatees
 * 04   else:
 * 05     include all non-controlled fields
 * 06     if the view-schema's 'includeRelatees' option is true:
 * 07       include all non-controlled relatees
 * 08     if the view-schema has an 'exclude' option:
 * 09       exclude all fields and relatees listed in the 'exclude' option
 * 10   if the given mode is the create-mode:
 * 11     include all non-included required fields that do not have a default value
 * 12     if the view-schema's 'includeRelatees' option is true:
 * 13       include all non-included required relatees
 * 14 else:
 * 15   include all non-controlled fields
 * 16   if the view-schema's 'includeRelatees' option is true:
 * 17     include all non-controlled relatees
 * </pre>
 *
 * @todo Currently, the 'includeRelatees' option may only be true for view-schemas used in a dxDMS.
 *
 * Their order is: first the fields listed in the 'fields' option, in the given order, followed
 * by the unlisted included fields in the order given in the dxSchema.
 *
 * Each field view is an instantiation of the custom component provided in the view-schema, or the
 * default component for that field's type.
 *
 * @param {CollectionSchema} collSchema - The collection schema.
 * @param {object} [item] - The item object.
 * @param {symbol} mode - One of CREATE_MODE/EDIT_MODE/VIEW_MODE defined in `react-frontend/constants`.
 * @param {object} [options]
 * @param {object} [options.viewSchema] - The view schema. @see ../propTypes/viewSchemaPropType
 * @param {object} [options.relateeProps] - Optional props to inject in relatee views.
 * @returns {{ elements: array, initialValues: {} }}
 */
export const initFieldViews = (collSchema, item, mode, options = {}) => {
  invariant(isObject(item), `Expected an object as "item", got "${item}".`);
  invariant(mode === CREATE_MODE || isString(item.id),
    `Expected either CREATE_MODE or a string as "item.id", got "${item.id}".`);
  const { relateeProps, viewSchema = { includeRelatees: false } } = options;
  const elMap = {};
  const elements = [];
  const initialValues = {};

  // normalize the given fields, i.e. use default values when appropriate:
  const fields = collSchema.normalizeFields(item);

  const addElement = (element, fieldId) => {
    elements.push(element);
    elMap[fieldId] = true;
  };

  // nested helper function that creates the elements:
  const addFieldElement = (fieldId, fieldOptions = {}) => {
    const {
      component, editable, initialValue, label, ...otherProps
    } = fieldOptions;
    const fieldSchema = collSchema.getField(fieldId);

    // controlled fields should not be included in create-mode or edit-mode:
    if (fieldSchema.isControlled && mode === CREATE_MODE) { return; }

    let value = fields[fieldId];
    if (value === undefined) { value = initialValue === undefined ? null : initialValue; }
    initialValues[fieldId] = value; // Add values in the initialValues object

    // Create the element and add it in the list and the map:
    const element = React.createElement(component || getDefaultFieldView(fieldSchema.type), {
      editMode: isBoolean(editable)
        ? editable && mode !== VIEW_MODE
        : (mode === EDIT_MODE && fieldSchema.isEditable) || (mode === CREATE_MODE && !fieldSchema.isControlled),
      fieldId,
      fieldSchema,
      key: fieldId,
      label: label || fieldSchema.label || capitalize(fieldId),
      required: fieldSchema.isRequired,
      value,
      ...otherProps,
    });
    addElement(element, fieldId);
  };

  const addRelateeElement = (fieldId, relateeOptions = {}) => {
    const {
      component, editable, label, ...otherProps
    } = relateeOptions;
    const relateeSchema = collSchema.getRelatee(fieldId);
    const value = fields[fieldId] === undefined ? null : fields[fieldId];
    initialValues[fieldId] = value; // Add values in the initialValues object

    // Create the element and add it in the list and the map:
    const element = React.createElement(component || DMSRelateesField, {
      editMode: mode !== VIEW_MODE && (isBoolean(editable) ? editable : true),
      fieldId,
      item,
      key: fieldId,
      label: label || relateeSchema.label || capitalize(fieldId),
      relateeSchema,
      value,
      ...relateeProps,
      ...otherProps,
    });
    addElement(element, fieldId);
  };

  // Add elements depending on the view-schema and/or the field-schema:
  if (viewSchema.fields) {
    viewSchema.fields.forEach(({ id, ...otherProps }) => {
      if (viewSchema.exclude && viewSchema.exclude.includes(id)) { return; }
      if (collSchema.hasField(id)) { // this is a property field
        addFieldElement(id, otherProps);
      } else if (collSchema.hasRelatee(id)) {
        addRelateeElement(id, otherProps);
      } else {
        throw new Error(`Unexpected field "${id}" in viewSchema.`);
      }
    });
    if (mode === CREATE_MODE) {
      // Include all unlisted required fields without default value in create-mode.:
      collSchema.forEachRequiredField((field) => {
        if (field.isRequired && !field.hasDefaultValue && !elMap[field.id]) {
          addFieldElement(field.id);
        }
      });
      // Include all unlisted required relatees in create-mode:
      if (viewSchema.includeRelatees) {
        collSchema.forEachRelatee((relatee) => {
          if (relatee.isRequired && !elMap[relatee.fieldId]) {
            addRelateeElement(relatee.fieldId);
          }
        });
      }
    }
  } else if (viewSchema.exclude) {
    const { exclude } = viewSchema;
    if (mode === CREATE_MODE) {
      collSchema.forEachField((field) => {
        if (!exclude.includes(field.id) || (field.isRequired && !field.hasDefaultValue)) {
          addFieldElement(field.id);
        }
      });
      if (viewSchema.includeRelatees) {
        collSchema.forEachRelatee((relatee) => {
          if (!exclude.includes(relatee.id) || relatee.isRequired) {
            addRelateeElement(relatee.fieldId);
          }
        });
      }
    } else {
      collSchema.forEachField((field) => {
        if (!exclude.includes(field.id)) {
          addFieldElement(field.id);
        }
      });
      if (viewSchema.includeRelatees) {
        collSchema.forEachRelatee((relatee) => {
          if (!exclude.includes(relatee.id)) {
            addRelateeElement(relatee.fieldId);
          }
        });
      }
    }
  } else {
    collSchema.forEachField((field) => addFieldElement(field.id));
    if (viewSchema.includeRelatees) {
      collSchema.forEachRelatee((relatee) => addRelateeElement(relatee.fieldId));
    }
  }

  return { elements, initialValues };
};

// -- Helpers --------------- --- --  -

const getDefaultFieldView = (dxType) => {
  if (defaultFieldViews[dxType]) {
    return defaultFieldViews[dxType];
  }

  throw new Error(`There is no default field-view component for type "${dxType}".`);
};

import PropTypes from 'prop-types';
import React from 'react';
import { connectAdvanced } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { immute } from '../../../utils/immute';
import { toString } from '../../../utils/toString';

import { dmsMountAction } from '../../actions';
import { AlertSection } from '../AlertSection';
import { CREATE_MODE, EDIT_MODE, VIEW_MODE } from '../../constants';
import { DMSConfig } from '../../dms';
import { getDMSConfig } from '../../selectors';
import { inject, join } from '../../utils';

import { DMSCollectionChapter } from './DMSCollectionChapter';
import { DMSItemChapter } from './DMSItemChapter';

// -- Component --------------- --- --  -

function DMSCollection(props) {
  const { config } = props;
  if (props.dmsError) {
    return <AlertSection error={props.dmsError} />;
  }
  const { collections } = config;
  const collection = collections[0];
  const { id } = collections[0];
  return (
    <Switch>
      <Route
        component={inject(DMSCollectionChapter, { collection, showBreadcrumbs: false })}
        exact
        key={`${id}-0`}
        path={collection.path}
      />
      ,
      <Route
        component={inject(DMSItemChapter, { collection, mode: CREATE_MODE, showBreadcrumbs: false })}
        exact
        key={`${id}-1`}
        path={collection.createPath}
      />
      ,
      <Route
        component={inject(DMSItemChapter, (ownProps) => ({
          collection,
          itemId: ownProps.match.params.id,
          mode: VIEW_MODE,
          showBreadcrumbs: false,
        }))}
        exact
        key={`${id}-2`}
        path={join(collection.path, ':id')}
      />
      ,
      <Route
        component={inject(DMSItemChapter, (ownProps) => ({
          collection,
          itemId: ownProps.match.params.id,
          mode: EDIT_MODE,
          showBreadcrumbs: false,
        }))}
        exact
        key={`${id}-3`}
        path={join(collection.path, ':id', 'edit')}
      />
    </Switch>
  );
}

DMSCollection.propTypes = {
  /** To be provided. */
  config: PropTypes.instanceOf(DMSConfig).isRequired,

  /** DMSRoutes error message to show. */
  dmsError: PropTypes.string,
};

// -- Container --------------- --- --  -

export const DMSCollectionRoutes = withRouter(connectAdvanced(
  (dispatch) => {
    // Memoized props to pass to wrapped component:
    const result = { config: null };

    return (state, nextProps) => {
      const { config, path } = nextProps;
      if (!config || !config.isDMSConfig) {
        return {
          ...nextProps,
          dmsError: `The DMSRoutes requires a DMSConfig instance as "config" prop, got "${toString(config)}".`,
        };
      }

      if (!path) {
        return {
          ...nextProps,
          dmsError: `The DMSRoutes requires a string as "path" prop, got "${toString(path)}".`,
        };
      }

      if (config !== getDMSConfig(state)) {
        //
        // By using the router prop match.path we know which parent component matches this request
        // which is used as the DMS "basePath"
        // and the path to the collection is normally generated based on its collection name
        // the path prop of this component can customize the route at which we want to mount it
        //
        config._rawConfig.collections[0].customPath = path;
        config.initialize(nextProps.match.path);

        dispatch(dmsMountAction(config));
        // Note that the Redux store is now updated. The state object we have been given above is
        // thus out-of-date.
      }

      return immute(result, nextProps);
    };
  },
  {
    getDisplayName: (name) => name,
    methodName: 'connectDMSRoutes',
    shouldHandleStateChanges: false,
  }
)(DMSCollection));

import { queryBuilder } from '../../utils/dxf';

export const auth0IdentifyQuery = queryBuilder({
  variables: {
    accessToken: { type: 'String!' },
    payload: { type: 'JSON' },
  },
  fields: {
    auth0: {
      fields: {
        identify: {
          varArgs: { accessToken: 'accessToken', payload: 'payload' },
          fields: {
            dxToken: true,
            genericRights: true,
            id: true,
            reason: true,
            scopedRights: true,
            success: true,
          },
        },
      },
    },
  },
});

export const simpleAuthenticateQuery = queryBuilder({
  variables: {
    password: { type: 'String!' },
    username: { type: 'String!' },
  },
  fields: {
    simpleAuthenticate: {
      varArgs: { password: 'password', username: 'username' },
      fields: {
        dxToken: true,
        expiresAt: true,
        genericRights: true,
        id: true,
        reason: true,
        scopedRights: true,
        success: true,
      },
    },
  },
});

export const refreshTokenQuery = queryBuilder({
  fields: {
    refreshToken: {
      fields: {
        dxToken: true,
        expiresAt: true,
        genericRights: true,
        reason: true,
        scopedRights: true,
        success: true,
      },
    },
  },
});

import PropTypes from 'prop-types';

import { cssPropType } from '../../propTypes';

export const modalPropTypes = {
  component: PropTypes.func.isRequired,

  /**
   * The unique modal ID, injected by the ModalManager. This ID can be used to close the modal
   * programmatically.
   */
  id: PropTypes.string,

  /**
   * The index of the modal, injected by the ModalManager. Determines the z-order of multiple
   * modals such that the latest is shown on top.
   */
  index: PropTypes.number,

  /**
   * All additional props are passed to the component prop.
   */
  options: PropTypes.shape({
    /** Close the modal window when the user clicks outside of it. Defaults to false. */
    closeOnOutsideClick: PropTypes.bool,

    /** Hide the close button in the modal title bar is not shown. Defaults to false. */
    hideCloseButton: PropTypes.bool,

    /** Hide the modal title bar. Defaults to false. */
    hideTitleBar: PropTypes.bool,

    /** One of 'small', 'medium' (default) or 'large'. */
    size: PropTypes.string,

    /** Optional title. */
    title: PropTypes.string,
  }).isRequired,

  /** Dispatches the action to remove the modal. */
  removeModal: PropTypes.func,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,
};

export const modalPropType = PropTypes.shape(modalPropTypes);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { equalShallow } from '../../../utils/equalShallow';

import {
  dmsItemCreateInitAction,
  dmsItemDeleteAction,
  dmsItemDeselectAction,
  dmsItemsBulkActionInitAction,
  dmsItemsDeselectAllAction,
  dmsItemsSelectAllAction,
  dmsItemSelectAction,
  dmsPageFetchAction,
} from '../../actions';
import { dxSchema } from '../../dxSchema';
import { dmsCollectionComponentPropTypes } from '../../propTypes';
import {
  getDMSItemsPageState,
  getDMSItemsSelectionState,
  getDMSState,
  getDxAuthUser,
} from '../../selectors';
import { AlertSection } from '../AlertSection';
import { BreadcrumbsSection } from '../BreadcrumbsSection';
import { Chapter } from '../Chapter';
import { ChapterHeader } from '../ChapterHeader';
import { DescriptionSection } from '../DescriptionSection';
import { DxErrorDetails } from '../DxErrorDetails';
import { ItemsList } from '../items';
import { Module } from '../Module';
import { Spinner } from '../Spinner';

// -- Component --------------- --- --  -

class DMSCollectionChapterComponent extends React.Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info.componentStack);
  }

  getCrumbs() {
    const { collection } = this.props;
    return [
      { label: 'Collections', url: collection.dmsConfig.basePath },
      { label: collection.label },
    ];
  }

  renderList() {
    if (!this.props.canView) {
      return <AlertSection error="You are not authorized to view this collection." />;
    }
    const {
      bulkActionInit, canCreate, canDelete, canView, collection, createItem
    } = this.props;
    const {
      deleteItem, deselectAll, deselectItem, loadItems, loadState
    } = this.props;
    const { selectAll, selectionState, selectItem } = this.props;
    const { bulkActions, disableCreate, disableDelete } = collection;
    const listProps = {
      bulkActionInit,
      bulkActions,
      canCreate: canCreate && !disableCreate,
      canDelete: canDelete && !disableDelete,
      canView,
      createItem,
      deleteItem,
      deselectAll,
      deselectItem,
      itemLabel: collection.collSchema.labelSingular,
      loadItems,
      loadState,
      pluralCapped: collection.collSchema.pluralCapped,
      // sectionStyles: ...,
      selectAll,
      selectable: bulkActions !== undefined,
      selection: selectionState,
      selectItem,
      showDeleteButton: collection.showListItemDelete,
      silentDelete: collection.silentDelete,
      singularCapped: collection.collSchema.singularCapped,
    };
    if (collection.listComponent) {
      return <collection.listComponent {...listProps} />;
    }

    return <ItemsList {...listProps} />;
  }

  renderChapterContent() {
    const {
      collection, error, hasError, showBreadcrumbs
    } = this.props;
    if (hasError) {
      return <DxErrorDetails error={error} />;
    }
    const collSchema = dxSchema.getCollection(collection.id);
    return (
      <Module>
        { showBreadcrumbs && <BreadcrumbsSection items={this.getCrumbs()} /> }
        <DescriptionSection description={collSchema.description} />
        {this.renderList()}
      </Module>
    );
  }

  render() {
    const { collection } = this.props;
    if (!collection) { return <Spinner />; }
    const title = dxSchema.getCollection(collection.id).pluralCapped;
    return (
      <Chapter chapterColor="white">
        <ChapterHeader title={title} />
        {this.renderChapterContent()}
      </Chapter>
    );
  }
}

// -- Container --------------- --- --  -

export const DMSCollectionChapter = connect(
  (state, ownProps) => {
    const { collSchema } = ownProps.collection;
    const user = getDxAuthUser(state);
    return {
      canCreate: user.canCreate(collSchema),
      canDelete: user.canDelete(collSchema),
      canView: user.canView(collSchema),
      dxSchema,
      loadState: getDMSItemsPageState(state),
      selectionState: getDMSItemsSelectionState(state),
    };
  },
  (dispatch, { collection }) => ({
    bulkActionInit: (bulkAction) => dispatch(dmsItemsBulkActionInitAction(bulkAction)),
    createItem: () => dispatch(dmsItemCreateInitAction(collection.id)),
    deleteItem: (id) => dispatch(dmsItemDeleteAction(collection.id, id)),
    deselectAll: () => dispatch(dmsItemsDeselectAllAction()),
    deselectItem: (id) => dispatch(dmsItemDeselectAction(id)),
    loadItems: (options) => dispatch(dmsPageFetchAction(collection.id, options)),
    selectAll: () => dispatch(dmsItemsSelectAllAction()),
    selectItem: (id) => dispatch(dmsItemSelectAction(id)),
  }),
  undefined, // use default mergeProps implementation
  {
    areOwnPropsEqual: (next, prev) => equalShallow(next.collection, prev.collection),
    areStatesEqual: (next, prev) => equalShallow(getDMSState(next), getDMSState(prev)),
  },
)(DMSCollectionChapterComponent);

DMSCollectionChapterComponent.propTypes = {
  ...dmsCollectionComponentPropTypes,
  bulkActionInit: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  error: PropTypes.object,
  hasError: PropTypes.bool,
  selectAll: PropTypes.func.isRequired,
  showBreadcrumbs: PropTypes.bool,
};

DMSCollectionChapterComponent.defaultProps = {
  hasError: false,
  showBreadcrumbs: true,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { DxError } from '../../../utils/dxf';

import { DxErrorDetails } from '../DxErrorDetails';

// -- Component --------------- --- --  -

/**
 * A modal that renders the details of the given error object.
 */
export class ErrorModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  handleClose() {
    if (this.props.onClose) { this.props.onClose(); }
    this.props.removeModal();
  }

  render() {
    return (
      <div className="dx-modal dx-error-modal">
        <DxErrorDetails error={this.props.dxError || this.props.error} />
        <div className="buttons">
          <Button autoFocus bsStyle="danger" onClick={this.handleClose}>Close</Button>
        </div>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  dxError: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(DxError),
    PropTypes.instanceOf(Error),
    PropTypes.object,
    PropTypes.string
  ]),
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(DxError),
    PropTypes.instanceOf(Error),
    PropTypes.object,
    PropTypes.string
  ]),
  onClose: PropTypes.func,
  removeModal: PropTypes.func.isRequired,
};

import { isString } from '../isString';

// -- Setup --------------- --- --  -

export const collectionActivityRegExp = /(\w+)::(view|update|manage)/;

const acceptedActivityTypes = {
  view: true, update: true, manage: true, create_own: true
};

const impliedByMap = {
  view: ['view', 'update', 'manage'],
  update: ['update', 'manage'],
  manage: ['manage'],
  create_own: ['create_own'],
};

const impliedMapMap = {
  view: { view: true },
  update: { view: true, update: true },
  manage: { view: true, update: true, manage: true },
};

const impliedByMapMap = {
  view: { view: true, update: true, manage: true },
  update: { update: true, manage: true },
  manage: { manage: true },
};

// -- Activity Class --------------- --- --  -

/**
 * Represents a rule that requires authorization for a generic (unscoped) collection activity.
 */
export class Activity {
  /**
   * @param {string} args - Either a formatted collection-activity string of the form
   * "<collection-id>::<activity-type>" (regex `/\w+::\w+/`), or a collection ID and an activity-
   * type ID.
   */
  constructor(...args) {
    if (collectionActivityRegExp.test(args[0])) {
      this._activityString = args[0];
      const result = collectionActivityRegExp.exec(args[0]);
      this._collectionId = result[1];
      this._activityType = result[2];
    } else if (isString(args[0]) && isString(args[1])) {
      this._activityString = `${args[0]}::${args[1]}`;
      this._collectionId = args[0];
      this._activityType = args[1];
    } else {
      throw new Error('Expected Either a formatted collection-activity string '
        + 'or a collection ID and an activity-type ID, '
        + `instead received [${args.join(', ')}].`);
    }
    if (!acceptedActivityTypes[this._activityType]) {
      throw new Error('Expected "view", "update", "manage" or "create_own" as activity type, '
        + `got "${this._activityType}".`);
    }
    // this._implied = impliedMap[this._activityType].map((act) => `${this._collectionId}::${act}`);
    this._impliedBy = impliedByMap[this._activityType].map((act) => `${this._collectionId}::${act}`);
  }

  /** @returns {string} The formatted activity string (regex=/\w+::\w+/). */
  get activityString() { return this._activityString; }

  /** @returns {string} The activity type. */
  get activityType() { return this._activityType; }

  /** @returns {string} The collection ID. */
  get collectionId() { return this._collectionId; }

  // /** @returns {Array<string>} The raw activities implied by this collection activity. */
  // get impliedActivityTypes() { return this._implied; }  // TODO: rename to impliedActivities ?

  /** @returns {Array<string>} The raw activities that imply this collection activity. */
  get impliedByActivities() { return this._impliedBy; }

  /** @return {boolean} True for all Activity instances. */
  get isActivity() { return true; }

  toString() {
    // return `{{Activity coll=${this.collectionId} activity=${this.activityType}}}`;
    return `<${this.collectionId}::${this.activityType}>`;
  }

  // -- Support Functions --------------- --- --  -

  /**
   * @param {Activity|string} activity - An Activity object, collection-activity
   *   string or an activity-type string.
   * @param {string} [collectionId] - Required when _activity_ is an activity-type string.
   * @returns {boolean}
   */
  authorizes(activity, collectionId) {
    // console.log(`>>> Activity.authorizes(${activity.toString()}) -- this:`, this.toString());
    if (isActivity(activity)) {
      // console.log('> Case: activity is an Activity');
      return this.collectionId === activity.collectionId
        && activitiesMatch(this.activityType, activity.activityType);
    }
    if (isCollectionActivityString(activity)) {
      const parsed = collectionActivityRegExp.exec(activity);
      return this.collectionId === parsed[1] && activitiesMatch(this.activityType, parsed[2]);
    }
    if (isString(activity)) {
      return this.collectionId === collectionId
        && activitiesMatch(this.activityType, activity);
    }

    throw new Error('Expected Activity or a formatted collection-activity string, '
        + `got "${activity}".`);
  }

  isAuthorizedBy(activity) {
    if (isActivity(activity)) {
      return this.collectionId === activity.collectionId
        && impliedByMapMap[this.activityType][activity.activityType];
    }
    if (isCollectionActivityString(activity)) {
      const parsed = collectionActivityRegExp.exec(activity);
      return this.collectionId === parsed[1]
        && impliedByMapMap[this.activityType][parsed[2]];
    }

    throw new Error('Expected Activity or a formatted collection-activity string, '
        + `got "${activity}".`);
  }
}

// -- Support Functions --------------- --- --  -

/**
 * Checks if the _given_ activity-type authorizes the _subject_ activity-type.
 * @param {string} given
 * @param {string} subject
 * @return {boolean} true when the _given_ activity-type authorizes the _subject_ activity-type.
 */
export const activitiesMatch = (given, subject) => impliedMapMap[given][subject];

/**
 * @param {*} value
 * @returns {boolean} True when the given value is an Activity instance.
 */
export const isActivity = (value) => (value instanceof Activity);

/**
 * @param {*} value
 * @returns {boolean} True when the given value is a formatted collection-activity string.
 */
export const isCollectionActivityString = (value) => isString(value) && collectionActivityRegExp.test(value);

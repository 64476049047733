import { FETCH_EXTERNAL_ITEMS_START, FETCH_EXTERNAL_ITEMS_SUCCESS } from '../constants/actionTypes';

import { createPageLoadStateReducer } from './pageLoadStateReducer';
import dxSchemaReducer from './dxSchemaReducer';

export const reducers = {
  externalItems: createPageLoadStateReducer(
    FETCH_EXTERNAL_ITEMS_START,
    FETCH_EXTERNAL_ITEMS_SUCCESS,
  ),
  externalSchema: dxSchemaReducer,
};

/* eslint-disable camelcase */

import { queryBuilder } from '../../utils/dxf';

export const getTasksQuery = queryBuilder({
  fields: {
    getTasks: {
      fields: {
        tasks: true,
      },
    },
  },
});

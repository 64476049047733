import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  bulkActionPropType,
  itemsSelectionPropType,
  pageLoadStatePropType,
} from '../../propTypes';
import {
  getItemsSelectionAllSelected,
  getItemsSelectionCount,
  getItemsSelectionHasSelected,
  getItemsSelectionIds,
  getItemsSelectionNoneSelected,
} from '../../selectors';
import { dxColors } from '../../styles';
import { SelectedItemsList } from '../items/SelectedItemsList';

// -- Styles --------------- --- --  -

const buttonsBoxStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  '> *': { marginLeft: 10 },
});

const splitViewStyles = css({
  display: 'flex',
  // '> .split-view': { width: '50%' },
  '> .panel-left': {
    borderRight: `1px solid ${dxColors.thinBorder}`,
    flex: '50% 1 1',
    paddingRight: '16px',
  },
  '> .panel-right': {
    flex: '50% 1 1',
    paddingLeft: '16px',
  },
  // '@media(max-width: 900px)': {
  //   flexFlow: 'column nowrap',
  //   '> .split-view': { width: 'auto' },
  //   '> .panel-left, > .panel-right': {
  //     border: 'none',
  //     margin: 0,
  //     padding: 0,
  //   },
  //   '> .panel-left': {
  //     borderBottom: `1px solid ${dxColors.thinBorder}`,
  //     flex: 'auto',
  //     paddingBottom: '16px',
  //   },
  //   '> .panel-right': {
  //     flex: 'auto',
  //     paddingTop: '16px',
  //   },
  // },
});

// -- Component --------------- --- --  -

/**
 * Base component.
 */
export class BulkActionsModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      readyForSubmit: !props.bulkAction.render,
    };
    this.customValueSet = this.customValueSet.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleLoadItems = this.handleLoadItems.bind(this);
    this.handleReject = this.handleReject.bind(this);
  }

  componentDidMount() {
    this.handleLoadItems();
  }

  componentDidUpdate(prevProps) {
    const currSelection = this.props.selectionState;
    const prevSelection = prevProps.selectionState;
    // const currAllSelected = getItemsSelectionAllSelected(currSelection);
    const currNoneSelected = getItemsSelectionNoneSelected(currSelection);
    if (currNoneSelected) {
      return this.props.removeModal();
    }
    if (getItemsSelectionCount(prevSelection) !== getItemsSelectionCount(currSelection)) {
      this.handleLoadItems();
    }
  }

  handleApply() {
    const { apply, removeModal } = this.props;
    apply(this.state.data);
    removeModal();
  }

  handleLoadItems(options) {
    const { loadItems, selectionState } = this.props;
    const allSelected = getItemsSelectionAllSelected(selectionState);
    if (allSelected) { return; }
    const hasSelected = getItemsSelectionHasSelected(selectionState);
    const ids = hasSelected ? getItemsSelectionIds(selectionState) : [];
    loadItems(ids, options);
  }

  handleReject() {
    this.props.removeModal();
  }

  customValueSet(data) {
    const readyForSubmit = Boolean(data);
    this.setState({ data, readyForSubmit });
  }

  renderItemsPanel() {
    const {
      deselectItem, getItemLabel, loadState, selectionState
    } = this.props;
    if (getItemsSelectionAllSelected(selectionState)) {
      return <div className="split-view panel-left"><p>All selected.</p></div>;
    }
    if (getItemsSelectionNoneSelected(selectionState)) {
      return <div className="split-view panel-left"><p>None selected.</p></div>;
    }
    return (
      <div className="split-view panel-left">
        <p><em>Deselect items you want to keep unchanged</em></p>
        <SelectedItemsList
          deselectItem={deselectItem}
          getItemLabel={getItemLabel}
          loadItems={this.handleLoadItems}
          loadState={loadState}
        />
      </div>
    );
  }

  renderSelectedItems() {
    const { bulkAction: { render: CustomComponent } } = this.props;
    if (CustomComponent) {
      return (
        <div {...splitViewStyles}>
          {this.renderItemsPanel()}
          <div className="split-view panel-right">
            <CustomComponent onValueSet={this.customValueSet} />
          </div>
        </div>
      );
    }
    return this.renderItemsPanel();
  }

  render() { //
    const { bulkAction } = this.props;
    const {
      isDestructive, label, message, noLabel, yesLabel
    } = bulkAction;
    const { readyForSubmit } = this.state;
    return (
      <div>
        {message && <div><p>{message}</p></div>}
        {this.renderSelectedItems()}
        <div {...buttonsBoxStyles}>
          <Button onClick={this.handleReject}>{noLabel || 'Cancel'}</Button>
          <Button
            autoFocus={readyForSubmit}
            bsStyle={isDestructive ? 'danger' : 'primary'}
            disabled={!readyForSubmit}
            onClick={this.handleApply}
          >
            {yesLabel || label}
          </Button>
        </div>
      </div>
    );
  }
}

BulkActionsModalComponent.propTypes = {
  /**
   * A function that is called when the user applies the bulk action for the selected items.
   * This function is called with one argument: the custom value passed to the _onValueSet_
   * handler provided to the optional custom component (the _render_ prop).
   * Alternatively provide the _applyAction_ prop to the _BulkActionsModal_ container.
   */
  apply: PropTypes.func.isRequired,

  /** The bulk-action specification. */
  bulkAction: bulkActionPropType.isRequired,

  /**
   * A function that takes an item identifier and deselects that item.
   * Alternatively provide the _deselectItemAction_ prop to the _BulkActionsModal_ container.
   */
  deselectItem: PropTypes.func.isRequired,

  /**
   * A function that takes a selected item and returns the label to use for that item.
   * Options, default to `(item) => item.label`.
   */
  getItemLabel: PropTypes.func,

  /** The selector for the items-selection-state. To be provided by the consumer. */
  getItemsSelectionState: PropTypes.func.isRequired,

  /** The selector for the selected items page-load-state. To be provided by the consumer. */
  getPageLoadState: PropTypes.func.isRequired,

  /**
   * A function that should load the items. This function is called with two arguments: 1) an
   * array with the selected item identifiers; and 3) an optional options object.
   * Alternatively provide the _loadItemsAction_ prop to the _BulkActionsModal_ container.
   */
  loadItems: PropTypes.func.isRequired,

  /** The selected items page-load-state. Injected in the connect HOC. */
  loadState: pageLoadStatePropType,

  /** Removes the modal. Injected by {@link ModalManager}. */
  removeModal: PropTypes.func.isRequired,

  /**
   * The identifiers of the selected items (except when all are selected).
   * Injected in the connect HOC.
   */
  selectedIds: PropTypes.arrayOf(PropTypes.string),

  /** The items-selection-state. Injected in the connect HOC. */
  selectionState: itemsSelectionPropType,
};

BulkActionsModalComponent.defaultProps = {
  getItemLabel: (item) => item.label,
};

// -- Container --------------- --- --  -

/**
 * A modal for use in the dxModals framework. This modal is opened when users trigger a bulk-action.
 * It shows the selected items and the bulk-action options.
 *
 * See {@link BulkActionsModalComponent.propTypes} for more information on the required and optional
 * props that should be provided.
 */
export const BulkActionsModal = connect(
  (state, ownProps) => ({
    selectionState: ownProps.getItemsSelectionState(state),
    loadState: ownProps.getPageLoadState(state),
  }),
  (dispatch, ownProps) => {
    const { bulkAction } = ownProps;
    const apply = ownProps.applyAction
      ? (data) => dispatch(ownProps.applyAction(bulkAction, data))
      : (data) => ownProps.apply(bulkAction, data);
    const deselectItem = ownProps.deselectItemAction
      ? (itemId) => dispatch(ownProps.deselectItemAction(itemId))
      : ownProps.deselectItem;
    const loadItems = ownProps.loadItemsAction
      ? (itemIds, options) => dispatch(ownProps.loadItemsAction(bulkAction.collectionId, itemIds, options))
      : (itemIds, options) => ownProps.loadItems(bulkAction.collectionId, itemIds, options);

    return { apply, deselectItem, loadItems };
  },
)(BulkActionsModalComponent);

BulkActionsModal.propTypes = {

  /**
   * An action creator function that takes two arguments:
   *
   * 1) The bulk-action object as originally provided to the {@link BulkActionsBar}.
   * 2) The custom value passed to the _onValueSet_ handler provided to the optional custom
   *    component (the _render_ prop).
   */
  applyAction: PropTypes.func.isRequired,

  /**
   * An action creator function that takes one argument, the item identifier.
   */
  deselectItemAction: PropTypes.func.isRequired,

  /**
   * An action creator function that takes three arguments:
   *
   * 1) the collection identifier;
   * 2) an array with the selected item identifiers;
   * 3) an optional options object.
   */
  loadItemsAction: PropTypes.func.isRequired,

};

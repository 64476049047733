import { NumberField } from './NumberField';

// -- Component --------------- ---  --  -

export class IntField extends NumberField {
  static defaultProps = {
    editMode: false,
    isInt: true,
  };
}

/**
 * Compares two objects and returns true when they are shallowly equal, i.e. if they have the same
 * properties, compared using strict equality.
 * @param {*} a
 * @param {*} b
 * @return {boolean}
 */
export const equalShallow = (a, b) => {
  if (a === b) {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (typeof a === 'object') {
    if (!a && !b) { return true; }
    if (!a && b || a && !b) { return false; }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    try {
      return keysA.length === keysB.length
        && keysA.every((key) => Object.prototype.hasOwnProperty.call(b, key) && a[key] === b[key])
        && keysB.every((key) => Object.prototype.hasOwnProperty.call(a, key));
    } catch (error) {
      console.error(`Error in equalShallow:`, error);
      throw error;
    }
  }
  if (Array.isArray(a)) {
    return a.length === b.length && a.every((val, idx) => b[idx] === val);
  }
  return false;
};

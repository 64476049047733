import { GraphQLID } from 'graphql';
import { capitalize } from '../capitalize';
import { DxScalarType } from './DxScalarType';

/**
 * @typedef {object} FieldConstraints
 * @property {string} [controlled] - e.g. 'duxis/createdBy'
 * @property {EnumFieldConstraint[]} [enum] - The value must be one the enumerated values.
 * @property {number} [maxLength] -
 * @property {number} [minLength] -
 * @property {number} [maxValue] -
 * @property {number} [minValue] -
 * @property {string} [pattern] - The string value must match the given regex-pattern.
 * @property {boolean} [unique] -
 */

/**
 * @typedef {object} EnumFieldConstraint
 * @property {string} [id] -
 * @property {string} [label] -
 * @property {*} value -
 */

/**
 * @typedef {object} FieldFilteringOptions
 * @property {boolean} [searchableText] -
 */

/**
 * Represents the schema for a field (property) in a collection in a Duxis schema.
 */
export class FieldSchema {
  /**
   * @param {string} id - The ID for this field.
   * @param {object} rawSchema - The raw JSON schema.
   * @param {boolean} required - True when this is a required field.
   * @param {CollectionSchema|RelationSchema} parentSchema - The schema of the collection this field belongs to.
   */
  constructor(id, rawSchema, required, parentSchema) {
    this._id = id;
    this._rawSchema = rawSchema;
    this._isRequired = required;
    if (parentSchema.isCollectionSchema) { this._collSchema = parentSchema; }
    if (parentSchema.isRelationSchema) { this._relationSchema = parentSchema; }

    this._idCapped = capitalize(this._id);
    this._constraints = this._rawSchema.constraints || {}; // TODO: add {} when normalizing
    this._isEnum = Boolean(this._constraints.enum);
    this._dxType = DxScalarType.getType(this._rawSchema.type);
    this._graphQLTypeString = this._id === 'id' ? 'ID' : this._dxType.graphQLTypeString;
    this._hidden = this._rawSchema.hidden;

    if (this._rawSchema.orderable === undefined) {
      this._orderable = this._dxType.orderable;
    } else {
      this._orderable = this._rawSchema.orderable;
    }
  }

  // -- Accessors --------------- ---  --  -

  /**
   * @returns {CollectionSchema} The schema of the collection to which this field belongs.
   */
  get collectionScheme() { return this._collSchema; }

  /**
   * @returns {FieldConstraints} The constraints object.
   */
  get constraints() { return this._constraints; }

  /**
   * @returns {*} The default value to use for missing non-controlled values when normalizing fields.
   */
  get defaultValue() { return this._rawSchema.defaultValue; }

  /**
   * @returns {string}
   */
  get description() { return this._rawSchema.description; }

  /**
   * @return {DxScalarType} The {@link DxScalarType} declared for this field.
   */
  get dxType() { return this._dxType; }

  /**
   * @returns {boolean} True when this field should be excluded from the derived DxAPI.
   */
  get excludeFromAPI() { return Boolean(this._rawSchema.excludeFromAPI); }

  /**
   * @returns {FieldFilteringOptions}
   */
  get filtering() { return this._rawSchema.filtering; }

  /**
   * @returns {boolean} True when a default value is declared for this field.
   */
  get hasDefaultValue() { return this._rawSchema.defaultValue !== undefined; }

  /**
   * @returns {string} The field ID.
   */
  get id() { return this._id; }

  /**
   * @return {string} The capitalized field ID.
   */
  get idCapped() { return this._idCapped; }

  /**
   * @returns {boolean} True if this field has a `controlled` constraint.
   */
  get isControlled() { return Boolean(this.constraints.controlled); }

  /**
   * @returns {boolean} True if this field is a 'controlled' field.
   */
  get isEditable() {
    return !this.isControlled && !this.isImmutable;
  }

  /**
   * @returns {boolean} True if an _enum_ constraint is declared for this field.
   */
  get isEnum() { return this._isEnum; }

  /**
   * @returns {boolean} True
   */
  get isFieldSchema() { return true; }

  /**
   * @returns {Boolean}
   */
  get isHidden() { return this._hidden; }

  /**
   * @returns {boolean} True if this field is editable.
   */
  get isImmutable() { return Boolean(this.constraints.immutable); }

  /**
   * @returns {boolean} True if this field is controlled as 'duxis/itemId'.
   */
  get isPrimaryKey() { return this.isControlled && this.constraints.controlled === 'duxis/itemId'; }

  /**
   * @returns {boolean} True when this is a required field.
   */
  get isRequired() { return this._isRequired; }

  /**
   * @returns {boolean} True if this field has the `unique` constraint.
   */
  get isUnique() { return this.constraints.unique; }

  /**
   * @returns {string}
   */
  get label() { return this._rawSchema.label; }

  /**
   * @returns {boolean}
   */
  get orderable() { return this._orderable; }

  /**
   * @returns {CollectionSchema|RelationSchema} The schema of the collection or relation to which
   *   this field belongs.
   */
  get parentSchema() { return this._collSchema || this._relationSchema; }

  /**
   * @returns {object} The raw field schema.
   */
  get rawSchema() { return this._rawSchema; }

  /**
   * @returns {RelationSchema} The schema of the relation to which this field belongs.
   */
  get relationScheme() { return this._relationSchema; }

  /**
   * @returns {string} E.g. 'duxis/boolean', 'duxis/date', etc.
   */
  get type() { return this._rawSchema.type; }

  /**
   * The name for the GraphQL type that is constructed for certain fields, i.e. fields with an 'enum' constraint.
   * @returns {string}
   */
  get typeName() { return this._rawSchema.typeName; }

  // -- Methods --------------- --- --  -

  /**
   * @param {boolean} [applyRequired = true] Adds ! when this field is required.
   * @returns {string} The GraphQL scalar type string for this field.
   */
  gqlTypeString(applyRequired = false) {
    const gqlTypeString = this._graphQLTypeString;
    return (applyRequired && this.isRequired) ? `${gqlTypeString}!` : gqlTypeString;
  }

  /**
   * @returns {*}
   */
  gqlTypeObject() {
    if (!this._graphQLTypeObject) {
      //
      // The following disabled code creates a GraphQLEnumType for fields declared as enums.
      // While seemingly logical, the result is slightly problematic for two reasons: first is that
      // the type should be unique for each enum. Here it is a concatenation of the capitalized
      // name of the collection and the capitalized name of the field. This solution, or any other,
      // cause considerable friction when writing queries. The second problem is that the value
      // is a constant literal from GraphQL point-of-view, but serialization to and from strings
      // results in tricky behaviour.
      //
      // if (this.isEnum) {
      //   // const name = this.gqlTypeString();
      //   const parentId = this.collectionScheme && this.collectionScheme.singularCapped || this.relationScheme.idCapped;
      //   const name = `${parentId}${this.idCapped}`;
      //   logDxAPI.debug(`[FieldSchema.gqlTypeObject] Create new GraphQLEnumType type with name "${name}"` +
      //     ` for enum field "${this.id}" in "${this.parentSchema.id}".`);
      //   let type = enumTypes[name];
      //   if (!type) {
      //     const description = `${this.idCapped} enumeration for ${this.parentSchema.id}.`;
      //     const values = {};
      //     this.constraints.enum.forEach(({ id, value }) => {
      //       values[id] = { value: value || id };
      //     });
      //     type = new GraphQLEnumType({ description, name, values });
      //     enumTypes[type] = type;
      //   }
      //   this._graphQLTypeObject = type;
      // }
      // else {
      //   this._graphQLTypeObject = this.dxType.graphQLTypeObject;
      // }
      this._graphQLTypeObject = this._id === 'id' ? GraphQLID : this.dxType.graphQLTypeObject;
    }
    return this._graphQLTypeObject;
  }

  serialize(value) {
    return this._dxType.serialize(value);
  }

  deserialize(value) {
    return this._dxType.deserialize(value);
  }

  validateValue(value) {
    return this._collSchema.schema.validatePropField(this._collSchema.id, this._id, value);
  }
}

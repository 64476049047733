import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import isDate from 'lodash/isDate';

import { dxFormFieldViewPropTypes, dxFormInputControlPropTypes } from '../../propTypes';

import { InputFFC } from './formFieldComponents/InputFFC';

// -- Component --------------- ---  --  -

/**
 * A field for a date/time.
 */
export class DateField extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.editMode !== nextProps.editMode;
  }

  render() {
    // console.log('[DateField] render');
    const {
      editMode, fieldId, fieldProps, fieldSchema, minValue, maxValue, value, ...otherProps
    } = this.props;

    const childProps = {
      baseValue: value,
      className: 'dx-date-field',
      component: InputFFC,
      controlId: `date-${fieldId}`,
      editMode,
      format: editMode ? editModeFormat : viewModeFormat,
      getInitialValue,
      max: maxValue || (fieldSchema && fieldSchema.constraints.maxValue),
      min: minValue || (fieldSchema && fieldSchema.constraints.minValue),
      name: fieldId,
      parse,
      step: 1,
      type: 'datetime-local',
      ...otherProps,
      ...fieldProps,
    };

    return <Field {...childProps} />;
  }
}

DateField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...dxFormInputControlPropTypes,

  editMode: PropTypes.bool,

  /** Override `maxValue` field schema constraint. TODO: check type requirements */
  maxValue: PropTypes.string,

  /** Override `minValue` field schema constraint. TODO: check type requirements */
  minValue: PropTypes.string,
};

DateField.defaultProps = {
  editMode: false,
};

// -- Support --------------- ---  --  -

function viewModeFormat(val) {
  if (isDate(val)) { return val.toISOString().slice(0, 19); }
  if (val === '' || val === null) { return ''; }
  console.warn(`Unexpected value type ''${typeof val} in JsonField > viewModeFormat(). Returning empty string.`);
  return '';
}

function editModeFormat(val) {
  if (isDate(val)) { return val.toISOString().slice(0, 19); }
  if (val === '' || val === null) { return ''; }
  console.warn(`Unexpected value type "${typeof val}" in JsonField > editModeFormat(). Returning empty string.`);
  return '';
}

function parse(val) {
  return new Date(val);
}

/**
 *
 * @return {*|string}
 */
function getInitialValue() {
  return editModeFormat(new Date());
}

/* eslint-disable no-async-promise-executor */
/* eslint-disable no-plusplus */
// -- Async iteration --------------- --- --  -

/**
 * @typedef {object} AsyncForEachOptions
 * @property {boolean} [concurrent = false] - When true then call the delegates as soon as possible,
 *   else call the next one when the previous one returned or throws.
 * @property {boolean} [ignoreErrors = false] - When true then execute all regardless of errors,
 *   else throw when one of the delegates throws.
 * @property {*} [thisArg] - Value to use as `this` when executing the delegate.
 */

/**
 * Async function that calls the given async callback for each item in the given iterable,
 * sequentially.
 * @param {Iterable|Promise.<Iterable>} iterable
 * @param {Function|Promise.<Function>} callback
 * @param {AsyncForEachOptions} options
 */
export const asyncForEach = async (iterable, callback, options = {}) => {
  iterable = await iterable;
  callback = await callback;
  const { concurrent = false, ignoreErrors = false, thisArg } = options;
  let index = 0;
  if (concurrent) {
    return new Promise(async (resolve, reject) => {
      let toComplete = 0;
      let idx = 0;
      iterable.forEach(async (el) => {
        toComplete++;
        try {
          await callback.call(thisArg, el, idx++);
        } catch (error) {
          if (!ignoreErrors) {
            reject(error);
          }
        }
        if (--toComplete === 0) { resolve(); }
      });
      if (toComplete === 0) { resolve(); }
    });
  }

  // note that for-of loops are async-aware:
  for (const el of iterable) {
    await callback.call(thisArg, el, index++);
  }
};

/**
 * @callback AsyncForInCallback
 * @param {*} value
 * @param {string} key
 */

/**
 * Async function that calls the given async callback for each key/value pair in the given object or
 * map.
 * @param {object|Map|Promise.<object|Map>} object
 * @param {AsyncForInCallback|Promise.<AsyncForInCallback>} callback
 */
export const asyncForIn = async (object, callback) => {
  object = await object;
  callback = await callback;
  if (object instanceof Map) {
    // note that for-of loops are async-aware:
    for (const key of object.keys()) {
      await callback(object.get(key), key);
    }
  } else {
    // note that for-of loops are async-aware:
    for (const key of Object.keys(object)) {
      await callback(object[key], key);
    }
  }
};

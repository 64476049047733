import forIn from 'lodash/forIn';

import { capitalize } from '../capitalize';
import { isString } from '../isString';
import { one, many } from './Arity';
import { FieldSchema } from './FieldSchema';
import { RelateeSchema } from './RelateeSchema';

/**
 * Represents a relation in a dxSchema.
 */
export class RelationSchema {
  /**
   * @param {string} id - The ID for this relation.
   * @param {object} rawSchema - The raw JSON schema.
   * @param {DxSchemaBase} schema - The parent dxSchema object.
   */
  constructor(id, rawSchema, schema) {
    this._id = id;
    this._rawSchema = rawSchema;
    this._schema = schema;

    this._idCapped = capitalize(this._id);
    this._left = new RelateeSchema();
    this._right = new RelateeSchema();
    this._propFields = new Map();
    this._controller = null;
    this._auth = rawSchema.auth || {};

    this._isOneToOne = this.type === 'duxis/oneToOne';
    this._isOneToMany = this.type === 'duxis/oneToMany';
    this._isManyToMany = this.type === 'duxis/manyToMany';

    // Property fields are
    if (this._isManyToMany) {
      forIn(rawSchema.fields, (rawFieldSchema, fieldId) => {
        const fieldSchema = new FieldSchema(fieldId, rawFieldSchema, false, this);
        this._propFields.set(fieldId, fieldSchema);
      });
    }

    const arityL = this._isOneToOne ? one : many;
    const arityR = this._isManyToMany ? many : one;

    this._left.initAlpha('left', rawSchema.left, arityL, true, this._right, this, schema);
    this._right.initAlpha('right', rawSchema.right, arityR, false, this._left, this, schema);

    this._interStore = this.leftStoreId !== this.rightStoreId;

    this._left.initBeta();
    this._right.initBeta();
  }

  // -- Accessors --------------- ---  --  -

  /**
   * @return {Controller} The controller that manages the intermediary table for this relation.
   */
  get controller() { return this._controller; }

  /**
   * @return {Object} The authorization specifics for this relation.
   */
  get auth() { return this._auth; }

  /**
   * @param {Controller} controller - The controller that manages the intermediary table for this
   *   relation.
   * @throws {Error} When this relation does not use an intermediary table.
   */
  set controller(controller) {
    if (!this.isManyToMany) {
      throw new Error(`Cannot set the controller for the "${this.id}" relation, because it does not use an intermediary table..`);
    }
    this._controller = controller;
  }

  // get description() { return this._rawSchema.description; }

  /**
   * @return {FieldSchema[]}
   */
  get fields() { return [...this._propFields.values()]; }

  /**
   * @return {boolean} True when this relation has property fields.
   */
  get hasFields() { return this._propFields.size > 0; }

  /**
   * @returns {string} The Relation ID.
   */
  get id() { return this._id; }

  /**
   * @return {string} The capitalized ID.
   */
  get idCapped() { return this._idCapped; }

  /**
   * @return {boolean} True when this relation relates collections managed by different stores.
   */
  get interStore() { return this._interStore; }

  /**
   * @returns {boolean} True if this is a many-to-many relation.
   */
  get isManyToMany() { return this._isManyToMany; }

  /**
   * @returns {boolean} True if this is a one-to-many relation.
   */
  get isOneToMany() { return this._isOneToMany; }

  /**
   * @returns {boolean} True if this is a one-to-one relation.
   */
  get isOneToOne() { return this._isOneToOne; }

  get isRelationSchema() { return true; }

  /**
   * @returns {RelateeSchema} The left-hand RelateeSchema.
   */
  get left() { return this._left; }

  /**
   * @returns {Arity} The arity of the left-hand relatee, either `Arity.one` or `Arity.many`.
   */
  get leftArity() { return this._left.arity; }

  /**
   * @returns {boolean} True if the left-hand relatee has arity > 1.
   */
  get leftArityMany() { return this._left.arityMany; }

  /**
   * @returns {boolean} True if the left-hand relatee has arity one.
   */
  get leftArityOne() { return this._left.arityOne; }

  /**
   * @returns {string} The ID of the left-hand collection.
   */
  get leftCollectionId() { return this._left.collectionId; }

  /**
   * @return {CollectionSchema}
   */
  get leftCollection() { return this._left.collectionSchema; }

  /**
   * @returns {Controller} The controller for the left-hand relatee collection.
   */
  get leftController() { return this.leftCollection.controller; }

  /**
   * @returns {string} The ID of the left-hand field.
   */
  get leftFieldId() { return this._left.fieldId; }

  /**
   * @returns {string} The id of the store of the left-hand relatee.
   */
  get leftStoreId() { return this.leftCollection.storeId; }

  /**
   * @returns {object} The raw relation-schema object.
   */
  get rawSchema() { return this._rawSchema; }

  /**
   * @returns {string} The relation dependency, if applicable
   */
  get relationDependency() { return this._rawSchema.relationDependency; }

  /**
   * @returns {RelateeSchema} The right-hand RelateeSchema.
   */
  get right() { return this._right; }

  /**
   * @returns {Arity} The arity of the right-hand relatee, either `Arity.one` or `Arity.many`.
   */
  get rightArity() { return this._right.arity; }

  /**
   * @returns {boolean} True if the right-hand relatee has arity > 1.
   */
  get rightArityMany() { return this._right.arityMany; }

  /**
   * @returns {boolean} True if the right-hand relatee has arity one.
   */
  get rightArityOne() { return this._right.arityOne; }

  /**
   * @returns {string} The ID of the right-hand collection.
   */
  get rightCollectionId() { return this._right.collectionId; }

  /**
   * @return {CollectionSchema}
   */
  get rightCollection() { return this._right.collectionSchema; }

  /**
   * @returns {Controller} The controller for the right-hand relatee collection.
   */
  get rightController() { return this.rightCollection.controller; }

  /**
   * @returns {string} The ID of the right-hand field.
   */
  get rightFieldId() { return this._right.fieldId; }

  /**
   * @returns {string} The id of the store of the right-hand relatee.
   */
  get rightStoreId() { return this.rightCollection.storeId; }

  /**
   * @return {DxSchemaBase} The dxSchema that contains this relation.
   */
  get schema() { return this._schema; }

  /**
   * @returns {string} The store id for the right-hand collection.
   */
  get storeId() { return this.rightCollection.storeId; }

  /**
   * @returns {string} The relation type.
   */
  get type() { return this._rawSchema.type; }

  // -- Iterators --------------- --- --  -

  /**
   * Calls the given callback function once for each field in this relation, passing two
   * arguments: 1) the field schema, and 2) the field id.
   * @param {DxSchema_ForEachFieldCallback} callback - Function to execute for each element.
   * @param {*} [thisArg] - Value to use as this when executing callback.
   */
  forEachField(callback, thisArg) {
    this._propFields.forEach(callback, thisArg);
  }

  // -- Accessor Methods --------------- --- --  -

  /**
   * Returns the relatee for the given collection.
   * @param {string|CollectionSchema} collId - Either a collection or its ID.
   * @return {CollectionSchema}
   */
  getRelatee(collId) {
    if (collId.isCollectionSchema) {
      collId = collId.id;
    } else if (!isString(collId)) {
      throw new Error(`Expected a string or a CollectionSchema as "collection", got "${collId}".`);
    }
    if (this.leftCollectionId === collId) {
      return this.leftCollection;
    }
    if (this.rightCollectionId === collId) {
      return this.rightCollection;
    }
    throw new Error(`There is no "${collId}" collection in the "${this.id}" relation to get `
      + 'the relatee for.');
  }
}

RelationSchema.is = (obj) => (obj instanceof RelationSchema);

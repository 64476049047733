import {
  GraphQLBoolean, GraphQLFloat, GraphQLID, GraphQLInt, GraphQLString
} from 'graphql';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import GraphQLJson from 'graphql-type-json';
import { DxScalarType } from './DxScalarType';
import { isString } from '../isString';
import { isUuid } from '../uuid';
import { GraphQLDate } from '../graphql/GraphQLDate';

// -- dxType Constants --------------- --- --  -

export const DX_BOOLEAN = 'duxis/boolean';
export const DX_DATE = 'duxis/date';
export const DX_FLOAT = 'duxis/float';
export const DX_INT = 'duxis/int';
export const DX_JSON = 'duxis/json';
export const DX_RICH_TEXT = 'duxis/rich_text';
export const DX_STRING = 'duxis/string';
export const DX_TEXT = 'duxis/text';
export const DX_UUID = 'duxis/uuid';

// -- GraphQL Scalar Type Declarations --------------- --- --  -

export const DxBoolean = new DxScalarType(DX_BOOLEAN, {
  validate: (value) => isBoolean(value),
  graphQLTypeString: 'Boolean',
  graphQLTypeObject: GraphQLBoolean,
  label: 'boolean',
  orderable: true,
});

// noinspection JSUnresolvedFunction
export const DxDate = new DxScalarType(DX_DATE, {
  validate: (value) => (value instanceof Date),
  serialize: (value) => GraphQLDate.serialize(value),
  deserialize: (value) => GraphQLDate.parseValue(value),
  graphQLTypeString: 'Date',
  graphQLTypeObject: GraphQLDate,
  label: 'date',
  orderable: true,
});

export const DxFloat = new DxScalarType(DX_FLOAT, {
  validate: (value) => isNumber(value),
  graphQLTypeString: 'Float',
  graphQLTypeObject: GraphQLFloat,
  label: 'number (float)',
  orderable: true,
});

export const DxInt = new DxScalarType(DX_INT, {
  validate: (value) => isNumber(value) && Number.isInteger(value),
  graphQLTypeString: 'Int',
  graphQLTypeObject: GraphQLInt,
  label: 'integer number',
  orderable: true,
});

export const DxJSON = new DxScalarType(DX_JSON, {
  validate: (value) => isNumber(value) || isString(value) || isArray(value) || isObject(value),
  graphQLTypeString: 'JSON',
  graphQLTypeObject: GraphQLJson,
  label: 'JSON document',
  orderable: false,
});

export const DxRichText = new DxScalarType(DX_RICH_TEXT, {
  validate: (value) => isString(value),
  graphQLTypeString: 'String',
  graphQLTypeObject: GraphQLString,
  label: 'rich text',
  orderable: false,
});

export const DxString = new DxScalarType(DX_STRING, {
  validate: (value) => isString(value),
  graphQLTypeString: 'String',
  graphQLTypeObject: GraphQLString,
  label: 'string',
  orderable: true,
});

export const DxText = new DxScalarType(DX_TEXT, {
  validate: (value) => isString(value),
  graphQLTypeString: 'String',
  graphQLTypeObject: GraphQLString,
  label: 'text',
  orderable: false,
});

export const DxUUID = new DxScalarType(DX_UUID, {
  validate: isUuid,
  graphQLTypeString: 'ID',
  graphQLTypeObject: GraphQLID,
  label: 'UUID',
  orderable: true,
});

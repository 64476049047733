const isArray = require('lodash/isArray');
const isBoolean = require('lodash/isBoolean');
const isBuffer = require('lodash/isBuffer');
const isFunction = require('lodash/isFunction');
const isNumber = require('lodash/isNumber');
const isObject = require('lodash/isObject');
const isPlainObject = require('lodash/isPlainObject');
const isRegExp = require('lodash/isRegExp');
const isString = require('lodash/isString');

export const toString = (val, { maxDepth = 10, quotes = true, maxStrLen = 50 } = {}) => _toStringRec(val, '', '', '', maxDepth, quotes, [], { maxStrLen }).join('\n');

const _toStringRec = (val, indent = '', prefix = '', postfix = '', depth, quotes, lines = [], options) => {
  if (val === null) {
    lines.push(`${indent}${prefix}null${postfix}`);
  } else if (val === undefined) {
    lines.push(`${indent}${prefix}undefined${postfix}`);
  } else if (isString(val)) {
    const trimmed = _trimString(val, { maxLength: options.maxStrLen });
    val = quotes ? `"${trimmed}"` : trimmed;
    lines.push(`${indent}${prefix}${val}${postfix}`);
  } else if (isBoolean(val) || isRegExp(val) || isNumber(val)) {
    lines.push(`${indent}${prefix}${val}${postfix}`);
  } else if (isFunction(val)) {
    lines.push(`${indent}${prefix}${val.name} (function)${postfix}`);
  } else if (isArray(val)) {
    if (depth === 0) {
      lines.push(`${indent}${prefix}[<pruned>]${postfix}`);
    } else if (val.length === 0) {
      lines.push(`${indent}${prefix}[]${postfix}`);
    } else {
      lines.push(`${indent}${prefix}[`);
      val.forEach((item) => {
        lines = _toStringRec(item, `${indent}  `, '', ',', depth - 1, quotes, lines, options);
      });
      lines.push(`${indent}]${postfix}`);
    }
  } else if (isBuffer(val)) {
    _toStringRec(val.toString(), indent, prefix, postfix, depth, quotes, lines, options);
  } else if (isPlainObject(val)) {
    if (depth === 0) {
      lines.push(`${indent}${prefix}{ <pruned> }${postfix}`);
    } else if (Object.keys(val).length === 0) {
      lines.push(`${indent}${prefix}{}${postfix}`);
    } else {
      lines.push(`${indent}${prefix}{`);
      Object.keys(val).sort().forEach((key) => {
        lines = _toStringRec(val[key], `${indent}  `, `${key}: `, ',', depth - 1, quotes, lines, options);
      });
      lines.push(`${indent}}${postfix}`);
    }
  } else if (isObject(val)) {
    lines.push(`${indent}${prefix}<${val.constructor.name}>${postfix}`);
  } else {
    lines.push(`${indent}${prefix}${val.toString()} (${typeof val})${postfix}`);
  }
  return lines;
};

const _trimString = (str, { maxLength = 50 } = {}) => {
  if (!isString(str)) {
    throw new Error(`Expected string, instead got "${str}".`);
  }
  str = str.split('\n').join('\\n');
  if (maxLength > 0 && str.length > (maxLength - 4)) {
    str = str.substr(0, (maxLength - 4)).concat(' ...');
  }
  return str;
};

import classNames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';
import { isString } from '../../../utils/isString';

import { bulkActionPropType } from '../../propTypes/bulkActionPropTypes';
import { dxColors, dxStyles } from '../../styles';
import { ActionBar } from '../actionBar/ActionBar';

// -- Styles --------------- --- --  -

const defaultStyles = css([
  dxStyles.boxShadowUp,
  {
    backgroundColor: 'white',
    bottom: 0,
    display: 'block',
    height: '75px',
    left: 0,
    position: 'fixed',
    width: '100%',
    '> .dx-action-bar-container': {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '960px',
      margin: '0 auto',
      minWidth: '640px',
      padding: '16px 32px',
      width: '90%',
      '> .dx-multi-select-info': {
        display: 'flex',
        '> .dx-item-count': {
          alignSelf: 'flex-start',
          backgroundColor: dxColors.bondiBlue,
          borderRadius: '3px',
          color: 'white',
          fontWeight: 'bold',
          padding: '0 5px',
          marginRight: '10px',
          marginTop: '4px',
        },
        '> .dx-multi-select-count-text': {
          display: 'flex',
          flexDirection: 'column',
          '> .dx-multi-select-primary': {
            lineHeight: '28px',
          },
          '> .dx-multi-select-meta': {
            color: dxColors.fgLighter_2,
            fontSize: '12px',
            fontStyle: 'italic',
            lineHeight: '10px',
          },
        },
      },
    },
  },
]);

const bulkActionStyles = css({
  padding: '14px 0 8px 20px',
  '> .dx-bulk-action-label': {
    cursor: 'pointer',
    lineHeight: 1.6,
    margin: '0 20px',
    textTransform: 'uppercase',
  },
  '> .dx-action-divider-left': {
    borderLeft: `1px solid ${dxColors.bgDarker_1}`,
    paddingLeft: '20px',
  },
  '> .dx-action-divider-right': {
    borderRight: `1px solid ${dxColors.bgDarker_1}`,
    paddingRight: '20px',
  },
});

const iconStyles = css({
  cursor: 'pointer',
  height: '15px',
  width: '15px',
});

// -- Component --------------- --- --  -

export class BulkActionsBar extends Component {
  constructor(props) {
    super(props);
    this.handleBulkActionInit = this.handleBulkActionInit.bind(this);
    this.handleDeselectAll = this.handleDeselectAll.bind(this);
    this.renderBulkAction = this.renderBulkAction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleDeselectAll);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleDeselectAll);
  }

  handleBulkActionInit(bulkAction) {
    this.props.bulkActionInit(bulkAction);
  }

  handleDeselectAll(evt) {
    if (evt.key === 'Escape') {
      this.props.deselectAll();
    }
  }

  renderBulkAction(bulkAction, id) {
    const { actionProps, divider, icon: Icon } = bulkAction;
    let { label } = bulkAction;
    if (isFunction(label)) {
      label = label(actionProps);
    }
    const dividerClass = {
      'dx-action-divider-left': divider === 'left',
      'dx-action-divider-right': divider === 'right',
    };
    const className = Icon ? 'dx-bulk-action-icon' : 'dx-bulk-action-label';

    const icon = () => {
      if (Icon && isString(Icon)) return <img src={Icon} {...iconStyles} alt="bulk-action-icon" />;
      if (Icon) return <Icon />;
      return label;
    };

    return (
      <span
        className={classNames(className, dividerClass)}
        key={id}
        onClick={() => this.handleBulkActionInit(bulkAction)}
      >
        {icon()}
      </span>
    );
  }

  render() {
    const {
      bulkActions, pluralCapped, selectedCount, singularCapped
    } = this.props;
    return (
      <ActionBar styles={defaultStyles} className="dx-bulk-actions-action-bar">
        <div className="dx-action-bar-container">
          <div className="dx-multi-select-info">
            <span className="dx-item-count">{`${selectedCount}`}</span>
            <div className="dx-multi-select-count-text">
              <div className="dx-multi-select-primary">
                {
                  selectedCount > 1
                    ? `${pluralCapped || 'Item'} selected`
                    : `${singularCapped || 'Items'} selected`
                }
              </div>
              <div className="dx-multi-select-meta">press esc to clear selection</div>
            </div>
          </div>
          <div {...bulkActionStyles} className="dx-bulk-actions">
            {bulkActions.map(this.renderBulkAction)}
          </div>
        </div>
      </ActionBar>
    );
  }
}

BulkActionsBar.propTypes = {
  /** Dispatches the action to open the bulk-command modal interface. */
  bulkActionInit: PropTypes.func.isRequired,

  /** The bulk-commands. */
  bulkActions: PropTypes.arrayOf(bulkActionPropType),

  /** Dispatches the action to deselect all items. */
  deselectAll: PropTypes.func.isRequired,

  /** A category label for the items, e.g. "Projects". */
  pluralCapped: PropTypes.string,

  /** The number of selected items. */
  selectedCount: PropTypes.number.isRequired,

  /** A category label for one item, e.g. "Project". */
  singularCapped: PropTypes.string,
};

BulkActionsBar.defaultProps = {
  bulkActions: [],
};

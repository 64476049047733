import invariant from 'invariant';
import isArray from 'lodash/isArray';
import { isString } from '../../utils/isString';

import { ofType } from './loadStateSupport';

// -- Setup --------------- ---  --  -

/**
 * @typedef {object} ItemsSelectionReducerOptions
 * @properties {string|Array.<string>|Function.<string>:boolean} [deselectActionType] - The type of
 *   the action that is dispatched when an item (or a set of items) should be deselected. The action
 *   should have an _id_ or _ids_ property with the identifier(s) of the to be deselected items.
 * @properties {string|Array.<string>|Function.<string>:boolean} [deselectAllActionType] - The type
 *   of the action that is dispatched when all items should be deselected.
 * @properties {string|Array.<string>|Function.<string>:boolean} [selectActionType] - The type of
 *   the action that is dispatched when an item (or a set of items) should be selected. The action
 *   should have an _id_ or _ids_ property with the identifier(s) of the to be selected items.
 * @properties {string|Array.<string>|Function.<string>:boolean} [selectAllActionType] - The type of
 *   the action that is dispatched when all items should be selected.
 * @properties {string|Array.<string>|Function.<string>:boolean} [toggleActionType] - The type of
 *   the action that is dispatched when an item (or a set of items) should be selected when it is
 *   not, of vice versa. The action should have an _id_ or _ids_ property with the identifier(s) of
 *   the to be toggled items.
 */

/**
 * @typedef {object} ItemsSelectionState
 * @property {boolean} allSelected - True when all items are selected.
 * @property {number} count - The number of selected items or NaN when all are selected.
 * @property {boolean} isItemsSelection - True.
 * @property {object} selected - An object that has true for each selected item identifier key.
 */

/**
 * A higher-order function that returns a Redux reducer for managing a selection of items.
 * @param {ItemsSelectionReducerOptions} options
 */
export const createItemsSelectionReducer = (options = {}) => {
  const {
    deselectActionType,
    deselectAllActionType,
    selectActionType,
    selectAllActionType,
    toggleActionType,
  } = options;
  const defaultState = {
    allSelected: false,
    count: 0,
    isItemsSelection: true,
    selected: {},
  };
  return (state = defaultState, action) => {
    // console.log('>>> itemsSelectionReducer() --', { state, action });
    if (ofType(action, selectActionType)) {
      const { allSelected, selected } = state;
      const { id, ids } = action;
      invariant(isArray(ids) || isString(id),
        `Expected an array as "ids" or a string as "id" in the action, got "${action}" as action.`);
      if (allSelected) {
        const next = addSelected(selected, ids, id);
        return {
          ...state, allSelected: false, count: getCount(next), selected: next
        };
      }
      const next = addSelected(selected, ids, id);
      return selected === next ? state : { ...state, count: getCount(next), selected: next };
    }
    if (ofType(action, deselectActionType)) {
      const { allSelected, selected } = state;
      const { id, ids } = action;
      invariant(isArray(ids) || isString(id),
        `Expected an array as "ids" or a string as "id" in the action, got "${action}" as action.`);
      if (allSelected) {
        return { ...state, allSelected: false, count: 0 };
      }
      const next = removeSelected(selected, ids, id);
      return selected === next ? state : { ...state, count: getCount(next), selected: next };
    }
    if (ofType(action, toggleActionType)) {
      const { allSelected, selected } = state;
      const { id, ids } = action;
      invariant(isArray(ids) || isString(id),
        `Expected an array as "ids" or a string as "id" in the action, got "${action}" as action.`);
      if (allSelected) {
        const next = addSelected(selected, ids, id);
        return {
          ...state, allSelected: false, count: getCount(next), selected: next
        };
      }
      const next = toggleSelected(selected, ids, id);
      return selected === next ? state : { ...state, count: getCount(next), selected: next };
    }
    if (ofType(action, selectAllActionType)) {
      const { allSelected, count } = state;
      if (allSelected) {
        return state;
      }
      if (count > 0) {
        return {
          ...state, allSelected: true, count: NaN, selected: {}
        };
      }
      return { ...state, allSelected: true, count: NaN };
    }
    if (ofType(action, deselectAllActionType)) {
      const { allSelected, count } = state;
      if (allSelected) {
        return { ...state, allSelected: false, count: 0 };
      }
      if (count > 0) {
        return { ...state, count: 0, selected: {} };
      }
      return state;
    }
    return state;
  };
};

// -- Helper Functions --------------- ---  --  -

const addSelected = (dict, ids, id) => {
  if (ids) {
    return ids.reduce((result, id2) => addId(result, id2), dict);
  }
  return addId(dict, id);
};

const addId = (dict, id) => (dict[id] ? dict : { ...dict, [id]: true });

const removeSelected = (dict, ids, id) => {
  if (ids) {
    return ids.reduce((result, id2) => removeId(result, id2), dict);
  }
  return removeId(dict, id);
};

const removeId = (dict, id) => {
  if (dict[id]) {
    const { [id]: __, ...rest } = dict;
    return rest;
  }
  return dict;
};

const toggleSelected = (dict, ids, id) => {
  if (ids) {
    return ids.reduce((result, id2) => toggleId(result, id2), dict);
  }
  return toggleId(dict, id);
};

const toggleId = (dict, id) => {
  if (dict[id]) {
    const { [id]: __, ...rest } = dict;
    return rest;
  }
  return { ...dict, [id]: true };
};

const getCount = (dict) => Object.keys(dict).length;

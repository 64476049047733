import React from 'react';
import { Field } from 'redux-form';
import isObject from 'lodash/isObject';
import { isString } from '../../../utils/isString';

import { dxFormFieldViewPropTypes } from '../../propTypes';

import { TextareaFFC, textAreaControlPropTypes } from './formFieldComponents/TextareaFFC';

// -- Component --------------- ---  --  -

export class JsonField extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.editMode !== nextProps.editMode;
  }

  render() {
    // console.log('[JsonField] render');
    const {
      fieldId, fieldProps, fieldSchema, value, ...otherProps
    } = this.props;
    const childProps = {
      baseValue: value,
      className: 'dx-json-field',
      component: TextareaFFC,
      controlId: `textarea-${fieldId}`,
      format,
      name: fieldId,
      parse,
      staticComponentClass: 'pre',
      ...otherProps,
      ...fieldProps,
    };
    return <Field {...childProps} />;
  }
}

JsonField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...textAreaControlPropTypes,
};

JsonField.defaultProps = {
  editMode: false,
  rows: 8,
};

// -- Support --------------- ---  --  -

function format(val) {
  if (isObject(val)) {
    return JSON.stringify(val, null, 2);
  }
  if (isString(val)) {
    // Note: As long as the input in the form control is not proper JSON, the `parse` function will
    // return a string, which is fed back into this `format` function, and should be returned as is.
    return val;
  }
  if (val === null) { return ''; }
  console.warn(`Unexpected value type "${typeof val}" in JsonField > format(). Returning empty string.`);
  return '';
}

function parse(string) {
  try {
    return JSON.parse(string);
  } catch (error) {
    // Note: When the parsing fails because the input in the form control is not proper JSON, then
    // the value is returned as a string, which will cause the type validator in the schema to fail,
    // which will cause the react-bootstrap <FormGroup/> component to show an error message.
    return string;
  }
}

import { immute } from '../../utils/immute';

import {
  DX_DMS_ITEM_CREATE_FAILED,
  DX_DMS_ITEM_CREATE_INIT,
  DX_DMS_ITEM_CREATE_SUCCESS,
  DX_DMS_ITEM_DESELECT,
  DX_DMS_ITEM_FETCH,
  DX_DMS_ITEM_FETCH_SUCCESS,
  DX_DMS_ITEM_SELECT,
  DX_DMS_ITEM_UPDATE_COMMIT,
  DX_DMS_ITEM_UPDATE_FAILED,
  DX_DMS_ITEM_UPDATE_SUCCESS,
  DX_DMS_ITEMS_DESELECT_ALL,
  DX_DMS_ITEMS_SELECT_ALL,
  DX_DMS_MOUNT,
  DX_DMS_PAGE_FETCH,
  DX_DMS_PAGE_FETCH_SUCCESS,
  DX_DMS_RELATEE_OPTIONS_FETCH,
  DX_DMS_RELATEE_OPTIONS_FETCH_SUCCESS,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH,
  DX_DMS_SELECTED_ITEMS_PAGE_FETCH_SUCCESS,
} from '../constants';
import { isItemSelected } from '../selectors';

import { createItemLoadStateReducer } from './itemLoadStateReducer';
import { createPageLoadStateReducer } from './pageLoadStateReducer';
import { createItemsSelectionReducer } from './itemsSelectionReducer';

// -- dxDMS Reducer --------------- --- --  -

/**
 * @typedef {object} DMSState
 * @property {DMSConfig} config - The DMS configuration object.
 * @property {ItemLoadState} item - The load-state for the current item.
 * @property {DMSItemsState} items - The state for the current items and items selection.
 * @property {PageLoadState} relatees - The load-state for the current page of relatees.
 */

/**
 * @typedef {object} DMSItemsState
 * @property {PageLoadState} page - The load-state for the current page of items.
 * @property {PageLoadState} selectedItemsPage - The load-state for the current page of selected
 *   items.
 * @property {ItemsSelectionState} selection - The items selection state.
 */

/**
 * @type {DMSState}
 */
const initialState = {
  config: undefined,
  item: undefined,
  items: undefined,
  relatees: undefined,
};

let itemPageReducer; let selectionReducer; let itemsPageReducer; let relateesPageReducer; let
  selectedItemsPageReducer;

/**
 * @param {DMSState} state
 * @param {{ type: string }} action
 * @return {DMSState}
 */
export const dxDMSReducer = (state = initialState, action) => {
  if (!action) { return state; }

  // handle this specific case so we can use a switch statement for the rest
  if (!state.config && action.type !== DX_DMS_MOUNT) { return state; }

  switch (action.type) {
    case DX_DMS_MOUNT: {
      const { dmsConfig } = action;
      if (state.config && state.config === dmsConfig) { return state; }

      // The load state of the item details
      itemPageReducer = createItemLoadStateReducer(
        DX_DMS_ITEM_FETCH,
        DX_DMS_ITEM_FETCH_SUCCESS,
        {
          createInitActionType: DX_DMS_ITEM_CREATE_INIT,
          createFailedActionType: DX_DMS_ITEM_CREATE_FAILED,
          createSuccessActionType: DX_DMS_ITEM_CREATE_SUCCESS,
          updateCommitActionType: DX_DMS_ITEM_UPDATE_COMMIT,
          updateFailedActionType: DX_DMS_ITEM_UPDATE_FAILED,
          updateSuccessActionType: DX_DMS_ITEM_UPDATE_SUCCESS,
        }
      );

      // The load-state of the page of selected items
      selectionReducer = createItemsSelectionReducer({
        deselectActionType: DX_DMS_ITEM_DESELECT,
        deselectAllActionType: DX_DMS_ITEMS_DESELECT_ALL,
        selectActionType: DX_DMS_ITEM_SELECT,
        selectAllActionType: DX_DMS_ITEMS_SELECT_ALL,
      });

      // The load-state for the page of items shown in a collection view
      itemsPageReducer = createPageLoadStateReducer(
        DX_DMS_PAGE_FETCH,
        DX_DMS_PAGE_FETCH_SUCCESS,
      );

      // The load-state for the page of selected items shown in a bulk actions modal
      selectedItemsPageReducer = createPageLoadStateReducer(
        DX_DMS_SELECTED_ITEMS_PAGE_FETCH,
        DX_DMS_SELECTED_ITEMS_PAGE_FETCH_SUCCESS,
      );

      // The load-state for the relatee options
      relateesPageReducer = createPageLoadStateReducer(
        DX_DMS_RELATEE_OPTIONS_FETCH,
        DX_DMS_RELATEE_OPTIONS_FETCH_SUCCESS,
      );

      return {
        config: dmsConfig,
        item: itemPageReducer(undefined, action),
        items: {
          page: itemsPageReducer(undefined, action),
          selectedItemsPage: selectedItemsPageReducer(undefined, action),
          selection: selectionReducer(),
        },
        relatees: relateesPageReducer(undefined, action),
      };
    }

    case DX_DMS_ITEM_DESELECT:
    case DX_DMS_ITEM_SELECT:
    case DX_DMS_ITEMS_DESELECT_ALL:
    case DX_DMS_ITEMS_SELECT_ALL: {
      // update selection and items.page.items.selected
      const selection = selectionReducer(state.items.selection, action);
      return {
        ...state,
        items: {
          ...state.items,
          page: selection === state.items.selection
            ? state.items.page
            : updateSelected(state.items.page, selection),
          selection,
        },
      };
    }

    case DX_DMS_PAGE_FETCH_SUCCESS: {
      // set all the page.items.selected properties
      const page = updateSelected(
        itemsPageReducer(state.items.page, action),
        state.items.selection
      );
      return { ...state, items: { ...state.items, page } };
    }

    default:
      // delegate the actions to the connected reducers
      return immute(state, {
        ...state,
        item: itemPageReducer(state.item, action),
        items: immute(state.items, {
          ...state.items,
          page: itemsPageReducer(state.items.page, action),
          selectedItemsPage: selectedItemsPageReducer(state.items.selectedItemsPage, action),
          selection: selectionReducer(state.items.selection, action),
        }),
        relatees: relateesPageReducer(state.relatees, action),
      });
  }
};

// -- Helper Functions --------------- --- --  -

/**
 * @private
 * This helper returns the original item/items state objects when their content did not change,
 * in order to avoid unnecessary re-rendering.
 * @param {PageLoadState} page
 * @param {ItemsSelectionState} selection
 * @return {PageLoadState}
 */
const updateSelected = (page, selection) => {
  if (!page) { throw new Error('page is undefined in dxDMSReducer.updateSelected'); }
  let updated = false;
  const itemsNxt = page.items.map((item) => {
    const selected = isItemSelected(selection, item.id);
    if (selected === Boolean(item.selected)) {
      return item;
    }

    updated = true;
    return { ...item, selected };
  });
  if (updated) {
    return { ...page, items: itemsNxt };
  }
  return page;
};

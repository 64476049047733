import classnames from 'classnames';
import { css } from 'glamor';
import React, { Component } from 'react';

import { modalPropTypes } from './modalPropTypes';

// -- Styles --------------- --- --  -

export const defaultStyles = css({
  // height: '100vh', // causes an extra scrollbar
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  bottom: 0, // allow overflow scroll
  '> .dx-modal-content': {
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    width: '100%',
    zIndex: 1,
    '> .dx-modal-panel': {
      backgroundColor: '#fcfcfc',
      borderRadius: '4px',
      boxShadow: '0px 0px 20px #333',
      margin: '15vh auto',
      overflow: 'hidden',
      '> .dx-modal-header': {
        borderBottom: '1px solid #dbdbdb',
        boxSizing: 'content-box',
        color: '#444',
        display: 'flex',
        height: '60',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        '> h2': {
          boxSizing: 'content-box',
          // float: 'left',
          fontFamily: 'Raleway Bold',
          fontSize: '1.4em',
          fontWeight: 600,
          height: '60',
          lineHeight: '60px',
          margin: 0,
          overflow: 'hidden',
          padding: '0px 20px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '> .dx-modal-header-action': {
          height: '100%',
          textAlign: 'center',
          '> button': {
            backgroundColor: 'transparent',
            border: '1px solid transparent',
            borderRadius: '50%',
            color: '#666',
            // float: 'right',
            fontSize: '1.2em',
            height: 40,
            lineHeight: '40px',
            margin: '10px 8px',
            padding: 0,
            position: 'relative',
            textAlign: 'center',
            width: 40,
            // '&:focus': { outline: 0 },
            ':hover, :active, :focus': {
              backgroundColor: 'rgba(65, 134, 191, .1)',
              borderColor: 'rgba(65, 134, 191, .2)',
              boxShadow: '2px 2px 3px #f0f0f0',
              color: '#4186bf',
              cursor: 'pointer',
            },
          },
        },
      },
      '> .dx-modal-body': {
        boxSizing: 'border-box',
        padding: '20px',
        width: '100%',
      },
      '&.xl': { width: 960, maxWidth: '90%', '> .dx-modal-header > h2': { width: 902 } },
      '&.large': { width: 700, '> .dx-modal-header > h2': { width: 642 } },
      '&.medium': { width: 500, '> .dx-modal-header > h2': { width: 442 } },
      '&.small': { width: 300, '> .dx-modal-header > h2': { width: 242 } },
    },
  },
  '> .dx-modal-mask': {
    height: '100vh',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(50, 58, 68, .2)',
  },
});

// -- Component --------------- --- --  -

/**
 * Generic modal component. The content is determined by the `component` and `options` props.
 *
 * Derived from https://github.com/ErrorPro/react-redux-modal
 */
export class Modal extends Component {
  constructor(props) {
    super(props);

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.id !== nextProps.id;
  }

  handleOutsideClick(event) {
    const { options } = this.props;
    if (options && options.closeOnOutsideClick && !isChildOf(event.target, this.panel)) {
      this.handleRemove();
    }
  }

  handleRemove() {
    this.props.removeModal(this.props.id);
  }

  render() {
    // console.log('[Modal] render');
    const {
      component, id, index, options = {}, styles
    } = this.props;
    const {
      hideCloseButton = false,
      hideTitleBar = false,
      size = 'medium',
      title = 'Attention',
    } = options;

    const contentProps = {
      modalId: id,
      removeModal: this.handleRemove,
      ...options,
    };

    return (
      <div
        className="dx-modal-container"
        {...css(defaultStyles, styles, { zIndex: `998${index}` })}
      >
        <div className="dx-modal-content" onClick={this.handleOutsideClick}>
          <div className={classnames('dx-modal-panel', size)} ref={(node) => { this.panel = node; }}>
            {hideTitleBar ? null : (
              <div className="dx-modal-header">
                <h2>{title}</h2>
                <div className="dx-modal-header-action">
                  {hideCloseButton ? null : (
                    <button
                      type="button"
                      className="rr-close rrm-icon-cancel"
                      onClick={this.handleRemove}
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
            )}
            <div className="dx-modal-body">
              {React.createElement(component, contentProps)}
            </div>
          </div>
        </div>
        <div className="dx-modal-mask" />
      </div>
    );
  }
}

Modal.propTypes = modalPropTypes;

// -- Helpers --------------- --- --  -

const isChildOf = (child, parent) => child.parentNode === parent || child.parentNode !== null && isChildOf(child.parentNode, parent);

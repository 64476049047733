import React, { Component } from 'react';
import {
  ControlLabel, FormControl, FormGroup, HelpBlock
} from 'react-bootstrap';

import { dxFormFieldControlPropTypes } from '../../../propTypes';

// -- Component --------------- ---  --  -

export class FormControlFFC extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
  }

  /**
   * When the base value is null and a default-value getter (`getInitialValue`) is provided, then
   * when the <input/> element is focused on, its value is initialized to this initial value.
   * @param {*} args
   */
  handleFocus(...args) {
    const { baseValue, getInitialValue, input } = this.props;
    if (baseValue === null && getInitialValue) {
      input.onChange(getInitialValue());
      input.onFocus(...args);
    } else {
      input.onFocus(...args);
    }
  }

  render() {
    // console.log('[FormControlFFC] render');
    if (this.props.controlProps) {
      throw new Error('You can no longer use the `controlProps` prop to pass props to the '
        + '`FormControl` component. Simply add your custom props for the `FormControl` component '
        + 'as props in the `*Field` component. They will be passed to the `FormControl` component.');
    }

    // noinspection JSUnusedLocalSymbols - remove all unneeded vars from otherProps
    const {
      baseValue,
      children,
      className,
      controlId,
      editMode,
      getInitialValue,
      input,
      label,
      meta: {
        active, dirty, error, warning, touched
      },
      staticComponentClass,
      styles,
      ...otherProps
    } = this.props;

    const formControlProps = {
      ...input,
      onFocus: this.handleFocus, // customize onFocus
      readOnly: !editMode,
      // placeholder: label,
      ...otherProps,
    };

    let validationState; let
      help;
    // touched is similar to 'lostFocus' as in the user has interacted with the input
    // this prevents the form from displaying an error when the form first opens
    if (!active && touched) {
      if (error) {
        validationState = 'error';
        help = error;
      } else if (warning) {
        validationState = 'warning';
        help = warning;
      } else if (dirty && !(baseValue === null && input.value === '')) {
        validationState = 'success';
      }
    }

    const groupProps = {
      className: editMode ? `${className} dx-field` : `${className} dx-field disabled`,
      controlId,
      validationState,
    };

    if (editMode) {
      // console.log('- FormGroup:', FormGroup);
      // console.log('- ControlLabel:', ControlLabel);
      // console.log('- FormControl:', FormControl);
      // console.log('- FormControl.Feedback:', FormControl.Feedback);
      return (
        <FormGroup {...groupProps} {...styles}>
          <ControlLabel>{label}</ControlLabel>
          <div className="dx-form-group">
            <FormControl {...formControlProps}>{children}</FormControl>
            <FormControl.Feedback />
            { help && <HelpBlock>{help}</HelpBlock>}
          </div>
        </FormGroup>
      );
    }

    formControlProps.tabIndex = '-1';
    formControlProps.disabled = true;
    return (
      <FormGroup {...groupProps} {...styles}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...formControlProps}>{children}</FormControl>
      </FormGroup>
    );
    // Former setup:
    // <FormControl.Static componentClass={staticComponentClass}>{input.value}</FormControl.Static>
  }
}

FormControlFFC.propTypes = dxFormFieldControlPropTypes;

FormControlFFC.defaultProps = {
  componentClass: 'input',
};

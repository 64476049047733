import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../../propTypes';
// import { dxStyles } from '../../styles';

// -- Styles --------------- --- --  -

const defaultStyles = css({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-end',
  margin: '16px 0 0 0',
});

// -- Component --------------- --- --  -

/**
 * @experimental
 * You would typically use ActionButtons as children.
 */
export const ActionBar = (props) => (
  <div
    className={classnames('dx-action-bar', props.className)}
    {...css(defaultStyles, props.styles)}
  >
    {props.children}
  </div>
);

ActionBar.propTypes = {
  /** The action buttons. */
  children: PropTypes.node,

  /** Optional additional class name. */
  className: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,
};

import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import deleteIcon from '../../assets/icons/delete.svg';
import {
  bulkActionPropType,
  cssPropType,
  itemsSelectionPropType,
  pageLoadStatePropTypesOf,
} from '../../propTypes';
import {
  getItemsSelectionAllSelected,
  getItemsSelectionCount,
  getItemsSelectionHasSelected,
  getPageLoadStateItems,
} from '../../selectors';
import { dxColors, dxStyles } from '../../styles';
import { /* ActionBar, */ ActionButton } from '../actionBar';
import { DxErrorDetails } from '../DxErrorDetails';
import CheckBox from '../form/CheckBox';
import { Section } from '../Section';
import { Spinner } from '../Spinner';
import { Toolbar, ToolbarButton, ToolbarPagination } from '../toolbar';

import { BulkActionsBar } from './BulkActionsBar';

// -- Styles --------------- --- --  -

const sectionStyles = css(dxStyles.sectionPadding, {
  background: 'white',
  '> .dx-toolbar': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  '> .dx-toolbar.no-pagination': { marginTop: '12px', },
  '> ul': [dxStyles.thinBorderBottom, {
    alignItems: 'stretch',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column nowrap',
    listStyleType: 'none',
    margin: '0 0 16px 0',
    padding: 0,
  }],
});

const listItemStyles = css([dxStyles.thinBorderTop, {
  alignItems: 'stretch',
  display: 'flex',
  justifyContent: 'space-between',
  '> .item-body': {
    color: dxColors.fgMain,
    display: 'block',
    padding: '8px',
    textDecoration: 'none',
    maxWidth: '95%',
    '> .item-label': {
      display: 'block',
      fontSize: '1.3em',
      fontWeight: '600',
      height: '24px',
      lineHeight: '24px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  '> a.delete-btn': { ':hover, :active, :focus': { backgroundColor: dxColors.bgFocus, } },
  '> a.delete-btn > span.helper': {
    // this helper keeps the icon vertically centered
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
  },
  '> a.delete-btn > img, > img.delete-btn': {
    height: '28px',
    padding: '6px',
    verticalAlign: 'middle',
    width: '28px',
  },
  '> img.delete-btn': { opacity: '.5' },
  '&.deleting': { background: 'rgb(250, 225, 225)', },
  '&, :hover, :active, :focus': {
    color: dxColors.fgMain,
    textDecoration: 'none',
  },
  ':hover, :active, :focus': {
    backgroundColor: dxColors.bgFocus,
  },
}]);

const toolbarButtonStyles = css({
  border: 'none',
});

// -- Component --------------- --- --  -

/**
 * A generic component that renders a module with an items list. The rendering of the items in this
 * list is delegated to the provided ItemComponent.
 */
export class ItemsList extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  constructor(props) {
    super(props);
    this.handlePageTo = this.handlePageTo.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.toggleSelectItem = this.toggleSelectItem.bind(this);
    this.state = {
      searchTerm: '',
    };
  }

  componentDidMount() {
    if (this.props.canView) { this.props.loadItems({ from: 0, limit: 10000 }); }
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info.componentStack);
  }

  handlePageTo() {
    const { canView, loadItems, loadState: { ordering } } = this.props;
    if (canView) {
      loadItems({ from: 0, limit: 10000, ordering });
    }
  }

  get enableBulkActions() {
    const { bulkActions } = this.props;
    return bulkActions && bulkActions.length > 0;
  }

  get selectedCount() {
    const { loadState: { total }, selection } = this.props;
    if (this.enableBulkActions && getItemsSelectionHasSelected(selection)) {
      return getItemsSelectionAllSelected(selection) ? total : getItemsSelectionCount(selection);
    }
    return 0;
  }

  toggleSelectItem(item) {
    const { deselectItem, selectItem } = this.props;
    return item.selected ? deselectItem(item.id) : selectItem(item.id);
  }

  toggleSelectAll() {
    const { deselectAll, selectAll, selection } = this.props;
    if (getItemsSelectionAllSelected(selection)) {
      deselectAll();
    } else {
      selectAll();
    }
  }

  renderCheckbox(item) {
    return (
      <CheckBox
        className="selected-chk"
        checked={item.selected}
        onChange={() => this.toggleSelectItem(item)}
      />
    );
  }

  renderDeleteBtn(item) {
    const { canDelete, deleteItem } = this.props;
    if (canDelete && deleteItem) {
      if (item.__deleting__ || !canDelete) {
        return (
          <img className="delete-btn" src={deleteIcon} alt="Delete Item" />
        );
      }
      return (
        <a className="delete-btn" onClick={() => deleteItem(item.id)}>
          <span className="helper" />
          <img src={deleteIcon} alt="Delete Item" />
        </a>
      );
    }
    return null;
  }

  renderDefaultItems(items) {
    return (
      <ul>
        {items.map((item) => (item.hideListItem ? null : (
          <li className="dx-list-item" key={item.id} {...listItemStyles}>
            {this.enableBulkActions && this.renderCheckbox(item)}
            <div className="item-body">
              <Link className="item-label" to={item.path}>{item.label}</Link>
            </div>
            {this.props.showDeleteButton && this.renderDeleteBtn(item)}
          </li>
        )))}
      </ul>
    );
  }

  renderItems() {
    const { loadState } = this.props;
    const { searchTerm } = this.state;

    if (loadState.loading) {
      return <Spinner />;
    }
    const {
      canDelete, deleteItem, ItemComponent, renderItems, silentDelete
    } = this.props;
    const items = getPageLoadStateItems(loadState);
    const filteredItems = searchTerm ? items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())) : items;

    if (ItemComponent) {
      const itemProps = { canDelete, deleteItem, silentDelete };
      return (
        <ul>
          {filteredItems.map((item) => (
            <li key={item.id} {...listItemStyles}>
              <ItemComponent item={item} {...itemProps} />
            </li>
          ))}
        </ul>
      );
    }
    if (renderItems) { return renderItems(filteredItems); }
    return this.renderDefaultItems(filteredItems);
  }

  renderCreateButton() {
    const { createItem, createPath, itemLabel } = this.props;
    if (createItem) {
      return <ActionButton primary onClick={createItem} label={`Add ${itemLabel}`} placement="right" />;
    }
    if (createPath) {
      return <ActionButton primary link={createPath} label={`Add ${itemLabel}`} placement="right" />;
    }
    throw new Error('Either the "createItem" or the "createPath" prop should be provided to '
      + 'ItemsList when create-mode is active.');
  }

  renderBulkActionsBar() {
    const {
      bulkActionInit, bulkActions, deselectAll, pluralCapped
    } = this.props;
    const { selection, singularCapped } = this.props;
    if (this.enableBulkActions && getItemsSelectionHasSelected(selection)) {
      return (
        <BulkActionsBar
          bulkActionInit={bulkActionInit}
          bulkActions={bulkActions}
          deselectAll={deselectAll}
          pluralCapped={pluralCapped}
          selectedCount={this.selectedCount}
          singularCapped={singularCapped}
        />
      );
    }
  }

  renderPagination(total) {
    const { from, limit } = this.props.loadState;
    return (
      <ToolbarPagination
        activePage={Math.floor(from / limit) + 1}
        maxButtons={0}
        noBorders
        pageCount={Math.max(1, Math.ceil(total / limit))}
        pageTo={this.handlePageTo}
      />
    );
  }

  renderToggleAll() {
    return (
      <ToolbarButton
        label={getItemsSelectionAllSelected(this.props.selection) ? 'Deselect All' : 'Select All'}
        onClick={this.toggleSelectAll}
        placement="left"
        styles={toolbarButtonStyles}
      />
    );
  }

  renderSearchField() {
    return (
      <input
        className="form-control"
        onChange={(e) => this.setState({ searchTerm: e.currentTarget.value })}
        value={this.state.searchTerm}
        placeholder="Search..."
      />
    );
  }

  renderToolbar() {
    const {
      canCreate, className, deselectAll, loadState, selectAll, bulkActionInit
    } = this.props;
    const { limit, total } = loadState;
    const showPagination = total > limit;
    const showSelectAll = this.enableBulkActions && deselectAll && selectAll;
    if (showPagination || showSelectAll) {
      return (
        <Toolbar
          className={classnames('dx-items-list', className, { 'no-pagination': !showPagination })}
        >
          {this.renderSearchField()}
          {showSelectAll && this.renderToggleAll()}
          {showPagination && this.renderPagination(total)}
          {canCreate && this.renderCreateButton()}
        </Toolbar>
      );
    }

    if (bulkActionInit) {
      return (
        <Toolbar
          className={classnames('dx-items-list', className, { 'no-pagination': true })}
        >
          {this.renderSearchField()}
          {canCreate && this.renderCreateButton()}
        </Toolbar>
      );
    }
  }

  renderSectionContent() {
    const {
      /* canCreate, */ canDelete, canView, deleteItem, deselectAll
    } = this.props;
    const {
      deselectItem, hasError, selectAll, selection, selectItem
    } = this.props;

    // Capture and display errors and warnings:
    let error;
    if (hasError) {
      error = this.props.error;
    } else if (!canView) {
      error = 'You are not authorized to view these items.';
    }
    if (canDelete && !deleteItem) { // Check the conditionally required prop
      error = 'When `canDelete` is true, then the `deleteItem` action dispatcher prop '
        + 'should be provided.';
    }
    if (this.enableBulkActions) { // Check the conditionally required prop
      if (!selection) { // Check the conditionally required prop
        error = 'When bulk-actions are provided, then the `selection` prop '
          + 'should be provided.';
      }
      if (!selectItem) { // Check the conditionally required prop
        error = 'When bulk-actions are provided, then the `selectItem` handler prop '
          + 'should be provided.';
      }
      if (!deselectItem) { // Check the conditionally required prop
        error = 'When bulk-actions are provided, then the `deselectItem` handler prop '
          + 'should be provided.';
      }
      if (!selectAll !== !deselectAll) {
        console.warn('You need to provide both the selectAll and deselectAll '
          + 'props to enable the "Select All" toggle button.');
      }
    }
    if (error) {
      return <DxErrorDetails error={error} />;
    }

    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderItems()}
        {this.renderBulkActionsBar()}
      </React.Fragment>
    );
  }

  render() {
    if (this.props.items || this.props.itemsPaging || this.props.loading) {
      throw new Error('The "items", "itemsPaging" or "loading" '
        + ' props are not longer supported for the "ItemsList" component.'
        + ' Provide a "loadState" prop instead.');
    }
    return (
      <Section styles={css(sectionStyles, this.props.sectionStyles)}>
        {this.renderSectionContent()}
      </Section>
    );
  }
}

ItemsList.propTypes = {
  /** Dispatches the action to open the bulk-command modal interface. */
  bulkActionInit: PropTypes.func,

  /** The bulk-actions. */
  bulkActions: PropTypes.arrayOf(bulkActionPropType),

  /** True when the user is authorized to create new items in the collection. */
  canCreate: PropTypes.bool.isRequired,

  /** True when the user is authorized to delete items from the collection. */
  canDelete: PropTypes.bool.isRequired,

  /** True when the user is authorized to view the collection. */
  canView: PropTypes.bool.isRequired,

  /** Optional additional class name to add as attribute in the html div element. */
  className: PropTypes.string,

  /**
   * A handler function that is called when the user wants to create a new item. This function
   * (or the _createPath_ prop) should be provided when _canCreate_ is true.
   */
  createItem: PropTypes.func,

  /**
   * When the _createItem_ prop is not provided, then the value for this prop will be used as
   * the path of the page for creating a new item.  Defaults to the relative path 'create'.
   */
  createPath: PropTypes.string,

  /**
   * A function that dispatches an action to deletes an item. This function takes one argument,
   * the ID of the to be deleted item. This function should be provided when _canDelete_ is true.
   */
  deleteItem: PropTypes.func,

  /**
   * Dispatches the action to deselect all items.
   * Provide the _selectAll_ and _deselectAll_ props to show the _Select-All_ toggle button.
   */
  deselectAll: PropTypes.func,

  /**
   * A function that dispatches an action to deselect an item.
   * This function takes one argument: the item ID.
   * This function should be provided when bulk-actions are provided.
   */
  deselectItem: PropTypes.func,

  /** The (optional) error object when _hasError_ is true. */
  error: PropTypes.object,

  /** True when an error occurred. */
  hasError: PropTypes.bool,

  /**
   * Optional component that renders items in the list. Alternative for the _renderItems_ prop.
   */
  ItemComponent: PropTypes.func,

  /** Singular name that describes the items in the list. Used as label in the new-item button. */
  itemLabel: PropTypes.string.isRequired,

  /** @deprecated */
  items: PropTypes.oneOf([]),

  /** @deprecated */
  itemsPaging: PropTypes.oneOf([]),

  /** @deprecated */
  loading: PropTypes.oneOf([]),

  /**
   * A function that dispatches an action to load a page of items. This function takes one
   * argument: an object with three properties:
   * 1) from: The index of the first item,
   * 2) limit: The maximum number of items in the page, and
   * 3) ordering: An optional ordering specification.
   */
  loadItems: PropTypes.func.isRequired,

  /**
   * The page-load-state that represents the items to show. See the _dxLoadState_ manual for more
   * details.
   * When _ItemComponent_ is not provided, then the default item renderer expects the following
   * item props:
   * - id: The item identifier.
   * - label: The label.
   * - path: The path to the item details.
   */
  loadState: pageLoadStatePropTypesOf(PropTypes.shape({
    __deleting__: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    path: PropTypes.string,
  })),

  /** A plural category label for the items in this list, e.g. "Projects". */
  pluralCapped: PropTypes.string,

  /**
   * Optional function that renders the items. Alternative for the _ItemComponent_ prop.
   * @type {function(items: object[])}
   */
  renderItems: PropTypes.func,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  sectionStyles: cssPropType,

  /**
   * Dispatches the action to select all items.
   * Provide the _selectAll_ and _deselectAll_ props to show the _Select-All_ toggle button.
   */
  selectAll: PropTypes.func,

  /** The items-selection state. Should be provided when bulk-actions are provided. */
  selection: itemsSelectionPropType,

  /**
   * A function that dispatches an action to select an item.
   * This function takes one argument: the item ID.
   * This function should be provided when bulk-actions are provided.
   */
  selectItem: PropTypes.func,

  /** When true a button is rendered to delete the item. Defaults to false. */
  showDeleteButton: PropTypes.bool,

  /** When true the user does not need to confirm item deletions. Defaults to false. */
  silentDelete: PropTypes.bool,

  /** A singular category label for the items in this list, e.g. "Project". */
  singularCapped: PropTypes.string,
};

ItemsList.defaultProps = {
  bulkActions: [],
  createPath: 'create',
  hasError: false,
  pluralCapped: 'Items',
  silentDelete: false,
  singularCapped: 'Item',
  showDeleteButton: false,
};

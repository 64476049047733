import { css } from 'glamor';
import React from 'react';
import { DxError } from '../../utils/dxf';
import { uuid } from '../../utils/uuid';
import { isString } from '../../utils/isString';

import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import { ErrorModal } from '../components/modals/ErrorModal';
import { MessageModal } from '../components/modals/MessageModal';
import { Spinner } from '../components/Spinner';
import { DX_MODAL_ADD, DX_MODAL_CLEAR, DX_MODAL_REMOVE } from '../constants';

/**
 * Add a modal window.
 * @param {Function} component
 * @param {DxModalOptions|string} [options]
 * @param {string} [id] - Optional ID to use, useful if you later wish to remove the modal window in
 *   response to some event.
 * @return {{ type, component, id, options }}
 */
export const addModalAction = (component, options = {}, id) => {
  if (isString(options)) {
    id = options;
    options = {};
  }
  return {
    type: DX_MODAL_ADD, component, id: id || uuid(), options
  };
};

/**
 * Remove all modal windows.
 * @return {{ type }}
 */
export const clearModalsAction = () => ({ type: DX_MODAL_CLEAR });

/**
 * Remove the given modal window.
 * @param {string} id - The ID of the modal window to remove.
 * @return {{ type, id }}
 */
export const removeModalAction = (id) => ({ type: DX_MODAL_REMOVE, id });

/**
 * @param {string} title
 * @param {*} content - Content that can be rendered in React component.
 * @param {object} [options]
 * @param {string} [options.id] - The modal ID to use, in case you need to close the modal
 *   programmatically.
 * @param {string} [options.noLabel = 'No']
 * @param {Function} [options.onConfirm]
 * @param {string} [options.size = 'medium'] - Either 'small', 'medium' (default) or 'large'.
 * @param {string} [options.yesLabel = 'Yes']
 * @return {{ type, component, id, options }}
 */
export const showConfirmationModalAction = (title, content, options = {}) => {
  const id = options.id || uuid();
  const {
    noLabel, onConfirm, size = 'medium', yesLabel
  } = options;
  options = {
    closeOnOutsideClick: false,
    content,
    hideCloseButton: true,
    noLabel,
    onConfirm,
    size,
    title,
    yesLabel,
  };
  return addModalAction(ConfirmationModal, options, id);
};

/**
 * Shows an error modal and returns when the modal is closed.
 * @param {DxErrorArgument} error - See `/utils/dxf/DxError`.
 * @param {object} [options] - Options injected in the props of the `ErrorModal` component.
 * @param {string} [options.id] - The modal ID to use, in case you need to close the modal
 *   programmatically
 * @param {string} [id] - Optional ID to use, useful if you later wish to remove the modal window in
 *   response to some event.
 * @return {{ type, component, id, options }}
 */
export const showErrorModalAction = (error, options = {}, id) => {
  if (id) { throw new Error('Pass the legacy in the options object instead.'); } // legacy
  const dxError = DxError.isDxError(error) ? error : new DxError(error);
  let size = 'medium';
  if (dxError.queryString) {
    size = Math.max(dxError.queryString.split('\n').map((l) => l.length)) > 40 ? 'large' : 'medium';
  }
  id = options.id || uuid();
  options = {
    closeOnOutsideClick: true,
    dxError,
    hideCloseButton: false,
    size,
    title: 'Error',
    ...options,
  };
  return addModalAction(ErrorModal, options, options.id || uuid());
};

/**
 * @param {string} titleOrMessage - Either title when `message` is also provided, or the title.
 * @param {string} [message] - When provided, the first argument will be taken as title.
 * @param {object} [options]
 * @param {string} [options.id] - The modal ID to use, in case you need to close the modal
 *   programmatically.
 * @param {Function} [options.onClose]
 * @param {string} [options.size = 'small'] - Either 'small' (default), 'medium' or 'large'.
 * @return {{ type, component, id, options }}
 */
export const showMessageModalAction = (titleOrMessage, message, options = {}) => {
  const id = options.id || uuid();
  const { onClose, size = 'medium' } = options;
  options = {
    closeOnOutsideClick: true,
    hideCloseButton: false,
    onClose,
    size,
  };
  if (message) {
    options.message = message;
    options.title = titleOrMessage;
    options.hideTitleBar = false;
  } else {
    options.message = titleOrMessage;
    options.hideTitleBar = true;
  }
  return addModalAction(MessageModal, options, id);
};

// -- Progress Modals --------------- --- --  -

const spinnerStyles = css({ height: 150 });

const ProgressModal = () => React.createElement(Spinner, { styles: spinnerStyles });

/**
 * Show a progress modal window. Use `removeModalAction` to close the window when the
 * operation for which the user is waiting has completed.
 * @param {string} message - The message to show in the window.
 * @param {object} [options]
 * @param {string} [options.id] - The modal ID to use, in case you need to close the modal
 *   programmatically.
 * @return {{type, message: *}}
 */
export const showProgressModalAction = (message, options = {}) => {
  const id = options.id || uuid();
  options = {
    closeOnOutsideClick: false,
    hideCloseButton: true,
    hideTitleBar: false,
    size: 'small',
    title: message,
    ...options,
  };
  return addModalAction(ProgressModal, options, id);
};

import PropTypes from 'prop-types';
import { FieldSchema } from '../../utils/dxSchema/FieldSchema';

import { cssPropType } from './cssPropType';

// -- Redux-Form Prop-Types --------------- ---  --  -

/**
 * Convenience re-export of the [redux-form prop-types](https://github.com/erikras/redux-form/blob/master/docs/api/Props.md).
 */
export { propTypes as reduxFormPropTypes } from 'redux-form';

// -- Redux-Form Input Prop-Types --------------- ---  --  -

/**
 * The `input` prop injected in the component provided to the `redux-form` `Field` component. For
 * the framework-specific field-views, the provided component is a form-field-control (FFC)
 * component.
 *
 * @see http://redux-form.com/6.3.1/docs/api/Field.md/
 */
export const reduxFormInputPropTypes = {
  checked: PropTypes.bool, // An alias for value only when value is a boolean.
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
};

export const reduxFormInputPropType = PropTypes.shape(reduxFormInputPropTypes);

// -- Redux-Form Meta Prop-Types --------------- ---  --  -

/**
 * The `meta` prop injected in the component provided to the `redux-form` `Field` component.
 * These props provide metadata about the state of this field that redux-form is tracking.
 *
 * @see http://redux-form.com/6.3.1/docs/api/Field.md/
 */
export const reduxFormMetaPropTypes = {
  active: PropTypes.bool.isRequired,
  autofilled: PropTypes.bool.isRequired,
  asyncValidating: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired, // The Redux dispatch function.
  error: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  visited: PropTypes.bool.isRequired,
  warning: PropTypes.string,
};

export const reduxFormMetaPropType = PropTypes.shape(reduxFormMetaPropTypes);

// -- Form-Field-Control Prop-Types --------------- ---  --  -

/**
 * The Duxis field-view components render a `redux-form` [Field](https://github.com/erikras/redux-form/blob/master/docs/api/Field.md)
 * component that receives a forn-field-control component as wrapped component. These
 * form-field-control components receive the following props.
 */
export const dxFormFieldControlPropTypes = {
  /**
   * The original value, which, unlike the `input.value` prop, is not cast to a string.
   */
  baseValue: PropTypes.any,

  /**
   * The optional children of the <FormControl/> component, e.g. option values when `componentClass`
   * is set to `select`, which will result in <select><option/>...</select> html.
   */
  children: PropTypes.node,

  /**
   * Optional string to be appended to the `className` prop of the (React-Bootstrap) <FormGroup/>
   * component, which will end up as the `class` attribute of the `div` that wraps the <label/>, the
   * <input|select|textarea/>, the warning, etc.
   */
  className: PropTypes.string,

  /**
   * The value for the `componentClass` prop on the (React-Bootstrap) <FormControl/> component in
   * edit-mode. The default is `input`.
   */
  componentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),

  /**
   * The value for the `controlId` prop of the (React-Bootstrap) <FormGroup/> component, which will
   * end up as the `id` attribute of the <input|select|textarea/> element, and the `for`
   * attribute of the <label/> element.
   */
  controlId: PropTypes.string.isRequired,

  /**
   * True when the edit mode is enabled. Defaults to false.
   */
  editMode: PropTypes.bool,

  /**
   * When the current value is null, and this function is provided, then when the user clicks on the
   * empty form, the value is set to the result of calling this function.
   */
  getInitialValue: PropTypes.func,

  /**
   * Passed by the wrapping redux-form <Field/> component. The props under the input key are
   * destructured in the <input/> component, thus connecting it to Redux.
   * @see http://redux-form.com/6.3.1/docs/api/Field.md/
   */
  input: reduxFormInputPropType.isRequired,

  /**
   * The string to display using the `label` element and to use as `placeholder` property of the
   * <input|select|textarea/>` element.
   */
  label: PropTypes.string.isRequired,

  /**
   * Passed by the wrapping redux-form <Field/> component. These props provide metadata about the
   * state of this field that redux-form is tracking.
   * @see http://redux-form.com/6.3.1/docs/api/Field.md/
   */
  meta: reduxFormMetaPropType.isRequired,

  /** Duplicate of the `input.name` prop. */
  name: PropTypes.string, // .isRequired, TODO: should be required, but is not always given

  /**
   * The value for the `required` prop of the (React-Bootstrap) <FormGroup/> component, which will
   * end up as the `required` attribute of the <input|select|textarea/> element.
   */
  required: PropTypes.bool,

  /**
   * The value for the `componentClass` prop on the (React-Bootstrap) <FormControl.Static/>
   * component when not in edit-mode. The default is `p`.
   */
  staticComponentClass: PropTypes.string,

  /** Optional Glamor styling. See [cssPropType](../../../propTypes/cssPropType). */
  styles: cssPropType,
};

export const dxFormFieldControlPropType = PropTypes.shape(dxFormFieldControlPropTypes);

// -- Field-View Prop-Types --------------- ---  --  -

export const dxFormFieldConstraintsPropTypes = {
  allowNewTags: PropTypes.bool,
  controlled: PropTypes.string,
  enum: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  pattern: PropTypes.string,
  unique: PropTypes.bool,
  immutable: PropTypes.bool,
};

export const dxFormFieldConstraintsPropType = PropTypes.shape(dxFormFieldConstraintsPropTypes);

// -- Field-View Prop-Types --------------- ---  --  -

/**
 * The form decorated with _[reduxForm](https://redux-form.com/7.3.0/docs/api/reduxform.md/)_ in
 * the _ItemForm_ class takes one or more dx-field-views as children. These field-views are
 * framework-specific components that render a configured redux-form
 * [Field](https://github.com/erikras/redux-form/blob/master/docs/api/Field.md) component. These
 * field-view components take the following props.
 */
export const dxFormFieldViewPropTypes = {
  /** Optional constraints to apply, when `fieldSchema` is not provided. */
  constraints: dxFormFieldConstraintsPropType,

  /** True when the edit mode is enabled. Default: true. */
  editMode: PropTypes.bool,

  /** The id of the field shown in this view. */
  fieldId: PropTypes.string.isRequired,

  /**
   * Optional set of properties for the redux-form `Field` component. These properties override
   * system-defined properties.
   */
  fieldProps: PropTypes.object,

  /** Optional dxFieldSchema for the field shown in this view. */
  fieldSchema: PropTypes.instanceOf(FieldSchema),

  /**
   * The string to display using the `label` element and to use as `placeholder` property of the
   * <input|select|textarea/>` element.
   */
  label: PropTypes.string.isRequired,

  /**
   * The value for the `required` prop of the (React-Bootstrap) <FormGroup/> component, which will
   * end up as the `required` attribute of the <input|select|textarea/> element.
   */
  required: PropTypes.bool,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,

  /** The value (can be null for fields without a value). */
  value: PropTypes.any,
};

export const dxFormFieldViewPropType = PropTypes.shape(dxFormFieldViewPropTypes);

export const dxFormDefaultFieldViewProps = { editMode: false };

// -- Control Prop-Types --------------- ---  --  -

/**
 * The prop-types for the optional custom component in a form-field-control, set using
 * _componentClass_. Such custom control components are passed to the _react-bootstrap_
 * [FormControl](https://react-bootstrap.github.io/components/forms/#forms-props-form-control).
 */
export const dxFormControlPropTypes = {
  children: PropTypes.node,

  /** Optional class name for the rendered component. */
  className: PropTypes.string.isRequired,

  /** The ID for the resulting HTML element. */
  id: PropTypes.string.isRequired,

  /** Injected by the wrapping FormControl. Identifies the field in the form. */
  name: PropTypes.string.isRequired,

  /** Injected by the wrapping FormControl. */
  onBlur: PropTypes.func.isRequired,

  /** Injected by the wrapping FormControl. */
  onChange: PropTypes.func.isRequired,

  /** Injected by the wrapping FormControl. */
  onDragStart: PropTypes.func.isRequired,

  /** Injected by the wrapping FormControl. */
  onDrop: PropTypes.func.isRequired,

  /** Injected by the wrapping FormControl. */
  onFocus: PropTypes.func.isRequired,

  /** When true, render as read-only widget. */
  readOnly: PropTypes.bool.isRequired,

  /** Injected by the wrapping FormControl. The _type_ prop passed to the FormControl wrapper. */
  type: PropTypes.string,

  /** The field value to show or edit with this control. */
  value: PropTypes.any.isRequired,
};

export const dxFormControlPropType = PropTypes.shape(dxFormControlPropTypes);

// -- Input Control Prop-Types --------------- ---  --  -

/**
 * The prop-types for the form-field-controls based on the `input` element.
 */
export const dxFormInputControlPropTypes = {
  /**
   * The optional value for the `inputmode` prop of the <input/> element.
   */
  inputMode: PropTypes.string,

  /**
   * The optional value for the `max` prop of the <input/> element.
   */
  max: PropTypes.number,

  /**
   * The optional value for the `maxlength` prop of the <input/> element.
   */
  maxLength: PropTypes.number,

  /**
   * The optional value for the `min` prop of the <input/> element.
   */
  min: PropTypes.number,

  /**
   * The optional value for the `minlength` prop of the <input/> element.
   */
  minLength: PropTypes.number,

  /**
   * The optional value for the `pattern` prop of the <input/> element.
   */
  pattern: PropTypes.string,

  /**
   * The optional value for the `step` prop of the <input/> element.
   */
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const dxFormInputControlPropType = PropTypes.shape(dxFormInputControlPropTypes);

// -- Select Control Prop-Types --------------- ---  --  -

/**
 * The prop-types for the form-field-controls based on the `select` element.
 */
export const dxFormSelectControlPropTypes = {
  /**
   * The optional value for the `size` prop of the <select/> element.
   */
  size: PropTypes.number,
};

export const dxFormSelectControlPropType = PropTypes.shape(dxFormSelectControlPropTypes);

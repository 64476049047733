import { ThreeBounce } from 'better-react-spinkit';
import classnames from 'classnames';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';

import { cssPropType } from '../propTypes';
import { dxColors } from '../styles';

// -- Styles --------------- --- --  -

const spinnerStyles = css({
  alignItems: 'stretch',
  display: 'flex',
  height: 300,
  width: '100%',
  '> span': {
    display: 'block',
    margin: '60px auto',
  },
});

// -- Component --------------- --- --  -

export const Spinner = ({
  className, color, size, styles, ...otherProps
}) => (
  <div className={classnames('dx-spinner', className)} {...css(spinnerStyles, styles)}>
    <ThreeBounce {...{ color, size, ...otherProps }} />
  </div>
);

Spinner.propTypes = {
  /** Optional additional class name(s). */
  className: PropTypes.string,

  /** The spinner color. @see https://www.npmjs.com/package/better-react-spinkit */
  color: PropTypes.string,

  /** The spinner size. @see https://www.npmjs.com/package/better-react-spinkit */
  size: PropTypes.number,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  styles: cssPropType,
};

Spinner.defaultProps = {
  color: dxColors.bondiBlue,
  size: 20,
};

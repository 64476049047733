import invariant from 'invariant';
import forIn from 'lodash/forIn';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import { isString } from '../../utils/isString';

import { dxSchema } from '../dxSchema';
import {
  buildCreateItemQuery,
  buildDeleteItemQuery,
  buildFetchItemQuery,
  buildFetchPageQuery,
  buildFilterQuery,
  buildUpdateItemQuery,
} from '../queries';
import { join } from '../utils/join';

// -- Local Support --------------- --- --  -

const identityFunction = (value) => value;

// -- DMSCollection Class --------------- --- --  -

export class DMSCollection {
  /**
   * @param {DMSCollectionConfigOptions} rawCollConfig
   * @param {DMSConfig} dmsConfig
   */
  constructor(rawCollConfig, dmsConfig) {
    invariant(isObject(rawCollConfig),
      `Expected an object as DMS collection config, got "${rawCollConfig}".`);

    const {
      bulkActions = [],
      customPath,
      disableCreate = false,
      disableDelete = false,
      disableUpdate = false,
      // excludeCreateFields,
      getItemLabel,
      id,
      itemComponent,
      itemLabelFields,
      listComponent,
      queries = {},
      showListItemDelete = false,
      silentDelete = false,
      updateItemIncludeRelatees = true,
      viewSchema,
      ...rest
    } = rawCollConfig;

    invariant(isString(id),
      `Expected a string as id in the DMSCollection config, got "${id}" in "${rawCollConfig}".`);

    if (isBoolean(rawCollConfig.confirmDelete)) {
      throw new Error('The "confirmDelete" option for collections in a DMSConfig has been replaced by "silentDelete" (the opposite, which defaults to false.');
    }
    if (isBoolean(rawCollConfig.enableCreate)) {
      throw new Error('The "enableCreate" option for collections in a DMSConfig has been replaced by "disableCreate" (the opposite, which defaults to false.');
    }
    if (isBoolean(rawCollConfig.enableDelete)) {
      throw new Error('The "enableDelete" option for collections in a DMSConfig has been replaced by "disableDelete" (the opposite, which defaults to false.');
    }
    if (isBoolean(rawCollConfig.enableUpdate)) {
      throw new Error('The "enableUpdate" option for collections in a DMSConfig has been replaced by "disableUpdate" (the opposite, which defaults to false.');
    }

    this.bulkActions = bulkActions.map((bulkAction) => ({ ...bulkAction, collectionId: id }));
    this.collSchema = dxSchema.getCollection(id);
    this.customPath = customPath;
    this.disableCreate = disableCreate;
    this.disableDelete = disableDelete;
    this.disableUpdate = disableUpdate;
    this.dmsConfig = dmsConfig;
    this.getItemLabel = getItemLabel;
    this.id = id;
    this.itemLabelFields = itemLabelFields;
    this.itemComponent = itemComponent;
    this.label = this.collSchema.label;
    this.listComponent = listComponent;
    this.queries = queries;
    this.selectable = bulkActions.length > 0;
    this.showListItemDelete = showListItemDelete;
    this.silentDelete = silentDelete;
    this.updateItemIncludeRelatees = updateItemIncludeRelatees;

    // The includeRelatees option is taken into account in initFieldViews. It should not be set to
    // true for non-DMS item-forms because there is not yet a generic relatee-field-view. It should
    // be true for DMS item-forms because the DMSRelateeField can be used. This is a temporary hack.
    this.viewSchema = { ...viewSchema, includeRelatees: true };

    if (!this.itemLabelFields) {
      if (this.collSchema.hasField('label')) {
        this.itemLabelFields = ['label'];
      } else if (this.collSchema.hasField('name')) {
        this.itemLabelFields = ['name'];
      } else if (this.collSchema.hasField('title')) {
        this.itemLabelFields = ['title'];
      } else {
        this.itemLabelFields = ['id'];
      }
      this.getItemLabel = identityFunction;
    }

    forIn(rest, (value, key) => { this[key] = value; });

    // TODO: Consider the use-case for the following options, which is used in e.g. buildCreateItemQuery.
    // // Field names to exclude from the create query:
    // this.excludeCreateFields = excludeCreateFields;
  }

  // -- Initialization --------------- --- --  -

  /*
   * @todo: allow passing a custom path to mount a single collection at initialize time or find a better way to configure
   */
  initialize() {
    const collPath = this.customPath || this.id;
    this.path = join(this.dmsConfig.basePath, collPath);
    this.createPath = join(this.path, 'create');
  }

  // -- Query Accessors --------------- --- --  -

  get createItemQuery() {
    if (!this._createItemQuery) {
      if (this.queries.createItem && isFunction(this.queries.createItem)) {
        this._createItemQuery = this.queries.createItem(this.itemQueryFields, this);
      } else {
        this._createItemQuery = this.queries.createItem || buildCreateItemQuery(this.itemQueryFields, this);
      }
    }
    return this._createItemQuery;
  }

  get deleteItemQuery() {
    if (!this._deleteItemQuery) {
      if (this.queries.deleteItem && isFunction(this.queries.deleteItem)) {
        this._deleteItemQuery = this.queries.deleteItem(this);
      } else {
        this._deleteItemQuery = this.queries.deleteItem || buildDeleteItemQuery(this);
      }
    }
    return this._deleteItemQuery;
  }

  get fetchItemQuery() {
    if (!this._fetchItemQuery) {
      if (this.queries.fetchItem && isFunction(this.queries.fetchItem)) {
        this._fetchItemQuery = this.queries.fetchItem(this.itemQueryFields, this);
      } else {
        this._fetchItemQuery = this.queries.fetchItem || buildFetchItemQuery(this.itemQueryFields, this);
      }
    }
    return this._fetchItemQuery;
  }

  get fetchPageQuery() {
    if (!this._fetchPageQuery) {
      // noinspection JSUnresolvedVariable
      const { fetchPage } = this.queries;
      if (fetchPage && isFunction(fetchPage)) {
        this._fetchPageQuery = fetchPage(this.dmsConfig.getBasicItemFields(this.id), this);
      } else {
        this._fetchPageQuery = fetchPage || buildFetchPageQuery(this.dmsConfig.getBasicItemFields(this.id), this);
      }
    }
    return this._fetchPageQuery;
  }

  get filterQuery() {
    if (!this._filterQuery) {
      if (this.queries.filterQuery && isFunction) {
        this._filterQuery = this.queries.filterQuery(this.dmsConfig.getBasicItemFields(this.id), this);
      } else {
        this._filterQuery = this.queries.filterQuery || buildFilterQuery(this.dmsConfig.getBasicItemFields(this.id), this);
      }
    }
    return this._filterQuery;
  }

  get updateItemQuery() {
    if (!this._updateItemQuery) {
      if (this.queries.updateItem && isFunction(this.queries.updateItem)) {
        this._updateItemQuery = this.queries.updateItem(this.itemQueryFields, this);
      } else {
        this._updateItemQuery = this.queries.updateItem || buildUpdateItemQuery(this.itemQueryFields, this);
      }
    }
    return this._updateItemQuery;
  }

  /**
   * @private
   * Get fields for item queries.
   */
  get itemQueryFields() {
    if (!this._itemQueryFields) {
      this._itemQueryFields = this.collSchema.fields
        .reduce((result, field) => ({ ...result, [field.id]: true }), {});
      if (this.collSchema.relateesNxt.length > 0) {
        this.collSchema.forEachRelatee(({ coCollectionId }, relateeId) => {
          if (this.dmsConfig.hasCollection(coCollectionId)) {
            this._itemQueryFields[relateeId] = {
              fields: this.dmsConfig.getBasicItemFields(coCollectionId)
            };
          } else {
            console.warn('The dmsConfig is missing a config for the related collection '
              + `"${coCollectionId}".`);
          }
        });
      }
    }
    return this._itemQueryFields;
  }

  // -- Misc Accessors --------------- --- --  -

  /**
   * @param {string} itemId - item identifier
   * @returns {string} The address path of the item page for the given item.
   */
  getItemPath(itemId) {
    return join(this.path, itemId);
  }
}

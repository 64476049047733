import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import isBoolean from 'lodash/isBoolean';

import {
  dmsItemCreateCommitAction,
  dmsItemCreateInitAction,
  dmsItemFetchAction,
  dmsItemUpdateCommitAction,
} from '../../actions';
import { CREATE_MODE, EDIT_MODE, VIEW_MODE } from '../../constants';
import { dmsItemComponentPropTypes } from '../../propTypes';
import { getDMSItemLoadState, getDxAuthUser } from '../../selectors';
import { AlertSection } from '../AlertSection';
import { BreadcrumbsSection } from '../BreadcrumbsSection';
import { Chapter } from '../Chapter';
import { ChapterHeader } from '../ChapterHeader';
import { DescriptionSection } from '../DescriptionSection';
import { ItemDetails } from '../item/ItemDetails';
import { Module } from '../Module';

// -- Component --------------- --- --  -

class DMSItemChapterComponent extends React.Component {
  componentDidMount() {
    this.updateLoadState();
  }

  componentDidUpdate() {
    this.updateLoadState();
  }

  updateLoadState() {
    const {
      collection, itemId, loadItem, loadState, mode
    } = this.props;
    if (itemId && mode !== CREATE_MODE && itemId !== loadState.id) {
      loadItem(collection.id, itemId);
    }
  }

  renderTitle() {
    const { collection, loadState } = this.props;
    if (this.props.mode === CREATE_MODE) {
      return `Create ${collection.collSchema.labelSingular}`;
    }
    if (loadState.available) {
      return this.props.loadState.item.label;
    }
    return 'Loading...';
  }

  renderItem() {
    if (!this.props.canView) {
      return <AlertSection error="You are not authorized to view this item." />;
    }
    if (this.props.mode === CREATE_MODE && !this.props.canCreate) {
      return <AlertSection error="You are not authorized to create new items for this collection." />;
    }
    if (this.props.mode === CREATE_MODE && this.props.collection.disableCreate) {
      return <AlertSection error="The creation of items has been disabled for this collection." />;
    }
    if (this.props.warning) {
      return (
        <React.Fragment>
          <AlertSection error={this.props.warning} thin />
          {this.props.itemId && this.renderItemAux()}
        </React.Fragment>
      );
    }
    return this.renderItemAux();
  }

  renderItemAux() {
    const {
      canCreate, canUpdate, canView, collection
    } = this.props;
    // Pass ItemDetails-specific props also to the custom item component, such that when this
    // uses an ItemDetails component in a composition, these props can be injected as is.
    const itemProps = {
      canCreate,
      canUpdate,
      canView,
      collection,
      collectionId: collection.id,
      createCommit: canCreate ? this.props.createCommit : undefined,
      createInit: canCreate ? this.props.createInit : undefined,
      formId: `dms-${collection.collSchema.singular}`,
      item: this.props.loadState.item,
      itemId: this.props.itemId,
      loadState: this.props.loadState,
      mode: this.props.mode,
      relateeProps: { dmsCollection: collection },
      updateCommit: canUpdate ? this.props.updateCommit : undefined,
      viewSchema: collection.viewSchema,
    };
    if (collection.itemComponent) {
      // Keep backwards compatability.
      return <collection.itemComponent collectionId={collection.id} {...itemProps} />;
    }

    return <ItemDetails {...itemProps} />;
  }

  render() {
    const { collection, loadState, showBreadcrumbs = true } = this.props;
    const { description, labelSingular } = collection.collSchema;
    const crumbs = showBreadcrumbs ? [
      { label: 'Collections', url: collection.dmsConfig.basePath },
      { label: collection.label, url: collection.path },
      { label: loadState.available ? loadState.item.label : 'Loading...' },
    ] : [];
    return (
      <Chapter chapterColor="white">
        <ChapterHeader
          title={this.renderTitle()}
          titlePrefix={labelSingular}
        />
        <Module>
          {showBreadcrumbs && <BreadcrumbsSection items={crumbs} />}
          <DescriptionSection description={description} />
          {this.renderItem()}
        </Module>
      </Chapter>
    );
  }
}

DMSItemChapterComponent.propTypes = {
  ...dmsItemComponentPropTypes,

  /**
   * Optional function that dispatches the action to fetch the item details.
   * This function takes two argument: the item ID and the collection ID.
   * When you do not provide this function, then you should take care of loading the item (in the
   * load-state) whenever needed.
   * Injected by the connect HOC.
   */
  loadItem: PropTypes.func.isRequired,

  /** An optional warning to show at the top of the content section. */
  warning: PropTypes.string,
};

// -- Container --------------- --- --  -

export const DMSItemChapter = connect(
  (state, ownProps) => {
    let {
      canCreate, canUpdate, canView, mode = VIEW_MODE, warning
    } = ownProps;
    const { collection: { collSchema }, itemId } = ownProps;
    const user = getDxAuthUser(state);
    if (!isBoolean(canCreate)) { canCreate = user.canCreate(collSchema); }
    if (!isBoolean(canUpdate)) { canUpdate = user.canUpdate(collSchema, itemId); }
    if (!isBoolean(canView)) { canView = user.canView(collSchema, itemId); }
    if (ownProps.mode === EDIT_MODE && !canUpdate) {
      mode = VIEW_MODE;
      warning = 'You are not authorized to edit this item.';
    }
    if (ownProps.mode === EDIT_MODE && ownProps.location.disableUpdate) {
      mode = VIEW_MODE;
      warning = 'Item editing has been disabled for this collection.';
    }
    return {
      canCreate,
      canUpdate,
      canView,
      loadState: getDMSItemLoadState(state),
      mode,
      warning,
    };
  },
  {
    createCommit: dmsItemCreateCommitAction,
    createInit: dmsItemCreateInitAction,
    loadItem: dmsItemFetchAction,
    updateCommit: dmsItemUpdateCommitAction,
  },
  undefined, // use default mergeProps implementation
  {
    areOwnPropsEqual: (next, prev) => next.collection === prev.collection
        && next.itemId === prev.itemId
        && next.mode === prev.mode,
    areStatesEqual: (next, prev) => getDMSItemLoadState(next) === getDMSItemLoadState(prev)
        && getDxAuthUser(next) === getDxAuthUser(prev)
  },
)(DMSItemChapterComponent);

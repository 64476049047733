import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import thunkMiddleware from 'redux-thunk';
import forIn from 'lodash/forIn';

import {
  DX_QUERY_CANCELLED,
  DX_QUERY_CONTEXT_COMPLETE,
  DX_QUERY_CONTEXT_PENDING,
  DX_QUERY_FAILED,
  DX_QUERY_POSTED,
  DX_QUERY_SUCCESS,
  DX_TASKS_RECEIVE,
} from '../constants';
import { dxReducers } from '../reducers';
import { dxSagas } from '../sagas';
import { getDxAuthUser, getDxAuthToken } from '../selectors';

import { dxRequest } from './dxRequest';

const actionsLogBlacklist = [
  '@@redux-form/BLUR',
  '@@redux-form/CHANGE',
  '@@redux-form/CLEAR_SUBMIT',
  '@@redux-form/DESTROY',
  '@@redux-form/FOCUS',
  '@@redux-form/INITIALIZE',
  '@@redux-form/REGISTER_FIELD',
  '@@redux-form/RESET',
  '@@redux-form/SET_SUBMIT_SUCCEEDED',
  '@@redux-form/START_SUBMIT',
  '@@redux-form/STOP_SUBMIT',
  '@@redux-form/SUBMIT',
  '@@redux-form/TOUCH',
  '@@redux-form/UPDATE_SYNC_ERRORS',
  '@@router/LOCATION_CHANGE',
  DX_QUERY_CANCELLED,
  DX_QUERY_CONTEXT_COMPLETE,
  DX_QUERY_CONTEXT_PENDING,
  DX_QUERY_FAILED,
  DX_QUERY_POSTED,
  DX_QUERY_SUCCESS,
  DX_TASKS_RECEIVE,
];

const defaultReduxLoggerOptions = {
  collapsed: true,
  predicate: (getState, action) => !actionsLogBlacklist.includes(action.type)
};

/**
 * The redux-saga middleware. Use this object to activate the sagas in your project, as follows:
 *
 * sagaMiddleware.run(yourSaga);
 *
 */
export const sagaMiddleware = createSagaMiddleware();

/**
 * @name createStore
 * Creates a standard fully-featured Redux store.
 * @param {object} [reducers] - An object with project-specific Redux reducers (reducing functions),
 *   indexed by the keys to use in the resulting combined reducer.
 * @param {Function} [saga] - A generator function to be run by the redux-saga middleware.
 * @param {object} [history] - A custom history tied to both router and redux. Defaults to a
 *   browser-history.
 * @param {object} [reduxLoggerOptions] - An optional object with options for the `redux-logger`
 *   middleware. See `https://github.com/evgenyrodionov/redux-logger` for more details on the
 *   possible options.
 * @param {object} [formPlugins] - React-form plugins. An optional object with react-form IDs as
 *   keys and the custom reducer to install for those forms, as values.
 *   See https://redux-form.com/7.3.0/docs/api/reducerplugin.md/ for an example implementation
 * @returns {object} A Redux store.
 */
export function createStore(reducers, saga, history, reduxLoggerOptions, formPlugins = {}) {
  // Use browser-history as default history:
  if (!history) { history = createBrowserHistory(); }

  // Apply all default middleware:
  let middleware = applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
    promiseMiddleware,
    thunkMiddleware,
    createLogger({ ...defaultReduxLoggerOptions, ...reduxLoggerOptions })
  );

  // Enable Redux-Devtools as browser extension in dev-mode.
  if (process.env.NODE_ENV === 'development') {
    middleware = composeWithDevTools({
      // Specify here name, actionsBlacklist, actionsCreators and other options
      name: 'duxis',
      actionsBlacklist: actionsLogBlacklist,
    })(middleware);
  }

  // The generic Duxis Foundation reducers:
  const duxisReducers = {
    // Needed to allow reduxForm plugins
    form: formReducer.plugin(formPlugins), // source: redux-form
    router: connectRouter(history),
    ...dxReducers,
  };

  // Assert that the project-specific reducers do not use reserved keys:
  forIn(reducers, (reducer, key) => {
    if (duxisReducers[key]) {
      throw new Error(`The keys "${[...Object.keys(duxisReducers)].join(', ')}" are reserved and may not be used for project-specific reducers.`);
    }
  });

  // Create the store:
  const store = createReduxStore(
    combineReducers({ ...duxisReducers, ...reducers }),
    middleware
  );

  // Initialize the dxRequest singleton such that it can get the jwt-token for the current
  // authenticated user from the Redux store.
  dxRequest.initialize(
    () => getDxAuthUser(store.getState()),
    () => getDxAuthToken(store.getState())
  );

  // Run the main saga (when given):
  sagaMiddleware.run(function* () {
    yield spawn(dxSagas);
    if (saga) { yield spawn(saga, store); }
  });

  return store;
}

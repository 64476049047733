import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import isNumber from 'lodash/isNumber';

import {
  dxFormFieldViewPropTypes,
  dxFormInputControlPropTypes,
  dxFormSelectControlPropTypes,
} from '../../propTypes';

import { InputFFC } from './formFieldComponents/InputFFC';
import { SelectFFC } from './formFieldComponents/SelectFFC';

// -- Component --------------- ---  --  -

/**
 * @todo add step prop
 */
export class NumberField extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value;
  }

  render() {
    const {
      editMode, fieldId, fieldProps, fieldSchema, isInt, value, ...otherProps
    } = this.props;

    const baseProps = {
      baseValue: value,
      editMode,
      format,
      name: fieldId, // identifies the value in the form
      parse,
      ...otherProps,
    };

    if (fieldSchema && fieldSchema.constraints.enum && editMode) {
      const props = {
        ...baseProps,
        className: 'dx-number-field dx-select-field',
        component: SelectFFC,
        controlId: `select-${fieldId}`,
        options: fieldSchema.constraints.enum,
        ...fieldProps,
      };
      return <Field {...props} />;
    }

    const maxValue = (isNumber(this.props.maxValue) && this.props.maxValue)
        || (fieldSchema && fieldSchema.constraints.maxValue);
    const minValue = (isNumber(this.props.minValue) && this.props.minValue)
        || (fieldSchema && fieldSchema.constraints.minValue);
    const childProps = {
      ...baseProps,
      className: 'dx-number-field',
      component: InputFFC,
      controlId: `input-${fieldId}`,
      inputMode: 'numeric',
      type: 'number',
    };
    if (isInt) {
      if (isNumber(this.props.maxValue)) { childProps.max = Math.floor(maxValue); }
      if (isNumber(this.props.minValue)) { childProps.min = Math.ceil(minValue); }
      childProps.step = 1;
    } else {
      childProps.min = minValue;
      childProps.max = maxValue;
      childProps.step = 'any';
    }
    Object.assign(childProps, fieldProps);
    return <Field {...childProps} />;
  }
}

NumberField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...dxFormInputControlPropTypes,
  ...dxFormSelectControlPropTypes,

  editMode: PropTypes.bool,

  /** True when this field should permit only integer numbers. */
  isInt: PropTypes.bool,

  /** Override `maxValue` field schema constraint. */
  maxValue: PropTypes.number,

  /** Override `minValue` field schema constraint. */
  minValue: PropTypes.number,
};

NumberField.defaultProps = {
  editMode: false,
  isInt: false,
};

// -- Support --------------- ---  --  -

function format(value) {
  // console.log('>> NumberField.format - value:', value, isNaN(value));
  return value === null || value === undefined || Number.isNaN(value) ? '' : value;
}

function parse(string) {
  // console.log('>> NumberField.parse - string::', string, string === '');
  return string === '' ? null : Number.parseFloat(string);
}

import invariant from 'invariant';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';

// -- Helpers --------------- --- --  -

/**
 * @param {FetchPageActionMetadata} options
 */
export const assertPageFetchArgs = (options = {}) => {
  const {
    from, includeActive, includeArchived, limit
  } = options;
  invariant(isObject(options), `Expected an object as "options", got "${options}".`);
  invariant(!from || isNumber(from), `Expected a number as "from", got "${from}".`);
  invariant(!includeActive || isBoolean(includeActive),
    `Expected a boolean as "includeActive", got "${includeActive}".`);
  invariant(!includeArchived || isBoolean(includeArchived),
    `Expected a boolean as "includeArchived", got "${includeArchived}".`);
  invariant(!limit || isNumber(limit), `Expected a number as "limit", got "${limit}".`);
  // TODO: check ordering
};

/** '
 * @param {Array.<{ id: string }>} items
 * @param {FetchPageSuccessActionMetadata} options
 */
export const assertPageFetchSuccessArgs = (items, options = {}) => {
  const { pagedCount, total } = options;
  invariant(isArray(items), `Expected an array as "items", got "${items}".`);
  assertPageFetchArgs(options);
  invariant(isNumber(pagedCount), `Expected a number as "pagedCount", got "${pagedCount}".`);
  invariant(isNumber(total), `Expected a number as "total", got "${total}".`);
};

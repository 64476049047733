import { css } from 'glamor';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { dxAuthSignOutAction } from '../react-frontend/actions';
import { Authenticate } from '../react-frontend/auth';
import {
  AlertSection, DMSRoutes, NotFound, Spinner
} from '../react-frontend/components';
import { DxError } from '../utils/dxf';
import { getDxAuthenticated } from '../react-frontend/selectors';

import { dmsConfig } from '../dmsConfig';
import { fetchExternalDxSchemaAction } from '../actions/externalSchema';

import { AppNav } from './AppNav';
import { Footer } from './Footer';

// -- Styles --------------- --- --  -

const appStyles = css({
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column nowrap',
  maxWidth: '960px',
  margin: '0 auto',
  minWidth: '640px',
  width: '90%',
});

// -- Component --------------- --- --  -

class AppComponent extends React.PureComponent {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps) {
    const { authenticated, fetchExternalDxSchema } = this.props;
    // When the user authenticates fetch the external schema.
    if (!prevProps.authenticated && authenticated) {
      // TODO: provide warning if external service is offline.
      fetchExternalDxSchema();
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: new DxError(error, info) });
  }

  render() {
    const { schemaLoading, signOut } = this.props;
    if (this.state.hasError) { return <AlertSection error={this.state.error} />; }
    return (
      <Authenticate>
        <div {...appStyles}>
          <AppNav {...{ signOut }} />
          {/*
            Load while we have no schema, this way we have a consistent UI when for example reloading on
            the details page. This way we also make sure our user is authorized for "dx/schema/view" before
            we show the user interface.
          */}
          {schemaLoading
            ? <Spinner />
            : (
              <Switch>
                <DMSRoutes path="/" config={dmsConfig} />
                <Route path="*" component={NotFound} />
              </Switch>
            )}
          <Footer />
        </div>
      </Authenticate>
    );
  }
}

AppComponent.propTypes = {
  authenticated: PropTypes.bool,
  fetchExternalDxSchema: PropTypes.func.isRequired,
  schemaLoading: PropTypes.bool,
  signOut: PropTypes.func.isRequired
};

// -- Wrappers --------------- --- --  -

export const App = withRouter(connect(
  (state) => ({
    authenticated: getDxAuthenticated(state, true),
    schemaLoading: state.externalSchema.loading,
  }),
  {
    fetchExternalDxSchema: fetchExternalDxSchemaAction,
    signOut: dxAuthSignOutAction
  }
)(AppComponent));

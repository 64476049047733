import React from 'react';

import { dxStyles } from '../styles';

import { Chapter } from './Chapter';
import { ChapterHeader } from './ChapterHeader';
import { Module } from './Module';
import { Section } from './Section';

// -- Component --------------- --- --  -

export const NotFound = () => (
  <Chapter>
    <ChapterHeader title="Not Found" />
    <Module>
      <Section styles={dxStyles.contentSection}>
        There is nothing here...
      </Section>
    </Module>
  </Chapter>
);

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import removeIcon from '../../react-frontend/assets/icons/stop.svg';
import addIcon from '../../react-frontend/assets/icons/add.svg';
import { dxColors, dxStyles } from '../../react-frontend/styles';
import { FormControlFFC } from '../../react-frontend/components/fieldViews';
import { dxFormFieldViewPropTypes } from '../../react-frontend/propTypes';

import { fetchExternalItemsAction } from '../../actions';
import { getExternalCollection } from '../../selectors/externalSchema';

import { RelateeDropdown } from './RelateeDropdown';

// -- Styles --------------- --- --  -

const warningLabelStyles = css({
  color: dxColors.dangerRed,
  marginBottom: 0,
  marginTop: 6,
});

const formGroupStyles = css({ '> .form-control': { height: 'auto' } });
const controlStyles = css(dxStyles.thinBorder, {
  height: 'auto !important',
  padding: '0 !important',
  width: '100%',
  '> ul': {
    listStyle: 'none',
    padding: '4px',
    margin: 0,
    '> li.dx-relatee, > li.dx-relatee-add': {
      display: 'flex',
      height: '20px',
      lineHeight: '20px',
      padding: '0 0 0 6px',
      ':hover, :active, :focus': {
        backgroundColor: dxColors.bgFocus,
        '> a': {
          color: dxColors.fgMain,
          textDecoration: 'none',
        },
      },
      '> a': {
        color: dxColors.fgMain,
        textDecoration: 'none',
      },
      '> .aux-label': {
        display: 'block',
        flexGrow: 1,
      },
      '> .add-label': {
        color: '#aaa',
        cursor: 'pointer',
        ':hover, :active, :focus': { color: dxColors.fgMain },
      },
      '> .add-btn, > .remove-btn': {
        cursor: 'pointer',
        height: '14px',
        margin: '3px 0',
        width: '14px',
      },
    },
  },
});

// -- Component --------------- --- --  -

class ExternalItemSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.renderRelatee = this.renderRelatee.bind(this);
    this.renderAddItem = this.renderAddItem.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.showDropDown = this.showDropDown.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleDropDownClose = this.handleDropDownClose.bind(this);

    this.state = { dropDownOpen: false };
  }

  componentDidMount() {
    const {
      disabled, fetchExternalItems, itemsLoadState, scope
    } = this.props;
    if (!disabled && !itemsLoadState.loading && scope.id) {
      fetchExternalItems({ scopeId: scope.id });
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchExternalItems, itemsLoadState, scope } = this.props;
    if ((prevProps.scope.id !== scope.id) && !itemsLoadState.loading) {
      this.handleRemoveItem();
      if (scope.id) {
        fetchExternalItems({ scopeId: scope.id });
      }
    }
  }

  handleDropDownClose() {
    this.setState({ dropDownOpen: false });
  }

  handleRemoveItem() {
    const { onChange } = this.props;
    onChange(null);
  }

  handleSelectItem(id) {
    const { onChange } = this.props;
    onChange(id);
  }

  showDropDown() {
    this.setState({ dropDownOpen: true });
  }

  renderRelatee() {
    const { itemsLoadState: { items }, value } = this.props;
    if (value) {
      const item = items.find(({ id }) => id === value) || {};
      return (
        <li className="dx-relatee" key={item.id}>
          <p className="aux-label">{item.label}</p>
          <img
            alt="Remove"
            className="remove-btn"
            onClick={() => this.handleRemoveItem(item.id)}
            src={removeIcon}
          />
        </li>
      );
    }
    return null;
  }

  renderAddItem() {
    const { scope } = this.props;
    return (
      <li className="dx-relatee-add" key="__addItem__" onClick={scope.id ? this.showDropDown : undefined}>
        <div
          {...css({ cursor: scope.id ? 'pointer' : 'default !important' })}
          className="aux-label add-label"
        >
          {scope.id ? 'Add an item' : 'Select a scope first'}
        </div>
        <img className="add-btn" src={addIcon} alt="Add" />
      </li>
    );
  }

  renderDropDown() {
    const {
      fetchExternalItems, itemsLoadState: { items, loading }, scope, value
    } = this.props;
    return (
      <RelateeDropdown
        loadItems={fetchExternalItems}
        items={items}
        loading={loading}
        onToggle={this.handleDropDownClose}
        onSelect={this.handleSelectItem}
        keyword={scope.id}
        value={value}
      />
    );
  }

  renderActions() {
    const { value } = this.props;
    if (!value) {
      if (this.state.dropDownOpen) {
        return (
          <React.Fragment>
            {this.renderAddItem()}
            {this.renderDropDown()}
          </React.Fragment>
        );
      }
      return this.renderAddItem();
    }
    return null;
  }

  render() {
    const { scope, scopeCollection } = this.props;
    return (
      <React.Fragment>
        {scopeCollection.auth && (!scopeCollection.auth.scopes || scopeCollection.auth.scopes.length === 0)
          && (
          <p {...warningLabelStyles}>
            This is not a scoped collection,
            {scope.id}
            {' '}
            will be evaluated by activity.
          </p>
          )}
        <div
          className="dms-relatees-control"
          id={this.props.id}
          {...controlStyles}
        >
          <ul>
            {this.renderRelatee()}
            {this.renderActions()}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

ExternalItemSelect.propTypes = {
  disabled: PropTypes.bool,
  fetchExternalItems: PropTypes.func.isRequired,
  id: PropTypes.string,
  itemsLoadState: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  scope: PropTypes.shape({ id: PropTypes.string }),
  scopeCollection: PropTypes.shape({
    auth: PropTypes.shape({
      scopes: PropTypes.array,
    }),
  }),
  value: PropTypes.string,
};

const selector = formValueSelector('dms-scopedRight');

const ConnectedExternalItemSelect = connect(
  (state) => {
    const scope = selector(state, 'scope') || {};
    const value = selector(state, 'item_id');
    return {
      disabled: !scope,
      id: 'ExternalItemSelect',
      itemsLoadState: state.externalItems,
      scope,
      scopeCollection: getExternalCollection(state, scope.id),
      value,
    };
  }, {
    fetchExternalItems: fetchExternalItemsAction,
  }
)(ExternalItemSelect);

const ExternalItemSelectFFC = (props) => <FormControlFFC {...props} componentClass={ConnectedExternalItemSelect} />;

// -- DMSRelateesField Component --------------- --- --  -

const ExternalItemSelectField = (props) => {
  const {
    editMode, fieldId, fieldProps, styles, value, ...otherProps
  } = props;
  const childProps = {
    baseValue: value,
    className: 'dx-relatees-field',
    component: ExternalItemSelectFFC,
    controlId: `item-${fieldId}`,
    editMode,
    name: fieldId, // identifies the value in the form
    styles: { ...formGroupStyles, ...styles },
    ...otherProps,
    ...fieldProps,
  };
  return <Field {...childProps} />;
};

ExternalItemSelectField.propTypes = dxFormFieldViewPropTypes;

export default ExternalItemSelectField;

import isNumber from 'lodash/isNumber';
import React from 'react';
import { Field } from 'redux-form';

import { dxFormFieldViewPropTypes } from '../../propTypes';

import { TextareaFFC, textAreaControlPropTypes } from './formFieldComponents/TextareaFFC';

// -- Component --------------- ---  --  -

export class TextField extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.editMode !== nextProps.editMode;
  }

  render() {
    // console.log('[TextField] render');
    const {
      fieldId, fieldProps, fieldSchema, maxLength, minLength, value, ...otherProps
    } = this.props;
    const childProps = {
      baseValue: value,
      className: 'dx-text-field',
      component: TextareaFFC,
      controlId: `textarea-${fieldId}`,
      maxLength: isNumber(maxLength) ? maxLength : fieldSchema && fieldSchema.constraints.maxLength,
      minLength: isNumber(minLength) ? minLength : fieldSchema && fieldSchema.constraints.minLength,
      name: fieldId, // identifies the value in the form
      type: 'text',
      ...otherProps,
      ...fieldProps,
    };
    return <Field {...childProps} />;
  }
}

TextField.propTypes = {
  ...dxFormFieldViewPropTypes,
  ...textAreaControlPropTypes,
};

TextField.defaultProps = {
  editMode: false,
};

import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  isPristine, isSubmitting, isValid, reset, submit
} from 'redux-form';
import isBoolean from 'lodash/isBoolean';

import { CREATE_MODE, EDIT_MODE, VIEW_MODE } from '../../constants';
import { dxSchema } from '../../dxSchema';
import { cssPropType, itemLoadStatePropType, viewSchemaPropType } from '../../propTypes';
import { getDxAuthUser, getFormState } from '../../selectors';
import { dxStyles } from '../../styles';
import { ActionBar, ActionButton } from '../actionBar';
import { AlertSection } from '../AlertSection';
import { DxErrorDetails } from '../DxErrorDetails';
import { Section } from '../Section';
import { Spinner } from '../Spinner';

import { ItemForm } from './ItemForm';

// -- Styles --------------- --- --  -

const defaultSectionStyles = dxStyles.contentSection;

// -- Component --------------- --- --  -

/**
 * A component that renders a {@link Section} with the details of the given item using an
 * {@link ItemForm}.
 *
 * This component can be used in one three modes: _view_, _edit_ or _create_ (see the _mode_ prop).
 * The respective authorization is assert, downgrading or showing an alert message when needed.
 * When _view_ mode is given and the user is authorized to edit the item, then the _edit_ mode is
 * used instead.
 *
 * The manner is which the details are rendered can be customized with the `viewSchema` prop. See
 * {@link ItemForm} and {@link ViewSchema} for more details.
 */
class ItemDetailsComponent extends React.PureComponent {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.updateLoadState();
  }

  componentDidUpdate() {
    this.updateLoadState();
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info.componentStack);
  }

  handleCancel() {
    this.props.createCancel();
  }

  handleReset() {
    this.props.resetForm(this.props.formId);
  }

  handleSubmit() {
    this.props.submitForm(this.props.formId);
  }

  updateLoadState() {
    const {
      collectionId, createInit, itemId, loadItem, loadState, mode
    } = this.props;
    if (mode === CREATE_MODE) {
      if (createInit && !loadState.creating) {
        createInit(collectionId);
      }
    } else if (itemId && loadItem && itemId !== loadState.id) {
      loadItem();
    }
  }

  renderActionBar() {
    const {
      cancelIcon,
      createIcon,
      mode,
      pristineForm,
      resetIcon,
      saveIcon,
      submittingForm,
      validForm,
    } = this.props;
    if (mode === VIEW_MODE) {
      return null;
    }
    if (mode === EDIT_MODE) {
      return (
        <ActionBar>
          <ActionButton
            secondary
            disabled={pristineForm || submittingForm}
            icon={resetIcon}
            label="Reset"
            onClick={this.handleReset}
          />
          <ActionButton
            primary
            disabled={pristineForm || submittingForm || !validForm}
            icon={saveIcon}
            label="Save"
            onClick={this.handleSubmit}
          />
        </ActionBar>
      );
    }
    if (mode === CREATE_MODE) {
      return (
        <ActionBar>
          <ActionButton
            secondary
            disabled={submittingForm}
            icon={cancelIcon}
            label="Cancel"
            onClick={this.handleCancel}
          />
          <ActionButton
            primary
            disabled={pristineForm || submittingForm || !validForm}
            icon={createIcon}
            label="Create"
            onClick={this.handleSubmit}
          />
        </ActionBar>
      );
    }
    throw new Error(`Unexpected mode "${mode}".`);
  }

  renderSectionContent() {
    if (this.props.hasError) {
      return <DxErrorDetails error={this.props.error} />;
    }
    const { loadState, mode } = this.props;
    if (mode !== CREATE_MODE && !loadState.available) {
      return <div className="dx-spinner"><Spinner /></div>;
    }
    return (
      <React.Fragment>
        <ItemForm {...this.props} fields={mode === CREATE_MODE ? {} : loadState.item} />
        {this.renderActionBar()}
      </React.Fragment>
    );
  }

  renderSection() {
    const { sectionStyles } = this.props;
    return (
      <Section className="dx-item-details" styles={css(defaultSectionStyles, sectionStyles)}>
        {this.renderSectionContent()}
      </Section>
    );
  }

  render() {
    const {
      canCreate, canView, itemId, mode, warning
    } = this.props;
    if (mode === CREATE_MODE && !canCreate) {
      return <AlertSection error="You are not authorized to create new items for this collection." />;
    }
    if (mode === VIEW_MODE && !canView) {
      return <AlertSection error="You are not authorized to view this item." />;
    }
    if (warning) {
      return (
        <React.Fragment>
          <AlertSection error={warning} thin />
          {itemId && this.renderSection()}
        </React.Fragment>
      );
    }
    return this.renderSection();
  }
}

ItemDetailsComponent.propTypes = {
  /** Optional icon for the _Cancel_ button. */
  cancelIcon: PropTypes.element,

  /**
   * True when the user is authorized to create new items in the collection. Defaults to the
   * current user's create-rights for the collection identified with the _collectionId_ prop.
   */
  canCreate: PropTypes.bool.isRequired,

  /**
   * True when the user is authorized to edit the items in the collection. Defaults to the
   * current user's update-rights for the collection identified with the _collectionId_ prop.
   */
  canUpdate: PropTypes.bool.isRequired,

  /**
   * True when the user is authorized to view the collection. Defaults to the
   * current user's view-rights for the collection identified with the _collectionId_ prop.
   */
  canView: PropTypes.bool.isRequired,

  /**
   * The collection identifier for the given item.
   */
  collectionId: PropTypes.string.isRequired,

  /**
   * Optional function that dispatches an action when the user cancels the creation of a new item.
   * This function takes one argument: the collection ID.
   * Defaults to going back to the previous location in the router history.
   */
  createCancel: PropTypes.func,

  /**
   * Optional function that creates an action when the user cancels the creation of a new item.
   * This function takes one argument: the collection ID.
   * Defaults to going back to the previous location in the router history.
   */
  createCancelAction: PropTypes.func,

  /**
   * A function that dispatches an action to commit the new item in the store.
   * This function should take two arguments: 1) an object that contains the new item's fields,
   * and 2) the collection ID.
   * This action dispatcher should be provided when using this component in 'create' mode while
   * not providing the _createCommitAction_ prop.
   */
  createCommit: PropTypes.func,

  /**
   * A function that creates an action to commit the new item in the store.
   * This function should take two arguments: 1) an object that contains the new item's fields,
   * and 2) the collection ID.
   * This action creator should be provided when using this component in 'create' mode.
   * Alternatively, you can provide the _createCommit_ prop.
   */
  createCommitAction: PropTypes.func,

  /**
   * Optional icon for the _Create_ button.
   */
  createIcon: PropTypes.element,

  /**
   * An optional function that dispatches an action to initialize the creation of a new item. This
   * action dispatcher is called when this component is used in 'create' mode while the
   * item-load-state is not yet in "creating" mode.
   * This function takes one arguments: the ID of the collection to which the new item belongs.
   */
  createInit: PropTypes.func,

  /** The (optional) error object when _hasError_ is true. */
  error: PropTypes.object,

  /**
   * This value is used as the `form` config value for the `reduxForm(config)` decorator.
   * Defaults to a string generated based on the collection identifier and the mode.
   * @see http://redux-form.com/6.7.0/docs/api/ReduxForm.md/
   */
  formId: PropTypes.string.isRequired,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  formStyles: cssPropType,

  /** True when an error occurred. */
  hasError: PropTypes.bool,

  /** The ID of the item. Required when `mode` is `EDIT_MODE` or `CREATE_MODE`. */
  itemId: PropTypes.string,

  /**
   * Optional function that dispatches the necessary action when the item details should be
   * fetched. This function takes two argument: the item ID and the collection ID.
   * When you do not provide this function, then you should take care of loading the item (in the
   * item-load-state) whenever needed.
   * Alternatively, you can provide the _loadItemAction_ prop.
   */
  loadItem: PropTypes.func,

  /**
   * Optional function that creates the action that is dispatched when the item details should be
   * fetched. This function takes two argument: the item ID and the collection ID.
   * When you do not provide this function, then you should take care of loading the item (in the
   * item-load-state) whenever needed.
   * Alternatively, you can provide the _loadItem_ prop.
   */
  loadItemAction: PropTypes.func,

  /** To be provide. The item-load-state for the item. */
  loadState: itemLoadStatePropType.isRequired,

  /**
   * Either `VIEW_MODE` (default), `EDIT_MODE` or `CREATE_MODE` from `react-frontend/constants`.
   */
  mode: PropTypes.string,

  /** True when the form is pristine. Injected by the connect HOC. */
  pristineForm: PropTypes.bool.isRequired,

  /** Optional props to inject in relatee views. */
  relateeProps: PropTypes.object,

  /** Resets the form. Injected by the connect HOC. */
  resetForm: PropTypes.func.isRequired,

  /** Optional icon for the _Reset_ button. */
  resetIcon: PropTypes.element,

  /** Optional icon for the _Save_ button. */
  saveIcon: PropTypes.element,

  /** Optional Glamor styling. See [cssPropType](../propTypes/cssPropType). */
  sectionStyles: cssPropType,

  /** Submits the form. Injected by the connect HOC. */
  submitForm: PropTypes.func.isRequired,

  /** True when the form is being submitted. Injected by the connect HOC. */
  submittingForm: PropTypes.bool.isRequired,

  /**
   * A function that dispatches the action to commit the updated item in the store.
   * This function takes three arguments: 1) the item ID, 2) an object with the updated fields,
   * and 3) the collection ID.
   * This action dispatcher should be provided when using this component in 'edit' mode while not
   * providing the _updateCommitAction_ prop.
   */
  updateCommit: PropTypes.func,

  /**
   * A function that create the action to commit the updated item in the store.
   * This function takes three arguments: 1) the item ID, 2) an object with the updated fields,
   * and 3) the collection ID.
   * This action creator should be provided when using this component in 'edit' mode.
   * Alternatively, you can provide the _updateCommit_.
   */
  updateCommitAction: PropTypes.func,

  /** True when the form is valid. Injected by the connect HOC. */
  validForm: PropTypes.bool.isRequired,

  /**
   * Optional specification of how the item details should be rendered. Defaults to showing all
   * fields.
   * @see {@link module:components/item/ItemForm:ViewSchema}
   */
  viewSchema: viewSchemaPropType,

  /** An optional warning to show at the top of the content section. */
  warning: PropTypes.string,
};

ItemDetailsComponent.defaultProps = {
  hasError: false,
  mode: EDIT_MODE,
};

// -- Container --------------- --- --  -

export const ItemDetails = connect(
  (state, ownProps) => {
    if (ownProps.collId) {
      throw new Error('The "collId" prop for the "ItemDetails" component is no longer supported.'
        + ' Use the "collectionId" prop instead.');
    }
    let {
      canCreate, canUpdate, canView, mode = VIEW_MODE, warning
    } = ownProps;
    const { collectionId, itemId } = ownProps;

    // Assert authorisation:
    const collSchema = dxSchema.getCollection(collectionId);
    const user = getDxAuthUser(state);
    if (!isBoolean(canCreate)) { canCreate = user.canCreate(collSchema); }
    if (!isBoolean(canUpdate)) { canUpdate = user.canUpdate(collSchema, itemId); }
    if (!isBoolean(canView)) { canView = user.canView(collSchema, itemId); }

    if (mode === VIEW_MODE && canUpdate && ownProps.updateCommit) {
      mode = EDIT_MODE;
    } else if (mode === EDIT_MODE && !canUpdate) {
      mode = VIEW_MODE;
      warning = 'You are not authorized to edit this item.';
    }

    // Assert required props:
    if (mode === CREATE_MODE && !(ownProps.createCommitAction || ownProps.createCommit)) {
      throw new Error('When using create-mode then either the `createCommitAction` or '
        + '`createCommit` prop should be provided.');
    }
    if (mode === EDIT_MODE && !(ownProps.updateCommitAction || ownProps.updateCommit)) {
      throw new Error('When using edit-mode then either the `updateCommitAction` or '
        + '`updateCommit` prop should be provided.');
    }

    const formId = ownProps.formId || makeFormId(mode, collectionId);
    return {
      canCreate,
      canUpdate,
      canView,
      formId,
      mode,
      pristineForm: isPristine(formId)(state),
      submittingForm: isSubmitting(formId)(state),
      validForm: isValid(formId)(state),
      warning,
    };
  },
  (dispatch, ownProps) => {
    const { collectionId, itemId } = ownProps;
    return {
      collectionId,
      createCancel: () => {
        if (ownProps.createCancel) {
          ownProps.createCancel(collectionId);
        } else if (ownProps.createCancelAction) {
          dispatch(ownProps.createCancelAction(collectionId));
        } else {
          dispatch(goBack());
        }
      },
      createCommit: (fields) => {
        if (ownProps.createCommit) {
          ownProps.createCommit(fields, collectionId);
        } else {
          dispatch(ownProps.createCommitAction(fields, collectionId));
        }
      },
      loadItem: () => {
        if (ownProps.loadItem) {
          ownProps.loadItem(itemId, collectionId);
        } else if (ownProps.loadItemAction) {
          dispatch(ownProps.loadItemAction(itemId, collectionId));
        }
      },
      resetForm: (formId) => dispatch(reset(formId)),
      submitForm: (formId) => dispatch(submit(formId)),
      updateCommit: (id, fields) => {
        if (ownProps.updateCommit) {
          ownProps.updateCommit(id, fields, collectionId);
        } else {
          dispatch(ownProps.updateCommitAction(id, fields, collectionId));
        }
      },
    };
  },
  undefined, // use default mergeProps implementation
  {
    areOwnPropsEqual: (next, prev) => next.formId === prev.formId
        && next.itemId === prev.itemId
        && next.mode === prev.mode
        && next.loadState === prev.loadState,
    areStatesEqual: (next, prev) => getFormState(next) === getFormState(prev)
        // getFormState(next).values === getFormState(prev).values &&
        && getDxAuthUser(next) === getDxAuthUser(prev)
  },
)(ItemDetailsComponent);

// -- Local Support --------------- --- --  -

const makeFormId = (mode, collectionId) => {
  const base = dxSchema.getCollection(collectionId).singular;
  if (mode === VIEW_MODE) { return `${base}-view`; }
  if (mode === EDIT_MODE) { return `${base}-edit`; }
  if (mode === CREATE_MODE) { return `${base}-create`; }
  throw new Error(`Unexpected mode "${mode}".`);
};

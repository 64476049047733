import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { isString } from '../isString';

export const impliedByScopedActivityTypes = {
  view: ['view', 'update', 'manage'],
  update: ['update', 'manage'],
  manage: ['manage'],
};

export const authRuleToString = (rule) => {
  if (isString(rule)) {
    return rule;
  }
  if (isArray(rule)) {
    return `[${rule.map((subRule) => authRuleToString(subRule)).join(', ')}]`;
  }
  if (isObject(rule)) {
    if (rule.and) {
      return `(${rule.and.map((subRule) => authRuleToString(subRule)).join(' and ')})`;
    }
    if (rule.or) {
      return `(${rule.or.map((subRule) => authRuleToString(subRule)).join(' or ')})`;
    }

    return rule.toString();
  }

  return rule;
};
